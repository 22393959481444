import 'simplebar/dist/simplebar.min.css';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import SimpleBar from 'simplebar-react';

import { ReactComponent as ArrowLeftIcon } from '../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/arrowRight.svg';
import isMobile from '../helpers/isMobile';

interface HorizontalScrollbarProps {
    children?: ReactNode;
}

const HorizontalScrollbar = ({
    children
}: HorizontalScrollbarProps): JSX.Element => {
    const theme = useTheme();
    const ref = useRef<SimpleBar>(null);
    const [isScrolledToStart, setIsScrolledToStart] = useState<boolean>(true);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState<boolean>(false);

    const recalculateScrollPosition = useCallback(() => {
        const scrollContainer = ref.current?.getScrollElement();

        if (scrollContainer) {
            const start = scrollContainer.scrollLeft === 0;
            const end =
                Math.round(
                    scrollContainer.scrollLeft + scrollContainer.clientWidth
                ) === scrollContainer.scrollWidth;

            if (start && !isScrolledToStart) {
                setIsScrolledToStart(true);
            } else if (!start && isScrolledToStart) {
                setIsScrolledToStart(false);
            }

            if (end && !isScrolledToEnd) {
                setIsScrolledToEnd(true);
            } else if (!end && isScrolledToEnd) {
                setIsScrolledToEnd(false);
            }
        }
    }, [isScrolledToStart, isScrolledToEnd]);

    useEffect(() => {
        const scrollbarInstance = ref.current;

        recalculateScrollPosition();

        scrollbarInstance
            ?.getScrollElement()
            .addEventListener('scroll', recalculateScrollPosition);
        window.addEventListener('resize', recalculateScrollPosition);

        return () => {
            window.removeEventListener('resize', recalculateScrollPosition);
            scrollbarInstance
                ?.getScrollElement()
                .removeEventListener('scroll', recalculateScrollPosition);
        };
    }, [recalculateScrollPosition]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                '& .simplebar-track.simplebar-vertical, & .simplebar-track.simplebar-horizontal':
                    {
                        display: 'none'
                    },
                '& .simplebar-wrapper': {
                    maskImage: !isScrolledToStart
                        ? `linear-gradient(270deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 32px), transparent 100%)`
                        : 'none'
                },
                '& .simplebar-content-wrapper': {
                    maskImage: !isScrolledToEnd
                        ? `linear-gradient(90deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 32px), transparent 100%)`
                        : 'none'
                }
            }}
        >
            <SimpleBar ref={ref}>{children}</SimpleBar>

            {!isScrolledToStart && !isMobile() && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width: '32px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        const ele = ref.current?.getScrollElement();

                        if (ele) {
                            ele.scrollBy({
                                top: 0,
                                left: -200,
                                behavior: 'smooth'
                            });
                        }
                    }}
                >
                    <Button
                        sx={{
                            position: 'absolute',
                            width: '28px',
                            minWidth: '28px',
                            height: '28px',
                            p: 0,
                            borderRadius: '50%',
                            left: '7px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'baseCloud.transparent15',
                            '&:hover': {
                                backgroundColor: 'baseCloud.transparent20'
                            }
                        }}
                        variant="contained"
                        color="subtle"
                    >
                        <ArrowLeftIcon />
                    </Button>
                </Box>
            )}
            {!isScrolledToEnd && !isMobile() && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        width: '32px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        const ele = ref.current?.getScrollElement();

                        if (ele) {
                            ele.scrollBy({
                                top: 0,
                                left: 200,
                                behavior: 'smooth'
                            });
                        }
                    }}
                >
                    <Button
                        sx={{
                            position: 'absolute',
                            width: '28px',
                            minWidth: '28px',
                            height: '28px',
                            p: 0,
                            borderRadius: '50%',
                            right: '7px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'baseCloud.transparent15',
                            '&:hover': {
                                backgroundColor: 'baseCloud.transparent20'
                            }
                        }}
                        variant="contained"
                        color="subtle"
                    >
                        <ArrowRightIcon />
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default HorizontalScrollbar;
