import { gql } from '@apollo/client';

export default gql`
    fragment GameDeveloperCampaignStoreFields on GameDeveloperCampaignStoreOut {
        id
        numberOfKeys
        numberOfKeysLeft
        gameStoreId
        gameDeveloperCampaignId
        timeStamp
    }
`;
