import { toast } from 'react-toastify';

import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/error.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg';
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg';

export enum ToastVariant {
    error,
    success,
    successBottom,
    warning,
    info
}

export default (
    message: string,
    variant: ToastVariant = ToastVariant.success,
    duration = 5000
) => {
    if (variant === ToastVariant.success) {
        return toast.success(message, {
            icon: <SuccessIcon />,
            autoClose: duration
        });
    } else if (variant === ToastVariant.warning) {
        return toast.warning(message, {
            icon: <WarningIcon />,
            autoClose: duration
        });
    } else if (variant === ToastVariant.info) {
        return toast.info(message, {
            icon: <InfoIcon />,
            autoClose: duration
        });
    } else if (variant === ToastVariant.successBottom) {
        return toast.success(message, {
            icon: <CheckIcon />,
            autoClose: duration,
            position: 'bottom-center'
        });
    }

    return toast.error(message, {
        icon: <ErrorIcon />,
        autoClose: duration
    });
};
