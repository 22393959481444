import moment from 'moment';

export const startDate = moment('2023-11-27T03:17:00.000Z');
export const endDate = moment('2023-11-27T07:58:00.000Z');

export const getIsoDateMinutes = (index: number, interval = 5): Date =>
    moment(startDate)
        .add(index * interval, 'minutes')
        .toDate();

export const getIsoDateDays = (index: number): Date =>
    moment(startDate).subtract(index, 'days').toDate();
