import Box from '@mui/material/Box';
import React from 'react';
import { animated, useSpring } from 'react-spring';

interface ModalFadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => Record<string, unknown>;
    onExited?: () => Record<string, unknown>;
}

const AnimatedBox = animated(Box);

const ModalBounce = React.forwardRef<HTMLDivElement, ModalFadeProps>(
    (props, ref) => {
        const { in: open, children, onEnter, onExited, ...other } = props;

        const style = useSpring({
            from: { opacity: 0, transform: 'scale(0.85)' },
            to: {
                opacity: open ? 1 : 0,
                transform: open ? 'scale(1)' : 'scale(0.85)'
            },
            onStart: () => {
                if (open && onEnter) {
                    onEnter();
                }
            },
            onRest: () => {
                if (!open && onExited) {
                    onExited();
                }
            },
            config: {
                tension: 180,
                friction: 17,
                clamp: !open
            }
        });

        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 0
                }}
            >
                <AnimatedBox
                    ref={ref}
                    style={style}
                    {...other}
                    sx={{
                        outline: 0
                    }}
                >
                    {children}
                </AnimatedBox>
            </Box>
        );
    }
);

export default ModalBounce;
