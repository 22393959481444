import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import FeatureFlagsProvider from './components/FeatureFlagsProvider';
import GlobalStateProvider from './components/GlobalStateProvider';
import GraphqlProvider from './components/GraphqlProvider';
import UserCountryProvider from './components/UserCountryProvider';
import isSlobs from './helpers/isSlobs';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import theme from './theme';
import {
    StreamLabsDesktopApi,
    StreamLabsPlatformApi
} from './types/streamlabs';

declare global {
    interface Window {
        Streamlabs: StreamLabsPlatformApi;
        streamlabsOBS: StreamLabsDesktopApi;
        isUltra?: boolean;
    }
}

const redirectUri = `${window.location.protocol}//${window.location.hostname}${
    window?.location?.port && window.location.port !== '80'
        ? `:${window.location.port}`
        : ''
}`;

const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY as string,
    client_id: process.env.REACT_APP_IDENTITY_SERVER_CLIENT_ID as string,
    silent_redirect_uri: `${redirectUri}/auth/renew`,
    post_logout_redirect_uri: `${redirectUri}/`,
    redirect_uri: `${redirectUri}/auth/callback`,
    scope: 'openid profile StreamBeeApi StreamBeeAnalytics',
    response_type: 'code',
    automaticSilentRenew: true
};

const container = document.getElementById('root');

const streamlabs = window.Streamlabs;
const basename = isSlobs()
    ? `${window.location.pathname.split('index.html')[0]}index.html`
    : '/';

if (streamlabs) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    __webpack_public_path__ = window.location.pathname.split('index.html')[0];

    streamlabs
        .init({ receiveEvents: false, twitchChat: false })
        .then((data) => {
            // eslint-disable-next-line no-console
            console.log(
                `Streamlabs initialized. Username: ${data.profiles.twitch.display_name}`
            );

            window.isUltra = data.profiles.streamlabs.prime ?? false;
        })
        .catch((err) => {
            console.error(err); // eslint-disable-line no-console
        });
}

if (container) {
    const root = createRoot(container);

    root.render(
        <AuthProvider {...oidcConfig}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <ToastContainer
                        position="top-center"
                        limit={5}
                        closeButton={false}
                        newestOnTop
                    />
                    <GlobalStateProvider>
                        <FeatureFlagsProvider>
                            <UserCountryProvider>
                                <CssBaseline />
                                <BrowserRouter basename={basename}>
                                    <GraphqlProvider>
                                        <App />
                                    </GraphqlProvider>
                                </BrowserRouter>
                            </UserCountryProvider>
                        </FeatureFlagsProvider>
                    </GlobalStateProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </AuthProvider>
    );
}

serviceWorkerRegistration.unregister();
