import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';

import REMOVE_TENANT, {
    RemoveTenant,
    RemoveTenantVars
} from '../graphql/mutations/removeTenant';
import REMOVE_TENANT_MANAGER, {
    RemoveTenantManager,
    RemoveTenantManagerVars
} from '../graphql/mutations/removeTenantManager';
import TENANT_MANAGERS, {
    TenantManagers
} from '../graphql/queries/tenantManagers';
import USER_TENANTS, { UserTenants } from '../graphql/queries/userTenants';
import showToast, { ToastVariant } from '../helpers/showToast';
import ConfirmationModal from './ConfirmationModal';
import LoadingButton from './LoadingButton';
import { useAnalytics } from './TrackingProvider';

interface TenantCardProps {
    name: string;
    id: string;
    avatarUrl: string;
    isManager?: boolean;
}

const TenantCard = ({
    name,
    id,
    avatarUrl,
    isManager = false
}: TenantCardProps): JSX.Element => {
    const analytics = useAnalytics();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [removeTenant, { loading: removeTenantLoading }] = useMutation<
        RemoveTenant,
        RemoveTenantVars
    >(REMOVE_TENANT, {
        errorPolicy: 'ignore',
        update: (cache, mutationData) => {
            if (mutationData.data?.removeTenant.value) {
                const userTenantsData = cache.readQuery<UserTenants>({
                    query: USER_TENANTS
                });

                cache.writeQuery({
                    query: USER_TENANTS,
                    data: {
                        userTenants: {
                            tenants:
                                userTenantsData?.userTenants.tenants.filter(
                                    (item) => item.id !== id
                                )
                        }
                    }
                });

                void analytics?.track('Remove tenant success');
                showToast(`You're no longer ${name}'s manager`);
            } else {
                void analytics?.track('Remove tenant fail');
                showToast(
                    `Removing creator ${name} failed. Please try again later.`,
                    ToastVariant.error
                );
            }

            setModalOpen(false);
        }
    });

    const [removeTenantManager, { loading: removeTenantManagerLoading }] =
        useMutation<RemoveTenantManager, RemoveTenantManagerVars>(
            REMOVE_TENANT_MANAGER,
            {
                errorPolicy: 'ignore',
                update: (cache, mutationData) => {
                    if (mutationData.data?.removeTenantManager.value) {
                        const userTenantsData = cache.readQuery<TenantManagers>(
                            {
                                query: TENANT_MANAGERS
                            }
                        );

                        cache.writeQuery({
                            query: TENANT_MANAGERS,
                            data: {
                                tenantManagers: {
                                    managers:
                                        userTenantsData?.tenantManagers.managers.filter(
                                            (item) => item.userId !== id
                                        )
                                }
                            }
                        });

                        void analytics?.track('Remove tenant manager success');
                        showToast(
                            `Manager ${name} will no longer see your data.`
                        );
                    } else {
                        void analytics?.track('Remove tenant manager fail');
                        showToast(
                            `Removing your manager ${name} failed. Please try again later.`,
                            ToastVariant.error
                        );
                    }

                    setModalOpen(false);
                }
            }
        );

    const removeLabel = isManager ? 'Revoke' : 'Remove';
    const roleLabel = isManager ? 'Manager' : 'Creator';

    return (
        <>
            <Box
                sx={{
                    p: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    backgroundColor: 'baseCloud.transparent10'
                }}
            >
                <Box
                    component="img"
                    src={avatarUrl}
                    sx={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '100%',
                        mr: 4
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minWidth: 0,
                        mr: 'auto'
                    }}
                >
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: '1.6rem',
                            lineHeight: '2.4rem',
                            fontWeight: 500
                        }}
                        noWrap
                    >
                        {name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '1.4rem',
                            lineHeight: '2rem',
                            color: 'text.secondary'
                        }}
                    >
                        {roleLabel}
                    </Typography>
                </Box>
                <LoadingButton
                    isLoading={
                        removeTenantLoading || removeTenantManagerLoading
                    }
                    label={removeLabel}
                    variant="text"
                    color="error"
                    size="compact"
                    disableRipple
                    sx={{
                        flexShrink: 0,
                        ml: 4
                    }}
                    onClick={() => setModalOpen(true)}
                />
            </Box>
            <ConfirmationModal
                isOpen={modalOpen}
                setOpen={setModalOpen}
                confirmButtonLoading={
                    removeTenantLoading || removeTenantManagerLoading
                }
                confirmCallback={() => {
                    if (isManager) {
                        void removeTenantManager({
                            variables: {
                                userId: id
                            }
                        });
                    } else {
                        void removeTenant({
                            variables: {
                                tenantId: id
                            }
                        });
                    }
                }}
                title={
                    isManager
                        ? `Are you sure you want to revoke access to ${name}?`
                        : `Are you sure you want to remove ${name}?`
                }
                description={
                    isManager
                        ? 'This action is not reversible. If you want to continue sharing your data with this manager you will have to invite him again.'
                        : 'This action is not reversible and you would have to ask the owner of that account to send you a new invite.'
                }
                confirmButtonLabel={removeLabel}
            />
        </>
    );
};

export default TenantCard;
