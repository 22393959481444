import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React, { ReactNode } from 'react';

import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import Avatar from './Avatar';

export enum TagVariant {
    text,
    textSmall,
    image,
    avatar,
    icon,
    color
}

interface TagProps {
    variant?: TagVariant;
    activeColor?: string;
    color?: string;
    label: string;
    image?: string;
    icon?: ReactNode;
    description?: string;
}

const Tag = ({
    variant = TagVariant.image,
    activeColor,
    color,
    label,
    image,
    icon,
    description
}: TagProps): JSX.Element => {
    const theme = useTheme();

    let py = 2.5;
    let pl = 2;
    let pr = 8.5;

    if (variant === TagVariant.avatar) {
        py = 2.5;
        pl = 2;
        pr = 2;
    }

    if (variant === TagVariant.text) {
        pr = 2;
    }

    if (variant === TagVariant.icon) {
        pr = 3;
    }

    if (variant === TagVariant.textSmall) {
        py = 0.5;
        pr = 2;
    }

    if (variant === TagVariant.image) {
        py = 1;
        pl = 1;
        pr = 2;
    }

    if (variant === TagVariant.color) {
        py = 1;
        pl = 1;
        pr = 2;
    }

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                borderRadius: 2,
                color: 'common.white',
                fontSize: '1.4rem',
                lineHeight: '2.0rem',
                fontWeight: 500,
                textTransform: 'none',
                border: 0,
                whiteSpace: 'nowrap',
                py,
                pl,
                pr,
                '& img': {
                    boxShadow: activeColor
                        ? `0px 0px 0px 1px ${activeColor}`
                        : `none`
                },
                transition: theme.transitions.create(
                    ['background-color', 'color'],
                    {
                        duration: theme.transitions.duration.standard
                    }
                ),
                '& svg': {
                    width: '20px',
                    height: '20px',
                    mr: 2
                },
                '& svg.info': {
                    mr: 0
                },
                backgroundColor: 'baseCloud.transparent10',
                backdropFilter: ['none', undefined, theme.blurs.elevation2]
            }}
        >
            {variant === TagVariant.avatar && image && (
                <Avatar src={image} size={20} mr={2} />
            )}

            {variant === TagVariant.image && image && (
                <Box
                    component="img"
                    src={image}
                    alt={label}
                    sx={{
                        borderRadius: 1,
                        maxHeight: '32px',
                        mr: 2
                    }}
                />
            )}

            {variant === TagVariant.color && color && (
                <Box
                    sx={{
                        width: '24px',
                        height: '32px',
                        backgroundColor: color,
                        borderRadius: 1,
                        mr: 2
                    }}
                />
            )}

            {variant === TagVariant.icon && icon && icon}

            {label}

            {description && (
                <Tooltip title={description} arrow>
                    <Box
                        component={InfoIcon}
                        className="info"
                        sx={{
                            mt: '2px',
                            ml: 2,
                            flexShrink: 0,
                            '& > *': {
                                fill: theme.palette.text.secondary
                            }
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    );
};

export default Tag;
