import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';

export const portfolioManagementTabNavConfig = [
    {
        value: 'media-kits',
        label: 'Media Kits'
    },
    {
        value: 'portfolio-insights',
        label: 'Portfolio Insights',
        hasFeatureFlag: true
    }
];

function PortfolioManagement(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {!isMobile && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={portfolioManagementTabNavConfig}
                    />
                </TopMenu>
            )}

            <Outlet />
        </>
    );
}

export default PortfolioManagement;
