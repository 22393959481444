import { gql } from '@apollo/client';

import USER_SUBSCRIPTION_FIELDS from '../fragments/userSubscriptionFields';
import {
    QueryUserSubscriptionArgs,
    UserSubscription
} from '../generated/graphqlTypesAnalytics';

export interface UserSubscriptionData {
    userSubscription: UserSubscription;
}

export type UserSubscriptionVars = QueryUserSubscriptionArgs;

export default gql`
    ${USER_SUBSCRIPTION_FIELDS}
    query UserSubscription($getTenantData: Boolean!) {
        userSubscription(getTenantData: $getTenantData) {
            ...UserSubscriptionFields
        }
    }
`;
