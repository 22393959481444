import { alpha } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

import baseTheme from './baseTheme';
import MuiButton from './components/MuiButton';
import MuiCheckbox from './components/MuiCheckbox';
import MuiCssBaseline from './components/MuiCssBaseline';
import MuiFormControlLabel from './components/MuiFormControlLabel';
import MuiInputBase from './components/MuiInputBase';
import MuiLink from './components/MuiLink';
import MuiRadio from './components/MuiRadio';
import MuiSlider from './components/MuiSlider';
import MuiSwitch from './components/MuiSwitch';
import MuiTab from './components/MuiTab';
import MuiTabs from './components/MuiTabs';
import MuiTooltip from './components/MuiTooltip';
import typography from './typography';

declare module '@mui/material/styles' {
    interface Theme {
        blurs: {
            none: string;
            lightest: string;
            elevation0: string;
            elevation1: string;
            elevation2: string;
            elevation3: string;
        };
        gradients: {
            product20Horizontal: string;
            product20Vertical: string;
            product20Radial: string;
            premium100Horizontal: string;
        };
        menuWidth: string;
        mobileMenuHeight: string;
    }

    interface Easing {
        easeOutBack: string;
    }

    interface ThemeOptions {
        blurs?: {
            none?: string;
            lightest?: string;
            elevation0?: string;
            elevation1?: string;
            elevation2?: string;
            elevation3?: string;
        };
        gradients?: {
            product20Horizontal?: string;
            product20Vertical?: string;
            product20Radial?: string;
            premium100Horizontal?: string;
        };
        menuWidth?: string;
        mobileMenuHeight?: string;
    }
}

const theme = createTheme(baseTheme, {
    components: {
        MuiCssBaseline,
        MuiTab,
        MuiTabs,
        MuiSlider,
        MuiLink,
        MuiButton,
        MuiRadio,
        MuiCheckbox,
        MuiSwitch,
        MuiFormControlLabel,
        MuiTooltip,
        MuiInputBase
    },
    typography,
    zIndex: {
        ...baseTheme.zIndex,
        demoTooltip: 1501,
        demoTooltipBackdrop: 1500,
        modal: 1400,
        select: 1400,
        modalConfetti: 1401,
        scrollbarShadow: 1401,
        tabNavModalClosed: 1401,
        tabNavModalOpen: 1300,
        mobileHeader: 1300,
        mobileTopMenu: 1299,
        mobileMenu: 1301,
        vodReviewEvent: 100,
        belowMainChart: 1287,
        mainChart: 1288,
        aboveMainChart: 1289,
        phoneFlipOverlay: 1290,
        tooltip: 1400,
        playerOverlay: 1000
    },
    transitions: {
        ...baseTheme.transitions,
        duration: {
            ...baseTheme.transitions.duration,
            standard: 333
        },
        easing: {
            ...baseTheme.transitions.easing,
            easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)'
        }
    },
    gradients: {
        product20Horizontal: `linear-gradient(90deg, ${
            baseTheme.palette.accentYellow.transparent20 ?? ''
        } 0%, transparent 100%)`,
        product20Vertical: `linear-gradient(180deg, ${
            baseTheme.palette.accentYellow.transparent20 ?? ''
        } 0%, transparent 100%)`,
        product20Radial: `radial-gradient(50% 50% at 50% 0%, ${
            baseTheme.palette.accentYellow.transparent30 ?? ''
        } 0%, transparent 100%)`,
        premium100Horizontal: `linear-gradient(90deg, ${
            baseTheme.palette.accentYellow.transparent15 ?? ''
        } 0%, ${baseTheme.palette.baseCloud.transparent10 ?? ''} 100%)`
    },
    shadows: [
        `0px 2px 4px -1px ${alpha(
            baseTheme.palette.common.black,
            0.12
        )}, 0px 4px 6px -1px ${alpha(baseTheme.palette.common.black, 0.2)}`,
        `0px 4px 6px -2px ${alpha(
            baseTheme.palette.common.black,
            0.1
        )}, 0px 10px 15px -3px ${alpha(baseTheme.palette.common.black, 0.2)}`,
        `0px 10px 10px -5px ${alpha(
            baseTheme.palette.common.black,
            0.16
        )}, 0px 20px 25px -5px ${alpha(baseTheme.palette.common.black, 0.4)}`,
        `0px 12px 18px ${alpha(
            baseTheme.palette.common.black,
            0.25
        )}, 0px 30px 45px ${alpha(baseTheme.palette.common.black, 0.4)}`,
        ...baseTheme.shadows
    ]
});

// console.log(theme);

export default theme;
