import { TwitchGame } from '../generated/graphqlTypesAnalytics';

const playedGames = [
    {
        twitchGameId: '6992c4d9-9719-43ef-92c2-4fc3deed79af',
        name: 'Valorant',
        imageUrl:
            'https://static-cdn.jtvnw.net/ttv-boxart/516575-{width}x{height}.jpg',
        dateTimeStart: new Date('2023-11-27T03:17:58.000Z'),
        dateTimeEnd: new Date('2023-11-27T04:32:21.153Z'),
        length: 4463,
        playedTimePercentageShare: 26.522850181256313
    },
    {
        twitchGameId: 'da76879d-e0ff-43c0-b1fc-4b9e3e3f97e8',
        name: 'Osu!',
        imageUrl:
            'https://static-cdn.jtvnw.net/ttv-boxart/21465_IGDB-{width}x{height}.jpg',
        dateTimeStart: new Date('2023-11-27T04:32:21.153Z'),
        dateTimeEnd: new Date('2023-11-27T05:13:59.482Z'),
        length: 2498,
        playedTimePercentageShare: 14.845189279134724
    },
    {
        twitchGameId: '3a5c264e-1240-459c-8267-a7abd523ba0c',
        name: 'Genshin Impact',
        imageUrl:
            'https://static-cdn.jtvnw.net/ttv-boxart/513181_IGDB-{width}x{height}.jpg',
        dateTimeStart: new Date('2023-11-27T05:13:59.482Z'),
        dateTimeEnd: new Date('2023-11-27T07:58:25.000Z'),
        length: 9865,
        playedTimePercentageShare: 58.62601770963333
    }
];

export const getGame = (
    index: number,
    length: number
): TwitchGame | undefined => {
    let output: TwitchGame | undefined;
    let played = 0;
    const indexPercentage = (index / length) * 100;

    playedGames.forEach((game) => {
        played += game.playedTimePercentageShare;

        if (indexPercentage < played && output === undefined) {
            output = {
                ...game,
                id: game?.twitchGameId ?? ''
            };
        }
    });

    return output;
};

export default playedGames;
