import baseTheme from '../baseTheme';

const MuiLink = {
    defaultProps: {
        color: baseTheme.palette.accentYellow.dark
    },
    styleOverrides: {
        root: {
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }
};

export default MuiLink;
