import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { ReactComponent as ChestIcon } from '../assets/icons/chest.svg';
import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg';
import { ReactComponent as DollarIcon } from '../assets/icons/dollar.svg';
import { ReactComponent as GamePadIcon } from '../assets/icons/gamepad.svg';
import { ReactComponent as GrowthIcon } from '../assets/icons/growth.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as KeyIcon } from '../assets/icons/key.svg';
import { ReactComponent as StarOutlineIcon } from '../assets/icons/starOutline.svg';
import { ReactComponent as StreamsIcon } from '../assets/icons/streams.svg';
import { ReactComponent as SuitcaseIcon } from '../assets/icons/suitcase.svg';
import { ReactComponent as ToolKitIcon } from '../assets/icons/toolKit.svg';
import { ReactComponent as TvIcon } from '../assets/icons/tv.svg';
import { DEMO_ID } from '../configs';
import GAME_DEVELOPER_CAMPAIGNS from '../graphql/queries/gameDeveloperCampaigns';
import { GameDeveloperCampaignsData } from '../graphql/queries/gameDeveloperCampaigns';
import LAST_STREAM, { LastStreamData } from '../graphql/queries/lastStream';
import isSlobs from '../helpers/isSlobs';
import {
    gameDeveloperLandingPageDataKey,
    storageGet,
    StorageType
} from '../helpers/storage';
import { accountManagementTabNavConfig } from '../modules/AccountManagement';
import { gameKeysTabNavConfig } from '../modules/GameKeys/index';
import { growthTabNavConfig } from '../modules/Growth';
import { monetizationInsightsTabNavConfig } from '../modules/MonetizationInsights';
import { monetizationToolsTabNavConfig } from '../modules/MonetizationTools';
import { portfolioManagementTabNavConfig } from '../modules/PortfolioManagement';
import { streamTabNavConfig } from '../modules/Stream';
import { whatToStreamTabNavConfig } from '../modules/WhatToStream';
import { whenToStreamTabNavConfig } from '../modules/WhenToStream';
import {
    accountManagement,
    buyCredits,
    campaignAnalytics,
    campaigns,
    gameKeys,
    growthRoute,
    homeRoute,
    marketingServices,
    monetizationInsights,
    monetizationToolsRoute,
    portfolioManagement,
    streamRoute,
    streamsRoute,
    whatToStream,
    whenToStream
} from '../routes';
import { GameDeveloperLandingPageData, TabNavConfig } from '../types';
import useAuth from './useAuth';
import useFeatureFlags from './useFeatureFlags';
import useGameKeys from './useGameKeys';
import useUserProfile from './useUserProfile';

export interface MenuItem {
    title: string;
    route?: string;
    href?: string;
    loading?: boolean;
    notificationDotColor?: string;
    featureFlag?: string;
    activePaths?: string[];
    omittedPaths?: (string | undefined)[];
    isPremium?: boolean;
    isAlpha?: boolean;
    isDemo?: boolean;
    icon?: ReactNode;
    items?: TabNavConfig[];
    numberBadgeValue?: number;
}

export default (isPremium = false): MenuItem[] | null => {
    const theme = useTheme();
    const auth = useAuth();
    const { isEnabled } = useFeatureFlags();
    const profile = auth?.profile;
    const isTenant = !!profile?.tenant_name;
    const { loading: lastStreamLoading, data: lastStreamData } =
        useQuery<LastStreamData>(LAST_STREAM);
    const { isBroadcasterView, loading: userProfileLoading } = useUserProfile();
    const [
        gameDeveloperCampaignLocalStorageData,
        setGameDeveloperCampaignLocalStorageData
    ] = useState<GameDeveloperLandingPageData>();

    const {
        loading: gameDeveloperCampaignsDataLoading,
        data: gameDeveloperCampaignsData
    } = useQuery<GameDeveloperCampaignsData>(GAME_DEVELOPER_CAMPAIGNS, {
        skip: isBroadcasterView
    });

    const {
        availableGameKeys,
        myGameKeys,
        loading: gameKeysLoading
    } = useGameKeys(
        {
            showMyKeys: true
        },
        !isBroadcasterView && isTenant
    );

    const gameKeysTabNavConfigWithKeysCounter = useMemo(() => {
        const config = gameKeysTabNavConfig.map((item) => {
            if (item.value === 'get-free-games') {
                return {
                    ...item,
                    numberBadgeValue: availableGameKeys?.length
                };
            } else if (item.value === 'my-games') {
                return {
                    ...item,
                    numberBadgeValue: myGameKeys?.length
                };
            }

            return item;
        });

        if (gameDeveloperCampaignLocalStorageData?.gameName && config[2]) {
            config[2] = {
                value: 'key-campaign',
                label: `Key Campaign - ${gameDeveloperCampaignLocalStorageData.gameName}`
            };
        } else {
            config.splice(2, 1);
        }

        return config;
    }, [availableGameKeys, myGameKeys, gameDeveloperCampaignLocalStorageData]);
    const showGameKeys =
        (availableGameKeys?.length ?? 0) > 0 || (myGameKeys?.length ?? 0) > 0;

    const broadcasterMenuItems = useMemo(() => {
        if (
            lastStreamLoading ||
            userProfileLoading ||
            gameKeysLoading ||
            !lastStreamData
        ) {
            return null;
        }

        return [
            ...(lastStreamData?.lastStream
                ? [
                      {
                          title: 'Home',
                          route: homeRoute(),
                          icon: <HomeIcon />,
                          loading: lastStreamLoading,
                          activePaths: [homeRoute()]
                      }
                  ]
                : []),
            ...(!isTenant && showGameKeys
                ? [
                      {
                          title: 'Free games',
                          featureFlag: 'app.menu.gameKeys',
                          route: gameKeys(),
                          icon: <ChestIcon />,
                          activePaths: [gameKeys()],
                          items: gameKeysTabNavConfigWithKeysCounter,
                          isAlpha: true,
                          notificationDotColor: theme.palette.accentOrange.dark
                      }
                  ]
                : []),
            {
                title: 'Last stream',
                route: streamRoute(lastStreamData?.lastStream?.id ?? DEMO_ID),
                icon: <StreamsIcon />,
                loading: lastStreamLoading,
                activePaths: [lastStreamData?.lastStream?.id ?? DEMO_ID],
                items: streamTabNavConfig
            },
            {
                title: 'Past streams',
                route: streamsRoute(),
                icon: <TvIcon />,
                activePaths: [streamsRoute(), `${streamRoute()}/`],
                omittedPaths: [lastStreamData?.lastStream?.id, DEMO_ID]
            },
            {
                title: 'Growth',
                route: growthRoute(),
                icon: <GrowthIcon />,
                activePaths: [growthRoute()],
                items: growthTabNavConfig,
                notificationDotColor: theme.palette.accentPink.dark
            },
            {
                title: 'What to Stream',
                route: whatToStream(),
                icon: <GamePadIcon />,
                activePaths: [whatToStream()],
                items: isEnabled('app.tabs.discover-new-games', true)
                    ? whatToStreamTabNavConfig
                    : undefined,
                notificationDotColor: theme.palette.accentCyan.dark
            },
            {
                title: 'When to Stream',
                route: whenToStream(),
                icon: <ClockIcon />,
                activePaths: [whenToStream()],
                items: whenToStreamTabNavConfig,
                notificationDotColor: theme.palette.accentYellow.dark
            },
            {
                title: 'Monetization insights',
                route: monetizationInsights(),
                featureFlag: 'app.menu.monetizationInsights',
                icon: <DollarIcon />,
                activePaths: [monetizationInsights()],
                items: monetizationInsightsTabNavConfig
            },
            {
                title: 'Portfolio Management',
                route: portfolioManagement(),
                featureFlag: 'app.menu.portfolioManagement',
                icon: <SuitcaseIcon />,
                activePaths: [portfolioManagement()],
                items: portfolioManagementTabNavConfig
            },
            ...(!isSlobs()
                ? [
                      {
                          title: 'Streamer Toolkit',
                          route: monetizationToolsRoute(),
                          icon: <ToolKitIcon />,
                          activePaths: [monetizationToolsRoute()],
                          items: monetizationToolsTabNavConfig
                      }
                  ]
                : []),
            ...(!isPremium
                ? [
                      {
                          title: 'Upgrade',
                          route: `${accountManagement()}/${
                              accountManagementTabNavConfig[3].value
                          }`,
                          icon: <StarOutlineIcon />,
                          activePaths: [accountManagement()],
                          isPremium: true
                      }
                  ]
                : [])
        ];
    }, [
        lastStreamLoading,
        lastStreamData,
        userProfileLoading,
        gameKeysLoading,
        gameKeysTabNavConfigWithKeysCounter,
        isPremium,
        isTenant,
        theme,
        isEnabled,
        showGameKeys
    ]);

    const developerMenuItems = useMemo(() => {
        if (gameDeveloperCampaignsDataLoading) {
            return null;
        }

        const campaignsWithStreams =
            gameDeveloperCampaignsData?.gameDeveloperCampaigns?.filter(
                (item) => item.hoursStreamed > 0
            );

        return [
            {
                title: 'Campaigns',
                route: campaigns(),
                icon: <HomeIcon />,
                activePaths: [campaigns()]
            },
            {
                title: 'Get more credits',
                route: buyCredits(),
                icon: <KeyIcon />,
                activePaths: [buyCredits()]
            },
            ...(gameDeveloperCampaignsData?.gameDeveloperCampaigns.length ===
                0 || campaignsWithStreams?.length === 0
                ? [
                      {
                          title: 'Analytics',
                          route: campaignAnalytics(DEMO_ID),
                          icon: <GrowthIcon />,
                          activePaths: [DEMO_ID],
                          isDemo: true
                      }
                  ]
                : []),
            {
                title: 'Marketing Services',
                route: marketingServices(),
                icon: <GamePadIcon />,
                activePaths: [marketingServices()]
            }
        ];
    }, [gameDeveloperCampaignsDataLoading, gameDeveloperCampaignsData]);

    useEffect(() => {
        const handleStorage = async () => {
            const localStorageData =
                await storageGet<GameDeveloperLandingPageData>(
                    gameDeveloperLandingPageDataKey,
                    StorageType.local
                );

            setGameDeveloperCampaignLocalStorageData(localStorageData);
        };

        void handleStorage();
    }, []);

    return isBroadcasterView ? broadcasterMenuItems : developerMenuItems;
};
