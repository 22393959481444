import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactNode, SetStateAction } from 'react';

import LoadingButton from './LoadingButton';
import Modal from './Modal';

interface ConfirmationModalProps {
    isOpen: boolean;
    setOpen: (value: SetStateAction<boolean>) => void;
    confirmCallback?: () => void;
    rejectCallback?: () => void;
    title?: string;
    description?: string | ReactNode;
    confirmButtonLabel?: string;
    confirmButtonLoading?: boolean;
    confirmButtonColor?: 'primary' | 'error' | 'subtle' | 'errorSubtle';
    confirmButtonVariant?: 'contained' | 'text';
    rejectButtonLabel?: string;
    rejectButtonLoading?: boolean;
    rejectButtonColor?: 'primary' | 'error' | 'subtle' | 'errorSubtle';
    rejectButtonVariant?: 'contained' | 'text';
    width?: number;
    hasCloseButton?: boolean;
}

const ConfirmationModal = ({
    isOpen,
    setOpen,
    confirmCallback,
    rejectCallback,
    title,
    description,
    confirmButtonLoading = false,
    confirmButtonLabel = 'Remove',
    confirmButtonColor = 'error',
    confirmButtonVariant = 'contained',
    rejectButtonLoading = false,
    rejectButtonLabel = 'Cancel',
    rejectButtonColor = 'subtle',
    rejectButtonVariant = 'contained',
    width = 520,
    hasCloseButton = false
}: ConfirmationModalProps): JSX.Element => (
    <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        hasCloseButton={hasCloseButton}
        width={`${width}px`}
        height="auto"
    >
        <Box
            sx={{
                pr: 17
            }}
        >
            {title && (
                <Typography
                    sx={{
                        fontSize: '2.8rem',
                        lineHeight: '3.2rem',
                        fontWeight: 600,
                        mb: 2
                    }}
                >
                    {title}
                </Typography>
            )}
            {description && (
                <Typography
                    sx={{
                        fontSize: '1.4rem',
                        lineHeight: '2rem',
                        mt: 1,
                        color: 'text.secondary'
                    }}
                >
                    {description}
                </Typography>
            )}
        </Box>

        <Box
            sx={{
                mt: 17,
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                gap: 4
            }}
        >
            <LoadingButton
                variant={rejectButtonVariant}
                color={rejectButtonColor}
                size="large"
                isLoading={rejectButtonLoading}
                label={rejectButtonLabel}
                sx={{
                    flexShrink: 0
                }}
                onClick={() => {
                    if (rejectCallback) {
                        rejectCallback();
                    } else {
                        setOpen(false);
                    }
                }}
            />
            <LoadingButton
                variant={confirmButtonVariant}
                color={confirmButtonColor}
                size="large"
                isLoading={confirmButtonLoading}
                label={confirmButtonLabel}
                sx={{
                    flexShrink: 0
                }}
                onClick={() => {
                    if (confirmCallback) {
                        confirmCallback();
                    } else {
                        setOpen(false);
                    }
                }}
            />
        </Box>
    </Modal>
);

export default ConfirmationModal;
