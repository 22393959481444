import { gql } from '@apollo/client';

import USER_TENANT_FIELDS from '../fragments/userTenantFields';
import { UserTenant } from '../generated/graphqlTypesAnalytics';

export interface UserTenants {
    userTenants: {
        tenants: UserTenant[];
    };
}

export default gql`
    ${USER_TENANT_FIELDS}
    query UserTenants {
        userTenants {
            tenants {
                ...UserTenantFields
            }
        }
    }
`;
