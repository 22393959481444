import { gql } from '@apollo/client';

import {
    QueryStreamChannelDataArgs,
    StreamChannelDataResult
} from '../generated/graphqlTypesAnalytics';

export interface StreamChannelData {
    streamChannelData: StreamChannelDataResult;
}

export type StreamChannelDataVars = QueryStreamChannelDataArgs;

export default gql`
    query StreamChannelData($streamDataId: UUID!) {
        streamChannelData(streamDataId: $streamDataId) {
            streamDataTwitchGames {
                twitchGameId
                name
                imageUrl
                dateTimeStart
                dateTimeEnd
                length
                playedTimePercentageShare
            }
            streamDataTwitchTitles {
                title
                length
            }
            streamDataTwitchTags {
                twitchTag {
                    name
                }
            }
        }
    }
`;
