import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ResponsiveValue } from '../../types';
import Avatar from '../Avatar';

interface ProfileItemProps {
    width?: ResponsiveValue;
    avatarUrl?: string;
    name: string;
    isMe?: boolean;
    isCurrentUser?: boolean;
    isCurrentUserButton?: boolean;
}

const ProfileItem = ({
    width = '100%',
    avatarUrl,
    name,
    isMe = false,
    isCurrentUser = false,
    isCurrentUserButton = false
}: ProfileItemProps): JSX.Element => {
    const label = isMe ? name : `Viewing ${name}`;

    return (
        <Box
            sx={{
                width,
                display: 'flex',
                alignItems: 'center',
                minWidth: 0,
                '& svg': {
                    ml: 'auto'
                }
            }}
        >
            <Avatar src={avatarUrl} size={isCurrentUserButton ? 32 : 40} />

            <Typography
                component="span"
                className="animateOpacity"
                sx={{
                    fontSize: '1.6rem',
                    lineHeight: '2.4rem',
                    fontWeight: 500,
                    color: 'common.white',
                    maxWidth: '100%',
                    ml: 3
                }}
                noWrap
            >
                {isCurrentUserButton ? label : name}
            </Typography>

            {!isCurrentUserButton && isCurrentUser && <CheckIcon />}
        </Box>
    );
};

export default ProfileItem;
