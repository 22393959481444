import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

declare global {
    interface Window {
        changecrab_config: {
            projectid: string;
            selector: string;
            type: string;
            emptycolor: string;
            activecolor: string;
        };
    }
}

interface UserMenuItemProps {
    href?: string;
    to?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isChangelog?: boolean;
    isSwitchToManager?: boolean;
    text: string;
}

const UserMenuItem = ({
    href,
    to,
    onClick,
    isChangelog = false,
    isSwitchToManager: isTenantBack = false,
    text
}: UserMenuItemProps): JSX.Element => {
    const theme = useTheme();
    const changelogRef = useRef<HTMLDivElement>();
    const isLogout = text === 'Log out';

    useEffect(() => {
        const src = 'https://changecrab.com/embed/embed.js';
        let script = document.querySelector<HTMLScriptElement>(
            `script[src="${src}"]`
        );

        if (!script && changelogRef.current) {
            window.changecrab_config = {
                projectid: 'EuerkK',
                selector: '.changeBubble',
                type: 'full',
                emptycolor: 'rgb(175 187 207)',
                activecolor: 'rgb(232 23 71)'
            };

            script = document.createElement('script');
            script.src = src;
            document.body.appendChild(script);
        }

        return () => {
            const scriptToRemove = document.querySelector<HTMLScriptElement>(
                `script[src="${src}"]`
            );

            if (scriptToRemove) {
                document.body.removeChild(scriptToRemove);
            }
        };
    }, [changelogRef]);

    let buttonColor: 'errorSubtle' | 'subtle' | 'primary' = isLogout
        ? 'errorSubtle'
        : 'subtle';
    let buttonTextColor = isLogout ? 'accentRed.dark' : 'common.white';
    if (isTenantBack) {
        buttonColor = 'primary';
        buttonTextColor = 'common.black';
    }

    const buttonComponent = (
        <Button
            // eslint-disable-next-line no-nested-ternary
            component={to ? NavLink : href ? 'a' : 'button'}
            variant="contained"
            color={buttonColor}
            href={href && href}
            to={to && to}
            target={href ? '_blank' : '_self'}
            onClick={onClick && onClick}
            sx={{
                height: '36px',
                textTransform: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                p: 2,
                mb: !isLogout ? 2 : 0,
                borderRadius: 2
            }}
        >
            <Typography
                component="span"
                sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '1.4rem',
                    lineHeight: '2rem',
                    fontWeight: 500,
                    color: buttonTextColor,
                    transition: theme.transitions.create(['color'], {
                        duration: theme.transitions.duration.standard
                    })
                }}
            >
                {text}
            </Typography>
        </Button>
    );

    return (
        <>
            {isChangelog && (
                <Box
                    ref={changelogRef}
                    sx={{
                        position: 'relative'
                    }}
                >
                    <Box
                        className="changeBubble"
                        sx={{
                            '& > button, & > a': {
                                width: '100%'
                            },
                            '& + .crab-count': {
                                width: '12px !important',
                                height: '12px !important',
                                top: '4px !important',
                                left: '170px !important',
                                textIndent: '-999px'
                            }
                        }}
                    >
                        {buttonComponent}
                    </Box>
                </Box>
            )}
            {!isChangelog && buttonComponent}
        </>
    );
};

export default UserMenuItem;
