import {
    StreamChattersEngagement,
    StreamData,
    StreamMetricsType,
    StreamStatus,
    StreamViewersMetric
} from '../generated/graphqlTypesAnalytics';
import { getIsoDateDays } from './helpers';
import playedGames from './playedGames';
import { streamTitles } from './streamChannelData';

export const mainValues = {
    tier1SubscribersGained: [10, 15, 13, 12, 6, 6, 7, 67, 56, 5],
    tier2SubscribersGained: [14, 35, 23, 23, 5, 6, 23, 13, 14, 0],
    tier3SubscribersGained: [1, 5, 2, 4, 2, 2, 4, 3, 3, 0],
    performanceIndicator: [
        0.69, 0.43, 0.54, 0.23, 0.76, 0, 0, 0.12, 0.34, 1.3558386830889422
    ],
    performanceIndicatorV2: [6, 4, 5, 2, 7, 0, 0, 1, 3, 9],
    performanceIndicatorV2Aggregated: [6, 4, 5, 2, 7, 0, 0, 1, 3, 9],
    chatRank: [5, 4, 5, 2, 7, 0, 0, 1, 3, 9],
    chattersRank: [4, 4, 5, 2, 7, 0, 0, 1, 3, 10],
    viewersRank: [6, 4, 5, 2, 7, 0, 0, 1, 3, 10],
    followersRank: [7, 4, 5, 2, 7, 0, 0, 1, 3, 9],
    dropRate: [0.43, 0, 0.23, 0, 0.12, 0.23, 0.15, 0.16, 0, 0],
    length: [2345, 3456, 5643, 976, 678, 1235, 1234, 3458, 98, 16827],
    interactions: [1234, 3456, 5674, 3456, 4568, 2345, 5432, 3456, 6543, 1252],
    channelPointsRedeemed: [
        9865, 345, 765, 3456, 2345, 6543, 2346, 6543, 4568, 6101
    ],
    predictionsInteractions: [1, 3, 0, 0, 5, 0, 1, 0, 1, 0],
    pollsInteractions: [2, 0, 0, 1, 0, 2, 3, 0, 1, 0],
    emotesUsed: [345, 126, 432, 654, 123, 76, 43, 23, 456, 380],
    activeChatters: [87, 5, 12, 65, 43, 65, 12, 23, 35, 97],
    clipsMade: [34, 13, 23, 65, 45, 23, 43, 26, 32, 1],
    followersGained: [54, 567, 234, 234, 556, 64, 75, 43, 56, 7],
    giftedSubscribersGained: [1, 4, 6, 7, 34, 5, 6, 4, 6, 4],
    pollCount: [0, 2, 0, 0, 3, 3, 0, 2, 0, 0],
    predictionCount: [0, 2, 23, 0, 5, 0, 2, 0, 1, 0],
    primeSubscribersGained: [2, 4, 0, 5, 0, 6, 0, 65, 45, 1],
    subscribersGained: [54, 45, 43, 34, 10, 15, 25, 53, 42, 5],
    totalWordCount: [
        23425, 32423, 5646, 45665, 45645, 43232, 56456, 12342, 23423, 5853
    ],
    averagePositionInDirectory: [
        23, 2, 1, 45, 23, 2, 3, 5, 3, 87.24137931034483
    ],
    averagePositionInDirectoryByLanguage: [
        1, 3, 5, 23, 1, 1, 11, 3, 3, 66.3103448275862
    ],
    raidsCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    averageTimeToFollow: [234, 124, 232, 345, 456, 321, 345, 553, 432, 300],
    newFollowers: [23, 34, 345, 54, 56, 45, 45, 34, 34, 6],
    bitsUsed: [578, 534, 677, 45, 37, 45, 234, 564, 78, 0],
    giftedSubscribersLost: [2, 5, 0, 4, 0, 1, 2, 0, 4, 0],
    primeSubscribersLost: [5, 3, 3, 3, 0, 12, 0, 5, 0, 0],
    subscribersLost: [5, 0, 1, 0, 3, 0, 1, 1, 0, 0],
    subscriptionPointsGained: [4, 3, 4, 3, 0, 4, 0, 5, 0, 5],
    subscriptionPointsLost: [4, 2, 0, 2, 3, 0, 3, 0, 3, 0],
    tier1GiftedSubscribersGained: [3, 2, 0, 0, 1, 4, 0, 5, 0, 4],
    tier1GiftedSubscribersLost: [5, 1, 0, 3, 0, 4, 0, 6, 1, 3],
    tier1SubscribersLost: [5, 2, 0, 3, 4, 0, 1, 1, 5, 0],
    tier2GiftedSubscribersGained: [3, 2, 0, 1, 0, 2, 0, 4, 0, 0],
    tier2GiftedSubscribersLost: [6, 0, 0, 4, 1, 0, 2, 3, 0, 0],
    tier2SubscribersLost: [6, 1, 0, 1, 2, 0, 5, 0, 2, 0],
    tier3GiftedSubscribersGained: [7, 2, 0, 0, 0, 0, 0, 4, 1, 0],
    tier3GiftedSubscribersLost: [3, 4, 0, 3, 0, 4, 2, 1, 2, 0],
    tier3SubscribersLost: [1, 0, 0, 4, 0, 2, 3, 1, 2, 0],
    newSubscriptions: [5, 0, 2, 0, 1, 1, 2, 4, 0, 4],
    renewedSubscriptions: [7, 3, 0, 2, 0, 1, 2, 5, 0, 1]
};

export const streamChattersEngagementsValues = {
    superChatters: [2, 0, 3, 1, 0, 2, 0, 3, 0, 1],
    engagedChatters: [3, 0, 3, 0, 1, 0, 2, 0, 0, 32],
    casualChatters: [4, 0, 0, 6, 2, 0, 3, 2, 0, 45],
    singleMessageChatters: [23, 345, 456, 123, 345, 456, 123, 454, 76, 19],
    lurkers: [34, 56, 23, 43, 56, 34, 34, 34, 45, 115],
    chatMessages: [1234, 3435, 3455, 5667, 3456, 1234, 7654, 3453, 7896, 1234],
    multiMessageChatters: [56, 78, 22, 43, 76, 32, 45, 0, 65, 35]
};

export const streamViewersMetricsValues = {
    regularViewers: [452, 163, 213, 232, 651, 453, 872, 353, 322, 38],
    casualViewers: [156, 324, 156, 432, 453, 675, 126, 234, 456, 59],
    oneStreamViewers: [123, 432, 643, 421, 234, 532, 77, 175, 364, 0],
    zeroStreamViewers: [90, 421, 79, 300, 269, 300, 69, 169, 421, 0],
    newViewers: [45, 45, 65, 34, 56, 65, 28, 78, 47, 56],
    estimatedUniqueViewers: [
        564, 345, 213, 456, 564, 332, 875, 567, 567, 174.08470588235295
    ],
    activeViewersRatio: [
        0.45, 0.23, 0.12, 0.43, 0.24, 0.54, 0.12, 0.3, 0.32, 0.12066342210844043
    ],
    views: [4857, 345, 643, 4567, 3421, 5432, 1234, 3215, 4312, 0],
    returningViewers: [43, 45, 23, 45, 75, 34, 22, 34, 56, 58],
    averageWatchtime: [
        2344, 3421, 4564, 3476, 1265, 2346, 3463, 4321, 356, 91.1244019138756
    ],
    hoursWatched: [345, 87, 45, 566, 653, 34, 68, 78, 24, 265.5],
    averageViewers: [453, 45, 567, 678, 456, 543, 543, 236, 673, 55],
    peakViewers: [347, 547, 523, 628, 452, 541, 783, 452, 134, 81]
};

type CustomStreamData = Omit<
    StreamData,
    | 'streamChattersEngagements'
    | 'streamViewersMetrics'
    | 'id'
    | 'version'
    | 'userProfileId'
    | 'dateTimeStart'
    | 'streamDataProcessingStatus'
    | 'streamChatProcessingStatus'
>;

export const getStreamDataValues = (index: number) => {
    const parsedMainValues: Record<string, number> = {};
    const parsedStreamChattersEngagementsValues: Record<string, unknown> = {};
    const parsedStreamViewersMetricsValues: Record<string, unknown> = {};

    Object.keys(mainValues).forEach((key) => {
        parsedMainValues[key] =
            mainValues[key as keyof typeof mainValues][index];
    });

    Object.keys(streamChattersEngagementsValues).forEach((key) => {
        parsedStreamChattersEngagementsValues[key] =
            streamChattersEngagementsValues[
                key as keyof typeof streamChattersEngagementsValues
            ][index];
    });

    Object.keys(streamViewersMetricsValues).forEach((key) => {
        parsedStreamViewersMetricsValues[key] =
            streamViewersMetricsValues[
                key as keyof typeof streamViewersMetricsValues
            ][index];
    });

    const output = {
        ...(parsedMainValues as unknown as CustomStreamData),
        streamChattersEngagements: [
            {
                ...parsedStreamChattersEngagementsValues,
                streamMetricsType: StreamMetricsType.All
            } as StreamChattersEngagement
        ],
        streamViewersMetrics: [
            {
                ...parsedStreamViewersMetricsValues,
                streamMetricsType: StreamMetricsType.All
            } as StreamViewersMetric,
            {
                ...parsedStreamViewersMetricsValues,
                streamMetricsType: StreamMetricsType.Subscribers
            } as StreamViewersMetric
        ]
    };

    return output;
};

const data: StreamData[] = [];
for (let index = 0; index < 10; index++) {
    const streamDataValues = getStreamDataValues(index);

    data.push({
        id: `8c0519d5-82b5-4888-bfe8-1aba3e27c3f9${index}`,
        version: 1,
        userProfileId: 'abc',
        dateTimeStart: getIsoDateDays(index),
        streamDataTwitchTitles: streamTitles,
        streamDataTwitchGames: playedGames,
        streamDataProcessingStatus: StreamStatus.Finished,
        streamChatProcessingStatus: StreamStatus.Finished,
        ...streamDataValues
    });
}

export default {
    searchStreamData: {
        pageNumber: 1,
        totalPages: 1,
        data
    }
};
