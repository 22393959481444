import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AddManagerCard from '../../components/AddManagerCard';
import Grid from '../../components/Grid';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import PremiumBanner from '../../components/PremiumBanner';
import SEO from '../../components/Seo';
import TenantCard from '../../components/TenantCard';
import TENANT_MANAGERS from '../../graphql/queries/tenantManagers';
import { TenantManagers } from '../../graphql/queries/tenantManagers';
import isSlobs from '../../helpers/isSlobs';
import useTenants from '../../hooks/useTenants';
import useUserSubscription from '../../hooks/useUserSubscription';

function TeamTab(): JSX.Element {
    const {
        hasPremium,
        hasProPlan,
        isLoading: isPremiumLoading
    } = useUserSubscription();
    const { allTenants, loading } = useTenants();

    const { loading: tenantManagersDataLoading, data: tenantManagersData } =
        useQuery<TenantManagers>(TENANT_MANAGERS, {});

    const managers = tenantManagersData?.tenantManagers.managers;

    return (
        <>
            <SEO title="Account management" />
            <Box
                sx={{
                    mt: [18, undefined, 0]
                }}
            >
                <Layout>
                    {!isPremiumLoading && !hasProPlan && !isSlobs() && (
                        <PremiumBanner
                            title="Get PRO to connect with your team"
                            description="Upgrade to PRO plan to give full access to all your StreamBee data to your moderators, editors or the manager."
                            buttonText="Discover PRO"
                        />
                    )}
                    {!isPremiumLoading &&
                        hasPremium &&
                        hasProPlan &&
                        !isSlobs() && (
                            <>
                                <Box
                                    sx={{
                                        mt: 10,
                                        pb: 10,
                                        borderBottom: '1px solid',
                                        borderColor: 'baseCloud.transparent10'
                                    }}
                                >
                                    <Typography variant="h2">
                                        Your managers
                                    </Typography>
                                    <Box
                                        sx={{
                                            mt: 6
                                        }}
                                    >
                                        {tenantManagersDataLoading && (
                                            <Loader height="100px" />
                                        )}
                                        {!tenantManagersDataLoading && (
                                            <Grid height="80px">
                                                {managers &&
                                                    managers.length > 0 &&
                                                    managers
                                                        .filter(
                                                            (item) =>
                                                                !item.name?.includes(
                                                                    'tenanttester'
                                                                )
                                                        )
                                                        .map((item, i) => (
                                                            <TenantCard
                                                                key={`${
                                                                    item.name ??
                                                                    ''
                                                                }${i}`}
                                                                isManager
                                                                name={
                                                                    item.name ??
                                                                    ''
                                                                }
                                                                id={
                                                                    item.userId ??
                                                                    ''
                                                                }
                                                                avatarUrl={
                                                                    item.profileImageUrl ??
                                                                    ''
                                                                }
                                                            />
                                                        ))}
                                                <AddManagerCard />
                                            </Grid>
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}
                    {loading && <Loader height="100px" />}
                    {!loading && !isSlobs() && allTenants && (
                        <Box
                            sx={{
                                pb: 10,
                                borderBottom: '1px solid',
                                borderColor: 'baseCloud.transparent10'
                            }}
                        >
                            <Typography variant="h2">Your creators</Typography>
                            <>
                                {allTenants.length > 0 && (
                                    <Box
                                        sx={{
                                            mt: 6
                                        }}
                                    >
                                        <Grid height="80px">
                                            {allTenants.map((item, i) => (
                                                <TenantCard
                                                    key={`${
                                                        item.name ?? ''
                                                    }${i}`}
                                                    name={item.name ?? ''}
                                                    id={item.id ?? ''}
                                                    avatarUrl={
                                                        item.profileImageUrl ??
                                                        ''
                                                    }
                                                />
                                            ))}
                                        </Grid>
                                    </Box>
                                )}
                                {allTenants.length === 0 && (
                                    <Typography
                                        sx={{
                                            fontSize: '1.4rem',
                                            lineHeight: '2rem',
                                            color: 'text.secondary',
                                            mt: 1
                                        }}
                                    >
                                        You&apos;re not managing any creators
                                        yet, they need to invite you first.
                                    </Typography>
                                )}
                            </>
                        </Box>
                    )}
                </Layout>
            </Box>
        </>
    );
}

export default TeamTab;
