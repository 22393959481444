import baseTheme from '../baseTheme';

declare module '@mui/material/Slider' {
    interface SliderPropsSizeOverrides {
        large: true;
    }
}

const MuiSlider = {
    styleOverrides: {
        rail: {
            backgroundColor: baseTheme.palette.baseCloud.transparent20,
            height: '4px',
            borderRadius: '2px',
            opacity: 1
        },
        track: {
            color: baseTheme.palette.accentBlue.dark,
            borderRadius: '2px',
            height: '4px'
        },
        thumb: {
            zIndex: 3,
            width: '24px',
            height: '24px',
            border: '3px solid',
            borderColor: baseTheme.palette.accentBlue.dark,
            backgroundColor: '#262F3E',
            transition: baseTheme.transitions.create(['background-color'], {
                duration: baseTheme.transitions.duration.standard
            }),
            '&:before': {
                width: '8px',
                height: '8px',
                boxShadow: 'none',
                backgroundColor: baseTheme.palette.common.white,
                opacity: 0.4,
                transition: baseTheme.transitions.create(['opacity'], {
                    duration: baseTheme.transitions.duration.standard
                })
            },
            '&:hover': {
                backgroundColor: '#262F3E'
            },
            '&.Mui-active': {
                backgroundColor: '#262F3E',
                boxShadow: 'none',
                '&:before': {
                    opacity: 1
                }
            },
            '&:hover, &:active, &.Mui-focusVisible': {
                boxShadow: 'none'
            }
        },
        mark: {
            visibility: 'hidden'
        },
        markLabel: {
            color: baseTheme.palette.common.white,
            fontSize: '1.4rem',
            lineHeight: '2rem',
            top: '32px',
            fontWeight: 500,
            '&[data-index="0"]': {
                transform: 'translateX(0)'
            },
            '&[data-index="1"]': {
                transform: 'translateX(-100%)'
            }
        },
        valueLabel: {
            top: '-14px',
            background: `linear-gradient(${
                baseTheme.palette.baseCloud.transparent20 ?? ''
            }, ${
                baseTheme.palette.baseCloud.transparent20 ?? ''
            }), linear-gradient(${baseTheme.palette.baseInk.main}, ${
                baseTheme.palette.baseInk.main
            })`,
            paddingTop: baseTheme.spacing(2),
            paddingBottom: baseTheme.spacing(2),
            paddingLeft: baseTheme.spacing(3),
            paddingRight: baseTheme.spacing(3),
            borderRadius: '6px',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '8px',
                height: '8px',
                transform: 'translate(-50%, 50%) rotate(45deg)',
                background: `linear-gradient(${
                    baseTheme.palette.baseCloud.transparent20 ?? ''
                }, ${
                    baseTheme.palette.baseCloud.transparent20 ?? ''
                }), linear-gradient(${baseTheme.palette.baseInk.main}, ${
                    baseTheme.palette.baseInk.main
                })`,
                bottom: 0,
                left: '50%'
            }
        }
    },
    variants: [
        {
            props: { size: 'small' },
            style: {
                '& .MuiSlider-valueLabel': {
                    top: '-12px'
                }
            }
        },
        {
            props: { size: 'medium' },
            style: {
                '& .MuiSlider-rail': {
                    height: 4,
                    borderRadius: '2px'
                },
                '& .MuiSlider-track': {
                    height: 2,
                    borderRadius: '2px'
                },
                '& .MuiSlider-thumb': {
                    width: '32px',
                    height: '32px',
                    border: '4px solid',
                    borderColor: baseTheme.palette.accentBlue.dark,
                    '&:before': {
                        width: '12px',
                        height: '12px',
                        boxShadow: 'none',
                        backgroundColor: baseTheme.palette.common.white,
                        opacity: 0.4,
                        transition: baseTheme.transitions.create(['opacity'], {
                            duration: baseTheme.transitions.duration.standard
                        })
                    }
                },
                '& .MuiSlider-markLabel': {
                    fontSize: '1.6rem',
                    lineHeight: '2.4rem'
                }
            }
        },
        {
            props: { size: 'large' },
            style: {
                '& .MuiSlider-rail': {
                    height: 8,
                    borderRadius: '4px'
                },
                '& .MuiSlider-track': {
                    height: 6,
                    borderRadius: '4px'
                },
                '& .MuiSlider-thumb': {
                    width: '40px',
                    height: '40px',
                    border: '4px solid',
                    borderColor: baseTheme.palette.accentBlue.dark,
                    '&:before': {
                        width: '16px',
                        height: '16px',
                        boxShadow: 'none',
                        backgroundColor: baseTheme.palette.common.white,
                        opacity: 0.4,
                        transition: baseTheme.transitions.create(['opacity'], {
                            duration: baseTheme.transitions.duration.standard
                        })
                    }
                },
                '& .MuiSlider-valueLabel': {
                    top: '-15px',
                    fontSize: '1.6rem',
                    lineHeight: '2.4rem'
                }
            }
        }
    ]
};

export default MuiSlider;
