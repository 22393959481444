import { gql } from '@apollo/client';

import { MutationInviteTenantManagerWithEmailArgs } from '../generated/graphqlTypesAnalytics';

export type InviteTenantManagerWithEmail = {
    inviteTenantManagerWithEmail: string;
};

export type InviteTenantManagerWithEmailVars =
    MutationInviteTenantManagerWithEmailArgs;

export default gql`
    mutation InviteTenantManagerWithEmail($email: String) {
        inviteTenantManagerWithEmail(email: $email)
    }
`;
