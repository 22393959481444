import { alpha, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiModal from '@mui/material/Modal';
import React, { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import ModalBounce from './Animations/ModalBounce';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    width?: string;
    height?: string;
    hasCloseButton?: boolean;
    isPremium?: boolean;
    hasBorder?: boolean;
    overflow?: boolean;
    children: ReactNode;
}

const Modal = ({
    open,
    onClose,
    width = '60vw',
    height = '232px',
    hasCloseButton = false,
    isPremium = false,
    hasBorder = false,
    overflow = false,
    children
}: ModalProps): JSX.Element => {
    const theme = useTheme();

    return (
        <MuiModal
            open={open}
            onClose={(event, reason) => {
                if (
                    (reason === 'backdropClick' ||
                        reason === 'escapeKeyDown') &&
                    hasCloseButton
                ) {
                    return onClose();
                }

                return;
            }}
            slotProps={{
                backdrop: {
                    style: {
                        backgroundColor: alpha(theme.palette.baseInk.dark, 0.8)
                    }
                }
            }}
            slots={{
                backdrop: Backdrop
            }}
        >
            <ModalBounce in={open}>
                <Box
                    sx={{
                        width: ['90vw', undefined, width],
                        height: ['auto', undefined, height],
                        maxHeight: '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'stretch',
                        backgroundColor: 'baseInk.light',
                        borderRadius: 4,
                        p: [1, undefined, 2],
                        position: 'relative',
                        backgroundImage: isPremium
                            ? theme.gradients.product20Radial
                            : 'none',
                        ...(hasBorder && {
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                zIndex: 11,
                                pointerEvents: 'none',
                                top: 0,
                                left: 0,
                                inset: 0,
                                padding: '1px',
                                borderRadius: 4,
                                mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                maskComposite: 'exclude',
                                WebkitMaskComposite: 'xor',
                                background: `linear-gradient(180deg, ${
                                    theme.palette.baseCloud.transparent15 ?? ''
                                } 0%, transparent 100%)`
                            }
                        })
                    }}
                >
                    {hasCloseButton && (
                        <Button
                            color="subtle"
                            sx={{
                                position: 'absolute',
                                zIndex: 12,
                                width: '44px',
                                height: '44px',
                                minWidth: '44px',
                                top: '12px',
                                right: '12px',
                                borderRadius: 3,
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.baseCloud.transparent15
                                },
                                '& > svg': {
                                    flexShrink: 0,
                                    width: '24px',
                                    height: '24px'
                                }
                            }}
                            onClick={() => onClose()}
                        >
                            <CloseIcon />
                        </Button>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            height: '100%',
                            p: [5, undefined, 6],
                            scrollbarWidth: 'thin',
                            '&::-webkit-scrollbar': {
                                width: '4px'
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'transparent'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'baseCloud.transparent25',
                                borderRadius: '2px'
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'baseCloud.transparent35'
                            },
                            ...(!overflow && {
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            })
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </ModalBounce>
        </MuiModal>
    );
};

export default Modal;
