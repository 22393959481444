import { gql } from '@apollo/client';

import GAME_DEVELOPER_CAMPAIGN_FIELDS from '../fragments/gameDeveloperCampaignFields';
import { GameDeveloperCampaignOut } from '../generated/graphqlTypesAnalytics';

export interface GameDeveloperCampaignsData {
    gameDeveloperCampaigns: GameDeveloperCampaignOut[];
}

export default gql`
    ${GAME_DEVELOPER_CAMPAIGN_FIELDS}
    query GameDeveloperCampaigns {
        gameDeveloperCampaigns {
            ...GameDeveloperCampaignFields
        }
    }
`;
