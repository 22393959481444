import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as StarOutlineIcon } from '../assets/icons/starOutline.svg';
import { accountManagementTabNavConfig } from '../modules/AccountManagement';
import { accountManagement } from '../routes';

interface PremiumBannerSmallProps {
    title?: string | ReactNode;
    mt?: number;
    mb?: number;
    hasButton?: boolean;
}

const PremiumBannerSmall = ({
    title,
    mt = 0,
    mb = 16,
    hasButton = true
}: PremiumBannerSmallProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 4,
                backgroundColor: 'baseCloud.transparent10',
                backgroundImage: theme.gradients.product20Horizontal,
                px: 5,
                py: 4,
                mt,
                mb,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '16px',
                    bottom: 0,
                    width: '3px',
                    height: 'calc(100% - 32px)',
                    backgroundColor: 'accentYellow.dark',
                    borderRadius: '4px',
                    boxShadow: `0px 2px 12px ${alpha(
                        theme.palette.accentYellow.dark,
                        0.5
                    )}`
                }}
            />
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '36px',
                        height: '36px',
                        mr: 3,
                        '& > svg': {
                            width: '24px',
                            height: '24px'
                        },
                        borderRadius: '18px',
                        backgroundColor: 'accentYellow.transparent15'
                    }}
                >
                    <StarOutlineIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: ['column', undefined, 'row'],
                    alignItems: ['start', undefined, 'center'],
                    flexGrow: 1
                }}
            >
                <Box>
                    <Typography>{title}</Typography>
                </Box>
                {hasButton && (
                    <Box
                        sx={{
                            ml: [0, undefined, 'auto'],
                            pl: [0, undefined, 4],
                            mt: [2, undefined, 0]
                        }}
                    >
                        <Button
                            component={NavLink}
                            to={`${accountManagement()}/${
                                accountManagementTabNavConfig[3].value
                            }`}
                            variant="contained"
                            color="primary"
                            sx={{
                                px: 6
                            }}
                        >
                            Discover Premium
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PremiumBannerSmall;
