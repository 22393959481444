import { useQuery } from '@apollo/client';
import { useContext } from 'react';

import { MAIN_API } from '../components/GraphqlProvider';
import { userProfileTypeBroadcaster } from '../configs';
import GlobalStateContext from '../context';
import { UserProfile } from '../graphql/generated/graphqlTypesApi';
import USER_PROFILE, {
    UserProfileData,
    UserProfileVars
} from '../graphql/queries/userProfile';
import useAuth from './useAuth';

interface UseTenantsHook {
    userProfileData: UserProfile | undefined;
    isBroadcaster: boolean;
    isBroadcasterView?: boolean;
    loading?: boolean;
}

export default (): UseTenantsHook => {
    const auth = useAuth();
    const profile = auth?.profile;
    const {
        state: { isDeveloperView }
    } = useContext(GlobalStateContext);

    const { data, loading } = useQuery<UserProfileData, UserProfileVars>(
        USER_PROFILE,
        {
            variables: { userId: profile?.sub },
            context: { clientName: MAIN_API },
            skip: profile?.sub === undefined
        }
    );

    const isBroadcaster =
        data?.userProfile?.userProfileType?.code === userProfileTypeBroadcaster;

    return {
        userProfileData: data?.userProfile,
        isBroadcaster,
        isBroadcasterView: !(isDeveloperView && !isBroadcaster),
        loading
    };
};
