import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import { ResponsiveValue } from '../types';

interface LayoutProps {
    children: ReactNode;
    px?: ResponsiveValue;
}

const Layout = ({
    children,
    px = [4, undefined, 8]
}: LayoutProps): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        }}
    >
        <Box
            sx={{
                width: '100%',
                maxWidth: '1664px',
                px
            }}
        >
            {children}
        </Box>
    </Box>
);

export default Layout;
