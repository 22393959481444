import { UserTenant } from './../generated/graphqlTypesAnalytics';

const values: UserTenant[] = [
    {
        name: 'manager',
        id: '1',
        userProfileId: '1',
        isMe: true,
        profileImageUrl: ''
    },
    {
        name: 'Creator 1',
        id: '2',
        userProfileId: '2',
        isMe: false,
        profileImageUrl: ''
    },
    {
        name: 'Creator 2',
        id: '3',
        userProfileId: '3',
        isMe: false,
        profileImageUrl: ''
    },
    {
        name: 'Creator 3',
        id: '4',
        userProfileId: '4',
        isMe: false,
        profileImageUrl: ''
    }
];

export default {
    userTenants: {
        tenants: values
    }
};
