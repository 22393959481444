import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import isSlobs from '../../helpers/isSlobs';
import {
    storageGet,
    storageGetAllKeys,
    storageRemove,
    StorageType,
    tenantIdKey,
    tenantIdpKey
} from '../../helpers/storage';
import useAuth from '../../hooks/useAuth';

const logoutTimeout = 5000;
const SilentLogout = (): JSX.Element | null => {
    const auth = useAuth();

    useEffect(() => {
        const handleLogout = async () => {
            const id = await storageGet<string>(
                tenantIdKey,
                StorageType.session
            );
            const idp = await storageGet<string>(
                tenantIdpKey,
                StorageType.session
            );

            if (id && idp && !isSlobs()) {
                await storageRemove(tenantIdKey);
                await storageRemove(tenantIdpKey);

                await auth.authContext.removeUser();
                await auth.authContext.signinRedirect({
                    extraQueryParams: {
                        tenant_id: id,
                        idp
                    }
                });
            }
        };

        const handleFilterReset = async () => {
            const keys = await storageGetAllKeys(StorageType.local);
            if (keys && keys.length) {
                for (const key of keys) {
                    if (key.search('-filter') > -1) {
                        await storageRemove(key, StorageType.local);
                    }
                }
            }
        };

        void handleFilterReset();
        setTimeout(() => {
            void handleLogout();
        }, logoutTimeout);
    }, [auth]);

    return (
        <>
            {!isSlobs() && (
                <Box
                    sx={{
                        display: 'none'
                    }}
                >
                    <iframe
                        title="silent-logout"
                        width="500"
                        height="500"
                        src={`${
                            process.env
                                .REACT_APP_IDENTITY_SERVER_AUTHORITY as string
                        }/connect/endsession?id_token_hint=${
                            auth.authContext.user?.id_token ?? ''
                        }`}
                    />
                </Box>
            )}

            <Layout>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 500,
                            mt: 20,
                            color: 'common.white'
                        }}
                    >
                        Switching accounts
                    </Typography>
                    <Loader height="130px" />
                </Box>
            </Layout>
        </>
    );
};

export default SilentLogout;
