import { gameColors } from '../theme/palette';

export const getRandomColor = (): string =>
    `${Math.random().toString(16)}00000`.slice(2, 8);

export default (index: number): string => {
    let output;
    const randomColor = getRandomColor();

    switch (index) {
        case 0:
            output = gameColors.green;
            break;
        case 1:
            output = gameColors.orange;
            break;
        case 2:
            output = gameColors.red;
            break;
        case 3:
            output = gameColors.purple;
            break;
        case 4:
            output = gameColors.pink;
            break;
        case 5:
            output = gameColors.blue;
            break;
        case 6:
            output = gameColors.cyan;
            break;
        case 7:
            output = gameColors.yellow;
            break;
        default:
            output = `#${randomColor}`;
            break;
    }

    return output;
};
