import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import { ResponsiveValue } from '../types';

export enum GridVariant {
    fiveColumns,
    fiveColumnsOverview,
    fourColumns,
    threeColumns,
    twoColumns
}

interface GridProps {
    height?: ResponsiveValue;
    children: ReactNode;
    variant?: GridVariant;
    gap?: ResponsiveValue;
    rowGap?: ResponsiveValue;
    columnGap?: ResponsiveValue;
}

const Grid = ({
    children,
    height = '160px',
    variant = GridVariant.fiveColumns,
    gap = [4, undefined, 6],
    rowGap,
    columnGap
}: GridProps): JSX.Element => {
    let gridTemplateColumns = {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
        xxl: 'repeat(5, 1fr)'
    };

    if (variant === GridVariant.twoColumns) {
        gridTemplateColumns = {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
            xxl: 'repeat(2, 1fr)'
        };
    } else if (variant === GridVariant.threeColumns) {
        gridTemplateColumns = {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xxl: 'repeat(3, 1fr)'
        };
    } else if (variant === GridVariant.fourColumns) {
        gridTemplateColumns = {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(4, 1fr)',
            xxl: 'repeat(4, 1fr)'
        };
    } else if (variant === GridVariant.fiveColumnsOverview) {
        gridTemplateColumns = {
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xxl: 'repeat(5, 1fr)'
        };
    }

    return (
        <Box
            sx={{
                display: 'grid',
                width: '100%',
                rowGap: rowGap === undefined ? gap : rowGap,
                columnGap: columnGap === undefined ? gap : columnGap,
                gridTemplateColumns,
                gridAutoRows: height,
                alignItems: 'stretch',
                '& > div': {
                    minWidth: 0
                }
            }}
        >
            {children}
        </Box>
    );
};

export default Grid;
