export default (url?: string, width = 1920, height = 2560): string => {
    let u = url;
    if (!u) {
        u =
            'https://static-cdn.jtvnw.net/ttv-static/404_boxart-{width}x{height}.jpg';
    }

    let parsedUrl = u.replace('{width}', width.toString());
    parsedUrl = parsedUrl.replace('{height}', height.toString());

    return parsedUrl;
};
