import { gql } from '@apollo/client';

import GAME_DEVELOPER_CAMPAIGN_STORE_FIELDS from './gameDeveloperCampaignStoreFields';
import TWITCH_GAME_WITH_IGDB_DATA_FIELDS from './twitchGameWithIgdbDataFields';

export default gql`
    ${GAME_DEVELOPER_CAMPAIGN_STORE_FIELDS}
    ${TWITCH_GAME_WITH_IGDB_DATA_FIELDS}
    fragment GameDeveloperCampaignFields on GameDeveloperCampaignOut {
        id
        type
        title
        description
        howToRedeemUrl
        name
        hoursStreamed
        hoursWatched
        keyRetailValue
        numberOfKeys
        numberOfKeysLeft
        preferredLanguages
        preferredCountries
        minimumTimesStreamed
        minimumTimesStreamedEnabled
        pEGIRatingStreamedGamesEnabled
        minimumPEGIRatingStreamedGames
        maximumPEGIRatingStreamedGames
        brandSafeRatingEnabled
        brandSafeRating
        minimumCCVEnabled
        minimumCCV
        minimumSubCountEnabled
        minimumSubCount
        minimumChatEngagementEnabled
        minimumChatEngagement
        status
        startDate
        startDateEnabled
        endDate
        endDateEnabled
        embargoUntil
        embargoUntilEnabled
        minimumHoursToDeliver
        minimumHoursToDeliverEnabled
        twitchGame {
            ...TwitchGameWithIgdbDataFields
        }
        stores {
            ...GameDeveloperCampaignStoreFields
        }
        timeStamp
    }
`;
