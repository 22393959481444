import baseTheme from '../baseTheme';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        icon: true;
    }

    interface ButtonPropsSizeOverrides {
        compact: true;
    }

    interface ButtonPropsColorOverrides {
        accentPink: true;
        subtle: true;
        errorSubtle: true;
    }
}

const MuiButton = {
    defaultProps: {
        variant: 'contained',
        size: 'small'
    },
    variants: [
        // VARIANTS
        {
            props: { variant: 'contained' },
            style: {
                borderRadius: baseTheme.shape.borderRadius * 2,
                textTransform: 'none',
                boxShadow: 'none',
                textAlign: 'center',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&.Mui-disabled': {
                    boxShadow: 'none'
                },
                '& > span > svg:not(.MuiCircularProgress-svg)': {
                    width: '20px',
                    height: '20px'
                },
                '& .MuiButton-startIcon': {
                    marginLeft: 0
                },
                '& .MuiButton-endIcon': {
                    marginRight: 0
                }
            }
        },
        {
            props: { variant: 'text' },
            style: {
                borderRadius: baseTheme.shape.borderRadius * 2,
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&.Mui-disabled': {
                    boxShadow: 'none'
                },
                '& > span > svg:not(.MuiCircularProgress-svg)': {
                    width: '20px',
                    height: '20px'
                },
                '& .MuiButton-startIcon': {
                    marginLeft: 0
                },
                '& .MuiButton-endIcon': {
                    marginRight: 0
                }
            }
        },
        {
            props: { variant: 'icon' },
            style: {
                minWidth: 'auto',
                borderRadius: baseTheme.shape.borderRadius * 2,
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&.Mui-disabled': {
                    boxShadow: 'none'
                },
                '& > span > svg': {
                    flexShrink: 0
                }
            }
        },
        // SIZES
        {
            props: { size: 'small' },
            style: {
                padding: `${baseTheme.spacing(2)} ${baseTheme.spacing(4)}`,
                fontSize: '1.4rem',
                lineHeight: '2rem'
            }
        },
        {
            props: { size: 'medium' },
            style: {
                padding: `${baseTheme.spacing(3)} ${baseTheme.spacing(5)}`,
                fontSize: '1.4rem',
                lineHeight: '2rem'
            }
        },
        {
            props: { size: 'large' },
            style: {
                padding: `${baseTheme.spacing(3.5)} ${baseTheme.spacing(6)}`,
                fontSize: '1.6rem',
                lineHeight: '2.4rem'
            }
        },
        {
            props: { variant: 'icon', size: 'small' },
            style: {
                padding: '8px',
                '& > svg': {
                    width: '20px',
                    height: '20px'
                }
            }
        },
        {
            props: { variant: 'icon', size: 'medium' },
            style: {
                padding: '8px',
                '& > svg': {
                    width: '28px',
                    height: '28px'
                }
            }
        },
        {
            props: { variant: 'icon', size: 'large' },
            style: {
                padding: '12px',
                '&  > svg': {
                    width: '28px',
                    height: '28px'
                }
            }
        },
        {
            props: { variant: 'text', size: 'small' },
            style: {
                padding: `${baseTheme.spacing(2)} ${baseTheme.spacing(4)}`,
                fontSize: '1.4rem',
                lineHeight: '2rem'
            }
        },
        {
            props: { variant: 'text', size: 'medium' },
            style: {
                padding: `10px ${baseTheme.spacing(4)}`,
                fontSize: '1.6rem',
                lineHeight: '2.4rem'
            }
        },
        {
            props: { variant: 'text', size: 'compact' },
            style: {
                minWidth: 0,
                padding: `${baseTheme.spacing(2)} 0`,
                fontSize: '1.4rem',
                lineHeight: '2rem'
            }
        },
        // COLORS
        {
            props: { color: 'primary' },
            style: {
                color: baseTheme.palette.baseInk.dark,
                backgroundColor: baseTheme.palette.accentYellow.dark,
                '&.Mui-disabled': {
                    color: baseTheme.palette.baseInk.dark,
                    backgroundColor: baseTheme.palette.accentYellow.dark,
                    opacity: 0.5
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentYellow.main
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.baseInk.dark
                }
            }
        },
        {
            props: { color: 'secondary' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: baseTheme.palette.accentBlue.dark,
                '&.Mui-disabled': {
                    color: baseTheme.palette.common.white,
                    backgroundColor: baseTheme.palette.accentBlue.dark,
                    opacity: 0.5
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentBlue.main
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.common.white
                }
            }
        },
        {
            props: { color: 'accentPink' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: baseTheme.palette.accentPink.dark,
                '&.Mui-disabled': {
                    color: baseTheme.palette.common.white,
                    backgroundColor: baseTheme.palette.accentPink.dark,
                    opacity: 0.5
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentPink.main
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.common.white
                }
            }
        },
        {
            props: { color: 'subtle' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: baseTheme.palette.baseCloud.transparent10,
                '&.Mui-disabled': {
                    color: baseTheme.palette.text.secondary,
                    backgroundColor: baseTheme.palette.baseCloud.transparent10,
                    opacity: 0.5
                },
                '&.Mui-disabled > span > svg *': {
                    fill: baseTheme.palette.text.secondary
                },
                '&:hover': {
                    color: baseTheme.palette.common.white,
                    backgroundColor: baseTheme.palette.baseCloud.transparent15
                },
                '& > span > svg *': {
                    fill: baseTheme.palette.common.white
                },
                '& > span > svg': {
                    transition: baseTheme.transitions.create(['opacity'], {
                        duration: baseTheme.transitions.duration.standard
                    })
                }
            }
        },
        {
            props: { color: 'error' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: baseTheme.palette.accentRed.dark,
                '&.Mui-disabled': {
                    backgroundColor: baseTheme.palette.accentRed.dark,
                    color: baseTheme.palette.baseInk.dark,
                    opacity: 0.5
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentRed.main
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.common.white
                }
            }
        },
        {
            props: { color: 'errorSubtle' },
            style: {
                color: baseTheme.palette.accentRed.main,
                backgroundColor: baseTheme.palette.accentRed.transparent15,
                '&.Mui-disabled': {
                    backgroundColor: baseTheme.palette.accentRed.transparent15,
                    color: baseTheme.palette.accentRed.main,
                    opacity: 0.5
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentRed.transparent20
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.accentRed.main
                }
            }
        },
        {
            props: { variant: 'text', color: 'primary' },
            style: {
                color: baseTheme.palette.accentYellow.dark,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.baseCloud.transparent5
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.accentYellow.dark
                }
            }
        },
        {
            props: { variant: 'text', color: 'secondary' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.baseCloud.transparent5
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.common.white
                }
            }
        },
        {
            props: { variant: 'text', color: 'error' },
            style: {
                color: baseTheme.palette.accentRed.dark,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentRed.transparent20,
                    color: baseTheme.palette.accentRed.light
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.accentRed.dark
                }
            }
        },
        {
            props: { variant: 'text', color: 'primary', size: 'compact' },
            style: {
                color: baseTheme.palette.accentYellow.dark,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    color: baseTheme.palette.accentYellow.main,
                    backgroundColor: 'transparent',
                    '& > span > svg > *': {
                        fill: baseTheme.palette.accentYellow.main
                    }
                },
                '&:active': {
                    color: baseTheme.palette.accentYellow.light,
                    backgroundColor: 'transparent',
                    '& > span > svg > *': {
                        fill: baseTheme.palette.accentYellow.light
                    }
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.accentYellow.dark
                }
            }
        },
        {
            props: { variant: 'text', color: 'secondary', size: 'compact' },
            style: {
                color: baseTheme.palette.common.white,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    backgroundColor: baseTheme.palette.baseCloud.transparent5
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.common.white
                }
            }
        },
        {
            props: { variant: 'text', color: 'error', size: 'compact' },
            style: {
                color: baseTheme.palette.accentRed.dark,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: baseTheme.palette.baseInk.dark
                },
                '&:hover': {
                    color: baseTheme.palette.accentRed.main,
                    backgroundColor: 'transparent',
                    '& > span > svg > *': {
                        fill: baseTheme.palette.accentRed.main
                    }
                },
                '&:active': {
                    color: baseTheme.palette.accentRed.light,
                    backgroundColor: 'transparent',
                    '& > span > svg > *': {
                        fill: baseTheme.palette.accentRed.light
                    }
                },
                '& > span > svg > *': {
                    fill: baseTheme.palette.accentRed.dark
                }
            }
        }
    ]
};

export default MuiButton;
