import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    from,
    InMemoryCache,
    ServerError,
    split
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import type { ReactNode } from 'react';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import GlobalStateContext from '../context';
import isSlobs from '../helpers/isSlobs';
import parseGraphQLErrorMessage from '../helpers/parseGraphQLErrorMessage';
import showToast, { ToastVariant } from '../helpers/showToast';
import {
    slobsTokenKey,
    storageGet,
    storageGetSync,
    StorageType,
    tokenKey
} from '../helpers/storage';
import { logoutRoute } from '../routes';
import Loader from './Loader';

interface Token {
    access_token: string;
    id_token: string;
    expires_at: number;
    profile: Record<string, unknown>;
    scope: string;
    session_state: string;
    token_type: string;
}

export const MAIN_API = 'mainApi';
export const PUBLIC_API = 'publicApi';

const mainApiLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI
});

const analyticsApiLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ANALYTICS_URI
});

const publicApiLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_PUBLIC_URI
});

interface GraphqlProviderProps {
    children?: ReactNode;
}

const ignoredOperations = [
    'AddDiscordServer',
    'AddYouTubeChannel',
    'InviteTenantManagerWithEmail',
    'CreateGameDeveloperCampaign'
];

const GraphqlProvider = ({ children }: GraphqlProviderProps): JSX.Element => {
    const navigate = useNavigate();
    const {
        state: { userCountry }
    } = useContext(GlobalStateContext);

    const apolloClient = useMemo(
        () =>
            new ApolloClient({
                link: from([
                    setContext(async (_, { headers }) => {
                        let accessToken: string | undefined;
                        if (isSlobs()) {
                            accessToken = storageGetSync<string>(
                                slobsTokenKey,
                                StorageType.local
                            );
                        } else {
                            const token = await storageGet<Token>(tokenKey);

                            accessToken = token?.access_token;
                        }

                        return {
                            headers: {
                                ...headers,
                                authorization: accessToken
                                    ? `Bearer ${accessToken}`
                                    : '',
                                'timezone-offset':
                                    new Date().getTimezoneOffset(),
                                'timezone-id':
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                                country: userCountry
                            } as ApolloLink
                        };
                    }),
                    onError(({ networkError, graphQLErrors, operation }) => {
                        if (graphQLErrors && graphQLErrors.length) {
                            const graphQLError = graphQLErrors[0];
                            const isIgnored =
                                ignoredOperations.indexOf(
                                    operation.operationName
                                ) > -1;

                            if (graphQLError && !isIgnored) {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                const message =
                                    parseGraphQLErrorMessage(graphQLError);

                                showToast(message, ToastVariant.error);
                                console.error(graphQLErrors); // eslint-disable-line no-console
                            }
                        }

                        if ((networkError as ServerError)?.statusCode === 401) {
                            navigate(logoutRoute());
                        } else if (networkError) {
                            showToast(
                                `${networkError?.name} - ${networkError?.message}`,
                                ToastVariant.error
                            );

                            console.error(networkError); // eslint-disable-line no-console
                        }
                    }),
                    split(
                        (operation) =>
                            operation.getContext().clientName === MAIN_API,
                        mainApiLink,
                        split(
                            (operation) =>
                                operation.getContext().clientName ===
                                PUBLIC_API,
                            publicApiLink,
                            analyticsApiLink
                        )
                    )
                ]),
                cache: new InMemoryCache()
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userCountry]
    );

    if (!apolloClient) {
        return <Loader />;
    }

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
