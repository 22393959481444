import isSlobs from './isSlobs';

const TEST_KEY = 'test';

export const invitationTokenKey = 'invitationToken';
export const requestedPathnameKey = 'requestedPathname';
export const tenantIdKey = 'tenantId';
export const tenantIdpKey = 'tenantIdp';
export const slobsTokenKey = 'slobsToken';
export const noStreamsModalKey = 'noStreamsModal';
export const gameKeysWelcomeModalKey = 'gameKeysWelcomeModal';
export const maintenanceModeKey = 'maintenanceMode';
export const streamNavigationTooltipKey = 'streamNavigationTooltip';
export const tiktokReconnectModalKey = 'tiktokReconnectModal';
export const sectionVisitedGrowthKey = 'section-visited-growth';
export const sectionVisitedWhatToStreamKey = 'section-visited-whattostream';
export const sectionVisitedWhenToStreamKey = 'section-visited-whentostream';
export const sectionVisitedGameKeysKey = 'section-visited-freegames';
export const isDeveloperViewKey = 'isDeveloperView';
export const ignoredGamesKey = 'ignoredGames';
export const gameDeveloperLandingPageDataKey =
    'gameDeveloperCampaignLandingPageData';
export const isGameDeveloperCampaignLandingPageRequestedKey =
    'isGameDeveloperCampaignLandingPageRequested';

export const tokenKey = `oidc.user:${
    process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY as string
}:${process.env.REACT_APP_IDENTITY_SERVER_CLIENT_ID as string}`;

export enum StorageType {
    local,
    session
}

export const isLocalStorageAvailable = (
    storageType: StorageType = StorageType.session
): boolean => {
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (!s) {
        return false;
    }
    try {
        s.setItem(TEST_KEY, '1');
        s.removeItem(TEST_KEY);
        return true;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console

        return false;
    }
};

export const storageSetSync = <T>(
    key: string,
    value: T,
    storageType: StorageType = StorageType.session
): void => {
    const localStorageAvailable: boolean = isLocalStorageAvailable(storageType);
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (localStorageAvailable) {
        s.setItem(key, JSON.stringify(value));
    }
};

export const storageSet = async <T>(
    key: string,
    value: T,
    storageType: StorageType = StorageType.session
): Promise<void> => {
    if (isSlobs() && storageType === StorageType.local) {
        await window.Streamlabs.userSettings.set<T>(key, value);

        const storageSetEvent = new CustomEvent('storageSet', {
            detail: {
                key
            }
        });
        document.dispatchEvent(storageSetEvent);
    } else {
        const localStorageAvailable: boolean =
            isLocalStorageAvailable(storageType);
        let s = window.localStorage;

        if (storageType === StorageType.session) {
            s = window.sessionStorage;
        }

        if (localStorageAvailable) {
            s.setItem(key, JSON.stringify(value));

            const storageSetEvent = new CustomEvent('storageSet', {
                detail: {
                    key
                }
            });
            document.dispatchEvent(storageSetEvent);
        }
    }
};

export const storageGetSync = <T>(
    key: string,
    storageType: StorageType = StorageType.session
): T | undefined => {
    const localStorageAvailable: boolean = isLocalStorageAvailable(storageType);
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (!localStorageAvailable) {
        return undefined;
    }

    const json = s.getItem(key);
    try {
        return json ? JSON.parse(json) : undefined; // eslint-disable-line @typescript-eslint/no-unsafe-return
    } catch (err) {
        console.error(err); // eslint-disable-line no-console

        return undefined;
    }
};

export const storageGet = async <T>(
    key: string,
    storageType: StorageType = StorageType.session
): Promise<T | undefined> => {
    if (isSlobs() && storageType === StorageType.local) {
        // TODO change this back to .get when its working
        // const value = await window.Streamlabs.userSettings.get(key);
        const allUserSettings =
            await window.Streamlabs.userSettings.getAll<T>();

        if (allUserSettings) {
            const foundKey = Object.keys(allUserSettings).find(
                (item) => item === key
            );

            if (foundKey) {
                return allUserSettings[foundKey] ?? undefined;
            }
        }

        return undefined;
    }

    const localStorageAvailable: boolean = isLocalStorageAvailable(storageType);
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (!localStorageAvailable) {
        return undefined;
    }

    const json = s.getItem(key);
    try {
        return json ? JSON.parse(json) : undefined; // eslint-disable-line @typescript-eslint/no-unsafe-return
    } catch (err) {
        console.error(err); // eslint-disable-line no-console

        return undefined;
    }
};

export const storageGetAllKeys = async <T>(
    storageType: StorageType = StorageType.session
): Promise<string[] | undefined> => {
    if (isSlobs() && storageType === StorageType.local) {
        const values = await window.Streamlabs.userSettings.getAll<unknown>();

        return Object.keys(values) ?? undefined;
    }

    const localStorageAvailable: boolean = isLocalStorageAvailable(storageType);
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (!localStorageAvailable) {
        return undefined;
    }

    return Object.keys(s);
};

export const storageRemoveSync = (
    key: string,
    storageType: StorageType = StorageType.session
): void => {
    const localStorageAvailable: boolean = isLocalStorageAvailable(storageType);
    let s = window.localStorage;

    if (storageType === StorageType.session) {
        s = window.sessionStorage;
    }

    if (localStorageAvailable) {
        s.removeItem(key);
    }
};

export const storageRemove = async (
    key: string,
    storageType: StorageType = StorageType.session
): Promise<void> => {
    if (isSlobs() && storageType === StorageType.local) {
        await window.Streamlabs.userSettings.delete(key);
    } else {
        const localStorageAvailable: boolean =
            isLocalStorageAvailable(storageType);
        let s = window.localStorage;

        if (storageType === StorageType.session) {
            s = window.sessionStorage;
        }

        if (localStorageAvailable) {
            s.removeItem(key);
        }
    }
};
