import { useContext, useEffect } from 'react';

import GlobalStateContext from '../context';
import { setUserCountry } from '../context/actions';
import Loader from './Loader';

interface UserCountryProviderProps {
    children: React.ReactNode;
}

const missingUserCountry = 'Unknown';
const UserCountryProvider = ({
    children
}: UserCountryProviderProps): JSX.Element => {
    const {
        state: { userCountry },
        dispatch
    } = useContext(GlobalStateContext);

    useEffect(() => {
        const fetchFavicon = async () => {
            try {
                const response = await fetch('/favicon.ico', {
                    method: 'GET',
                    cache: 'no-store'
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const countryHeader = response.headers.get('X-Viewer-Country');
                dispatch(setUserCountry(countryHeader ?? missingUserCountry));
            } catch (error: unknown) {
                // eslint-disable-next-line no-console
                console.error('Favicon fetch fail!', error);

                dispatch(setUserCountry(missingUserCountry));
            }
        };

        void fetchFavicon();
    }, [dispatch]);

    if (!userCountry) {
        return <Loader />;
    }

    return <>{children}</>;
};

export default UserCountryProvider;
