import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import React from 'react';

import Badge from './Badge';
import Layout from './Layout';
import PremiumBannerSmall from './PremiumBannerSmall';

export enum SectionHeaderVariant {
    normal,
    pastStreams,
    gameKeys
}

interface SectionHeaderProps {
    variant?: SectionHeaderVariant;
    title: string;
    rightComponent?: ReactNode;
    children?: ReactNode;
    description?: string;
    isAlpha?: boolean;
    isNew?: boolean;
    hasTabNav?: boolean;
    mobilePaddingTop?: number;
    isPremium?: boolean;
}

const SectionHeader = ({
    variant = SectionHeaderVariant.normal,
    title,
    rightComponent,
    children,
    description,
    isAlpha = false,
    isNew = false,
    hasTabNav = false,
    mobilePaddingTop = 10,
    isPremium = false
}: SectionHeaderProps): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    let bg =
        variant === SectionHeaderVariant.normal
            ? theme.palette.baseInk.light
            : 'transparent';
    let pt = hasTabNav ? 32 : 12;
    let childrenMt = variant === SectionHeaderVariant.pastStreams ? 10 : 4;

    if (isMobile) {
        pt += mobilePaddingTop;
    }

    if (variant === SectionHeaderVariant.gameKeys) {
        bg = `linear-gradient(180deg, ${theme.palette.baseInk.light} 0%, transparent 100%)`;
        childrenMt = 8;
    }

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    mt: hasTabNav ? '-80px' : 0,
                    pt,
                    pb: variant === SectionHeaderVariant.pastStreams ? 4 : 8,
                    background: bg
                }}
            >
                {isPremium && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: theme.gradients.product20Horizontal
                        }}
                    />
                )}

                <Layout>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'start'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: ['column', undefined, 'row'],
                                alignItems: 'start',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mr: 8,
                                    maxWidth: '785px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant="h1">
                                        {title}
                                    </Typography>

                                    {isAlpha && (
                                        <Box
                                            sx={{
                                                ml: 3
                                            }}
                                        >
                                            <Badge
                                                color={
                                                    theme.palette.accentBlue
                                                        .dark
                                                }
                                            >
                                                Alpha
                                            </Badge>
                                        </Box>
                                    )}
                                    {isNew && (
                                        <Box
                                            sx={{
                                                ml: 3
                                            }}
                                        >
                                            <Badge
                                                color={
                                                    theme.palette.accentBlue
                                                        .dark
                                                }
                                            >
                                                New
                                            </Badge>
                                        </Box>
                                    )}
                                </Box>

                                {description && (
                                    <Typography
                                        sx={{
                                            color: 'text.secondary',
                                            mt: '14px'
                                        }}
                                    >
                                        {description}
                                    </Typography>
                                )}
                            </Box>

                            {rightComponent && (
                                <Box
                                    sx={{
                                        width: ['100%', 'auto'],
                                        mt: [3, undefined, 0]
                                    }}
                                >
                                    {rightComponent}
                                </Box>
                            )}
                        </Box>

                        {React.Children.count(children) > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: ['column', 'row'],
                                    gap: 2,
                                    mt: childrenMt
                                }}
                            >
                                {children}
                            </Box>
                        )}

                        {isPremium && (
                            <PremiumBannerSmall
                                title="This dashboard is Premium. You're viewing demo data. Upgrade to unlock all features."
                                mt={8}
                                mb={0}
                            />
                        )}
                    </Box>
                </Layout>
            </Box>
        </Box>
    );
};

export default SectionHeader;
