import { gql } from '@apollo/client';

export default gql`
    fragment UserTenantFields on UserTenant {
        id
        name
        isMe
        profileImageUrl
        userProfileId
    }
`;
