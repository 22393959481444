import UAParser from 'ua-parser-js';

export default (): boolean => {
    const parser = new UAParser(window.navigator.userAgent);
    const device = parser.getDevice();

    return (
        device.type === 'mobile' ||
        device.type === 'tablet' ||
        device.type === 'wearable'
    );
};
