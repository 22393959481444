import baseTheme from '../baseTheme';

const MuiInputBase = {
    styleOverrides: {
        root: {
            height: '44px',
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'stretch',
            backgroundColor: baseTheme.palette.baseCloud.transparent10,
            borderRadius: '6px !important',
            boxShadow: `inset 0px 0px 0px 1px ${
                baseTheme.palette.baseCloud.transparent15 ?? ''
            }`,
            padding: 0,
            paddingLeft: baseTheme.spacing(3),
            paddingRight: baseTheme.spacing(3),
            '&:focus-within': {
                boxShadow: `inset 0px 0px 0px 2px ${baseTheme.palette.accentBlue.dark}`
            },
            transition: baseTheme.transitions.create(['box-shadow'], {
                duration: baseTheme.transitions.duration.standard
            }),
            '&:hover': {
                border: 0
            },
            '& fieldset': {
                border: 0
            },
            '& .MuiButtonBase-root': {
                marginRight: '-9px',
                '& svg': {
                    width: '20px',
                    height: '20px',
                    '& path': {
                        fill: baseTheme.palette.text.secondary
                    }
                }
            }
        },
        input: {
            height: '20px',
            padding: '0px !important',
            flex: 1,
            backgroundColor: 'transparent',
            border: 0,
            color: baseTheme.palette.common.white,
            fontSize: '1.4rem',
            lineHeight: '2rem',
            fontWeight: 400,
            fontFamily: baseTheme.typography.fontFamily,
            '&::placeholder': {
                color: baseTheme.palette.text.secondary,
                opacity: 1
            },
            '&:focus': {
                border: 0,
                outline: 'none'
            }
        }
    }
};

export default MuiInputBase;
