import { gql } from '@apollo/client';

import { MutationDeleteTenantInvitationTokenArgs } from '../generated/graphqlTypesAnalytics';

export type DeleteTenantInvitationTokenVars =
    MutationDeleteTenantInvitationTokenArgs;

export default gql`
    mutation DeleteTenantInvitationToken($invitationTokenId: UUID!) {
        deleteTenantInvitationToken(invitationTokenId: $invitationTokenId) {
            value
        }
    }
`;
