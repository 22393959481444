import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { hasAuthParams } from 'react-oidc-context';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import TenantInvitationsProvider from '../../components/TenantInvitationsProvider';
import TikTokReconnectModal from '../../components/TikTokReconnectModal';
import GlobalStateContext from '../../context';
import { setSlobsToken } from '../../context/actions';
import getCookie from '../../helpers/getCookie';
import handleSlobsAuth from '../../helpers/handleSlobsAuth';
import isSlobs from '../../helpers/isSlobs';
import {
    requestedPathnameKey,
    slobsTokenKey,
    storageSet,
    storageSetSync,
    StorageType
} from '../../helpers/storage';
import useAuth from '../../hooks/useAuth';
import { gameMarketing, homeRoute } from '../../routes';
import { authCallbackRoute } from '../../routes';

const PrivateRoutes = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const [loginError, setLoginError] = useState<boolean>(false);
    const [slobsLoginInProgress, setSlobsLoginInProgress] =
        useState<boolean>(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        state: { userCountry },
        dispatch
    } = useContext(GlobalStateContext);

    useEffect(() => {
        const handleSlobsLogin = async () => {
            if (!slobsLoginInProgress && !auth.isAuthenticated) {
                setSlobsLoginInProgress(true);
                const token = await handleSlobsAuth();

                if (token) {
                    storageSetSync<string>(
                        slobsTokenKey,
                        token,
                        StorageType.local
                    );
                    dispatch(setSlobsToken(token));

                    setSlobsLoginInProgress(false);
                    navigate(authCallbackRoute());
                } else {
                    setLoginError(true);
                }
            }
        };

        const handleLogin = async () => {
            try {
                if (
                    !hasAuthParams() &&
                    !auth.authContext.isAuthenticated &&
                    !auth.authContext.activeNavigator &&
                    !auth.authContext.isLoading
                ) {
                    let extraQueryParams = {};

                    if (location.pathname !== homeRoute()) {
                        await storageSet<string>(
                            requestedPathnameKey,
                            location.pathname
                        );
                    }

                    const refCookie = getCookie('utm_campaign');
                    if (refCookie) {
                        extraQueryParams = {
                            ...extraQueryParams,
                            utm_campaign: refCookie
                        };
                    }

                    if (location.pathname === gameMarketing()) {
                        extraQueryParams = {
                            ...extraQueryParams,
                            developer: true
                        };
                    }

                    if (userCountry) {
                        extraQueryParams = {
                            ...extraQueryParams,
                            country: userCountry
                        };
                    }

                    await auth.authContext.signinRedirect({
                        extraQueryParams
                    });
                }
            } catch (err) {
                console.error(err); // eslint-disable-line no-console

                setLoginError(true);
            }
        };

        if (isSlobs()) {
            void handleSlobsLogin();
        } else if (!loginError) {
            void handleLogin();
        }
    }, [
        auth,
        location,
        searchParams,
        userCountry,
        loginError,
        slobsLoginInProgress,
        dispatch,
        navigate
    ]);

    if (auth.isAuthenticated) {
        return (
            <TenantInvitationsProvider>
                <TikTokReconnectModal />
                <Outlet />
            </TenantInvitationsProvider>
        );
    }

    if (loginError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 10
                }}
            >
                <Typography>There was problem with your login.</Typography>

                {isSlobs() && (
                    <Typography>Please relaunch the application.</Typography>
                )}
            </Box>
        );
    }

    return (
        <>
            <Loader />
            {isSlobs() && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography>Please login in your browser.</Typography>
                </Box>
            )}
        </>
    );
};

export default PrivateRoutes;
