import { useEffect, useState } from 'react';

import { storageGet, storageSet, StorageType } from '../helpers/storage';

export default function useLocalStorage<T>(
    key: string,
    initialValue: T,
    type: StorageType = StorageType.local
): readonly [T | undefined, (value: T | ((val: T) => T)) => void] {
    const [storedValue, setStoredValue] = useState<T | undefined>(undefined);

    useEffect(() => {
        const getStoredValue = async () => {
            const value = await storageGet<T>(key, type);

            setStoredValue(value ?? initialValue);
        };

        void getStoredValue();
    }, [initialValue, key, type]);

    const setValue = async (value: T | ((val: T) => T)) => {
        if (storedValue !== undefined) {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;

            setStoredValue(valueToStore);
            await storageSet<T>(key, valueToStore, type);
        }
    };

    return [storedValue, setValue] as const;
}
