import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalytics } from '../components/TrackingProvider';
import { discordUrl } from '../configs';
import { accountManagementTabNavConfig } from '../modules/AccountManagement';
import { accountManagement, logoutRoute } from '../routes';
import useUserProfile from './useUserProfile';

interface PreferencesItemType {
    title: string;
    href?: string;
    route?: string;
    icon?: ReactNode;
    isChangelog?: boolean;
    onClick?: () => void;
}

export default (): PreferencesItemType[] => {
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { isBroadcasterView, loading: userProfileLoading } = useUserProfile();

    if (userProfileLoading) {
        return [];
    }

    return [
        {
            title: 'Updates',
            href: 'https://updates.streamb.ee',
            isChangelog: true,
            onClick: () => {
                void analytics?.track('Updates clicked');
            }
        },
        {
            title: 'Discord & Support',
            href: discordUrl
        },
        {
            title: isBroadcasterView ? 'Manage accounts' : 'Preferences',
            route: isBroadcasterView
                ? accountManagement()
                : `${accountManagement()}/${
                      accountManagementTabNavConfig[2].value
                  }`
        },
        {
            title: 'Log out',
            onClick: () => {
                void analytics?.track('Logged out');
                navigate(logoutRoute());
            }
        }
    ];
};
