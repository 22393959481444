import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import { ResponsiveValue } from '../types';

interface BoxIconProps {
    icon?: ReactNode;
    iconSize?: number;
    padding?: number;
    mr?: number;
    borderRadius?: ResponsiveValue;
    background?: string;
    hasBorder?: boolean;
    hasBoxShadow?: boolean;
    hasIconShadow?: boolean;
    hasTopBorder?: boolean;
    border?: string;
    iconColor?: string;
}

const BoxIcon = ({
    icon,
    iconSize = 20,
    padding = 8,
    mr = 3,
    borderRadius = 2,
    background,
    hasBorder = false,
    hasBoxShadow = false,
    hasIconShadow = false,
    hasTopBorder = false,
    border,
    iconColor
}: BoxIconProps): JSX.Element => {
    const theme = useTheme();

    return (
        <>
            {icon && (
                <Box
                    sx={{
                        overflow: 'hidden',
                        position: 'relative',
                        borderRadius,
                        display: 'flex',
                        flexShrink: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr,
                        transition: theme.transitions.create(['padding'], {
                            duration: theme.transitions.duration.standard
                        }),
                        p: `${padding}px`,
                        '& img': {
                            width: `${iconSize}px`,
                            height: `${iconSize}px`
                        },
                        '& svg': {
                            transition: theme.transitions.create(
                                ['width', 'height'],
                                {
                                    duration:
                                        theme.transitions.duration.standard,
                                    easing: theme.transitions.easing.easeOutBack
                                }
                            ),
                            width: `${iconSize}px`,
                            height: `${iconSize}px`,
                            filter: hasIconShadow
                                ? `drop-shadow(0px 4px 8px ${alpha(
                                      iconColor ??
                                          theme.palette.accentYellow.dark,
                                      0.25
                                  )}) drop-shadow(0px 0px 16px ${alpha(
                                      iconColor ??
                                          theme.palette.accentYellow.dark,
                                      0.75
                                  )})`
                                : 'none'
                        },
                        '& svg *:not(.keep-color)': {
                            ...(iconColor && {
                                fill: iconColor
                            })
                        },
                        boxShadow: hasBoxShadow ? 1 : 'none',
                        background:
                            background ?? theme.palette.baseCloud.transparent10,
                        '&:before': {
                            ...(!hasBorder &&
                                hasTopBorder && {
                                    content: '""',
                                    borderTop: '1px solid',
                                    borderColor: 'baseCloud.transparent20',
                                    position: 'absolute',
                                    zIndex: 5,
                                    inset: 0,
                                    borderRadius
                                }),
                            ...(hasBorder &&
                                !hasTopBorder && {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    inset: 0,
                                    padding: '1px',
                                    borderRadius,
                                    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                    maskComposite: 'exclude',
                                    WebkitMaskComposite: 'xor',
                                    background:
                                        border ??
                                        `linear-gradient(90deg, ${
                                            theme.palette.baseCloud
                                                .transparent10 ?? ''
                                        } 0%, transparent 100%)`
                                })
                        }
                    }}
                >
                    {icon}
                </Box>
            )}
        </>
    );
};

export default BoxIcon;
