import { gql } from '@apollo/client';

import { GetTenantInvitationsResponse } from '../generated/graphqlTypesAnalytics';

export interface TenantInvitationsData {
    tenantInvitations: GetTenantInvitationsResponse;
}

export default gql`
    query TenantInvitations {
        tenantInvitations {
            toUser {
                invitationTokenId
                userName
            }
            fromUser {
                invitationTokenId
                userName
            }
        }
    }
`;
