import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as StarOutlineIcon } from '../assets/icons/starOutline.svg';
import illustrationBoy from '../assets/images/illustration-boy.png';
import { accountManagementTabNavConfig } from '../modules/AccountManagement';
import { accountManagement } from '../routes';

interface PremiumBannerProps {
    title?: string;
    description?: string;
    buttonText?: string;
}

const PremiumBanner = ({
    title = 'Get premium to start tracking premium metrics with real data.',
    description = 'These features are a part of our premium plan, so right now you&amp;re looking at demo data. To start tracking real data, please, upgrade your plan to Growth or PRO. They&amp;re starting at just $10 a month.',
    buttonText = 'Discover Premium'
}: PremiumBannerProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'stretch',
                borderRadius: 4,
                backgroundColor: 'baseCloud.transparent10',
                backgroundImage: theme.gradients.product20Horizontal,
                px: 10,
                mt: 16,
                mb: 10,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '40px',
                    height: '6px',
                    width: 'calc(100% - 80px)',
                    backgroundColor: 'accentYellow.dark',
                    borderRadius: '6px',
                    boxShadow: `0px 4px 16px ${alpha(
                        theme.palette.accentYellow.dark,
                        0.25
                    )}, 0px 0px 32px ${alpha(
                        theme.palette.accentYellow.dark,
                        0.75
                    )}`
                }}
            />
            <Box
                sx={{
                    pt: '53px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '64px',
                        height: '64px',
                        mr: 6,
                        '& > svg': {
                            width: '32px',
                            height: '32px'
                        },
                        borderRadius: '32px',
                        backgroundColor: 'accentYellow.transparent15'
                    }}
                >
                    <StarOutlineIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    pb: '53px',
                    pt: '53px'
                }}
            >
                <Typography variant="h2">{title}</Typography>
                <Typography
                    sx={{
                        fontSize: '1.6rem',
                        lineHeight: '2.4rem',
                        color: 'text.secondary',
                        mt: 3,
                        mb: 6
                    }}
                >
                    {description}
                </Typography>

                <Button
                    component={NavLink}
                    to={`${accountManagement()}/${
                        accountManagementTabNavConfig[3].value
                    }`}
                    variant="contained"
                    size="large"
                    color="primary"
                >
                    {buttonText}
                </Button>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    backgroundImage: `url(${illustrationBoy})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right'
                }}
            />
        </Box>
    );
};

export default PremiumBanner;
