import { gql } from '@apollo/client';

import VALUE_AND_PERCENTAGE_FIELDS from '../fragments/valueAndPercentageFields';
import {
    QueryStreamOverviewDataArgs,
    StreamDataResult
} from '../generated/graphqlTypesAnalytics';

export interface StreamOverviewData {
    streamOverviewData: StreamDataResult;
}

export type StreamOverviewDataVars = QueryStreamOverviewDataArgs;

export default gql`
    ${VALUE_AND_PERCENTAGE_FIELDS}
    query StreamOverviewData($streamDataId: UUID!) {
        streamOverviewData(streamDataId: $streamDataId) {
            peakViewers {
                ...ValueAndPercentageFields
            }
            subscribersGained {
                ...ValueAndPercentageFields
            }
            averageViewers {
                ...ValueAndPercentageFields
            }
            averagePositionInDirectory {
                ...ValueAndPercentageFields
            }
            hoursWatched {
                ...ValueAndPercentageFields
            }
            performanceIndicator {
                ...ValueAndPercentageFields
            }
            performanceIndicatorV2 {
                ...ValueAndPercentageFields
            }
            followersGained {
                ...ValueAndPercentageFields
            }
            totalChatMessages {
                ...ValueAndPercentageFields
            }
            clipsMade {
                ...ValueAndPercentageFields
            }
            estimatedUniqueViewers {
                ...ValueAndPercentageFields
            }
            dropRate {
                ...ValueAndPercentageFields
            }
            engagedViewersRatio {
                ...ValueAndPercentageFields
            }
            airtimeSeconds
            dateTimeStart
            dateTimeEnd
        }
    }
`;
