import { useQuery } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { RadioButtonFilterConfig } from '../../components/RadioButtonFilter';
import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import {
    DayOfWeek,
    DayOfWeekResult,
    WhenToStream as WhenToStreamType
} from '../../graphql/generated/graphqlTypesAnalytics';
import WHEN_TO_STREAM, {
    WhenToStreamData,
    WhenToStreamDataArgs
} from '../../graphql/queries/whenToStream';
import decapitalize from '../../helpers/decapitalize';
import {
    sectionVisitedWhenToStreamKey,
    storageSet,
    StorageType
} from '../../helpers/storage';
import useLocalStorage from '../../hooks/useLocalStorage';

const now = new Date();
const msInDay = 86400000;

export const timeFilterConfig: RadioButtonFilterConfig[] = [
    {
        label: 'Past 30 days',
        value: 30,
        isActive: true
    },
    {
        label: 'Past 60 days',
        value: 60,
        isActive: true
    },
    {
        label: 'Past 90 days',
        value: 90,
        isActive: true
    },
    {
        label: 'Past 180 days',
        value: 180,
        isActive: true,
        isPremium: true
    },
    {
        label: 'Past 365 days',
        value: 365,
        isActive: true,
        isPremium: true
    }
];

export const emptyDaysFilterConfig = [
    {
        label: 'Hide days without data',
        value: 0,
        isActive: true
    },
    {
        label: 'Show days without data',
        value: 1,
        isActive: true
    }
];

export const whenToStreamTabNavConfig = [
    {
        value: 'what-time-to-stream',
        label: 'What time to stream'
    },
    {
        value: 'what-day-to-stream',
        label: 'What day to stream'
    },
    {
        value: 'stream-length-analytics',
        label: 'How long to stream'
    }
];

interface OutletContext {
    data: WhenToStreamType;
    displayPeriod?: RadioButtonFilterConfig;
    setDisplayPeriod: (
        value:
            | RadioButtonFilterConfig
            | ((val: RadioButtonFilterConfig) => RadioButtonFilterConfig)
    ) => void;
    showEmptyDays: RadioButtonFilterConfig;
    setShowEmptyDays: (
        value:
            | RadioButtonFilterConfig
            | ((val: RadioButtonFilterConfig) => RadioButtonFilterConfig)
    ) => void;
    emptyDaysCount?: number;
    notEmptyDaysCount?: number;
    loading?: boolean;
    whatTimeToStreamIsDemo?: boolean;
    whatDayToStreamIsDemo?: boolean;
    howLongToStreamIsDemo?: boolean;
}

export const useWhenToStreamOutletContext = () => {
    return useOutletContext<OutletContext>();
};

function WhenToStream(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [displayPeriod, setDisplayPeriod] =
        useLocalStorage<RadioButtonFilterConfig>(
            `whenToStreamTimePeriod-filter`,
            timeFilterConfig[2]
        );
    const [showEmptyDays, setShowEmptyDays] =
        useLocalStorage<RadioButtonFilterConfig>(
            `whatDayToStreamShowEmptyDays-filter`,
            emptyDaysFilterConfig[0]
        );

    const { loading, data } = useQuery<WhenToStreamData, WhenToStreamDataArgs>(
        WHEN_TO_STREAM,
        {
            variables: {
                dateTimeFrom: new Date(
                    now.getTime() - msInDay * (displayPeriod?.value ?? 0)
                ),
                dateTimeTo: now
            }
        }
    );

    const [d, emptyDaysCount, notEmptyDaysCount] = useMemo(() => {
        let emptyDays = 0;
        let notEmptyDays = 0;
        const weekDays = {
            MONDAY: 1,
            TUESDAY: 2,
            WEDNESDAY: 3,
            THURSDAY: 4,
            FRIDAY: 5,
            SATURDAY: 6,
            SUNDAY: 7
        };

        const dayOfWeekResults: DayOfWeekResult[] = [
            ...(data?.whenToStream.dayOfWeekResults ?? [])
        ];

        Object.keys(weekDays).forEach((day) => {
            const missingDay = data?.whenToStream.dayOfWeekResults?.findIndex(
                (item) => item.dayOfWeek.toLowerCase() === day.toLowerCase()
            );

            if (missingDay && missingDay < 0) {
                emptyDays++;

                if (showEmptyDays?.value === 1) {
                    dayOfWeekResults.push({
                        averageFollowersGainedPerHour: 0,
                        averageFollowersGained: 0,
                        averageViewers: 0,
                        averagePeakViewers: 0,
                        averageWatchtime: 0,
                        averageUniqueChatters: 0,
                        averageUniqueActiveChatters: 0,
                        averageChatMessagesPerHour: 0,
                        numberOfStreams: 0,
                        averageHoursWatched: 0,
                        averageHoursStreamed: 0,
                        dayOfWeek:
                            DayOfWeek[
                                decapitalize(day) as keyof typeof DayOfWeek
                            ]
                    });
                }
            } else {
                notEmptyDays++;
            }
        });

        dayOfWeekResults.sort((a, b) => {
            const day1 = a.dayOfWeek;
            const day2 = b.dayOfWeek;

            return weekDays[day1] - weekDays[day2];
        });

        return [
            {
                whenToStream: {
                    ...data?.whenToStream,
                    dayOfWeekResults
                }
            },
            emptyDays,
            notEmptyDays
        ];
    }, [data, showEmptyDays]);

    useEffect(() => {
        const hasData =
            (emptyDaysCount ?? 0) > 0 && (notEmptyDaysCount ?? 0) > 1;

        if (!hasData) {
            setShowEmptyDays(emptyDaysFilterConfig[1]);
        }
    }, [showEmptyDays, setShowEmptyDays, emptyDaysCount, notEmptyDaysCount]);

    useEffect(() => {
        const handleSectionVisited = async () => {
            await storageSet<boolean>(
                sectionVisitedWhenToStreamKey,
                true,
                StorageType.local
            );
        };

        void handleSectionVisited();
    }, []);

    return (
        <>
            {!isMobile && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={whenToStreamTabNavConfig}
                    />
                </TopMenu>
            )}

            <Outlet
                context={{
                    data: d?.whenToStream ?? {},
                    displayPeriod,
                    setDisplayPeriod,
                    showEmptyDays,
                    setShowEmptyDays,
                    emptyDaysCount,
                    notEmptyDaysCount,
                    loading,
                    whatTimeToStreamIsDemo:
                        data?.whenToStream?.dayOfWeekResults?.length === 0 &&
                        data.whenToStream?.weekendAverageViewers?.length === 0,
                    whatDayToStreamIsDemo:
                        data?.whenToStream?.dayOfWeekResults?.length === 0,
                    howLongToStreamIsDemo:
                        data?.whenToStream.joinedStreamPeriodicAverageViewers
                            ?.length === 0 &&
                        data?.whenToStream
                            .joinedStreamPeriodicAverageFollowersGained
                            ?.length === 0 &&
                        data?.whenToStream
                            .joinedStreamPeriodicTotalFollowersGained
                            ?.length === 0
                }}
            />
        </>
    );
}

export default WhenToStream;
