import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

interface MenuBadgeProps {
    color?: string;
    isPremium?: boolean;
    ml?: number;
    children: ReactNode;
}

const MenuBadge = ({
    color,
    isPremium = false,
    ml = 2,
    children
}: MenuBadgeProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
                borderRadius: 2,
                color: isPremium ? 'accentYellow.dark' : color,
                fontSize: '1.4rem',
                lineHeight: '2rem',
                py: '2px',
                px: '6px',
                ml,
                backgroundImage: isPremium
                    ? theme.gradients.product20Horizontal
                    : 'none',
                backgroundColor: isPremium
                    ? 'baseCloud.transparent10'
                    : alpha(color ?? '', 0.2)
            }}
        >
            {children}
        </Box>
    );
};

export default MenuBadge;
