import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { DebouncedState, useDebounce } from 'use-debounce';

import GameKeysWelcomeModal from '../../components/GameKeysWelcomeModal';
import Loader from '../../components/Loader';
import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import { UserAvailableGameDeveloperCampaign } from '../../graphql/generated/graphqlTypesAnalytics';
import { AvailableGameKeysForUserVars } from '../../graphql/queries/availableGameKeysForUser';
import {
    gameDeveloperLandingPageDataKey,
    sectionVisitedGameKeysKey,
    storageGet,
    storageSet,
    StorageType
} from '../../helpers/storage';
import useGameKeys from '../../hooks/useGameKeys';
import { GameDeveloperLandingPageData } from '../../types';

export const gameKeysTabNavConfig = [
    {
        value: 'get-free-games',
        label: 'Get free games'
    },
    {
        value: 'my-games',
        label: 'My games'
    },
    {
        value: 'key-campaign',
        label: 'Key Campaign'
    }
];

interface OutletContext {
    searchValue: string;
    setSearchValue: DebouncedState<(value: string) => void>;
    allGameKeys?: UserAvailableGameDeveloperCampaign[];
    availableGameKeys?: UserAvailableGameDeveloperCampaign[];
    myGameKeys?: UserAvailableGameDeveloperCampaign[];
    availableGameKeysForUserVars?: AvailableGameKeysForUserVars;
    isEligibleForCampaign?: boolean;
}

export const useGameKeysOutletContext = () => {
    return useOutletContext<OutletContext>();
};

function GameKeys(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [searchValue, setSearchValue] = useDebounce<string>('', 500);
    const [
        gameDeveloperCampaignLocalStorageData,
        setGameDeveloperCampaignLocalStorageData
    ] = useState<GameDeveloperLandingPageData>();

    const variables = {
        showMyKeys: true,
        fullTextSearch: searchValue
    };

    const { gameKeys, availableGameKeys, myGameKeys, loading } =
        useGameKeys(variables);

    const gameKeysTabNavConfigWithKeysCounter = useMemo(() => {
        const config = gameKeysTabNavConfig.map((item) => {
            if (item.value === 'get-free-games') {
                return {
                    ...item,
                    numberBadgeValue: availableGameKeys?.length
                };
            } else if (item.value === 'my-games') {
                return {
                    ...item,
                    numberBadgeValue: myGameKeys?.length
                };
            }

            return item;
        });

        if (gameDeveloperCampaignLocalStorageData?.gameName && config[2]) {
            config[2] = {
                value: 'key-campaign',
                label: `Key Campaign - ${gameDeveloperCampaignLocalStorageData.gameName}`
            };
        } else {
            config.splice(2, 1);
        }

        return config;
    }, [availableGameKeys, myGameKeys, gameDeveloperCampaignLocalStorageData]);

    const isEligibleForCampaign = useMemo(
        () =>
            availableGameKeys?.some(
                (gameKey) =>
                    gameKey.id ===
                    gameDeveloperCampaignLocalStorageData?.campaignId
            ),
        [availableGameKeys, gameDeveloperCampaignLocalStorageData]
    );

    useEffect(() => {
        const handleStorage = async () => {
            const localStorageData =
                await storageGet<GameDeveloperLandingPageData>(
                    gameDeveloperLandingPageDataKey,
                    StorageType.local
                );

            setGameDeveloperCampaignLocalStorageData(localStorageData);
        };

        void handleStorage();
    }, []);

    useEffect(() => {
        const handleSectionVisited = async () => {
            await storageSet<boolean>(
                sectionVisitedGameKeysKey,
                true,
                StorageType.local
            );
        };

        void handleSectionVisited();
    }, []);

    return (
        <>
            {!isMobile && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={gameKeysTabNavConfigWithKeysCounter}
                    />
                </TopMenu>
            )}

            {loading && <Loader />}

            {!loading && (
                <>
                    <Outlet
                        context={{
                            searchValue,
                            setSearchValue,
                            allGameKeys: gameKeys?.availableGameKeysForUser,
                            availableGameKeys,
                            myGameKeys,
                            availableGameKeysForUserVars: variables,
                            isEligibleForCampaign
                        }}
                    />
                    <GameKeysWelcomeModal />
                </>
            )}
        </>
    );
}

export default GameKeys;
