import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { ReactComponent as KeyIcon } from '../assets/icons/key.svg';
import { ReactComponent as RepeatIcon } from '../assets/icons/repeat.svg';
import { ReactComponent as TvIcon } from '../assets/icons/tv.svg';
import { ReactComponent as ViewerIcon } from '../assets/icons/viewer.svg';
import tripleGamesStack from '../assets/images/triple-games-stack.png';
import { gameKeysWelcomeModalKey } from '../helpers/storage';
import useLocalStorage from '../hooks/useLocalStorage';
import BoxIcon from './BoxIcon';
import Modal from './Modal';

const GameKeysWelcomeModal = (): JSX.Element => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isModalShowed, setIsModalShowed] = useLocalStorage<boolean>(
        gameKeysWelcomeModalKey,
        false
    );

    useEffect(() => {
        if (isModalShowed === false) {
            setIsOpen(true);
            setIsModalShowed(true);
        }
    }, [isModalShowed, setIsOpen, setIsModalShowed]);

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            width={`${740}px`}
            height="auto"
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    component="img"
                    src={tripleGamesStack}
                    alt="Games to stream"
                    sx={{
                        width: '361px'
                    }}
                />

                <Typography
                    sx={{
                        fontSize: '3.2rem',
                        lineHeight: '4rem',
                        fontWeight: 600,
                        mt: 2
                    }}
                >
                    Introducing Free Games
                </Typography>

                <Typography
                    sx={{
                        mt: 3,
                        textAlign: 'center'
                    }}
                >
                    Get free games in exchange for streaming them. Exclusively
                    by StreamBee and Game Developers & Publishers.
                </Typography>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        my: 8
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            pb: 6,
                            borderBottom: '1px solid',
                            borderColor: 'baseCloud.transparent20'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                pr: 4
                            }}
                        >
                            <BoxIcon
                                icon={<ViewerIcon />}
                                iconSize={24}
                                padding={12}
                                mr={3}
                                background={
                                    theme.palette.baseCloud.transparent10
                                }
                                iconColor={theme.palette.common.white}
                                hasBoxShadow
                                hasBorder
                                border={`linear-gradient(155deg, ${
                                    theme.palette.baseCloud.transparent10 ?? ''
                                } 0%, transparent 60%)`}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1.8rem',
                                        lineHeight: '2.8rem',
                                        fontWeight: 600
                                    }}
                                >
                                    Browse your free games
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '1.4rem',
                                        lineHeight: '2rem',
                                        mt: 0.5
                                    }}
                                >
                                    Fully personalized for you.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                pl: 4
                            }}
                        >
                            <BoxIcon
                                icon={<KeyIcon />}
                                iconSize={24}
                                padding={12}
                                mr={3}
                                background={
                                    theme.palette.baseCloud.transparent10
                                }
                                iconColor={theme.palette.common.white}
                                hasBoxShadow
                                hasBorder
                                border={`linear-gradient(155deg, ${
                                    theme.palette.baseCloud.transparent10 ?? ''
                                } 0%, transparent 60%)`}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1.8rem',
                                        lineHeight: '2.8rem',
                                        fontWeight: 600
                                    }}
                                >
                                    Claim your key
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '1.4rem',
                                        lineHeight: '2rem',
                                        mt: 0.5
                                    }}
                                >
                                    Up to 2 games at a time.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            pt: 6
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                pr: 4
                            }}
                        >
                            <BoxIcon
                                icon={<TvIcon />}
                                iconSize={24}
                                padding={12}
                                mr={3}
                                background={
                                    theme.palette.baseCloud.transparent10
                                }
                                iconColor={theme.palette.common.white}
                                hasBoxShadow
                                hasBorder
                                border={`linear-gradient(155deg, ${
                                    theme.palette.baseCloud.transparent10 ?? ''
                                } 0%, transparent 60%)`}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1.8rem',
                                        lineHeight: '2.8rem',
                                        fontWeight: 600
                                    }}
                                >
                                    Stream the game
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '1.4rem',
                                        lineHeight: '2rem',
                                        mt: 0.5
                                    }}
                                >
                                    You have 15 days to stream it.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                pl: 4
                            }}
                        >
                            <BoxIcon
                                icon={<RepeatIcon />}
                                iconSize={24}
                                padding={12}
                                mr={3}
                                background={
                                    theme.palette.baseCloud.transparent10
                                }
                                iconColor={theme.palette.common.white}
                                hasBoxShadow
                                hasBorder
                                border={`linear-gradient(155deg, ${
                                    theme.palette.baseCloud.transparent10 ?? ''
                                } 0%, transparent 60%)`}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1.8rem',
                                        lineHeight: '2.8rem',
                                        fontWeight: 600
                                    }}
                                >
                                    Repeat
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '1.4rem',
                                        lineHeight: '2rem',
                                        mt: 0.5
                                    }}
                                >
                                    There's always more games.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        mt: 6
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        Let's do it
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default GameKeysWelcomeModal;
