import { gql } from '@apollo/client';

export default gql`
    fragment UserSubscriptionFields on UserSubscription {
        userSubscriptionStatus
        userSubscriptionPlan
        productNickName
        validTo
        isActive
        cancelAtPeriodEnd
        sessionId
    }
`;
