import { gql } from '@apollo/client';

export default gql`
    fragment StreamDataRetentionFields on StreamDataRetention {
        streamDataRetentionType
        items {
            maxPercentageOfStream
            minPercentageOfStream
            minTimeInSeconds
            maxTimeInSeconds
            percentageOfViewers
        }
    }
`;
