import { OidcClient } from 'oidc-client-ts';
import { v4 as uuidv4 } from 'uuid';

import wait from './wait';

async function fetchUsername(uuid: string) {
    try {
        const response = await fetch(
            `${
                process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY as string
            }/api/ext/GetUserNameFromRequestId?requestId=${uuid}`,
            {
                method: 'GET',
                headers: { 'Content-Type': 'text/plain' }
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();

        return data.length > 0 ? data : undefined;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error("Couldn't fetch username!", error);

        return 'error';
    }
}

interface FetchTokenResponse {
    access_token?: string;
}

async function fetchToken(
    uuid: string,
    username: string
): Promise<FetchTokenResponse | undefined> {
    try {
        const params = {
            grant_type: 'password',
            scope: 'openid profile StreamBeeApi StreamBeeAnalytics',
            username,
            password: uuid,
            client_id: 'StreamLabs'
        };
        const urlParams = new URLSearchParams(params);

        const response = await fetch(
            `${
                process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY as string
            }/connect/token`,
            {
                method: 'POST',
                body: urlParams
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = (await response.json()) as FetchTokenResponse;

        return data;
    } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error("Couldn't fetch token!", error);
    }
}

const redirectUri = `slobs://app/${
    process.env.REACT_APP_SLOBS_APP_ID as string
}`;
const client = new OidcClient({
    authority: process.env.REACT_APP_IDENTITY_SERVER_AUTHORITY as string,
    client_id: process.env.REACT_APP_IDENTITY_SERVER_SLOBS_CLIENT_ID as string,
    post_logout_redirect_uri: '/',
    redirect_uri: redirectUri,
    scope: 'openid profile StreamBeeApi StreamBeeAnalytics',
    response_type: 'code'
});

const fetchUsernameInterval = 2000;
export default async (): Promise<string | null> => {
    const uuid = uuidv4();

    await wait(2000);
    const req = await client.createSigninRequest({
        extraQueryParams: {
            requestId: uuid,
            isUltra: window?.isUltra ?? false
        }
    });

    const searchParams = `?${req.url.split('?')[1]}`;
    window.streamlabsOBS.v1.External.openExternalLink(
        `${
            process.env.REACT_APP_BASE_URL as string
        }/auth/slobs-login${searchParams}`
    );

    let username: string | undefined;
    while (!username) {
        username = await fetchUsername(uuid);

        await wait(fetchUsernameInterval);
    }

    const token = await fetchToken(uuid, username);
    if (token && token?.access_token) {
        return token?.access_token;
    }

    return null;
};
