import { keyframes } from '@emotion/react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ScaleBand } from 'd3-scale';
import React, { useState } from 'react';

import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';
import openInNewTab from '../../helpers/openInNewTab';
import parseMinutesForTimeline from '../../helpers/parseMinutesForTimeline';
import { StreamDataTwitchGameWithColor, TopMoment } from '../../types';
import { useAnalytics } from '../TrackingProvider';

interface TopMomentsProps {
    left: number;
    topMoments?: TopMoment[];
    scaleX?: ScaleBand<number>;
    scaleXPadding?: number;
    games: StreamDataTwitchGameWithColor[];
}

interface TooltipData {
    index: number;
    gameColor: string;
    time: string;
    clipUrl: string;
    thumbnailUrl: string;
    concurrentViewers: number;
    activeViewersRatio: number;
    interactions: number;
    emoteUrl: string;
}

export const breatheAnimation = keyframes`
  0% {
    transform: scale(0.75);
  }
  25% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
`;

const TopMoments = ({
    left,
    topMoments,
    scaleX,
    scaleXPadding = 0.3,
    games
}: TopMomentsProps): JSX.Element => {
    const analytics = useAnalytics();
    const theme = useTheme();
    const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    paddingTop: '20px',
                    position: 'absolute',
                    zIndex: 11,
                    top: ['28px', undefined, '48px'],
                    left: '-8px',
                    width: 'calc(100% + 16px)',
                    height: '172px',
                    pointerEvents: tooltipData ? 'all' : 'none'
                }}
                onMouseEnter={() => setTooltipData(tooltipData)}
                onMouseLeave={() => setTooltipData(null)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        borderRadius: 4,
                        p: 4,
                        pt: 3,
                        overflow: 'hidden',
                        height: '152px',
                        transition: tooltipData
                            ? theme.transitions.create(['opacity'], {
                                  duration: theme.transitions.duration.standard
                              })
                            : 'none',
                        opacity: tooltipData ? 1 : 0,
                        backdropFilter: [
                            'none',
                            undefined,
                            theme.blurs.elevation2
                        ],
                        backgroundImage: [
                            'none',
                            undefined,
                            `linear-gradient(180deg, ${
                                theme.palette.baseCloud.transparent20 ?? ''
                            } 0%, ${
                                theme.palette.baseCloud.transparent10 ?? ''
                            } 100%)`
                        ],
                        backgroundColor: [
                            'baseCloud.blurFallback',
                            undefined,
                            'transparent'
                        ]
                    }}
                >
                    <Box
                        sx={{
                            mb: 3,
                            display: 'flex'
                        }}
                    >
                        <Box
                            sx={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: tooltipData?.gameColor ?? '',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2
                            }}
                        >
                            <Typography
                                sx={{
                                    lineHeight: '2rem',
                                    fontSize: '1.4rem'
                                }}
                            >
                                {tooltipData?.index}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '1.4rem',
                                lineHeight: '2rem'
                            }}
                        >
                            Top moment
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '1.4rem',
                                lineHeight: '2rem',
                                mx: 2
                            }}
                        >
                            ·
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '1.4rem',
                                lineHeight: '2rem',
                                color: 'text.secondary'
                            }}
                        >
                            {tooltipData?.time}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'stretch',
                            height: '100%',
                            gap: 4
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexBasis: '20%',
                                height: '95px',
                                flexGrow: 0,
                                minWidth: 0,
                                backgroundColor: 'baseCloud.transparent10',
                                borderRadius: 2,
                                px: 4,
                                py: 3,
                                cursor: 'pointer',
                                transition: theme.transitions.create(
                                    ['background-color'],
                                    {
                                        duration:
                                            theme.transitions.duration.standard
                                    }
                                ),
                                '&:hover': {
                                    backgroundColor: 'baseCloud.transparent15'
                                }
                            }}
                            onClick={() => {
                                if (tooltipData?.clipUrl) {
                                    openInNewTab(tooltipData.clipUrl);
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    mr: 'auto',
                                    pr: 2,
                                    minWidth: '20px'
                                }}
                                noWrap
                            >
                                Clip
                            </Typography>
                            {tooltipData?.thumbnailUrl ? (
                                <Box
                                    sx={{
                                        width: '121px',
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={tooltipData.thumbnailUrl}
                                        alt="Top clip"
                                        sx={{
                                            height: '100%',
                                            width: 'auto'
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        borderRadius: 1,
                                        width: '121px',
                                        backgroundColor:
                                            'baseCloud.transparent10',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <BanIcon />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexBasis: '20%',
                                height: '95px',
                                flexGrow: 0,
                                minWidth: 0,
                                backgroundColor: 'baseCloud.transparent10',
                                borderRadius: 2,
                                px: 4,
                                py: 3
                            }}
                        >
                            <Typography
                                sx={{
                                    mr: 'auto'
                                }}
                                noWrap
                            >
                                Viewers
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '2.4rem',
                                    lineHeight: '3.2rem',
                                    fontWeight: 600,
                                    mt: 'auto'
                                }}
                            >
                                {Math.round(
                                    tooltipData?.concurrentViewers ?? 0
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexBasis: '20%',
                                height: '95px',
                                flexGrow: 0,
                                minWidth: 0,
                                backgroundColor: 'baseCloud.transparent10',
                                borderRadius: 2,
                                px: 4,
                                py: 3
                            }}
                        >
                            <Typography
                                sx={{
                                    mr: 'auto'
                                }}
                                noWrap
                            >
                                Active viewers
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '2.4rem',
                                    lineHeight: '3.2rem',
                                    fontWeight: 600,
                                    mt: 'auto'
                                }}
                            >
                                {Math.round(
                                    (tooltipData?.activeViewersRatio ?? 0) * 100
                                )}
                                %
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexBasis: '20%',
                                height: '95px',
                                flexGrow: 0,
                                minWidth: 0,
                                backgroundColor: 'baseCloud.transparent10',
                                borderRadius: 2,
                                px: 4,
                                py: 3
                            }}
                        >
                            <Typography
                                sx={{
                                    mr: 'auto'
                                }}
                                noWrap
                            >
                                Interactions
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '2.4rem',
                                    lineHeight: '3.2rem',
                                    fontWeight: 600,
                                    mt: 'auto'
                                }}
                            >
                                {Math.round(tooltipData?.interactions ?? 0)}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexBasis: '20%',
                                height: '95px',
                                flexGrow: 0,
                                minWidth: 0,
                                alignItems: 'flex-start',
                                backgroundColor: 'baseCloud.transparent10',
                                borderRadius: 2,
                                px: 4,
                                py: 3
                            }}
                        >
                            <Typography
                                sx={{
                                    mr: 'auto'
                                }}
                                noWrap
                            >
                                Top emote
                            </Typography>
                            {tooltipData?.emoteUrl ? (
                                <Box
                                    component="img"
                                    src={tooltipData?.emoteUrl}
                                    alt="Top emote"
                                    sx={{
                                        borderRadius: 1,
                                        maxWidth: '32px',
                                        mt: 'auto'
                                    }}
                                />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '2.4rem',
                                        lineHeight: '3.2rem',
                                        fontWeight: 600,
                                        mt: 'auto'
                                    }}
                                >
                                    &#x2014;
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            width: '2px',
                            position: 'absolute',
                            left: 0,
                            top: '12px',
                            bottom: '12px',
                            borderRadius: '1px',
                            backgroundColor: tooltipData?.gameColor ?? '',
                            boxShadow: `0px 0px 11px 2px ${
                                tooltipData?.gameColor ?? ''
                            }`
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: '-22px',
                    overflow: 'hidden',
                    zIndex: 10
                }}
            >
                {topMoments &&
                    topMoments.length > 0 &&
                    scaleX &&
                    topMoments.map((item, index) => {
                        const dotSize = isMobile ? 25 : 28;
                        const x = scaleX(item.time) ?? 0;
                        const bandwidth = scaleX.bandwidth();
                        const padding = bandwidth * scaleXPadding;
                        const barWidth = bandwidth - padding;
                        const gameData = games?.filter(
                            (game) => game.twitchGameId === item.twitchGame?.id
                        )[0];

                        return (
                            <Box
                                key={`${item.time}${index}`}
                                sx={{
                                    position: 'absolute',
                                    left:
                                        x +
                                        left +
                                        padding / 2 +
                                        barWidth / 2 -
                                        dotSize / 2,
                                    top: ['16px', undefined, '30px'],
                                    width: dotSize,
                                    height: dotSize,
                                    zIndex: 12,
                                    cursor: 'pointer'
                                }}
                                onMouseEnter={() => {
                                    setTooltipData({
                                        index: index + 1,
                                        gameColor: gameData?.color ?? '',
                                        time: parseMinutesForTimeline(
                                            item.time
                                        ),
                                        thumbnailUrl:
                                            item.topClip?.thumbnailUrl ?? '',
                                        clipUrl: item.topClip?.url ?? '',
                                        emoteUrl: item.topEmote?.url1X ?? '',
                                        activeViewersRatio:
                                            item.activeViewersRatio,
                                        concurrentViewers:
                                            item.concurrentViewers,
                                        interactions: item.interactions
                                    });

                                    void analytics?.track('Top moment hover', {
                                        index: index + 1,
                                        time: item.time
                                    });
                                }}
                                onMouseLeave={() => setTooltipData(null)}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        width: dotSize,
                                        height: dotSize,
                                        borderRadius: '50%',
                                        backgroundColor: gameData?.color ?? '',
                                        opacity: 0.5,
                                        cursor: 'pointer',
                                        transition: theme.transitions.create(
                                            ['transform'],
                                            {
                                                duration:
                                                    theme.transitions.duration
                                                        .standard
                                            }
                                        ),
                                        transform:
                                            tooltipData?.index === index + 1
                                                ? 'scale(1)'
                                                : 'scale(0)'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        width: dotSize,
                                        height: dotSize,
                                        borderRadius: '50%',
                                        backgroundColor: gameData?.color ?? '',
                                        opacity: 0.5,
                                        cursor: 'pointer',
                                        display: tooltipData ? 'none' : 'block',
                                        animation: `${breatheAnimation} 2.5s ease-out infinite normal`
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        borderLeft: '5px solid transparent',
                                        borderRight: '5px solid transparent',
                                        borderBottom: '5px solid',
                                        pointerEvents: 'none',
                                        width: 0,
                                        height: 0,
                                        top: ['27px', undefined, '33px'],
                                        left: ['8px', undefined, '9px'],
                                        transition: tooltipData
                                            ? theme.transitions.create(
                                                  ['opacity'],
                                                  {
                                                      duration:
                                                          theme.transitions
                                                              .duration.standard
                                                  }
                                              )
                                            : 'none',
                                        opacity:
                                            tooltipData?.index === index + 1
                                                ? 1
                                                : 0,
                                        borderBottomColor: [
                                            'baseCloud.blurFallback',
                                            undefined,
                                            theme.palette.baseCloud
                                                .transparent20
                                        ]
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: dotSize - 8,
                                        height: dotSize - 8,
                                        top: '4px',
                                        left: '4px',
                                        borderRadius: '50%',
                                        backgroundColor: gameData?.color ?? '',
                                        opacity: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: '2rem',
                                            fontSize: [
                                                '1.2rem',
                                                undefined,
                                                '1.4rem'
                                            ]
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
        </>
    );
};

export default TopMoments;
