import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../assets/icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from '../assets/icons/chevronRight.svg';
import { DEMO_ID } from '../configs';
import GlobalStateContext from '../context';
import { setStreamScrollPos } from '../context/actions';
import SEARCH_STREAM_DATA_IDS, {
    SearchStreamDataIds,
    SearchStreamDataIdsArgs
} from '../graphql/queries/searchStreamDataIds';
import {
    noStreamsModalKey,
    sectionVisitedGrowthKey,
    sectionVisitedWhatToStreamKey,
    sectionVisitedWhenToStreamKey,
    storageGet,
    storageSet,
    StorageType,
    streamNavigationTooltipKey
} from '../helpers/storage';
import { streamTabNavConfig } from '../modules/Stream';
import { streamRoute } from '../routes';
import { StreamParams } from '../types';

interface StreamNavigationProps {
    userProfileId?: string;
}

const now = new Date();
const StreamNavigation = ({
    userProfileId
}: StreamNavigationProps): JSX.Element => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { dispatch } = useContext(GlobalStateContext);
    const { id } = useParams<StreamParams>();
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const { loading, data } = useQuery<
        SearchStreamDataIds,
        SearchStreamDataIdsArgs
    >(SEARCH_STREAM_DATA_IDS, {
        variables: {
            dateTimeEnd: now,
            userProfileIds: userProfileId ? [userProfileId] : []
        }
    });

    const { prevId, nextId } = useMemo(() => {
        const index = data?.searchStreamData?.data?.findIndex(
            (stream) => stream.id === id
        );

        if (index !== undefined) {
            return {
                prevId: data?.searchStreamData?.data?.[index - 1]?.id,
                nextId: data?.searchStreamData?.data?.[index + 1]?.id
            };
        }

        return {
            prevId: undefined,
            nextId: undefined
        };
    }, [data, id]);

    const handleClose = useCallback(async () => {
        if (isTooltipOpen) {
            setIsTooltipOpen(false);
            await storageSet<boolean>(
                streamNavigationTooltipKey,
                true,
                StorageType.local
            );
        }
    }, [isTooltipOpen]);

    const handleNavigate = useCallback(
        (newId: string) => {
            const currentTabName = location.pathname.split('/')[3];
            const currentTabNavConfigItem = streamTabNavConfig.find(
                (tab) => tab.value === currentTabName
            );

            const scrollPos = window.scrollY;

            if (scrollPos > 0) {
                dispatch(setStreamScrollPos(scrollPos));
            }

            navigate({
                pathname: streamRoute(
                    `${newId}${
                        currentTabNavConfigItem
                            ? `/${currentTabNavConfigItem.value}`
                            : ''
                    }`
                ),
                search: location.search
            });
        },
        [dispatch, location.pathname, location.search, navigate]
    );

    const handleKeyPress = useCallback(
        async (event: KeyboardEvent) => {
            if (event.key === 'j' && prevId) {
                handleNavigate(prevId);

                await handleClose();
            } else if (event.key === 'k' && nextId) {
                handleNavigate(nextId);

                await handleClose();
            }
        },
        [prevId, nextId, handleClose, handleNavigate]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        const openTooltip = async () => {
            const demoTooltipClosed = await storageGet<boolean>(
                noStreamsModalKey,
                StorageType.local
            );
            const streamNavigationTooltipClosed = await storageGet<boolean>(
                streamNavigationTooltipKey,
                StorageType.local
            );
            const growthVisited = await storageGet<boolean>(
                sectionVisitedGrowthKey,
                StorageType.local
            );
            const whatToStreamVisited = await storageGet<boolean>(
                sectionVisitedWhatToStreamKey,
                StorageType.local
            );
            const whenToStreamVisited = await storageGet<boolean>(
                sectionVisitedWhenToStreamKey,
                StorageType.local
            );

            if (
                demoTooltipClosed &&
                growthVisited &&
                whatToStreamVisited &&
                whenToStreamVisited &&
                !streamNavigationTooltipClosed
            ) {
                setIsTooltipOpen(true);
            }
        };

        void openTooltip();
    }, [setIsTooltipOpen]);

    const isDemo = id === DEMO_ID;

    return (
        <>
            {id && !isDemo && !loading && (
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        variant="icon"
                        color="subtle"
                        disabled={!prevId}
                        sx={{
                            mr: 2,
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        onClick={async () => {
                            if (prevId) {
                                handleNavigate(prevId);

                                await handleClose();
                            }
                        }}
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <Button
                        variant="icon"
                        color="subtle"
                        disabled={!nextId}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        onClick={async () => {
                            if (nextId) {
                                handleNavigate(nextId);

                                await handleClose();
                            }
                        }}
                    >
                        <ChevronRightIcon />
                    </Button>

                    <Box
                        sx={{
                            position: 'absolute',
                            right: '18px',
                            top: '52px',
                            height: '8px',
                            zIndex: 'demoTooltip',
                            opacity: isTooltipOpen ? 1 : 0,
                            pointerEvents: 'none',
                            transition: theme.transitions.create(['opacity'], {
                                duration: theme.transitions.duration.standard
                            }),
                            '& > svg': {
                                position: 'absolute',
                                transform: 'translateX(-50%)'
                            }
                        }}
                    >
                        <svg
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.58579 1.41421L0 8H16L9.41421 1.41421C8.63317 0.633165 7.36684 0.633163 6.58579 1.41421Z"
                                fill={theme.palette.baseCloud.transparent20}
                            />
                        </svg>
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '60px',
                            width: '320px',
                            zIndex: 'demoTooltip',
                            opacity: isTooltipOpen ? 1 : 0,
                            pointerEvents: isTooltipOpen ? 'all' : 'none',
                            transition: theme.transitions.create(['opacity'], {
                                duration: theme.transitions.duration.standard
                            })
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                px: 3,
                                py: 2,
                                borderRadius: 3,
                                backdropFilter: [
                                    'none',
                                    undefined,
                                    theme.blurs.elevation3
                                ],
                                backgroundColor: [
                                    'baseCloud.blurFallback',
                                    undefined,
                                    'baseCloud.transparent20'
                                ]
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.4rem',
                                    lineHeight: '2rem',
                                    fontWeight: 500,
                                    mb: 3
                                }}
                            >
                                Use J/K keys to navigate through your past
                                broadcasts with a shortcut.
                            </Typography>

                            <Button
                                color="subtle"
                                size="small"
                                sx={{
                                    width: '100%'
                                }}
                                onClick={async () => {
                                    await handleClose();
                                }}
                            >
                                Okay, thanks
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default StreamNavigation;
