import { gql } from '@apollo/client';

export default gql`
    fragment TwitchGameWithIgdbDataFields on TwitchGame {
        id
        twitchId
        name
        description
        imageUrl
        iGDBId
        iGDBSlug
        iGDBScreenshotImageIds
        iGDBFirstReleaseDate
        iGDBGamePublishers
        iGDBGenres
        iGDBGameModes
        iGDBThemes
        iGDBSummary
        iGDBStoryline
        iGDBGameVideoIds
        iGDBArtworkImageIds
        iGDBPlatforms
    }
`;
