import { StreamStatus } from '../generated/graphqlTypesAnalytics';
import { endDate, startDate } from './helpers';
import playedGames from './playedGames';
import { getStreamDataValues } from './searchStreamData';
import { streamTitles } from './streamChannelData';

const index = 9;
const streamDataValues = getStreamDataValues(index);

export default {
    searchStreamData: {
        data: [
            {
                id: '8c0519d5-82b5-4888-bfe8-1aba3e27c3f90',
                version: 1,
                userProfileId: '',
                dateTimeStart: startDate.toDate(),
                dateTimeEnd: endDate.toDate(),
                streamDataTwitchTitles: streamTitles,
                streamDataTwitchGames: playedGames,
                streamChatProcessingErrorMessages: StreamStatus.Finished,
                streamDataProcessingStatus: StreamStatus.Finished,
                streamChatProcessingStatus: StreamStatus.Finished,
                twitchVideoId: '',
                ...streamDataValues
            }
        ]
    }
};
