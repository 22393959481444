import { createContext, Reducer } from 'react';

import { storageSetSync, StorageType } from '../helpers/storage';
import {
    isDeveloperViewKey,
    slobsTokenKey,
    storageGetSync
} from './../helpers/storage';

export enum ActionTypes {
    SET_LEFT_MENU_OPEN = 'SET_LEFT_MENU_OPEN',
    SET_MODAL_STATE = 'SET_MODAL_STATE',
    SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP',
    SET_SLOBS_TOKEN = 'SET_SLOBS_TOKEN',
    SET_STREAM_SCROLL_POS = 'SET_STREAM_SCROLL_POS',
    SET_USER_COUNTRY = 'SET_USER_COUNTRY',
    SET_IS_DEVELOPER_VIEW = 'SET_IS_DEVELOPER_VIEW'
}

export enum ModalState {
    open,
    opening,
    closing,
    closed
}

interface State {
    isLeftMenuOpen: boolean;
    modalState: ModalState;
    onboardingStep: number;
    slobsToken?: string;
    streamScrollPos: number;
    userCountry?: string;
    isDeveloperView?: boolean;
}

const savedToken = storageGetSync<string>(slobsTokenKey, StorageType.local);
const isDeveloperView =
    storageGetSync<boolean>(isDeveloperViewKey, StorageType.local) ?? false;

export const INITIAL_STATE: State = {
    isLeftMenuOpen: false,
    modalState: ModalState.closed,
    onboardingStep: 1,
    slobsToken: savedToken,
    streamScrollPos: 0,
    userCountry: undefined,
    isDeveloperView
};

export interface Action {
    type: ActionTypes;
    payload?: unknown;
}

export default createContext<{
    state: State;
    dispatch: (action: Action) => void;
}>({ state: INITIAL_STATE, dispatch: () => {} });

export const globalReducer: Reducer<State, Action> = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case ActionTypes.SET_LEFT_MENU_OPEN:
            return { ...state, isLeftMenuOpen: payload as boolean };
        case ActionTypes.SET_MODAL_STATE:
            return { ...state, modalState: payload as ModalState };
        case ActionTypes.SET_ONBOARDING_STEP:
            return { ...state, onboardingStep: payload as number };
        case ActionTypes.SET_SLOBS_TOKEN:
            return { ...state, slobsToken: payload as string };
        case ActionTypes.SET_STREAM_SCROLL_POS:
            return { ...state, streamScrollPos: payload as number };
        case ActionTypes.SET_USER_COUNTRY:
            return { ...state, userCountry: payload as string };
        case ActionTypes.SET_IS_DEVELOPER_VIEW:
            storageSetSync<boolean>(
                isDeveloperViewKey,
                payload as boolean,
                StorageType.local
            );

            return { ...state, isDeveloperView: payload as boolean };
        default:
            return state;
    }
};
