import { StreamDataResult } from '../generated/graphqlTypesAnalytics';
import { endDate, startDate } from './helpers';

export default {
    streamOverviewData: {
        peakViewers: {
            value: 81,
            percentage: 38.225255972696246
        },
        subscribersGained: {
            value: 25,
            percentage: 5
        },
        averageViewers: {
            value: 54.9310344827586,
            percentage: 36.577930328592046
        },
        averagePositionInDirectory: {
            value: 87.2413793103448,
            percentage: -46.84073231262044
        },
        hoursWatched: {
            value: 265.5,
            percentage: 35.87512794268168
        },
        performanceIndicator: {
            value: 1.3558386830889422,
            percentage: -98.84308642463805
        },
        performanceIndicatorV2: {
            value: 9,
            percentage: 1
        },
        followersGained: {
            value: 7,
            percentage: 37.254901960784316
        },
        totalChatMessages: {
            value: 1234,
            percentage: -54.79025462538927
        },
        clipsMade: {
            value: 1,
            percentage: 233.33333333333334
        },
        estimatedUniqueViewers: {
            value: 174.08470588235295,
            percentage: -90.46305834937364
        },
        dropRate: {
            value: 0.5519422593463629,
            percentage: -29.677362175853247
        },
        engagedViewersRatio: {
            value: 0.12066342210844043,
            percentage: 241.11226943300164
        },
        airtimeSeconds: 16827,
        dateTimeStart: startDate.toDate(),
        dateTimeEnd: endDate.toDate()
    } as StreamDataResult
};
