import { gql } from '@apollo/client';

import VALUE_AND_PERCENTAGE_FIELDS from '../fragments/valueAndPercentageFields';
import {
    QueryTikTokAccountsDataArgs,
    TikTokAccountData
} from '../generated/graphqlTypesAnalytics';

export interface TikTokAccountsData {
    tikTokAccountsData: TikTokAccountData[];
}

export type TikTokAccountsDataArgs = QueryTikTokAccountsDataArgs;

export default gql`
    ${VALUE_AND_PERCENTAGE_FIELDS}
    query TikTokAccountsData($getTenantData: Boolean!) {
        tikTokAccountsData(getTenantData: $getTenantData) {
            accountId
            tikTokDisplayName
            viewCount {
                ...ValueAndPercentageFields
            }
            shareCount {
                ...ValueAndPercentageFields
            }
            commentCount {
                ...ValueAndPercentageFields
            }
            likeCount {
                ...ValueAndPercentageFields
            }
            likeCount {
                ...ValueAndPercentageFields
            }
            followersCount {
                ...ValueAndPercentageFields
            }
            growth {
                ...ValueAndPercentageFields
            }
            timeStamp
        }
    }
`;
