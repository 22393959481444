import { useQuery } from '@apollo/client';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ExternalLinkIcon } from '../assets/icons/externalLink.svg';
import tikTokReconnectImage from '../assets/images/tikTokReconnect.jpg';
import { tikTokReconnectDate } from '../configs';
import TIK_TOK_ACCOUNTS_DATA, {
    TikTokAccountsData,
    TikTokAccountsDataArgs
} from '../graphql/queries/tikTokAccountsData';
import isSlobs from '../helpers/isSlobs';
import { tiktokReconnectModalKey } from '../helpers/storage';
import useLocalStorage from '../hooks/useLocalStorage';
import Modal from './Modal';
import { useAnalytics } from './TrackingProvider';

const TikTokReconnectModal = (): JSX.Element => {
    const analytics = useAnalytics();
    const [reconnectModalOpen, setReconnectModalOpen] =
        useState<boolean>(false);
    const [modalOpened, setModalOpened] = useLocalStorage<boolean>(
        tiktokReconnectModalKey,
        false
    );

    const { loading: tikTokAccountsDataLoading, data: tikTokAccountsData } =
        useQuery<TikTokAccountsData, TikTokAccountsDataArgs>(
            TIK_TOK_ACCOUNTS_DATA,
            {
                variables: {
                    getTenantData: false
                }
            }
        );

    const firstAccount = tikTokAccountsData?.tikTokAccountsData[0];

    useEffect(() => {
        if (
            !tikTokAccountsDataLoading &&
            !modalOpened &&
            firstAccount &&
            moment(firstAccount.timeStamp).utc() <
                moment(tikTokReconnectDate).utc()
        ) {
            setReconnectModalOpen(true);
            setModalOpened(true);
        }
    }, [firstAccount, tikTokAccountsDataLoading, modalOpened, setModalOpened]);

    return (
        <Modal
            open={reconnectModalOpen}
            onClose={() => setReconnectModalOpen(false)}
            hasCloseButton
            width="600px"
            height="auto"
        >
            {!isSlobs() && (
                <Typography variant="h3">
                    Please reconnect your TikTok
                </Typography>
            )}
            {isSlobs() && (
                <Typography variant="h3">
                    Please reconnect your TikTok on StreamBee.io
                </Typography>
            )}

            <Box
                sx={{
                    mt: 5
                }}
            >
                <Box component="img" src={tikTokReconnectImage} />

                <Typography
                    sx={{
                        mt: 2
                    }}
                >
                    Hey there! TikTok's upgraded to API v 2.0 for better
                    security. Please reconnect your TikTok now. If not, tracking
                    will stop and your data might be lost!{' '}
                    <Link
                        href="https://developers.tiktok.com/bulletin/user-info-scope-migration"
                        target="_blank"
                    >
                        Learn more
                    </Link>
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    mr: 'auto',
                    mt: 5
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    endIcon={<ExternalLinkIcon />}
                    onClick={() => {
                        void analytics?.track(
                            'TikTok reconnect modal button clicked'
                        );

                        if (isSlobs()) {
                            window.streamlabsOBS.v1.External.openExternalLink(
                                `${
                                    process.env.REACT_APP_BASE_URL as string
                                }/account-management`
                            );
                        } else {
                            window.location.replace(
                                `${
                                    process.env
                                        .REACT_APP_IDENTITY_SERVER_AUTHORITY as string
                                }/External/tiktokauth`
                            );
                        }
                    }}
                >
                    Reconnect account
                </Button>
            </Box>
        </Modal>
    );
};

export default TikTokReconnectModal;
