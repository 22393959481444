import { gql } from '@apollo/client';

import GAME_DEVELOPER_CAMPAIGN_STORE_FIELDS from '../fragments/gameDeveloperCampaignStoreFields';
import TWITCH_GAME_WITH_IGDB_DATA_FIELDS from '../fragments/twitchGameWithIgdbDataFields';
import {
    QueryAvailableGameKeysForUserArgs,
    UserAvailableGameDeveloperCampaign
} from '../generated/graphqlTypesAnalytics';

export interface AvailableGameKeysForUserData {
    availableGameKeysForUser: UserAvailableGameDeveloperCampaign[];
}

export type AvailableGameKeysForUserVars = QueryAvailableGameKeysForUserArgs;

export default gql`
    ${GAME_DEVELOPER_CAMPAIGN_STORE_FIELDS}
    ${TWITCH_GAME_WITH_IGDB_DATA_FIELDS}
    query AvailableGameKeysForUser(
        $fullTextSearch: String
        $showMyKeys: Boolean!
        $gameStoreId: UUID
    ) {
        availableGameKeysForUser(
            fullTextSearch: $fullTextSearch
            showMyKeys: $showMyKeys
            gameStoreId: $gameStoreId
        ) {
            id
            type
            title
            description
            howToRedeemUrl
            endDate
            endDateEnabled
            embargoUntil
            embargoUntilEnabled
            minimumHoursToDeliver
            minimumHoursToDeliverEnabled
            fitForYou
            fitForYouGames {
                ...TwitchGameWithIgdbDataFields
            }
            numberOfKeys
            numberOfKeysLeft
            claimedAt
            hasStreamerDelivered
            isInWishList
            status
            stores {
                ...GameDeveloperCampaignStoreFields
            }
            twitchGame {
                ...TwitchGameWithIgdbDataFields
            }
        }
    }
`;
