import { gql } from '@apollo/client';

import { StreamData } from '../generated/graphqlTypesAnalytics';

export interface LastStreamData {
    lastStream: StreamData;
}

export default gql`
    query LastStream {
        lastStream {
            id
        }
    }
`;
