import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import SimpleBar from 'simplebar-react';

import { ReactComponent as ViewerIcon } from '../../assets/icons/viewer.svg';
import { ReactComponent as StreamBeeLogo } from '../../assets/images/logo.svg';
import GlobalStateContext from '../../context';
import { setIsDeveloperView } from '../../context/actions';
import isSlobs from '../../helpers/isSlobs';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import useMenuConfig from '../../hooks/useMenuConfig';
import useTenants from '../../hooks/useTenants';
import useUserProfile from '../../hooks/useUserProfile';
import useUserSubscription from '../../hooks/useUserSubscription';
import { homeRoute, onboarding } from '../../routes';
import ButtonWithSwitch from '../ButtonWithSwitch';
import MenuItem from './MenuItem';
import ProfileMenu from './ProfileMenu';

const AnimatedBox = animated(Box);
const LeftMenu = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isEnabled } = useFeatureFlags();
    const [isMenuHover, setIsMenuHover] = useState<boolean>(false);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [openSubMenu, setOpenSubMenu] = useState<string | undefined>(
        undefined
    );
    const { currentTenant, loading: userTenantsLoading } = useTenants();
    const {
        state: { onboardingStep, isDeveloperView },
        dispatch
    } = useContext(GlobalStateContext);
    const onboardingPathMatch = useMatch(onboarding());
    const [isPreferencesMenuOpen, setPreferencesMenuOpen] =
        useState<boolean>(false);
    const { isBroadcaster, loading: userProfileLoading } = useUserProfile();

    const { hasPremium } = useUserSubscription(false);

    const menuConfig = useMenuConfig(hasPremium);

    const menuAnimation = useSpring({
        width: isMenuOpen ? '320px' : theme.menuWidth,
        onStart: () => {
            if (openSubMenu !== undefined) {
                setOpenSubMenu(undefined);
            }
        },
        onRest: () => {
            if (!isMenuOpen && openSubMenu !== undefined) {
                setOpenSubMenu(undefined);
            }
        }
    });

    const handleClickAway = () => {
        if (isMenuOpen) {
            setPreferencesMenuOpen(false);

            if (!isMenuHover) {
                setMenuOpen(false);
            }
        }
    };

    return (
        <AnimatedBox
            style={menuAnimation}
            className={isMenuOpen ? 'menuOpen' : 'menuClosed'}
            sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                backgroundColor: 'baseCloud.transparent5',
                height: '100vh',
                px: 4,
                pt: 8,
                pb: 4,
                overflow: 'hidden',
                '& .animateOpacity': {
                    opacity: 0,
                    transition: theme.transitions.create(
                        ['opacity', 'transform'],
                        {
                            duration: theme.transitions.duration.standard
                        }
                    )
                },
                '&.menuOpen .animateOpacity': {
                    opacity: 1
                },
                '&.menuOpen .badge .animateOpacity': {
                    opacity: 1
                },
                '.badge': {
                    transition: theme.transitions.create(['width', 'left'], {
                        duration: theme.transitions.duration.standard
                    })
                },
                '&.menuOpen .badge': {
                    width: '144px',
                    left: '47px'
                },
                transform: `translateX(${
                    onboardingStep < 3 && !!onboardingPathMatch
                        ? `-${theme.menuWidth}`
                        : 0
                })`,
                transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.standard
                })
            }}
            onMouseEnter={() => {
                setIsMenuHover(true);
                setMenuOpen(true);
            }}
            onMouseLeave={(e) => {
                setIsMenuHover(false);
                if (!isPreferencesMenuOpen) {
                    setMenuOpen(false);
                }
            }}
        >
            <Box
                component={Link}
                to={homeRoute()}
                className="logo"
                sx={{
                    mb: 3,
                    pl: 2
                }}
            >
                <StreamBeeLogo />
            </Box>

            <Box
                sx={{
                    flex: 1,
                    minHeight: 0,
                    '& .simplebar-track.simplebar-vertical': {
                        width: '4px'
                    },
                    '& .simplebar-scrollbar.simplebar-visible:before': {
                        backgroundColor: 'baseCloud.transparent15',
                        borderRadius: '2px',
                        width: '4px',
                        opacity: 1,
                        left: 0,
                        top: '0px !important',
                        bottom: '0px !important'
                    },
                    '& .simplebar-wrapper': {
                        maskImage: `linear-gradient(0deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 12px), transparent 100%)`
                    },
                    '& .simplebar-content-wrapper': {
                        maskImage: `linear-gradient(180deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 12px), transparent 100%)`
                    }
                }}
            >
                <SimpleBar
                    style={{
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            py: 3
                        }}
                    >
                        {menuConfig &&
                            menuConfig
                                .filter((item) => item.route || item.href)
                                .map((item) => {
                                    if (item.loading) {
                                        return null;
                                    }

                                    if (
                                        item.featureFlag &&
                                        !isEnabled(item.featureFlag ?? '')
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <MenuItem
                                            key={item.title}
                                            to={item.route}
                                            href={item.href}
                                            text={item.title}
                                            icon={item.icon}
                                            activePaths={item.activePaths}
                                            omittedPaths={item.omittedPaths}
                                            isSubMenuOpen={
                                                item?.title === openSubMenu
                                            }
                                            setOpenSubMenu={setOpenSubMenu}
                                            items={item.items}
                                            notificationDotColor={
                                                item.notificationDotColor
                                            }
                                            isPremium={item.isPremium}
                                            isDemo={item.isDemo}
                                            isAlpha={item.isAlpha}
                                            numberBadgeValue={
                                                item.numberBadgeValue
                                            }
                                        />
                                    );
                                })}
                    </Box>
                </SimpleBar>
            </Box>

            {!userProfileLoading &&
                !userTenantsLoading &&
                currentTenant?.isMe &&
                !isBroadcaster &&
                !isSlobs() && (
                    <Box
                        sx={{
                            width: '100%',
                            mb: 2,
                            mt: 3,
                            px: '2px',
                            transition: theme.transitions.create(
                                ['padding-right'],
                                {
                                    duration:
                                        theme.transitions.duration.standard
                                }
                            ),
                            '& > button': {
                                width: '100%',
                                overflow: 'hidden',
                                p: '10px',
                                minWidth: '44px',
                                '& svg': {
                                    mr: 3,
                                    width: '24px !important',
                                    height: '24px !important'
                                }
                            }
                        }}
                    >
                        <ButtonWithSwitch
                            size="medium"
                            label="Game Marketer View"
                            value={isDeveloperView ?? false}
                            startIcon={<ViewerIcon />}
                            onChange={() => {
                                dispatch(setIsDeveloperView(!isDeveloperView));

                                navigate(homeRoute());
                            }}
                        />
                    </Box>
                )}

            <ProfileMenu
                isOpen={isPreferencesMenuOpen}
                isHover={isMenuHover}
                setIsOpen={setPreferencesMenuOpen}
                handleClickAway={handleClickAway}
                isDeveloperView={isDeveloperView}
            />
        </AnimatedBox>
    );
};

export default LeftMenu;
