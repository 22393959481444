import { gql } from '@apollo/client';

import {
    BooleanResult,
    MutationRemoveTenantManagerArgs
} from '../generated/graphqlTypesAnalytics';

export interface RemoveTenantManager {
    removeTenantManager: BooleanResult;
}

export type RemoveTenantManagerVars = MutationRemoveTenantManagerArgs;

export default gql`
    mutation RemoveTenantManager($userId: UUID!) {
        removeTenantManager(userId: $userId) {
            value
        }
    }
`;
