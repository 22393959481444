import { gql } from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  DateTime: Date;
  Decimal: number;
  Long: number;
  UUID: string;
};

export type AchievementHighlight = {
  __typename?: 'AchievementHighlight';
  days: Scalars['Int'];
  metric: AchievementMetricsEnum;
  streamDataId: Scalars['UUID'];
  value: Scalars['Float'];
};

export enum AchievementMetricsEnum {
  AverageConcurrentViewers = 'AVERAGE_CONCURRENT_VIEWERS',
  BitsEarned = 'BITS_EARNED',
  ChatMessages = 'CHAT_MESSAGES',
  FollowersGained = 'FOLLOWERS_GAINED',
  HoursWatched = 'HOURS_WATCHED',
  PeakViewers = 'PEAK_VIEWERS',
  StreamLength = 'STREAM_LENGTH',
  SubscribersGained = 'SUBSCRIBERS_GAINED',
  UniqueActiveChatters = 'UNIQUE_ACTIVE_CHATTERS'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type BillingDetails = {
  __typename?: 'BillingDetails';
  cardLast4Digits?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type BooleanResult = {
  __typename?: 'BooleanResult';
  value: Scalars['Boolean'];
};

export type ChurnPrediction = {
  __typename?: 'ChurnPrediction';
  byFollowAge?: Maybe<Array<Maybe<ChurnPredictionItem>>>;
  bySubLength?: Maybe<Array<Maybe<ChurnPredictionItem>>>;
  bySubType?: Maybe<Array<Maybe<ChurnPredictionItem>>>;
  expectedChurnRate?: Maybe<Scalars['Decimal']>;
  expectedChurnSum?: Maybe<Scalars['Int']>;
};

export type ChurnPredictionItem = {
  __typename?: 'ChurnPredictionItem';
  count: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  percentage: Scalars['Decimal'];
};

export type DataPageOfStreamData = {
  __typename?: 'DataPageOfStreamData';
  count: Scalars['Int'];
  data?: Maybe<Array<Maybe<StreamData>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type DataPageOfStreamDataTwitchGame = {
  __typename?: 'DataPageOfStreamDataTwitchGame';
  count: Scalars['Int'];
  data?: Maybe<Array<Maybe<StreamDataTwitchGame>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type DataPageOfTwitchGame = {
  __typename?: 'DataPageOfTwitchGame';
  count: Scalars['Int'];
  data?: Maybe<Array<Maybe<TwitchGame>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type DataPageOfUserKnownBot = {
  __typename?: 'DataPageOfUserKnownBot';
  count: Scalars['Int'];
  data?: Maybe<Array<Maybe<UserKnownBot>>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DayOfWeekResult = {
  __typename?: 'DayOfWeekResult';
  averageChatMessagesPerHour: Scalars['Decimal'];
  averageFollowersGained: Scalars['Decimal'];
  averageFollowersGainedPerHour: Scalars['Decimal'];
  averageHoursStreamed: Scalars['Decimal'];
  averageHoursWatched: Scalars['Decimal'];
  averagePeakViewers: Scalars['Decimal'];
  averageUniqueActiveChatters: Scalars['Decimal'];
  averageUniqueChatters: Scalars['Decimal'];
  averageViewers: Scalars['Float'];
  averageWatchtime: Scalars['Decimal'];
  dayOfWeek: DayOfWeek;
  numberOfStreams: Scalars['Int'];
  periodicAverageViewers?: Maybe<Array<Maybe<StreamPeriodicAverageViewers>>>;
};

export type DiscordPeriodicData = {
  __typename?: 'DiscordPeriodicData';
  approximateMemberCount: Scalars['Int'];
  approximatePresenceCount: Scalars['Decimal'];
  discordServerId: Scalars['UUID'];
  inviteLinkUses: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type DiscordPeriodicDataSearchSettingsInput = {
  discordServerIds?: Maybe<Array<Scalars['UUID']>>;
  monthsToQuery?: Maybe<Scalars['Int']>;
};

export type DiscordServerData = {
  __typename?: 'DiscordServerData';
  approximateMemberCount?: Maybe<ValueWithPercentageResultOfInt32>;
  approximatePresenceCount?: Maybe<ValueWithPercentageResult>;
  discordGuildName?: Maybe<Scalars['String']>;
  discordInviteCode?: Maybe<Scalars['String']>;
  discordInviteUrl?: Maybe<Scalars['String']>;
  discordServerId: Scalars['UUID'];
  growth?: Maybe<ValueWithPercentageResult>;
  inviteLinkUses?: Maybe<ValueWithPercentageResultOfInt32>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export enum FollowAge {
  LessThanMonth = 'LESS_THAN_MONTH',
  NotFollowing = 'NOT_FOLLOWING',
  OneMonth = 'ONE_MONTH',
  OneYear = 'ONE_YEAR',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  ThreeYears = 'THREE_YEARS',
  TwoYears = 'TWO_YEARS'
}

export type FollowerToSubscriberConversions = {
  __typename?: 'FollowerToSubscriberConversions';
  tipAverageNumberOfStreamsToConvert: Scalars['Decimal'];
  tipWeekConversion: Scalars['Int'];
  within7DaysConversion: Scalars['Decimal'];
  within7DaysSubs: Scalars['Int'];
  within30DaysConversion: Scalars['Decimal'];
  within30DaysSubs: Scalars['Int'];
  within60DaysConversion: Scalars['Decimal'];
  within60DaysSubs: Scalars['Int'];
  within90DaysConversion: Scalars['Decimal'];
  within90DaysSubs: Scalars['Int'];
};

export type GdcaBenchmarkItem = {
  __typename?: 'GDCABenchmarkItem';
  nicheStandard: Scalars['Decimal'];
  nicheStandardUpsDowns: Scalars['Decimal'];
  sponsored: Scalars['Decimal'];
  standard: Scalars['Decimal'];
  standardUpsDowns: Scalars['Decimal'];
  type: GdcaBenchmarkItemType;
};

export enum GdcaBenchmarkItemType {
  AverageWatchTime = 'AVERAGE_WATCH_TIME',
  BounceRate = 'BOUNCE_RATE',
  ChatMessagesPerMinute = 'CHAT_MESSAGES_PER_MINUTE',
  EngagedViewersRatio = 'ENGAGED_VIEWERS_RATIO'
}

export type GdcaCreatorsAvgWatchTimeBounceRate = {
  __typename?: 'GDCACreatorsAvgWatchTimeBounceRate';
  averageWatchTime: Scalars['Float'];
  bounceRate: Scalars['Float'];
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
};

export type GdcaCreatorsFailedToDeliver = {
  __typename?: 'GDCACreatorsFailedToDeliver';
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
};

export type GdcaCreatorsHoursStreamedWatched = {
  __typename?: 'GDCACreatorsHoursStreamedWatched';
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  hoursStreamed: Scalars['Decimal'];
  hoursWatched: Scalars['Float'];
};

export type GdcaCreatorsOverdelivered = {
  __typename?: 'GDCACreatorsOverdelivered';
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  daysStreamed: Scalars['Int'];
  hoursStreamed: Scalars['Decimal'];
  hoursWatched: Scalars['Float'];
  mediaSpaceValue: Scalars['Float'];
};

export type GdcaCreatorsPeakAverageCcv = {
  __typename?: 'GDCACreatorsPeakAverageCCV';
  averageCCV: Scalars['Float'];
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  peakCCV: Scalars['Float'];
};

export type GdcaCreatorsUniqueChattersEngagedViewersRatio = {
  __typename?: 'GDCACreatorsUniqueChattersEngagedViewersRatio';
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  engagedViewersRatio: Scalars['Decimal'];
  uniqueChatters: Scalars['Int'];
};

export type GdcaNotableViewers = {
  __typename?: 'GDCANotableViewers';
  averageCurrentCCV: Scalars['Float'];
  creatorImageUrl?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  currentFollowersCount: Scalars['Long'];
  gameStreaming: Scalars['Boolean'];
  streamerStatus?: Maybe<Scalars['String']>;
  throughCreatorImageUrl?: Maybe<Scalars['String']>;
  throughCreatorName?: Maybe<Scalars['String']>;
};

export type GdcaPeriodicGameRank = {
  __typename?: 'GDCAPeriodicGameRank';
  averageCCV: Scalars['Float'];
  averageGameCCV: Scalars['Float'];
  averagePositionInDirectory: Scalars['Float'];
  timeStamp: Scalars['DateTime'];
};

export type GdcaPeriodicKeyStreamsHours = {
  __typename?: 'GDCAPeriodicKeyStreamsHours';
  hoursWatched: Scalars['Float'];
  keysClaimed: Scalars['Float'];
  streamsDelivered: Scalars['Float'];
  timeStamp: Scalars['DateTime'];
};

export type GdcaPeriodicViewsAndHours = {
  __typename?: 'GDCAPeriodicViewsAndHours';
  hoursWatched: Scalars['Float'];
  timeStamp: Scalars['DateTime'];
  totalViews: Scalars['Float'];
};

export type GdcaTwitchClip = {
  __typename?: 'GDCATwitchClip';
  broadcasterId?: Maybe<Scalars['String']>;
  broadcasterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorId?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  embedUrl?: Maybe<Scalars['String']>;
  gameId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitchGame?: Maybe<TwitchGame>;
  url?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
};

export type GameDeveloperCampaignAnalytics = {
  __typename?: 'GameDeveloperCampaignAnalytics';
  averageCCV: Scalars['Float'];
  averageWatchTime: Scalars['Decimal'];
  benchmarkItems?: Maybe<Array<Maybe<GdcaBenchmarkItem>>>;
  bounceRate: Scalars['Decimal'];
  chatMessages: Scalars['Int'];
  clips: Scalars['Int'];
  creatorsAvgWatchTimeBounceRate?: Maybe<Array<Maybe<GdcaCreatorsAvgWatchTimeBounceRate>>>;
  creatorsFailedToDeliver?: Maybe<Array<Maybe<GdcaCreatorsFailedToDeliver>>>;
  creatorsHoursStreamedWatched?: Maybe<Array<Maybe<GdcaCreatorsHoursStreamedWatched>>>;
  creatorsOverdelivered?: Maybe<Array<Maybe<GdcaCreatorsOverdelivered>>>;
  creatorsPeakAverageCCV?: Maybe<Array<Maybe<GdcaCreatorsPeakAverageCcv>>>;
  creatorsUniqueChattersEngagedViewersRatio?: Maybe<Array<Maybe<GdcaCreatorsUniqueChattersEngagedViewersRatio>>>;
  engagedViewersRatio: Scalars['Decimal'];
  gDCANotableViewers?: Maybe<Array<Maybe<GdcaNotableViewers>>>;
  hoursStreamed: Scalars['Decimal'];
  hoursWatched: Scalars['Float'];
  peakCCV: Scalars['Float'];
  periodicGameRankData?: Maybe<Array<Maybe<GdcaPeriodicGameRank>>>;
  periodicKeyStreamsHoursData?: Maybe<Array<Maybe<GdcaPeriodicKeyStreamsHours>>>;
  periodicViewsAndHoursData?: Maybe<Array<Maybe<GdcaPeriodicViewsAndHours>>>;
  topClips?: Maybe<Array<Maybe<GdcaTwitchClip>>>;
  totalViewers: Scalars['Int'];
  uniqueChatters: Scalars['Int'];
};

export type GameDeveloperCampaignCreateInput = {
  iGDBGameId?: Maybe<Scalars['String']>;
  keyRetailValue: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  numberOfKeys: Scalars['Int'];
  type: GameDeveloperCampaignType;
};

export type GameDeveloperCampaignFiltersInput = {
  brandSafeRating?: Maybe<Scalars['Int']>;
  brandSafeRatingEnabled?: Maybe<Scalars['Boolean']>;
  chatMessage?: Maybe<Scalars['String']>;
  chatMessagesEnabled?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<Scalars['DateTime']>;
  deliveryMaximumCCV?: Maybe<Scalars['Int']>;
  deliveryMinimumCCV?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embargoUntil?: Maybe<Scalars['DateTime']>;
  embargoUntilEnabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDateEnabled?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<Scalars['Float']>;
  hoursToStream?: Maybe<Scalars['Float']>;
  howToRedeemUrl?: Maybe<Scalars['String']>;
  keyRetailValue?: Maybe<Scalars['Decimal']>;
  maximumPEGIRatingStreamedGames?: Maybe<Scalars['Int']>;
  minimumCCV?: Maybe<Scalars['Int']>;
  minimumCCVEnabled?: Maybe<Scalars['Boolean']>;
  minimumChatEngagement?: Maybe<MinimumChatEngagementType>;
  minimumChatEngagementEnabled?: Maybe<Scalars['Boolean']>;
  minimumHoursToDeliver?: Maybe<Scalars['Decimal']>;
  minimumHoursToDeliverEnabled?: Maybe<Scalars['Boolean']>;
  minimumNumberOfCreators?: Maybe<Scalars['Int']>;
  minimumPEGIRatingStreamedGames?: Maybe<Scalars['Int']>;
  minimumSubCount?: Maybe<Scalars['Int']>;
  minimumSubCountEnabled?: Maybe<Scalars['Boolean']>;
  minimumTimesStreamed?: Maybe<Scalars['Int']>;
  minimumTimesStreamedEnabled?: Maybe<Scalars['Boolean']>;
  moneyPerCreator?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  pEGIRatingStreamedGamesEnabled?: Maybe<Scalars['Boolean']>;
  paidSponsorshipEnabled?: Maybe<Scalars['Boolean']>;
  preferredCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  similarGames?: Maybe<Array<Maybe<GameDeveloperCampaignSimilarGameInput>>>;
  sponsorshipLength_StreamedHours?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDateEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<GameDeveloperCampaignStatus>;
  streamerGameSkills?: Maybe<Scalars['Int']>;
  streamerGameSkillsEnabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  totalBudget?: Maybe<Scalars['Decimal']>;
  twitchPanelCode?: Maybe<Scalars['String']>;
  twitchPanelEnabled?: Maybe<Scalars['Boolean']>;
  twitchPanelImage?: Maybe<Array<Scalars['Byte']>>;
};

export type GameDeveloperCampaignGameKey = {
  __typename?: 'GameDeveloperCampaignGameKey';
  gameDeveloperCampaignId: Scalars['UUID'];
  gameStore?: Maybe<GameStore>;
  key?: Maybe<Scalars['String']>;
  retrievedAt?: Maybe<Scalars['DateTime']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type GameDeveloperCampaignOut = {
  __typename?: 'GameDeveloperCampaignOut';
  brandSafeRating: Scalars['Int'];
  brandSafeRatingEnabled: Scalars['Boolean'];
  chatMessage?: Maybe<Scalars['String']>;
  chatMessagesEnabled: Scalars['Boolean'];
  deadline?: Maybe<Scalars['DateTime']>;
  deliveryMaximumCCV?: Maybe<Scalars['Int']>;
  deliveryMinimumCCV?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embargoUntil: Scalars['DateTime'];
  embargoUntilEnabled: Scalars['Boolean'];
  endDate: Scalars['DateTime'];
  endDateEnabled: Scalars['Boolean'];
  frequency: Scalars['Float'];
  hoursStreamed: Scalars['Decimal'];
  hoursToStream: Scalars['Float'];
  hoursWatched: Scalars['Float'];
  howToRedeemUrl?: Maybe<Scalars['String']>;
  iGDBGameId?: Maybe<Scalars['String']>;
  iGDBGameName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  keyRetailValue: Scalars['Decimal'];
  maximumPEGIRatingStreamedGames: Scalars['Int'];
  minimumCCV: Scalars['Int'];
  minimumCCVEnabled: Scalars['Boolean'];
  minimumChatEngagement: MinimumChatEngagementType;
  minimumChatEngagementEnabled: Scalars['Boolean'];
  minimumHoursToDeliver: Scalars['Decimal'];
  minimumHoursToDeliverEnabled: Scalars['Boolean'];
  minimumNumberOfCreators: Scalars['Int'];
  minimumPEGIRatingStreamedGames: Scalars['Int'];
  minimumSubCount: Scalars['Int'];
  minimumSubCountEnabled: Scalars['Boolean'];
  minimumTimesStreamed: Scalars['Int'];
  minimumTimesStreamedEnabled: Scalars['Boolean'];
  moneyPerCreator: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  numberOfKeys: Scalars['Int'];
  numberOfKeysLeft: Scalars['Int'];
  pEGIRatingStreamedGamesEnabled: Scalars['Boolean'];
  paidSponsorshipEnabled: Scalars['Boolean'];
  preferredCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsorshipLength_StreamedHours: Scalars['Float'];
  startDate: Scalars['DateTime'];
  startDateEnabled: Scalars['Boolean'];
  status: GameDeveloperCampaignStatus;
  stores?: Maybe<Array<Maybe<GameDeveloperCampaignStoreOut>>>;
  streamerGameSkills: Scalars['Int'];
  streamerGameSkillsEnabled: Scalars['Boolean'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  totalBudget: Scalars['Decimal'];
  twitchGame?: Maybe<TwitchGame>;
  twitchPanelCode?: Maybe<Scalars['String']>;
  twitchPanelEnabled: Scalars['Boolean'];
  twitchPanelImage?: Maybe<Array<Scalars['Byte']>>;
  type: GameDeveloperCampaignType;
};

export type GameDeveloperCampaignSimilarGameInput = {
  gameDeveloperCampaignId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGameId: Scalars['UUID'];
};

export type GameDeveloperCampaignSimilarGameOut = {
  __typename?: 'GameDeveloperCampaignSimilarGameOut';
  gameDeveloperCampaignId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export enum GameDeveloperCampaignStatus {
  /** The campaign is active and running. */
  Active = 'ACTIVE',
  /** The campaign is closed and not running. */
  Closed = 'CLOSED',
  /** The campaign is a draft. */
  Draft = 'DRAFT'
}

export type GameDeveloperCampaignStoreInput = {
  gameDeveloperCampaignId: Scalars['UUID'];
  gameStoreId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type GameDeveloperCampaignStoreKeysInput = {
  gameStoreId: Scalars['UUID'];
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GameDeveloperCampaignStoreOut = {
  __typename?: 'GameDeveloperCampaignStoreOut';
  gameDeveloperCampaignId: Scalars['UUID'];
  gameStoreId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  numberOfKeys: Scalars['Int'];
  numberOfKeysLeft: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export enum GameDeveloperCampaignType {
  /** The campaign is for game keys. */
  GameKey = 'GAME_KEY',
  /** The campaign is for in-game content. */
  InGameContent = 'IN_GAME_CONTENT'
}

export type GameDeveloperCampaignUpdateInput = {
  brandSafeRating?: Maybe<Scalars['Int']>;
  brandSafeRatingEnabled?: Maybe<Scalars['Boolean']>;
  chatMessage?: Maybe<Scalars['String']>;
  chatMessagesEnabled?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<Scalars['DateTime']>;
  deliveryMaximumCCV?: Maybe<Scalars['Int']>;
  deliveryMinimumCCV?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  embargoUntil?: Maybe<Scalars['DateTime']>;
  embargoUntilEnabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDateEnabled?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<Scalars['Float']>;
  hoursToStream?: Maybe<Scalars['Float']>;
  howToRedeemUrl?: Maybe<Scalars['String']>;
  keyRetailValue?: Maybe<Scalars['Decimal']>;
  maximumPEGIRatingStreamedGames?: Maybe<Scalars['Int']>;
  minimumCCV?: Maybe<Scalars['Int']>;
  minimumCCVEnabled?: Maybe<Scalars['Boolean']>;
  minimumChatEngagement?: Maybe<MinimumChatEngagementType>;
  minimumChatEngagementEnabled?: Maybe<Scalars['Boolean']>;
  minimumHoursToDeliver?: Maybe<Scalars['Decimal']>;
  minimumHoursToDeliverEnabled?: Maybe<Scalars['Boolean']>;
  minimumNumberOfCreators?: Maybe<Scalars['Int']>;
  minimumPEGIRatingStreamedGames?: Maybe<Scalars['Int']>;
  minimumSubCount?: Maybe<Scalars['Int']>;
  minimumSubCountEnabled?: Maybe<Scalars['Boolean']>;
  minimumTimesStreamed?: Maybe<Scalars['Int']>;
  minimumTimesStreamedEnabled?: Maybe<Scalars['Boolean']>;
  moneyPerCreator?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  pEGIRatingStreamedGamesEnabled?: Maybe<Scalars['Boolean']>;
  paidSponsorshipEnabled?: Maybe<Scalars['Boolean']>;
  preferredCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferredLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsorshipLength_StreamedHours?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDateEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<GameDeveloperCampaignStatus>;
  streamerGameSkills?: Maybe<Scalars['Int']>;
  streamerGameSkillsEnabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  totalBudget?: Maybe<Scalars['Decimal']>;
  twitchPanelCode?: Maybe<Scalars['String']>;
  twitchPanelEnabled?: Maybe<Scalars['Boolean']>;
  twitchPanelImage?: Maybe<Array<Scalars['Byte']>>;
};

export type GameDeveloperKeyRedeems = {
  __typename?: 'GameDeveloperKeyRedeems';
  id: Scalars['UUID'];
  numberOfKeys: Scalars['Int'];
  numberOfKeysWithoutCampaign: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type GameInsight = {
  __typename?: 'GameInsight';
  gameImageUrl?: Maybe<Scalars['String']>;
  hoursStreamed: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
};

export type GameMetricScore = {
  __typename?: 'GameMetricScore';
  gameMetricRank: Scalars['Float'];
  gameMetricType: WhatToStreamGameMetricType;
  name?: Maybe<Scalars['String']>;
  rawScore: Scalars['Float'];
  result: Scalars['Float'];
  twitchId?: Maybe<Scalars['String']>;
};

export type GameMetrics = {
  __typename?: 'GameMetrics';
  aggScore: Scalars['Float'];
  finalRank: Scalars['Int'];
  fitForYou: Scalars['Decimal'];
  fitForYouGames?: Maybe<Array<Maybe<TwitchGame>>>;
  scores?: Maybe<Array<Maybe<GameMetricScore>>>;
  twitchGame?: Maybe<TwitchGame>;
};

export type GamePerStreamerInsight = {
  __typename?: 'GamePerStreamerInsight';
  averageViewers: Scalars['Float'];
  followersPerHour: Scalars['Float'];
  gameImageUrl?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  hoursStreamed: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  twitchUserName?: Maybe<Scalars['String']>;
};

export type GamePlatform = {
  __typename?: 'GamePlatform';
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type GameStore = {
  __typename?: 'GameStore';
  howToRedeemKeyUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type GetPortfolioInsightsRequestInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
};

export type GetTenantInvitationsResponse = {
  __typename?: 'GetTenantInvitationsResponse';
  fromUser?: Maybe<Array<Maybe<TenantInvitation>>>;
  toUser?: Maybe<Array<Maybe<TenantInvitation>>>;
};

export type IgdbAgeRating = {
  __typename?: 'IGDBAgeRating';
  category: Scalars['Int'];
  checksum: Scalars['UUID'];
  contentDescriptions?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  rating: Scalars['Int'];
  ratingCoverUrl?: Maybe<Scalars['String']>;
  synopsis?: Maybe<Scalars['String']>;
};

export type IgdbArtwork = {
  __typename?: 'IGDBArtwork';
  alphaChannel: Scalars['Boolean'];
  animated: Scalars['Boolean'];
  checksum: Scalars['UUID'];
  game: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type IgdbCover = {
  __typename?: 'IGDBCover';
  alphaChannel: Scalars['Boolean'];
  animated: Scalars['Boolean'];
  checksum: Scalars['UUID'];
  game: Scalars['Int'];
  gameLocalization?: Maybe<Array<Scalars['Int']>>;
  height: Scalars['Int'];
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type IgdbGame = {
  __typename?: 'IGDBGame';
  ageRatings?: Maybe<Array<Maybe<IgdbAgeRating>>>;
  aggregatedRating?: Maybe<Scalars['Float']>;
  aggregatedRatingCount?: Maybe<Scalars['Int']>;
  alternativeNames?: Maybe<Array<Scalars['Int']>>;
  artworks?: Maybe<Array<Maybe<IgdbArtwork>>>;
  bundles?: Maybe<Array<Scalars['Int']>>;
  category?: Maybe<IgdbGameCategory>;
  checksum: Scalars['UUID'];
  collection?: Maybe<Scalars['Int']>;
  cover?: Maybe<IgdbCover>;
  createdAtUTC: Scalars['DateTime'];
  createdAtUnix: Scalars['Long'];
  dlcs?: Maybe<Array<Scalars['Int']>>;
  expandedGames?: Maybe<Array<Scalars['Int']>>;
  expansions?: Maybe<Array<Scalars['Int']>>;
  externalGames?: Maybe<Array<Scalars['Int']>>;
  firstReleaseDateUTC: Scalars['DateTime'];
  firstReleaseDateUnix: Scalars['Long'];
  follows: Scalars['Int'];
  forks?: Maybe<Array<Scalars['Int']>>;
  franchise?: Maybe<Scalars['Int']>;
  franchises?: Maybe<Array<Scalars['Int']>>;
  gameEngines?: Maybe<Array<Scalars['Int']>>;
  gameLocalizations?: Maybe<Array<Scalars['Int']>>;
  gameModes?: Maybe<Array<Maybe<IgdbGameMode>>>;
  genres?: Maybe<Array<Maybe<IgdbGenre>>>;
  hypes?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  involvedCompanies?: Maybe<Array<Maybe<IgdbInvolvedCompany>>>;
  keywords?: Maybe<Array<Scalars['Int']>>;
  languageSupports?: Maybe<Array<Scalars['Int']>>;
  multiplayerModes?: Maybe<Array<Scalars['Int']>>;
  name?: Maybe<Scalars['String']>;
  parentGame?: Maybe<Scalars['Int']>;
  platforms?: Maybe<Array<Maybe<IgdbPlatform>>>;
  playerPerspectives?: Maybe<Array<Scalars['Int']>>;
  ports?: Maybe<Array<Scalars['Int']>>;
  rating?: Maybe<Scalars['Float']>;
  ratingCount?: Maybe<Scalars['Int']>;
  releaseDates?: Maybe<Array<Scalars['Int']>>;
  remakes?: Maybe<Array<Scalars['Int']>>;
  remasters?: Maybe<Array<Scalars['Int']>>;
  screenshots?: Maybe<Array<Maybe<IgdbScreenshot>>>;
  similarGames?: Maybe<Array<Scalars['Int']>>;
  slug?: Maybe<Scalars['String']>;
  standaloneExpansions?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<IgdbGameStatus>;
  storyline?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['Int']>>;
  themes?: Maybe<Array<Maybe<IgdbTheme>>>;
  totalRating?: Maybe<Scalars['Float']>;
  totalRatingCount?: Maybe<Scalars['Int']>;
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
  url?: Maybe<Scalars['String']>;
  versionParent?: Maybe<Scalars['Int']>;
  versionTitle?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Maybe<IgdbGameVideo>>>;
  websites?: Maybe<Array<Maybe<IgdbWebsite>>>;
};

export enum IgdbGameCategory {
  Bundle = 'BUNDLE',
  DlcAddon = 'DLC_ADDON',
  Episode = 'EPISODE',
  ExpandedGame = 'EXPANDED_GAME',
  Expansion = 'EXPANSION',
  Fork = 'FORK',
  MainGame = 'MAIN_GAME',
  Mod = 'MOD',
  Pack = 'PACK',
  Port = 'PORT',
  Remake = 'REMAKE',
  Remaster = 'REMASTER',
  Season = 'SEASON',
  StandaloneExpansion = 'STANDALONE_EXPANSION',
  Update = 'UPDATE'
}

export type IgdbGameMode = {
  __typename?: 'IGDBGameMode';
  checksum: Scalars['UUID'];
  createdAtUTC: Scalars['DateTime'];
  createdAtUnix: Scalars['Long'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
  url?: Maybe<Scalars['String']>;
};

export enum IgdbGameStatus {
  Alpha = 'ALPHA',
  Beta = 'BETA',
  Cancelled = 'CANCELLED',
  Delisted = 'DELISTED',
  EarlyAccess = 'EARLY_ACCESS',
  Offline = 'OFFLINE',
  Released = 'RELEASED',
  Rumored = 'RUMORED'
}

export type IgdbGameVideo = {
  __typename?: 'IGDBGameVideo';
  checksum: Scalars['UUID'];
  game: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type IgdbGenre = {
  __typename?: 'IGDBGenre';
  checksum: Scalars['UUID'];
  createdAtUTC: Scalars['DateTime'];
  createdAtUnix: Scalars['Long'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
  url?: Maybe<Scalars['String']>;
};

export type IgdbInvolvedCompany = {
  __typename?: 'IGDBInvolvedCompany';
  checksum: Scalars['UUID'];
  company: Scalars['Int'];
  createdAtUTC: Scalars['DateTime'];
  createdAtUnix: Scalars['Long'];
  developer: Scalars['Boolean'];
  game: Scalars['Int'];
  id: Scalars['Int'];
  porting: Scalars['Boolean'];
  publisher: Scalars['Boolean'];
  supporting: Scalars['Boolean'];
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
};

export type IgdbPlatform = {
  __typename?: 'IGDBPlatform';
  abbreviation?: Maybe<Scalars['String']>;
  alternativeName?: Maybe<Scalars['String']>;
  category: IgdbPlatformCategory;
  checksum: Scalars['UUID'];
  createdAt: Scalars['Long'];
  generation?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  platformFamily?: Maybe<Scalars['Int']>;
  platformLogo?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
  url?: Maybe<Scalars['String']>;
  versions?: Maybe<Array<Scalars['Int']>>;
  websites?: Maybe<Array<Scalars['Int']>>;
};

export enum IgdbPlatformCategory {
  Arcade = 'ARCADE',
  Computer = 'COMPUTER',
  Console = 'CONSOLE',
  OperatingSystem = 'OPERATING_SYSTEM',
  Platform = 'PLATFORM',
  PortableConsole = 'PORTABLE_CONSOLE'
}

export type IgdbScreenshot = {
  __typename?: 'IGDBScreenshot';
  alphaChannel: Scalars['Boolean'];
  animated: Scalars['Boolean'];
  checksum: Scalars['UUID'];
  game: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  imageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type IgdbTheme = {
  __typename?: 'IGDBTheme';
  checksum: Scalars['UUID'];
  createdAtUTC: Scalars['DateTime'];
  createdAtUnix: Scalars['Long'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAtUTC: Scalars['DateTime'];
  updatedAtUnix: Scalars['Long'];
  url?: Maybe<Scalars['String']>;
};

export type IgdbWebsite = {
  __typename?: 'IGDBWebsite';
  category: IgdbWebsiteCategory;
  checksum: Scalars['UUID'];
  gameReferenceId: Scalars['Int'];
  id: Scalars['Int'];
  trusted: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export enum IgdbWebsiteCategory {
  Android = 'ANDROID',
  Discord = 'DISCORD',
  EpicGames = 'EPIC_GAMES',
  Facebook = 'FACEBOOK',
  Gog = 'GOG',
  Instagram = 'INSTAGRAM',
  Itch = 'ITCH',
  IPad = 'I_PAD',
  IPhone = 'I_PHONE',
  Official = 'OFFICIAL',
  Reddit = 'REDDIT',
  Steam = 'STEAM',
  Twitch = 'TWITCH',
  Twitter = 'TWITTER',
  Wikia = 'WIKIA',
  Wikipedia = 'WIKIPEDIA',
  Youtube = 'YOUTUBE'
}

export type InstagramAccountData = {
  __typename?: 'InstagramAccountData';
  accountId: Scalars['UUID'];
  externalInstagramAccountId: Scalars['Long'];
  facebookPageId: Scalars['Long'];
  facebookPageName?: Maybe<Scalars['String']>;
  followersCount?: Maybe<ValueWithPercentageResult>;
  growth?: Maybe<ValueWithPercentageResult>;
  impressionsCount?: Maybe<ValueWithPercentageResult>;
  instagramDisplayName?: Maybe<Scalars['String']>;
  instagramUserName?: Maybe<Scalars['String']>;
  reachCount?: Maybe<ValueWithPercentageResult>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type InstagramPeriodicData = {
  __typename?: 'InstagramPeriodicData';
  followersCount: Scalars['Long'];
  impressionsCount: Scalars['Long'];
  instagramAccountId: Scalars['UUID'];
  reachCount: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type InstagramPeriodicDataSearchSettingsInput = {
  instagramAccountIds?: Maybe<Array<Scalars['UUID']>>;
  monthsToQuery?: Maybe<Scalars['Int']>;
};

export type IntResult = {
  __typename?: 'IntResult';
  value: Scalars['Int'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amountInCents: Scalars['Long'];
  externalInvoiceId?: Maybe<Scalars['String']>;
  externalInvoiceNumber?: Maybe<Scalars['String']>;
  externalInvoiceUrl?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  userSubscriptionPlan?: Maybe<Scalars['String']>;
};

export type KeyValuePairOfGuidAndIListOfStreamPeriodicConcurrentViewers = {
  __typename?: 'KeyValuePairOfGuidAndIListOfStreamPeriodicConcurrentViewers';
  key: Scalars['UUID'];
  value: Array<StreamPeriodicConcurrentViewers>;
};

export type KeyValuePairOfStringAndInt32 = {
  __typename?: 'KeyValuePairOfStringAndInt32';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type KeyValuePairOfStringAndNullableOfDecimal = {
  __typename?: 'KeyValuePairOfStringAndNullableOfDecimal';
  key: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
};

export type MediaKit = {
  __typename?: 'MediaKit';
  discordMembers?: Maybe<Scalars['Long']>;
  instagramFollowers?: Maybe<Scalars['Long']>;
  tikTokFollowers?: Maybe<Scalars['Long']>;
  totalFans?: Maybe<Scalars['Long']>;
  twitchFollowers?: Maybe<Scalars['Long']>;
  twitchUserName?: Maybe<Scalars['String']>;
  twitterFollowers?: Maybe<Scalars['Long']>;
  userMediaInfos?: Maybe<Array<Maybe<UserMediaInfo>>>;
  youTubeSubscribers?: Maybe<Scalars['Long']>;
};

export enum MinimumChatEngagementType {
  /** The Any type. */
  Any = 'ANY',
  /** The 10% type. */
  TenPercent = 'TEN_PERCENT',
  /** The 25% type. */
  TwentyFivePercent = 'TWENTY_FIVE_PERCENT',
  /** The 20% type. */
  TwentyPercent = 'TWENTY_PERCENT'
}

export type Mutation = {
  __typename?: 'Mutation';
  addDiscordServer?: Maybe<DiscordServerData>;
  addUserKnownBot?: Maybe<UserKnownBot>;
  addYouTubeChannel?: Maybe<YouTubeChannelData>;
  buyGameKeys?: Maybe<StringResult>;
  buyLifetimeSubscription?: Maybe<StringResult>;
  cancelSubscription?: Maybe<BooleanResult>;
  cloneGameDeveloperCampaign?: Maybe<GameDeveloperCampaignOut>;
  confirmTenantInvitationToken?: Maybe<UserTenant>;
  createGameDeveloperCampaign?: Maybe<GameDeveloperCampaignOut>;
  createGameKeyUserWishListItem?: Maybe<BooleanResult>;
  createOrUpdateUserSetting?: Maybe<UserSetting>;
  deleteGameDeveloperCampaign?: Maybe<BooleanResult>;
  deleteGameKeyUserWishListItem?: Maybe<BooleanResult>;
  deleteStream?: Maybe<BooleanResult>;
  deleteTenantInvitationToken?: Maybe<BooleanResult>;
  increseCampaignNumberOfKeys?: Maybe<GameDeveloperCampaignOut>;
  inviteTenantManagerWithEmail?: Maybe<Scalars['String']>;
  inviteTenantManagerWithUserName?: Maybe<Scalars['String']>;
  pairTenantInvitationToken?: Maybe<TenantInvitation>;
  removeDiscordServer: Scalars['Boolean'];
  removeTenant?: Maybe<BooleanResult>;
  removeTenantManager?: Maybe<BooleanResult>;
  removeUserKnownBot: Scalars['Boolean'];
  removeYouTubeChannel: Scalars['Boolean'];
  retrieveGameKey?: Maybe<GameDeveloperCampaignGameKey>;
  setGameDeveloperCampaignSimilarGames?: Maybe<Array<Maybe<GameDeveloperCampaignSimilarGameOut>>>;
  setGameDeveloperCampaignStores?: Maybe<Array<Maybe<GameDeveloperCampaignStoreOut>>>;
  updateGameDeveloperCampaign?: Maybe<GameDeveloperCampaignOut>;
  updateTwitchGameFromIGDB?: Maybe<TwitchGame>;
  updateUserPreference?: Maybe<NotificationSetting>;
  updateUserProfileType?: Maybe<BooleanResult>;
  upgradeSubscription?: Maybe<BooleanResult>;
  uploadGameDeveloperCampaignGameKeys?: Maybe<BooleanResult>;
};


export type MutationAddDiscordServerArgs = {
  discordInviteUrl?: Maybe<Scalars['String']>;
};


export type MutationAddUserKnownBotArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationAddYouTubeChannelArgs = {
  youTubeChannelId?: Maybe<Scalars['String']>;
};


export type MutationBuyGameKeysArgs = {
  cancelUrlSuffix?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
  successUrlSuffix?: Maybe<Scalars['String']>;
};


export type MutationBuyLifetimeSubscriptionArgs = {
  cancelUrlSuffix?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
  successUrlSuffix?: Maybe<Scalars['String']>;
};


export type MutationCloneGameDeveloperCampaignArgs = {
  campaignId: Scalars['UUID'];
  campaignName?: Maybe<Scalars['String']>;
  numberOfKeys: Scalars['Int'];
};


export type MutationConfirmTenantInvitationTokenArgs = {
  invitationTokenId: Scalars['UUID'];
};


export type MutationCreateGameDeveloperCampaignArgs = {
  campaign?: Maybe<GameDeveloperCampaignCreateInput>;
};


export type MutationCreateGameKeyUserWishListItemArgs = {
  campaignId: Scalars['UUID'];
};


export type MutationCreateOrUpdateUserSettingArgs = {
  userSetting?: Maybe<UserSettingInput>;
};


export type MutationDeleteGameDeveloperCampaignArgs = {
  campaignId: Scalars['UUID'];
};


export type MutationDeleteGameKeyUserWishListItemArgs = {
  campaignId: Scalars['UUID'];
};


export type MutationDeleteStreamArgs = {
  streamDataId: Scalars['UUID'];
};


export type MutationDeleteTenantInvitationTokenArgs = {
  invitationTokenId: Scalars['UUID'];
};


export type MutationIncreseCampaignNumberOfKeysArgs = {
  campaignId: Scalars['UUID'];
  numberOfKeys: Scalars['Int'];
};


export type MutationInviteTenantManagerWithEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationInviteTenantManagerWithUserNameArgs = {
  userName?: Maybe<Scalars['String']>;
};


export type MutationPairTenantInvitationTokenArgs = {
  invitationTokenId: Scalars['UUID'];
};


export type MutationRemoveDiscordServerArgs = {
  discordServerId: Scalars['UUID'];
};


export type MutationRemoveTenantArgs = {
  tenantId: Scalars['UUID'];
};


export type MutationRemoveTenantManagerArgs = {
  userId: Scalars['UUID'];
};


export type MutationRemoveUserKnownBotArgs = {
  userKnownBotId: Scalars['UUID'];
};


export type MutationRemoveYouTubeChannelArgs = {
  youTubeChannelId: Scalars['UUID'];
};


export type MutationRetrieveGameKeyArgs = {
  campaignId: Scalars['UUID'];
  gameStoreId: Scalars['UUID'];
};


export type MutationSetGameDeveloperCampaignSimilarGamesArgs = {
  campaignId: Scalars['UUID'];
  similarGames?: Maybe<Array<Maybe<GameDeveloperCampaignSimilarGameInput>>>;
};


export type MutationSetGameDeveloperCampaignStoresArgs = {
  campaignId: Scalars['UUID'];
  gameStores?: Maybe<Array<Maybe<GameDeveloperCampaignStoreInput>>>;
};


export type MutationUpdateGameDeveloperCampaignArgs = {
  campaign?: Maybe<GameDeveloperCampaignUpdateInput>;
  campaignId: Scalars['UUID'];
};


export type MutationUpdateTwitchGameFromIgdbArgs = {
  twitchGameId?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserPreferenceArgs = {
  notificationSetting?: Maybe<NotificationSettingInput>;
};


export type MutationUpdateUserProfileTypeArgs = {
  changeToBroadcaster: Scalars['Boolean'];
  changeToGameDeveloper: Scalars['Boolean'];
};


export type MutationUpgradeSubscriptionArgs = {
  priceId?: Maybe<Scalars['String']>;
};


export type MutationUploadGameDeveloperCampaignGameKeysArgs = {
  campaignId: Scalars['UUID'];
  keys?: Maybe<Array<Maybe<GameDeveloperCampaignStoreKeysInput>>>;
};

export type NotificationChannel = {
  __typename?: 'NotificationChannel';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type NotificationChannelInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  notificationChannel?: Maybe<NotificationChannel>;
  notificationType?: Maybe<NotificationType>;
};

export type NotificationSettingInput = {
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  notificationChannel?: Maybe<NotificationChannelInput>;
  notificationType?: Maybe<NotificationTypeInput>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type NotificationTypeInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type OverviewDashboard = {
  __typename?: 'OverviewDashboard';
  achievementHighlights?: Maybe<Array<Maybe<AchievementHighlight>>>;
  daysInWeekStreamedStreak: Scalars['Int'];
  instagramImpressions?: Maybe<ValueWithPercentageResult>;
  monthlyStreamedHours: Scalars['Int'];
  tikTokViews?: Maybe<ValueWithPercentageResult>;
  totalFans: Scalars['Long'];
  twitchAverageViewers?: Maybe<ValueWithPercentageResult>;
  twitterImpressions?: Maybe<ValueWithPercentageResult>;
  userSetting?: Maybe<UserSetting>;
  weeklyHoursWatched: Scalars['Int'];
  weeklyHoursWatchedUpperRank: Scalars['Float'];
  weeksInRowStreamedStreak: Scalars['Int'];
  youTubeViews?: Maybe<ValueWithPercentageResult>;
};

export type PatreonAccountData = {
  __typename?: 'PatreonAccountData';
  accountId: Scalars['UUID'];
  patreonDisplayName?: Maybe<Scalars['String']>;
  patreonUserId?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type PatreonPeriodicData = {
  __typename?: 'PatreonPeriodicData';
  monthlyRevenueCents: Scalars['Decimal'];
  patreonPeriodicDataTiers?: Maybe<Array<Maybe<PatreonPeriodicDataTier>>>;
  timestamp: Scalars['DateTime'];
};

export type PatreonPeriodicDataTier = {
  __typename?: 'PatreonPeriodicDataTier';
  externalId?: Maybe<Scalars['String']>;
  usersCount: Scalars['Int'];
  valueCents: Scalars['Int'];
};

export type PatreonResult = {
  __typename?: 'PatreonResult';
  lastPayout: Scalars['Decimal'];
  membersByTier?: Maybe<Array<Maybe<ValueWithDescriptionAndPercentageResult>>>;
  nextPayout: Scalars['Decimal'];
  payoutDate: Scalars['DateTime'];
  periodicData?: Maybe<Array<Maybe<PatreonPeriodicData>>>;
  revenueByTier?: Maybe<Array<Maybe<ValueWithDescriptionAndPercentageResult>>>;
  totalMembers: Scalars['Int'];
};

export type PortfolioInsights = {
  __typename?: 'PortfolioInsights';
  gameInsights?: Maybe<Array<Maybe<GameInsight>>>;
  gamePerStreamerInsights?: Maybe<Array<Maybe<GamePerStreamerInsight>>>;
  userInsights?: Maybe<Array<Maybe<UserInsight>>>;
};

export type Query = {
  __typename?: 'Query';
  availableGameKeysForUser?: Maybe<Array<Maybe<UserAvailableGameDeveloperCampaign>>>;
  billingDetails?: Maybe<BillingDetails>;
  campaignUsersReach?: Maybe<IntResult>;
  churnCurrentPrediction?: Maybe<ChurnPrediction>;
  churnSubscriptionChangesAndBreakdown?: Maybe<SubscriptionChangeAndBreakdown>;
  createCustomerPortalSession?: Maybe<StringResult>;
  createSubscriptionSession?: Maybe<StringResult>;
  currentWhales?: Maybe<Array<Maybe<TwitchUserWhaleDataRecord>>>;
  discordServersData?: Maybe<Array<Maybe<DiscordServerData>>>;
  gameDeveloperCampaign?: Maybe<GameDeveloperCampaignOut>;
  gameDeveloperCampaignAnalytics?: Maybe<GameDeveloperCampaignAnalytics>;
  gameDeveloperCampaignSimilarGames?: Maybe<Array<Maybe<GameDeveloperCampaignSimilarGameOut>>>;
  gameDeveloperCampaignStores?: Maybe<Array<Maybe<GameDeveloperCampaignStoreOut>>>;
  gameDeveloperCampaigns?: Maybe<Array<Maybe<GameDeveloperCampaignOut>>>;
  gameDeveloperKeyRedeems?: Maybe<GameDeveloperKeyRedeems>;
  gameKey?: Maybe<GameDeveloperCampaignGameKey>;
  gameKeyProducts?: Maybe<Array<Maybe<StripeProduct>>>;
  gameStores?: Maybe<Array<Maybe<GameStore>>>;
  homeDashboard?: Maybe<OverviewDashboard>;
  instagramAccountsData?: Maybe<Array<Maybe<InstagramAccountData>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  lastStream?: Maybe<StreamData>;
  lifetimeSubscriptionProducts?: Maybe<Array<Maybe<StripeProduct>>>;
  mediaKit?: Maybe<MediaKit>;
  patreonAccountsData?: Maybe<Array<Maybe<PatreonAccountData>>>;
  patreonOverviewData?: Maybe<PatreonResult>;
  periodicActiveViewersRatio?: Maybe<Array<Maybe<StreamPeriodicActiveViewersRatio>>>;
  periodicChannelPointsRedeemed?: Maybe<Array<Maybe<StreamPeriodicChannelPointsRedeemed>>>;
  periodicChatActiveViewers?: Maybe<Array<Maybe<StreamPeriodicChatActiveViewers>>>;
  periodicChatMessages?: Maybe<Array<Maybe<StreamPeriodicChatMessages>>>;
  periodicClipsMade?: Maybe<Array<Maybe<StreamPeriodicClipsMade>>>;
  periodicConcurrentViewers?: Maybe<Array<Maybe<StreamPeriodicConcurrentViewers>>>;
  periodicConcurrentViewersBatch?: Maybe<Array<KeyValuePairOfGuidAndIListOfStreamPeriodicConcurrentViewers>>;
  periodicDropRate?: Maybe<Array<Maybe<StreamPeriodicDropRate>>>;
  periodicFollowersGained?: Maybe<Array<Maybe<StreamPeriodicFollowersGained>>>;
  periodicHoursWatched?: Maybe<Array<Maybe<StreamPeriodicHoursWatched>>>;
  periodicInteractions?: Maybe<Array<Maybe<StreamPeriodicInteractions>>>;
  periodicInteractionsPerViewer?: Maybe<Array<Maybe<StreamPeriodicInteractionsPerViewer>>>;
  periodicJoinedAndLeft?: Maybe<Array<Maybe<StreamPeriodicJoinedAndLeft>>>;
  periodicPollsAndPredictions?: Maybe<Array<Maybe<StreamPeriodicPollsAndPredictions>>>;
  periodicPositionInDirectory?: Maybe<Array<Maybe<StreamPeriodicPositionInDirectory>>>;
  periodicPositionInDirectoryByLanguage?: Maybe<Array<Maybe<StreamPeriodicPositionInDirectory>>>;
  periodicRewardsRedeemed?: Maybe<Array<Maybe<StreamPeriodicRewardsRedeemed>>>;
  periodicSubscribersGained?: Maybe<Array<Maybe<StreamPeriodicSubscribersGained>>>;
  periodicTopInteractionMoments?: Maybe<Array<Maybe<StreamPeriodicTopInteractionMoment>>>;
  periodicTypicalConcurrentViewers?: Maybe<Array<Maybe<StreamPeriodicTypicalConcurrentViewers>>>;
  periodicUniqueViewers?: Maybe<Array<Maybe<StreamPeriodicUniqueViewers>>>;
  platforms?: Maybe<Array<Maybe<GamePlatform>>>;
  playList?: Maybe<StreamDataTwitchGamePlayList>;
  portfolioInsights?: Maybe<PortfolioInsights>;
  raidStatsPerStream?: Maybe<Array<Maybe<TwitchRaidStat>>>;
  searchDiscordPeriodicData?: Maybe<Array<Maybe<DiscordPeriodicData>>>;
  searchIGDBGames?: Maybe<Array<Maybe<IgdbGame>>>;
  searchInstagramPeriodicData?: Maybe<Array<Maybe<InstagramPeriodicData>>>;
  searchStreamData?: Maybe<DataPageOfStreamData>;
  searchStreamDataTwitchGames?: Maybe<DataPageOfStreamDataTwitchGame>;
  searchTikTokPeriodicData?: Maybe<Array<Maybe<TikTokPeriodicData>>>;
  searchTwitchGames?: Maybe<DataPageOfTwitchGame>;
  searchTwitchPeriodicData?: Maybe<Array<Maybe<TwitchPeriodicData>>>;
  searchTwitterPeriodicData?: Maybe<Array<Maybe<TwitterPeriodicData>>>;
  searchUserKnownBots?: Maybe<DataPageOfUserKnownBot>;
  searchYouTubePeriodicData?: Maybe<Array<Maybe<YouTubePeriodicData>>>;
  streamChannelData?: Maybe<StreamChannelDataResult>;
  streamChatCommands?: Maybe<Array<Maybe<StreamChatCommand>>>;
  streamChatEmotes?: Maybe<Array<Maybe<StreamChatEmote>>>;
  streamChatOverviewData?: Maybe<StreamChatDataResult>;
  streamGames?: Maybe<Array<Maybe<StreamDataTwitchGame>>>;
  streamLanguages?: Maybe<Array<Maybe<StreamDataTwitchLanguage>>>;
  streamMonetizationOverviewData?: Maybe<StreamMonetizationDataResult>;
  streamOverviewData?: Maybe<StreamDataResult>;
  streamPeriodicChatEmotes?: Maybe<Array<Maybe<StreamPeriodicChatEmote>>>;
  streamRewardsRedeemed?: Maybe<Array<Maybe<StreamRewardRedeemed>>>;
  streamTags?: Maybe<Array<Maybe<StreamDataTwitchTag>>>;
  streamTitles?: Maybe<Array<Maybe<StreamDataTwitchTitle>>>;
  streamViewersOverviewData?: Maybe<StreamViewersDataResult>;
  subscriberConversionRate?: Maybe<SubscriberRates>;
  subscriptionProducts?: Maybe<Array<Maybe<StripeProduct>>>;
  tenantInvitations?: Maybe<GetTenantInvitationsResponse>;
  tenantManagers?: Maybe<TenantManagers>;
  tikTokAccountsData?: Maybe<Array<Maybe<TikTokAccountData>>>;
  topClips?: Maybe<Array<Maybe<TwitchClip>>>;
  topClipsReview?: Maybe<TopClipsReview>;
  twitchChannelData?: Maybe<TwitchChannelData>;
  twitchGameByIGDBId?: Maybe<TwitchGame>;
  twitchSubscriberFollowAges?: Maybe<Array<Maybe<TwitchSubscriberFollowAge>>>;
  twitchSubscriberStreamsWatched?: Maybe<Array<Maybe<TwitchSubscriberStreamsWatched>>>;
  twitchSubscriptionLengthsCumulative?: Maybe<Array<Maybe<TwitchSubscriptionLength>>>;
  twitchSubscriptionLengthsStreak?: Maybe<Array<Maybe<TwitchSubscriptionLength>>>;
  twitterAccountsData?: Maybe<Array<Maybe<TwitterAccountData>>>;
  userActiveTips?: Maybe<Array<Maybe<UserTip>>>;
  userBasicStreamInfo?: Maybe<UserBasicStreamInfo>;
  userPreferences?: Maybe<UserPreferences>;
  userSetting?: Maybe<UserSetting>;
  userSubscription?: Maybe<UserSubscription>;
  userTenants?: Maybe<UsersTenants>;
  vODReview?: Maybe<VodReview>;
  whatToStream?: Maybe<Array<Maybe<GameMetrics>>>;
  whenToStream?: Maybe<WhenToStream>;
  youTubeChannelsData?: Maybe<Array<Maybe<YouTubeChannelData>>>;
};


export type QueryAvailableGameKeysForUserArgs = {
  campaignType?: Maybe<GameDeveloperCampaignType>;
  fullTextSearch?: Maybe<Scalars['String']>;
  gameStoreId?: Maybe<Scalars['UUID']>;
  showMyKeys: Scalars['Boolean'];
};


export type QueryCampaignUsersReachArgs = {
  campaignFilters?: Maybe<GameDeveloperCampaignFiltersInput>;
  campaignId: Scalars['UUID'];
};


export type QueryChurnSubscriptionChangesAndBreakdownArgs = {
  numberOfMonthsBack: Scalars['Int'];
};


export type QueryCreateSubscriptionSessionArgs = {
  allowPromotionCode?: Scalars['Boolean'];
  priceId?: Maybe<Scalars['String']>;
};


export type QueryDiscordServersDataArgs = {
  getTenantData: Scalars['Boolean'];
};


export type QueryGameDeveloperCampaignArgs = {
  campaignId: Scalars['UUID'];
};


export type QueryGameDeveloperCampaignAnalyticsArgs = {
  campaignId: Scalars['UUID'];
};


export type QueryGameDeveloperCampaignSimilarGamesArgs = {
  campaignId: Scalars['UUID'];
};


export type QueryGameDeveloperCampaignStoresArgs = {
  campaignId: Scalars['UUID'];
};


export type QueryGameKeyArgs = {
  campaignId: Scalars['UUID'];
};


export type QueryInstagramAccountsDataArgs = {
  getTenantData?: Scalars['Boolean'];
};


export type QueryPatreonAccountsDataArgs = {
  getTenantData: Scalars['Boolean'];
};


export type QueryPeriodicActiveViewersRatioArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicChannelPointsRedeemedArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicChatActiveViewersArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicChatMessagesArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicClipsMadeArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicConcurrentViewersArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicConcurrentViewersBatchArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataIds?: Maybe<Array<Scalars['UUID']>>;
};


export type QueryPeriodicDropRateArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicFollowersGainedArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicHoursWatchedArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicInteractionsArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicInteractionsPerViewerArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicJoinedAndLeftArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicPollsAndPredictionsArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicPositionInDirectoryArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicPositionInDirectoryByLanguageArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicRewardsRedeemedArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicSubscribersGainedArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicTopInteractionMomentsArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicTypicalConcurrentViewersArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPeriodicUniqueViewersArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryPlayListArgs = {
  searchSettings?: Maybe<SearchSettingsTimeRangeInput>;
};


export type QueryPortfolioInsightsArgs = {
  request?: Maybe<GetPortfolioInsightsRequestInput>;
};


export type QueryRaidStatsPerStreamArgs = {
  streamId: Scalars['UUID'];
};


export type QuerySearchDiscordPeriodicDataArgs = {
  searchSettings?: Maybe<DiscordPeriodicDataSearchSettingsInput>;
};


export type QuerySearchIgdbGamesArgs = {
  gameName?: Maybe<Scalars['String']>;
};


export type QuerySearchInstagramPeriodicDataArgs = {
  searchSettings?: Maybe<InstagramPeriodicDataSearchSettingsInput>;
};


export type QuerySearchStreamDataArgs = {
  streamSearchSettings?: Maybe<StreamDataSearchSettingsInput>;
};


export type QuerySearchStreamDataTwitchGamesArgs = {
  searchSettings?: Maybe<StreamDataTwitchGameSearchSettingsInput>;
};


export type QuerySearchTikTokPeriodicDataArgs = {
  searchSettings?: Maybe<TikTokPeriodicDataSearchSettingsInput>;
};


export type QuerySearchTwitchGamesArgs = {
  searchSettings?: Maybe<TwitchGameSearchSettingsInput>;
};


export type QuerySearchTwitchPeriodicDataArgs = {
  searchSettings?: Maybe<TwitchPeriodicDataSearchSettingsInput>;
};


export type QuerySearchTwitterPeriodicDataArgs = {
  searchSettings?: Maybe<TwitterPeriodicDataSearchSettingsInput>;
};


export type QuerySearchUserKnownBotsArgs = {
  streamSearchSettings?: Maybe<UserKnownBotSearchSettingsInput>;
};


export type QuerySearchYouTubePeriodicDataArgs = {
  searchSettings?: Maybe<YouTubePeriodicDataSearchSettingsInput>;
};


export type QueryStreamChannelDataArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamChatCommandsArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamChatEmotesArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryStreamChatOverviewDataArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamGamesArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamLanguagesArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamMonetizationOverviewDataArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamOverviewDataArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamPeriodicChatEmotesArgs = {
  granularityMinutes?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryStreamRewardsRedeemedArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamTagsArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamTitlesArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryStreamViewersOverviewDataArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryTikTokAccountsDataArgs = {
  getTenantData?: Scalars['Boolean'];
};


export type QueryTopClipsArgs = {
  clipIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  endedAt?: Maybe<Scalars['DateTime']>;
  first: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
};


export type QueryTopClipsReviewArgs = {
  dataGranularitySeconds?: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  streamDataId: Scalars['UUID'];
};


export type QueryTwitchGameByIgdbIdArgs = {
  igdbGameId?: Maybe<Scalars['String']>;
};


export type QueryTwitterAccountsDataArgs = {
  getTenantData: Scalars['Boolean'];
};


export type QueryUserActiveTipsArgs = {
  area?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  sourceId: Scalars['UUID'];
};


export type QueryUserSubscriptionArgs = {
  getTenantData: Scalars['Boolean'];
};


export type QueryVOdReviewArgs = {
  streamDataId: Scalars['UUID'];
};


export type QueryWhenToStreamArgs = {
  searchSettings?: Maybe<SearchSettingsTimeRangeInput>;
};


export type QueryYouTubeChannelsDataArgs = {
  getTenantData: Scalars['Boolean'];
};

export type SearchSettingsTimeRangeInput = {
  dateTimeFrom?: Maybe<Scalars['DateTime']>;
  dateTimeTo?: Maybe<Scalars['DateTime']>;
};

export enum SocialPlatformUsage {
  /** The user is not using this platform. */
  NotUsing = 'NOT_USING',
  /** The user is using this platform. */
  Using = 'USING',
  /** The user is using this platform and has it connected. */
  UsingAndConnected = 'USING_AND_CONNECTED'
}

/** Enumeration of DAL sort orders. */
export enum SortOrder {
  /** The ascending sort order. */
  Ascending = 'ASCENDING',
  /** The descending sort order. */
  Descending = 'DESCENDING'
}

export type StreamChannelDataResult = {
  __typename?: 'StreamChannelDataResult';
  streamDataTwitchGames?: Maybe<Array<Maybe<StreamDataTwitchGame>>>;
  streamDataTwitchLanguages?: Maybe<Array<Maybe<StreamDataTwitchLanguage>>>;
  streamDataTwitchTags?: Maybe<Array<Maybe<StreamDataTwitchTag>>>;
  streamDataTwitchTitles?: Maybe<Array<Maybe<StreamDataTwitchTitle>>>;
};

export type StreamChatCommand = {
  __typename?: 'StreamChatCommand';
  count: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type StreamChatDataResult = {
  __typename?: 'StreamChatDataResult';
  activeViewersRatio?: Maybe<ValueWithPercentageResult>;
  averageChatsPerViewer?: Maybe<ValueWithPercentageResult>;
  channelPointsRedeemed?: Maybe<ValueWithPercentageResultOfInt64>;
  chatActiveViewers?: Maybe<ValueWithPercentageResult>;
  chatMessagesAll?: Maybe<ValueWithPercentageResult>;
  chatMessagesNonSubs?: Maybe<ValueWithPercentageResult>;
  chatMessagesSubs?: Maybe<ValueWithPercentageResult>;
  emotes?: Maybe<ValueWithPercentageResult>;
  interactions?: Maybe<ValueWithPercentageResult>;
  interactionsPerMinute?: Maybe<ValueWithPercentageResult>;
  interactionsPerViewer?: Maybe<ValueWithPercentageResult>;
  lurkers?: Maybe<ValueWithPercentageResult>;
  lurkersSubs?: Maybe<ValueWithPercentageResult>;
  multiMessageChatters?: Maybe<ValueWithPercentageResult>;
  multiMessageChattersSubs?: Maybe<ValueWithPercentageResult>;
  peakInteractions?: Maybe<ValueWithPercentageResult>;
  pollsAndPredictions?: Maybe<ValueWithPercentageResult>;
  singleMessageChatters?: Maybe<ValueWithPercentageResult>;
  singleMessageChattersSubs?: Maybe<ValueWithPercentageResult>;
  topChatCommand?: Maybe<ValueWithPercentageResultOfString>;
  totalWordCount?: Maybe<ValueWithPercentageResultOfInt64>;
};

export type StreamChatEmote = {
  __typename?: 'StreamChatEmote';
  bttvEmoteId?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  ffzEmoteId?: Maybe<Scalars['String']>;
  imageType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sevenTvEmoteId?: Maybe<Scalars['String']>;
  twitchEmoteId?: Maybe<Scalars['String']>;
  uniqueCount: Scalars['Int'];
  url1X?: Maybe<Scalars['String']>;
  url2X?: Maybe<Scalars['String']>;
  url3X?: Maybe<Scalars['String']>;
  url4X?: Maybe<Scalars['String']>;
};

export type StreamChattersEngagement = {
  __typename?: 'StreamChattersEngagement';
  casualChatters: Scalars['Int'];
  chatMessages: Scalars['Int'];
  engagedChatters: Scalars['Int'];
  lurkers: Scalars['Int'];
  multiMessageChatters: Scalars['Int'];
  singleMessageChatters: Scalars['Int'];
  streamMetricsType: StreamMetricsType;
  superChatters: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  uniqueChatUsers: Scalars['Int'];
};

export type StreamData = {
  __typename?: 'StreamData';
  activeChatters: Scalars['Int'];
  averagePositionInDirectory: Scalars['Float'];
  averagePositionInDirectoryByLanguage: Scalars['Float'];
  averageTimeToFollow?: Maybe<Scalars['Int']>;
  bitsUsed: Scalars['Int'];
  channelPointsRedeemed: Scalars['Long'];
  chatBlobName?: Maybe<Scalars['String']>;
  chatRank: Scalars['Int'];
  chattersRank: Scalars['Int'];
  clipsMade: Scalars['Int'];
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  dateTimeStart: Scalars['DateTime'];
  dropRate: Scalars['Decimal'];
  emotesUsed: Scalars['Int'];
  followersGained: Scalars['Long'];
  followersRank: Scalars['Int'];
  giftedSubscribersGained: Scalars['Int'];
  giftedSubscribersLost: Scalars['Int'];
  id: Scalars['UUID'];
  interactions: Scalars['Int'];
  length?: Maybe<Scalars['Long']>;
  newFollowers: Scalars['Int'];
  newSubscriptions: Scalars['Int'];
  performanceIndicator: Scalars['Decimal'];
  performanceIndicatorV2: Scalars['Int'];
  performanceIndicatorV2Aggregated: Scalars['Int'];
  pollCount: Scalars['Int'];
  pollsInteractions: Scalars['Int'];
  predictionCount: Scalars['Int'];
  predictionsInteractions: Scalars['Int'];
  primeSubscribersGained: Scalars['Int'];
  primeSubscribersLost: Scalars['Int'];
  raidsCount: Scalars['Int'];
  renewedSubscriptions: Scalars['Int'];
  streamChatProcessingErrorMessages?: Maybe<Scalars['String']>;
  streamChatProcessingStatus?: Maybe<StreamStatus>;
  streamChattersEngagements?: Maybe<Array<Maybe<StreamChattersEngagement>>>;
  streamDataProcessingErrorMessages?: Maybe<Scalars['String']>;
  streamDataProcessingStatus?: Maybe<StreamStatus>;
  streamDataRetentions?: Maybe<Array<Maybe<StreamDataRetention>>>;
  streamDataTwitchGames?: Maybe<Array<Maybe<StreamDataTwitchGame>>>;
  streamDataTwitchTitles?: Maybe<Array<Maybe<StreamDataTwitchTitle>>>;
  streamViewersMetrics?: Maybe<Array<Maybe<StreamViewersMetric>>>;
  subscribersGained: Scalars['Int'];
  subscribersLost: Scalars['Int'];
  subscriptionPointsGained: Scalars['Int'];
  subscriptionPointsLost: Scalars['Int'];
  tier1GiftedSubscribersGained: Scalars['Int'];
  tier1GiftedSubscribersLost: Scalars['Int'];
  tier1SubscribersGained: Scalars['Int'];
  tier1SubscribersLost: Scalars['Int'];
  tier2GiftedSubscribersGained: Scalars['Int'];
  tier2GiftedSubscribersLost: Scalars['Int'];
  tier2SubscribersGained: Scalars['Int'];
  tier2SubscribersLost: Scalars['Int'];
  tier3GiftedSubscribersGained: Scalars['Int'];
  tier3GiftedSubscribersLost: Scalars['Int'];
  tier3SubscribersGained: Scalars['Int'];
  tier3SubscribersLost: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  totalWordCount: Scalars['Long'];
  twitchStreamId?: Maybe<Scalars['String']>;
  twitchVideoId?: Maybe<Scalars['String']>;
  userProfileId: Scalars['UUID'];
  version: Scalars['Int'];
  viewersRank: Scalars['Int'];
};

export type StreamDataResult = {
  __typename?: 'StreamDataResult';
  airtimeSeconds: Scalars['Long'];
  averagePositionInDirectory?: Maybe<ValueWithPercentageResult>;
  averageViewers?: Maybe<ValueWithPercentageResult>;
  chatRank?: Maybe<ValueWithPercentageResult>;
  chattersCount: Scalars['Int'];
  chattersPercentage: Scalars['Int'];
  chattersRank?: Maybe<ValueWithPercentageResult>;
  clipsMade?: Maybe<ValueWithPercentageResult>;
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  dateTimeStart: Scalars['DateTime'];
  dropRate?: Maybe<ValueWithPercentageResult>;
  engagedViewersRatio?: Maybe<ValueWithPercentageResult>;
  estimatedUniqueViewers?: Maybe<ValueWithPercentageResult>;
  fansCount: Scalars['Int'];
  fansPercentage: Scalars['Int'];
  followersGained?: Maybe<ValueWithPercentageResult>;
  followersRank?: Maybe<ValueWithPercentageResult>;
  hoursWatched?: Maybe<ValueWithPercentageResult>;
  lurkersCount: Scalars['Int'];
  lurkersPercentage: Scalars['Int'];
  newViewersCount: Scalars['Int'];
  newViewersPercentage: Scalars['Int'];
  peakViewers?: Maybe<ValueWithPercentageResult>;
  performanceIndicator?: Maybe<ValueWithPercentageResult>;
  performanceIndicatorV2?: Maybe<ValueWithPercentageResult>;
  subscribersGained?: Maybe<ValueWithPercentageResult>;
  totalChatMessages?: Maybe<ValueWithPercentageResult>;
  viewersRank?: Maybe<ValueWithPercentageResult>;
};

export type StreamDataRetention = {
  __typename?: 'StreamDataRetention';
  id: Scalars['UUID'];
  items?: Maybe<Array<Maybe<StreamDataRetentionItem>>>;
  streamDataId: Scalars['UUID'];
  streamDataRetentionType?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type StreamDataRetentionItem = {
  __typename?: 'StreamDataRetentionItem';
  maxPercentageOfStream: Scalars['Int'];
  maxTimeInSeconds: Scalars['Long'];
  minPercentageOfStream: Scalars['Int'];
  minTimeInSeconds: Scalars['Long'];
  percentageOfViewers: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export enum StreamDataSearchMember {
  DateTimeEnd = 'DATE_TIME_END',
  DateTimeStart = 'DATE_TIME_START',
  FollowersGained = 'FOLLOWERS_GAINED',
  Length = 'LENGTH',
  SubscribersGained = 'SUBSCRIBERS_GAINED',
  TwitchStreamId = 'TWITCH_STREAM_ID'
}

export type StreamDataSearchSettingsInput = {
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  identifiers?: Maybe<Array<Scalars['UUID']>>;
  includeRetentions: Scalars['Boolean'];
  lengthSeconds?: Maybe<Scalars['Long']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sortMember?: Maybe<StreamDataSearchMember>;
  sortOrder?: Maybe<SortOrder>;
  textSearchTags?: Maybe<Scalars['String']>;
  textSearchTitles?: Maybe<Scalars['String']>;
  twitchGameIds?: Maybe<Array<Scalars['UUID']>>;
  userProfileIds?: Maybe<Array<Scalars['UUID']>>;
};

export type StreamDataTwitchGame = {
  __typename?: 'StreamDataTwitchGame';
  averageFollowersGainedPerHour?: Maybe<Scalars['Float']>;
  averageMessagesPerHour?: Maybe<Scalars['Float']>;
  averagePositionInDirectory?: Maybe<Scalars['Float']>;
  averagePositionInDirectoryByLanguage?: Maybe<Scalars['Float']>;
  averageViewers?: Maybe<Scalars['Float']>;
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  firstSubscriptions?: Maybe<Scalars['Int']>;
  followersGained?: Maybe<Scalars['Int']>;
  hoursWatched?: Maybe<Scalars['Float']>;
  imageUrl?: Maybe<Scalars['String']>;
  length: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  newViewersGained?: Maybe<Scalars['Int']>;
  peakViewers?: Maybe<Scalars['Int']>;
  playedTimePercentageShare: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGameId: Scalars['UUID'];
};

export type StreamDataTwitchGamePlayList = {
  __typename?: 'StreamDataTwitchGamePlayList';
  games?: Maybe<Array<Maybe<StreamDataTwitchGame>>>;
  unknownFirstSubscriptions: Scalars['Int'];
};

export enum StreamDataTwitchGameSearchMember {
  StreamDataId = 'STREAM_DATA_ID'
}

export type StreamDataTwitchGameSearchSettingsInput = {
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  identifiers?: Maybe<Array<Scalars['UUID']>>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sortMember?: Maybe<StreamDataTwitchGameSearchMember>;
  sortOrder?: Maybe<SortOrder>;
  streamDataId?: Maybe<Scalars['UUID']>;
  userProfileIds?: Maybe<Array<Scalars['UUID']>>;
};

export type StreamDataTwitchLanguage = {
  __typename?: 'StreamDataTwitchLanguage';
  dateTimeEnd: Scalars['DateTime'];
  dateTimeStart: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  length: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type StreamDataTwitchTag = {
  __typename?: 'StreamDataTwitchTag';
  dateTimeEnd: Scalars['DateTime'];
  dateTimeStart: Scalars['DateTime'];
  length: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchTag?: Maybe<TwitchTag>;
};

export type StreamDataTwitchTitle = {
  __typename?: 'StreamDataTwitchTitle';
  dateTimeEnd: Scalars['DateTime'];
  dateTimeStart: Scalars['DateTime'];
  length: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type StreamLengthGroup = {
  __typename?: 'StreamLengthGroup';
  averageFollowersPerHour: Scalars['Decimal'];
  averageViewers: Scalars['Float'];
  averageWatchtime: Scalars['Decimal'];
  hours: Scalars['Decimal'];
  streamsCount: Scalars['Int'];
};

export enum StreamMetricsType {
  All = 'ALL',
  NonSubscribers = 'NON_SUBSCRIBERS',
  Subscribers = 'SUBSCRIBERS'
}

export type StreamMonetizationDataResult = {
  __typename?: 'StreamMonetizationDataResult';
  bitsUsed?: Maybe<ValueWithPercentageResult>;
  giftedSubscribersGained?: Maybe<ValueWithPercentageResult>;
  newSubscriptions?: Maybe<ValueWithPercentageResult>;
  recurringSubscribersRatio?: Maybe<ValueWithPercentageResult>;
  renewedSubscriptions?: Maybe<ValueWithPercentageResult>;
  subscribersGained?: Maybe<ValueWithPercentageResult>;
  subscribersLost?: Maybe<ValueWithPercentageResult>;
  subscriptionPointsGained?: Maybe<ValueWithPercentageResult>;
  topBitsDonators?: Maybe<Array<KeyValuePairOfStringAndInt32>>;
  topCumulativeSubscribers?: Maybe<Array<KeyValuePairOfStringAndInt32>>;
  topRenewedSubscribers?: Maybe<Array<KeyValuePairOfStringAndInt32>>;
  topSubscriptionGifters?: Maybe<Array<KeyValuePairOfStringAndInt32>>;
};

export type StreamPeriodicActiveViewersRatio = {
  __typename?: 'StreamPeriodicActiveViewersRatio';
  activeViewersRatio: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicAverageFollowersGained = {
  __typename?: 'StreamPeriodicAverageFollowersGained';
  averageFollowersGained: Scalars['Float'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicAverageViewers = {
  __typename?: 'StreamPeriodicAverageViewers';
  averageViewers: Scalars['Decimal'];
  numberOfStreams: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicChannelPointsRedeemed = {
  __typename?: 'StreamPeriodicChannelPointsRedeemed';
  channelPointsRedeemed: Scalars['Long'];
  channelPointsRedemptions: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicChatActiveViewers = {
  __typename?: 'StreamPeriodicChatActiveViewers';
  activeChatViewers: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicChatEmote = {
  __typename?: 'StreamPeriodicChatEmote';
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
  usages: Scalars['Int'];
};

export type StreamPeriodicChatMessages = {
  __typename?: 'StreamPeriodicChatMessages';
  activeChatters: Scalars['Float'];
  chatMessages: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicClipsMade = {
  __typename?: 'StreamPeriodicClipsMade';
  clipsMade: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicConcurrentViewers = {
  __typename?: 'StreamPeriodicConcurrentViewers';
  concurrentViewers: Scalars['Float'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicCount = {
  __typename?: 'StreamPeriodicCount';
  count: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicDropRate = {
  __typename?: 'StreamPeriodicDropRate';
  dropRate: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicFollowersGained = {
  __typename?: 'StreamPeriodicFollowersGained';
  followersGained: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicHoursWatched = {
  __typename?: 'StreamPeriodicHoursWatched';
  hoursWatched: Scalars['Float'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicInteractions = {
  __typename?: 'StreamPeriodicInteractions';
  interactions: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicInteractionsPerViewer = {
  __typename?: 'StreamPeriodicInteractionsPerViewer';
  interactionsPerViewer: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicJoinedAndLeft = {
  __typename?: 'StreamPeriodicJoinedAndLeft';
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
  viewersJoined: Scalars['Float'];
  viewersLeft: Scalars['Float'];
};

export type StreamPeriodicPollsAndPredictions = {
  __typename?: 'StreamPeriodicPollsAndPredictions';
  pollCount: Scalars['Int'];
  pollsInteractions: Scalars['Int'];
  predictionCount: Scalars['Int'];
  predictionsInteractions: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicPositionInDirectory = {
  __typename?: 'StreamPeriodicPositionInDirectory';
  positionInDirectory: Scalars['Float'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicRewardsRedeemed = {
  __typename?: 'StreamPeriodicRewardsRedeemed';
  count: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicSubscribersGained = {
  __typename?: 'StreamPeriodicSubscribersGained';
  bitsUsed: Scalars['Int'];
  giftedSubscribersGained: Scalars['Int'];
  newSubscriptionsGained: Scalars['Int'];
  primeSubscribersGained: Scalars['Int'];
  renewedSubscriptions: Scalars['Int'];
  subscribersGained: Scalars['Int'];
  subscribersLost: Scalars['Int'];
  subscriptionPointsGained: Scalars['Int'];
  tier1NonGiftedSubscribersGained: Scalars['Int'];
  tier2NonGiftedSubscribersGained: Scalars['Int'];
  tier3NonGiftedSubscribersGained: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicTopInteractionMoment = {
  __typename?: 'StreamPeriodicTopInteractionMoment';
  activeViewersRatio: Scalars['Decimal'];
  concurrentViewers: Scalars['Int'];
  interactions: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  topClip?: Maybe<TwitchClip>;
  topEmote?: Maybe<StreamChatEmote>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicTypicalConcurrentViewers = {
  __typename?: 'StreamPeriodicTypicalConcurrentViewers';
  maxConcurrentViewers: Scalars['Decimal'];
  minConcurenntViewers: Scalars['Decimal'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
};

export type StreamPeriodicUniqueViewers = {
  __typename?: 'StreamPeriodicUniqueViewers';
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchGame?: Maybe<TwitchGame>;
  uniqueViewers: Scalars['Decimal'];
};

export type StreamRewardRedeemed = {
  __typename?: 'StreamRewardRedeemed';
  cost: Scalars['Int'];
  count: Scalars['Int'];
  prompt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalCost: Scalars['Long'];
  twitchCustomRewardId?: Maybe<Scalars['String']>;
};

export enum StreamStatus {
  CalculatedFromTracker = 'CALCULATED_FROM_TRACKER',
  Error = 'ERROR',
  Finished = 'FINISHED',
  Started = 'STARTED'
}

export type StreamViewersDataResult = {
  __typename?: 'StreamViewersDataResult';
  averageTimeToFollow?: Maybe<ValueWithPercentageResultOfNullableOfInt32>;
  averageViewers?: Maybe<ValueWithPercentageResult>;
  averageWatchtime?: Maybe<ValueWithPercentageResult>;
  averageWatchtimeNonSubs?: Maybe<ValueWithPercentageResult>;
  averageWatchtimeSubs?: Maybe<ValueWithPercentageResult>;
  casualViewers?: Maybe<ValueWithPercentageResult>;
  casualViewersSubs?: Maybe<ValueWithPercentageResult>;
  estimatedUniqueViewers?: Maybe<ValueWithPercentageResult>;
  followersGained?: Maybe<ValueWithPercentageResult>;
  hoursWatched?: Maybe<ValueWithPercentageResult>;
  hoursWatchedNonSubs?: Maybe<ValueWithPercentageResult>;
  hoursWatchedSubs?: Maybe<ValueWithPercentageResult>;
  newFollowers?: Maybe<ValueWithPercentageResult>;
  newViewers?: Maybe<ValueWithPercentageResult>;
  newViewersConversionRate?: Maybe<ValueWithPercentageResult>;
  oneStreamViewersSubs?: Maybe<ValueWithPercentageResult>;
  peakViewers?: Maybe<ValueWithPercentageResult>;
  regularViewers?: Maybe<ValueWithPercentageResult>;
  regularViewersSubs?: Maybe<ValueWithPercentageResult>;
  returningViewers?: Maybe<ValueWithPercentageResult>;
  returningViewersSubs?: Maybe<ValueWithPercentageResult>;
  streamDataRetentions?: Maybe<Array<Maybe<StreamDataRetention>>>;
  views?: Maybe<ValueWithPercentageResultOfNullableOfDecimal>;
  zeroStreamViewersSubs?: Maybe<ValueWithPercentageResult>;
};

export type StreamViewersMetric = {
  __typename?: 'StreamViewersMetric';
  activeViewersRatio?: Maybe<Scalars['Decimal']>;
  averageViewers?: Maybe<Scalars['Int']>;
  averageWatchtime: Scalars['Decimal'];
  casualViewers: Scalars['Int'];
  estimatedUniqueViewers?: Maybe<Scalars['Decimal']>;
  hoursWatched: Scalars['Decimal'];
  newViewers: Scalars['Int'];
  oneStreamViewers: Scalars['Int'];
  peakViewers?: Maybe<Scalars['Int']>;
  regularViewers: Scalars['Int'];
  returningViewers: Scalars['Int'];
  streamMetricsType: StreamMetricsType;
  timeStamp?: Maybe<Scalars['DateTime']>;
  views: Scalars['Int'];
  zeroStreamViewers: Scalars['Int'];
};

export enum StreamsWatched {
  LessThan50Percent = 'LESS_THAN50_PERCENT',
  MoreThan50Percent = 'MORE_THAN50_PERCENT',
  OneStream = 'ONE_STREAM',
  ZeroStreams = 'ZERO_STREAMS'
}

export type StringResult = {
  __typename?: 'StringResult';
  value?: Maybe<Scalars['String']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  currency?: Maybe<Scalars['String']>;
  currencyOptions?: Maybe<Array<KeyValuePairOfStringAndNullableOfDecimal>>;
  interval?: Maybe<Scalars['String']>;
  lookupKey?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  percentOff?: Maybe<Scalars['Decimal']>;
  price: Scalars['Long'];
  priceId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type SubscriberRates = {
  __typename?: 'SubscriberRates';
  followerToSubscriberConversions?: Maybe<FollowerToSubscriberConversions>;
  giftedToPaidRates?: Maybe<SubscriptionRenewalRates>;
  primeRenewalRates?: Maybe<SubscriptionRenewalRates>;
};

export type SubscriptionChangeAndBreakdown = {
  __typename?: 'SubscriptionChangeAndBreakdown';
  items?: Maybe<Array<Maybe<SubscriptionChangeAndBreakdownItem>>>;
};

export type SubscriptionChangeAndBreakdownItem = {
  __typename?: 'SubscriptionChangeAndBreakdownItem';
  change: Scalars['Int'];
  churnedSubs: Scalars['Int'];
  currentGiftedSubs: Scalars['Int'];
  currentPrimeSubs: Scalars['Int'];
  currentTier1Subs: Scalars['Int'];
  currentTier2Subs: Scalars['Int'];
  currentTier3Subs: Scalars['Int'];
  dateTime: Scalars['DateTime'];
  isValid: Scalars['Boolean'];
  newSubs: Scalars['Int'];
  totalSubs: Scalars['Int'];
};

export type SubscriptionRenewalMonthRate = {
  __typename?: 'SubscriptionRenewalMonthRate';
  converted: Scalars['Int'];
  count: Scalars['Int'];
  dateTime?: Maybe<Scalars['DateTime']>;
  percentage: Scalars['Decimal'];
};

export type SubscriptionRenewalRates = {
  __typename?: 'SubscriptionRenewalRates';
  average: Scalars['Decimal'];
  lastMonthDiff: Scalars['Decimal'];
  subscriptionRenewalMonthlyRates?: Maybe<Array<Maybe<SubscriptionRenewalMonthRate>>>;
};

export type TenantInvitation = {
  __typename?: 'TenantInvitation';
  invitationTokenId: Scalars['UUID'];
  userName?: Maybe<Scalars['String']>;
};

export type TenantManager = {
  __typename?: 'TenantManager';
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type TenantManagers = {
  __typename?: 'TenantManagers';
  managers?: Maybe<Array<Maybe<TenantManager>>>;
};

export type TikTokAccountData = {
  __typename?: 'TikTokAccountData';
  accountId: Scalars['UUID'];
  commentCount?: Maybe<ValueWithPercentageResult>;
  followersCount?: Maybe<ValueWithPercentageResult>;
  growth?: Maybe<ValueWithPercentageResult>;
  likeCount?: Maybe<ValueWithPercentageResult>;
  shareCount?: Maybe<ValueWithPercentageResult>;
  tikTokDisplayName?: Maybe<Scalars['String']>;
  tikTokUserId?: Maybe<Scalars['String']>;
  tikTokUserName?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  viewCount?: Maybe<ValueWithPercentageResult>;
};

export type TikTokPeriodicData = {
  __typename?: 'TikTokPeriodicData';
  commentCount: Scalars['Int'];
  diggCount: Scalars['Int'];
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  likeCount: Scalars['Int'];
  shareCount: Scalars['Int'];
  tikTokAccountId: Scalars['UUID'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  videoCount: Scalars['Int'];
  viewCount: Scalars['Long'];
};

export type TikTokPeriodicDataSearchSettingsInput = {
  monthsToQuery?: Maybe<Scalars['Int']>;
  tikTokAccountIds?: Maybe<Array<Scalars['UUID']>>;
};

export type Tip = {
  __typename?: 'Tip';
  area?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export type TopClipsReview = {
  __typename?: 'TopClipsReview';
  items?: Maybe<Array<Maybe<TopClipsReviewItem>>>;
  twitchVideoExists: Scalars['Boolean'];
  twitchVideoId?: Maybe<Scalars['String']>;
  twitchVideoThumbnailUrl?: Maybe<Scalars['String']>;
};

export type TopClipsReviewItem = {
  __typename?: 'TopClipsReviewItem';
  chatMessages?: Maybe<ValueWithPercentageResult>;
  chatMessagesScore: Scalars['Decimal'];
  clipViews?: Maybe<ValueWithPercentageResult>;
  clipViewsScore: Scalars['Decimal'];
  clips?: Maybe<Array<Maybe<TwitchClip>>>;
  clipsTaken?: Maybe<ValueWithPercentageResult>;
  clipsTakenScore: Scalars['Decimal'];
  dateTime: Scalars['DateTime'];
  emotesUsed?: Maybe<Array<Maybe<StreamChatEmote>>>;
  totalScore: Scalars['Decimal'];
  uniqueChatters?: Maybe<ValueWithPercentageResult>;
  uniqueChattersScore: Scalars['Decimal'];
  uniqueEmotes?: Maybe<ValueWithPercentageResult>;
  uniqueEmotesScore: Scalars['Decimal'];
};

export type TwitchChannelData = {
  __typename?: 'TwitchChannelData';
  followerCount?: Maybe<ValueWithPercentageResultOfInt64>;
  growth?: Maybe<ValueWithPercentageResult>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchUserName?: Maybe<Scalars['String']>;
  viewCount?: Maybe<ValueWithPercentageResultOfInt64>;
};

export type TwitchClip = {
  __typename?: 'TwitchClip';
  broadcasterId?: Maybe<Scalars['String']>;
  broadcasterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorId?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  embedUrl?: Maybe<Scalars['String']>;
  gameId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitchGame?: Maybe<TwitchGame>;
  url?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
};

export type TwitchGame = {
  __typename?: 'TwitchGame';
  description?: Maybe<Scalars['String']>;
  iGDBArtworkImageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBCoverImageId?: Maybe<Scalars['String']>;
  iGDBCreatedAt?: Maybe<Scalars['DateTime']>;
  iGDBFirstReleaseDate?: Maybe<Scalars['DateTime']>;
  iGDBGameDevelopers?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBGameModes?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBGamePublishers?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBGameVideoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBGenres?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBId?: Maybe<Scalars['String']>;
  iGDBName?: Maybe<Scalars['String']>;
  iGDBOfficialWebsiteUrl?: Maybe<Scalars['String']>;
  iGDBPlatforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBScreenshotImageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBSlug?: Maybe<Scalars['String']>;
  iGDBStoryline?: Maybe<Scalars['String']>;
  iGDBSummary?: Maybe<Scalars['String']>;
  iGDBThemes?: Maybe<Array<Maybe<Scalars['String']>>>;
  iGDBUpdatedAt?: Maybe<Scalars['DateTime']>;
  iGDBUrl?: Maybe<Scalars['String']>;
  iGDBWebsiteUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['UUID'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pEGIRatingValue?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchId?: Maybe<Scalars['String']>;
};

export enum TwitchGameSearchMember {
  Name = 'NAME'
}

export type TwitchGameSearchSettingsInput = {
  iGDBIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isIgdbIdRequired?: Maybe<Scalars['Boolean']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sortMember?: Maybe<TwitchGameSearchMember>;
  sortOrder?: Maybe<SortOrder>;
  textSearch?: Maybe<Scalars['String']>;
  twitchIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TwitchPeriodicData = {
  __typename?: 'TwitchPeriodicData';
  followerCount: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  viewCount: Scalars['Long'];
};

export type TwitchPeriodicDataSearchSettingsInput = {
  monthsToQuery?: Maybe<Scalars['Int']>;
  userProfileId?: Maybe<Scalars['UUID']>;
};

export type TwitchRaidPeriodicData = {
  __typename?: 'TwitchRaidPeriodicData';
  newFollowers: Scalars['Int'];
  newViewers: Scalars['Int'];
  raidTime: Scalars['DateTime'];
  remainingRaidViewers: Scalars['Int'];
  statsId: Scalars['UUID'];
  streamDataId: Scalars['UUID'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  totalViewers: Scalars['Int'];
  twitchUserId?: Maybe<Scalars['String']>;
  userProfileId: Scalars['UUID'];
};

export type TwitchRaidStat = {
  __typename?: 'TwitchRaidStat';
  conversions: Scalars['Int'];
  hoursWatched: Scalars['Decimal'];
  raidStatsId: Scalars['UUID'];
  raidTime: Scalars['DateTime'];
  retentionData?: Maybe<Array<Maybe<TwitchRaidPeriodicData>>>;
  sourceCategoryId?: Maybe<Scalars['String']>;
  sourceCategoryName?: Maybe<Scalars['String']>;
  sourceGameId?: Maybe<Scalars['String']>;
  sourceGameImageUrl?: Maybe<Scalars['String']>;
  sourceGameName?: Maybe<Scalars['String']>;
  sourceStreamId?: Maybe<Scalars['String']>;
  targetStreamGuid: Scalars['UUID'];
  targetStreamTwitchId?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchUserId?: Maybe<Scalars['String']>;
  twitchUserLogin?: Maybe<Scalars['String']>;
  twitchUserName?: Maybe<Scalars['String']>;
  twitchUserProfileImageUrl?: Maybe<Scalars['String']>;
  userProfileId: Scalars['UUID'];
  viewers: Scalars['Int'];
};

export type TwitchSubscriberFollowAge = {
  __typename?: 'TwitchSubscriberFollowAge';
  count: Scalars['Int'];
  followAge: FollowAge;
};

export type TwitchSubscriberStreamsWatched = {
  __typename?: 'TwitchSubscriberStreamsWatched';
  count: Scalars['Int'];
  streamsWatched: StreamsWatched;
};

export type TwitchSubscriptionLength = {
  __typename?: 'TwitchSubscriptionLength';
  count: Scalars['Int'];
  months?: Maybe<Scalars['Int']>;
};

export type TwitchTag = {
  __typename?: 'TwitchTag';
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isAuto: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitchId?: Maybe<Scalars['String']>;
};

export type TwitchUserWhaleDataRecord = {
  __typename?: 'TwitchUserWhaleDataRecord';
  accountAgeDays: Scalars['Int'];
  averageChatMessagesPerStream: Scalars['Decimal'];
  averageWatchTime: Scalars['Decimal'];
  bitsCheered: Scalars['Int'];
  bitsCheeredChartData?: Maybe<Array<Maybe<ValueWithDateTime>>>;
  chatMessages: Scalars['Int'];
  followerAgeDays: Scalars['Int'];
  hoursWatched: Scalars['Decimal'];
  lastChatMessage?: Maybe<Scalars['DateTime']>;
  lastTimeWatchingStream?: Maybe<Scalars['DateTime']>;
  numberOfFollowers: Scalars['Long'];
  profileImageUrl?: Maybe<Scalars['String']>;
  streamsWatchRate: Scalars['Decimal'];
  subsGifted: Scalars['Int'];
  subsGiftedChartData?: Maybe<Array<Maybe<ValueWithDateTime>>>;
  subscriberLengthMonths?: Maybe<Scalars['Int']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  totalBitsCheered: Scalars['Int'];
  totalSubsGifted: Scalars['Int'];
  twitchUserLogin?: Maybe<Scalars['String']>;
  twitchUserName?: Maybe<Scalars['String']>;
};

export type TwitterAccountData = {
  __typename?: 'TwitterAccountData';
  accountId: Scalars['UUID'];
  followersCount?: Maybe<ValueWithPercentageResult>;
  growth?: Maybe<ValueWithPercentageResult>;
  impressionsCount?: Maybe<ValueWithPercentageResult>;
  likeCount?: Maybe<ValueWithPercentageResult>;
  replyCount?: Maybe<ValueWithPercentageResult>;
  retweetCount?: Maybe<ValueWithPercentageResult>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitterUserId?: Maybe<Scalars['String']>;
  twitterUserName?: Maybe<Scalars['String']>;
  urlLinkClicks?: Maybe<ValueWithPercentageResult>;
  userProfileClicks?: Maybe<ValueWithPercentageResult>;
};

export type TwitterPeriodicData = {
  __typename?: 'TwitterPeriodicData';
  followersCount: Scalars['Int'];
  impressionsCount: Scalars['Int'];
  likeCount: Scalars['Int'];
  replyCount: Scalars['Int'];
  retweetCount: Scalars['Int'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitterAccountId: Scalars['UUID'];
  urlLinkClicks: Scalars['Int'];
  userProfileClicks: Scalars['Int'];
};

export type TwitterPeriodicDataSearchSettingsInput = {
  monthsToQuery?: Maybe<Scalars['Int']>;
  twitterAccountIds?: Maybe<Array<Scalars['UUID']>>;
};

export type UserAvailableGameDeveloperCampaign = {
  __typename?: 'UserAvailableGameDeveloperCampaign';
  claimedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  embargoUntil: Scalars['DateTime'];
  embargoUntilEnabled: Scalars['Boolean'];
  endDate: Scalars['DateTime'];
  endDateEnabled: Scalars['Boolean'];
  fitForYou: Scalars['Decimal'];
  fitForYouGames?: Maybe<Array<Maybe<TwitchGame>>>;
  hasStreamerDelivered?: Maybe<Scalars['Boolean']>;
  howToRedeemUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isInWishList: Scalars['Boolean'];
  minimumHoursToDeliver: Scalars['Decimal'];
  minimumHoursToDeliverEnabled: Scalars['Boolean'];
  numberOfKeys: Scalars['Int'];
  numberOfKeysLeft: Scalars['Int'];
  status: GameDeveloperCampaignStatus;
  stores?: Maybe<Array<Maybe<GameDeveloperCampaignStoreOut>>>;
  title?: Maybe<Scalars['String']>;
  twitchGame?: Maybe<TwitchGame>;
  type: GameDeveloperCampaignType;
};

export type UserBasicStreamInfo = {
  __typename?: 'UserBasicStreamInfo';
  firstStreamDate?: Maybe<Scalars['DateTime']>;
  lastStreamDate?: Maybe<Scalars['DateTime']>;
  streamCount: Scalars['Int'];
};

export type UserInsight = {
  __typename?: 'UserInsight';
  activeViewersRatio: Scalars['Decimal'];
  averageConcurrentViewers: Scalars['Float'];
  displayName?: Maybe<Scalars['String']>;
  followersPerHour: Scalars['Float'];
  hoursStreamed: Scalars['Float'];
  hoursWatched: Scalars['Decimal'];
  peekViewers?: Maybe<Scalars['Int']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  subscribers: Scalars['Int'];
  subscribersWithoutGiftedAndPrime: Scalars['Int'];
  totalChatMessages: Scalars['Int'];
  totalFollowersGained: Scalars['Long'];
  userProfileId: Scalars['UUID'];
};

export type UserKnownBot = {
  __typename?: 'UserKnownBot';
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
};

export enum UserKnownBotSearchMember {
  Name = 'NAME'
}

export type UserKnownBotSearchSettingsInput = {
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sortMember?: Maybe<UserKnownBotSearchMember>;
  sortOrder?: Maybe<SortOrder>;
};

export type UserMediaInfo = {
  __typename?: 'UserMediaInfo';
  discordImpression?: Maybe<Scalars['Int']>;
  discordMembers?: Maybe<Scalars['Int']>;
  instagramFollowers?: Maybe<Scalars['Long']>;
  instagramViewsImpression?: Maybe<Scalars['Long']>;
  tikTokFollowers?: Maybe<Scalars['Int']>;
  tikTokViewsImpression?: Maybe<Scalars['Long']>;
  totalFans?: Maybe<Scalars['Long']>;
  twitchAverageConcurrentViewers?: Maybe<Scalars['Int']>;
  twitchFollowers?: Maybe<Scalars['Long']>;
  twitchUserName?: Maybe<Scalars['String']>;
  twitterFollowers?: Maybe<Scalars['Int']>;
  twitterViewsImpression?: Maybe<Scalars['Int']>;
  userProfileUrl?: Maybe<Scalars['String']>;
  youTubeSubscribers?: Maybe<Scalars['Long']>;
  youTubeViewsImpression?: Maybe<Scalars['Long']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  monthlyReportSetting?: Maybe<NotificationSetting>;
  personalizedActionableTipsSetting?: Maybe<NotificationSetting>;
  streamReportSetting?: Maybe<NotificationSetting>;
  weeklyReportSetting?: Maybe<NotificationSetting>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  discord: SocialPlatformUsage;
  id?: Maybe<Scalars['UUID']>;
  instagram: SocialPlatformUsage;
  monthlyStreamingHoursGoal: Scalars['Int'];
  tikTok: SocialPlatformUsage;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitch: SocialPlatformUsage;
  twitter: SocialPlatformUsage;
  userProfileId?: Maybe<Scalars['UUID']>;
  weeklyStreamingHoursGoal: Scalars['Int'];
  youTube: SocialPlatformUsage;
};

export type UserSettingInput = {
  discord: SocialPlatformUsage;
  id?: Maybe<Scalars['UUID']>;
  instagram: SocialPlatformUsage;
  monthlyStreamingHoursGoal: Scalars['Int'];
  tikTok: SocialPlatformUsage;
  timeStamp?: Maybe<Scalars['DateTime']>;
  twitch: SocialPlatformUsage;
  twitter: SocialPlatformUsage;
  userProfileId?: Maybe<Scalars['UUID']>;
  weeklyStreamingHoursGoal: Scalars['Int'];
  youTube: SocialPlatformUsage;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  cancelAtPeriodEnd: Scalars['Boolean'];
  customerId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  productNickName?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  userProfileId: Scalars['UUID'];
  userSubscriptionPlan?: Maybe<Scalars['String']>;
  userSubscriptionStatus?: Maybe<Scalars['String']>;
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
};

export type UserTenant = {
  __typename?: 'UserTenant';
  id: Scalars['UUID'];
  isMe: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  userProfileId: Scalars['UUID'];
};

export type UserTip = {
  __typename?: 'UserTip';
  confirmedUntil?: Maybe<Scalars['DateTime']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  tip?: Maybe<Tip>;
  userId?: Maybe<Scalars['String']>;
};

export type UsersTenants = {
  __typename?: 'UsersTenants';
  tenants?: Maybe<Array<Maybe<UserTenant>>>;
};

export type VodReview = {
  __typename?: 'VODReview';
  items?: Maybe<Array<Maybe<VodReviewItem>>>;
  streamDataId: Scalars['UUID'];
  twitchRaidStats?: Maybe<Array<Maybe<TwitchRaidStat>>>;
  twitchVideoExists: Scalars['Boolean'];
  twitchVideoId?: Maybe<Scalars['String']>;
  twitchVideoThumbnailUrl?: Maybe<Scalars['String']>;
};

export type VodReviewItem = {
  __typename?: 'VODReviewItem';
  dateTime: Scalars['DateTime'];
  isPositive?: Maybe<Scalars['Boolean']>;
  percentageFromAverage: Scalars['Float'];
  propName?: Maybe<Scalars['String']>;
  tipText?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type ValueWithDateTime = {
  __typename?: 'ValueWithDateTime';
  dateTime: Scalars['DateTime'];
  value: Scalars['Decimal'];
};

export type ValueWithDescriptionAndPercentageResult = {
  __typename?: 'ValueWithDescriptionAndPercentageResult';
  description?: Maybe<Scalars['String']>;
  percentage: Scalars['Decimal'];
  value: Scalars['Decimal'];
};

export type ValueWithPercentageResult = {
  __typename?: 'ValueWithPercentageResult';
  percentage: Scalars['Decimal'];
  value: Scalars['Decimal'];
};

export type ValueWithPercentageResultOfInt32 = {
  __typename?: 'ValueWithPercentageResultOfInt32';
  percentage: Scalars['Decimal'];
  value: Scalars['Int'];
};

export type ValueWithPercentageResultOfInt64 = {
  __typename?: 'ValueWithPercentageResultOfInt64';
  percentage: Scalars['Decimal'];
  value: Scalars['Long'];
};

export type ValueWithPercentageResultOfNullableOfDecimal = {
  __typename?: 'ValueWithPercentageResultOfNullableOfDecimal';
  percentage: Scalars['Decimal'];
  value?: Maybe<Scalars['Decimal']>;
};

export type ValueWithPercentageResultOfNullableOfInt32 = {
  __typename?: 'ValueWithPercentageResultOfNullableOfInt32';
  percentage: Scalars['Decimal'];
  value?: Maybe<Scalars['Int']>;
};

export type ValueWithPercentageResultOfString = {
  __typename?: 'ValueWithPercentageResultOfString';
  percentage: Scalars['Decimal'];
  value?: Maybe<Scalars['String']>;
};

export enum WeekendEnum {
  Weekday = 'WEEKDAY',
  Weekend = 'WEEKEND'
}

export type WeekendResult = {
  __typename?: 'WeekendResult';
  averageViewers?: Maybe<Array<Maybe<StreamPeriodicAverageViewers>>>;
  weekendEnum: WeekendEnum;
};

export enum WhatToStreamGameMetricType {
  /** Average watchtime metric. */
  AverageWatchTimeMetric = 'AVERAGE_WATCH_TIME_METRIC',
  /** Concurrent viewers growth metric. */
  CcvGrowthMetric = 'CCV_GROWTH_METRIC',
  /** Chat engagement metric. */
  ChatEngagementMetric = 'CHAT_ENGAGEMENT_METRIC',
  /** Directory position metric. */
  DirectoryPositionMetric = 'DIRECTORY_POSITION_METRIC',
  /** Followers per hour metric. */
  FollowersPerHourMetric = 'FOLLOWERS_PER_HOUR_METRIC',
  /** Inverse game saturation metric. */
  InverseGameSaturationMetric = 'INVERSE_GAME_SATURATION_METRIC'
}

export type WhenToStream = {
  __typename?: 'WhenToStream';
  dayOfWeekResults?: Maybe<Array<Maybe<DayOfWeekResult>>>;
  joinedStreamPeriodicAverageFollowersGained?: Maybe<Array<Maybe<StreamPeriodicAverageFollowersGained>>>;
  joinedStreamPeriodicAverageViewers?: Maybe<Array<Maybe<StreamPeriodicAverageViewers>>>;
  joinedStreamPeriodicCounts?: Maybe<Array<Maybe<StreamPeriodicCount>>>;
  joinedStreamPeriodicTotalFollowersGained?: Maybe<Array<Maybe<StreamPeriodicFollowersGained>>>;
  streamLengthGroups?: Maybe<Array<Maybe<StreamLengthGroup>>>;
  weekendAverageViewers?: Maybe<Array<Maybe<WeekendResult>>>;
};

export type YouTubeChannelData = {
  __typename?: 'YouTubeChannelData';
  channelId: Scalars['UUID'];
  growth?: Maybe<ValueWithPercentageResult>;
  subscriberCount?: Maybe<ValueWithPercentageResultOfInt64>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  videoCount?: Maybe<ValueWithPercentageResultOfInt64>;
  viewCount?: Maybe<ValueWithPercentageResultOfInt64>;
  youTubeChannelId?: Maybe<Scalars['String']>;
};

export type YouTubePeriodicData = {
  __typename?: 'YouTubePeriodicData';
  channelId: Scalars['UUID'];
  subscriberCount: Scalars['Long'];
  timeStamp?: Maybe<Scalars['DateTime']>;
  videoCount: Scalars['Long'];
  viewCount: Scalars['Long'];
};

export type YouTubePeriodicDataSearchSettingsInput = {
  channelIds?: Maybe<Array<Scalars['UUID']>>;
  monthsToQuery?: Maybe<Scalars['Int']>;
};
