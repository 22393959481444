import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { memo, useEffect, useState } from 'react';

import fallbackAvatarImage from '../assets/images/avatar.png';
import { ResponsiveValue } from '../types';

interface AvatarProps {
    src?: string;
    size?: number;
    hasBorder?: boolean;
    borderColor?: string;
    mr?: ResponsiveValue;
}

const Avatar = ({
    src,
    size = 40,
    hasBorder = false,
    borderColor,
    mr = 0
}: AvatarProps): JSX.Element => {
    const theme = useTheme();
    const [imageError, setImageError] = useState<boolean>(false);

    useEffect(() => {
        setImageError(false);
    }, [src]);

    return (
        <>
            {src && (
                <Box
                    component="img"
                    src={imageError ? fallbackAvatarImage : src}
                    sx={{
                        width: `${size}px`,
                        height: `${size}px`,
                        borderRadius: `${size / 2}px`,
                        mr,
                        flexShrink: 0,
                        boxShadow: hasBorder
                            ? `0px 0px 0px 2px ${
                                  borderColor ??
                                  alpha(theme.palette.common.white, 0.1)
                              }`
                            : 'none',
                        transition: theme.transitions.create(
                            ['width', 'height', 'border-radius'],
                            {
                                duration: theme.transitions.duration.standard
                            }
                        ),
                        backgroundColor: imageError
                            ? 'baseInk.main'
                            : 'transparent'
                    }}
                    onError={() => setImageError(true)}
                />
            )}
            {!src && (
                <Box
                    sx={{
                        width: `${size}px`,
                        height: `${size}px`,
                        mr,
                        borderRadius: `${size / 2}px`,
                        flexShrink: 0,
                        backgroundColor: 'baseCloud.transparent10',
                        transition: theme.transitions.create(
                            ['width', 'height', 'border-radius'],
                            {
                                duration: theme.transitions.duration.standard
                            }
                        )
                    }}
                />
            )}
        </>
    );
};

export default memo(Avatar);
