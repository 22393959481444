import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {
    ChangeEvent,
    Dispatch,
    FocusEvent,
    HTMLInputTypeAttribute,
    ReactNode,
    SetStateAction
} from 'react';

import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import baseTheme from '../../theme/baseTheme';
import { ResponsiveValue } from '../../types';

interface InputProps {
    setValue: Dispatch<SetStateAction<string>>;
    value?: string;
    title?: string;
    error?: string;
    infoText?: string | ReactNode;
    tooltipText?: string;
    setError?: Dispatch<SetStateAction<string | undefined>>;
    validator?: (val: string) => string | undefined;
    mt?: ResponsiveValue;
    mb?: ResponsiveValue;
    mr?: ResponsiveValue;
    type?: HTMLInputTypeAttribute;
    min?: number;
    max?: number;
    startIcon?: ReactNode;
    button?: ReactNode;
    placeholder?: string;
    autoFocus?: boolean;
}

const Input = ({
    setValue,
    value,
    title,
    error,
    infoText,
    tooltipText,
    setError,
    validator,
    mt = 0,
    mb = 0,
    mr = 0,
    type = 'text',
    min,
    max,
    startIcon,
    button,
    placeholder,
    autoFocus = false
}: InputProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                mt,
                mb,
                mr
            }}
        >
            {title && (
                <Typography
                    sx={{
                        fontSize: '1.4rem',
                        lineHeight: '2rem',
                        fontWeight: 500,
                        mb: 1
                    }}
                >
                    {title}
                </Typography>
            )}

            <Box
                sx={{
                    height: '44px',
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'stretch',
                    px: 3,
                    pr: button ? 1.5 : 3,
                    backgroundColor: 'baseCloud.transparent10',
                    borderRadius: 2,
                    boxShadow: `inset 0px 0px 0px 1px ${
                        theme.palette.baseCloud.transparent15 ?? ''
                    }`,
                    '& > svg': {
                        width: '20px',
                        height: '20px',
                        '& path': {
                            fill: theme.palette.text.secondary
                        }
                    },
                    ...(!error && {
                        '&:focus-within': {
                            boxShadow: `inset 0px 0px 0px 2px ${theme.palette.accentBlue.dark}`
                        }
                    }),
                    ...(!!error && {
                        boxShadow: `inset 0px 0px 0px 2px ${theme.palette.accentRed.dark}`
                    }),
                    transition: theme.transitions.create(['box-shadow'], {
                        duration: theme.transitions.duration.standard
                    })
                }}
            >
                {startIcon && startIcon}

                <Box
                    component="input"
                    value={value}
                    placeholder={placeholder ?? ''}
                    sx={{
                        height: '20px',
                        flex: 1,
                        backgroundColor: 'transparent',
                        border: 0,
                        color: 'common.white',
                        fontSize: '1.4rem',
                        lineHeight: '2rem',
                        fontWeight: 400,
                        fontFamily: theme.typography.fontFamily,
                        '&::placeholder': {
                            color: 'text.secondary',
                            opacity: 1
                        },
                        '&:focus': {
                            border: 0,
                            outline: 'none'
                        }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setValue(e.target.value);
                    }}
                    onFocus={() => {
                        if (setError) {
                            setError(undefined);
                        }
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        if (validator) {
                            const err = validator(e.target.value);

                            if (setError) {
                                setError(err);
                            }
                        }
                    }}
                    autoFocus={autoFocus}
                    type={type}
                    min={min}
                    max={max}
                />

                {tooltipText && (
                    <Tooltip title={tooltipText} arrow>
                        <InfoIcon />
                    </Tooltip>
                )}

                {button && (
                    <Box
                        sx={{
                            '& > button': {
                                height: '32px',
                                ...(!!error && {
                                    backgroundColor: 'accentRed.dark',
                                    '&:hover': {
                                        backgroundColor:
                                            baseTheme.palette.accentRed.main
                                    }
                                })
                            }
                        }}
                    >
                        {button}
                    </Box>
                )}
            </Box>

            {!!error && (
                <Typography
                    sx={{
                        fontSize: '1.4rem',
                        lineHeight: '2rem',
                        color: 'accentRed.dark',
                        fontWeight: 450,
                        mt: 2,
                        display: 'flex',
                        '& > svg': {
                            mr: 1,
                            width: '20px',
                            height: '20px',
                            flexShrink: 0
                        },
                        '& > svg path': {
                            fill: theme.palette.accentRed.dark
                        }
                    }}
                >
                    <ErrorIcon />
                    {error}
                </Typography>
            )}

            {!!infoText && !error && (
                <Typography
                    sx={{
                        fontSize: '1.4rem',
                        lineHeight: '2rem',
                        color: 'accentBlue.dark',
                        fontWeight: 450,
                        mt: 2,
                        display: 'flex',
                        '& > svg': {
                            mr: 1,
                            width: '20px',
                            height: '20px',
                            flexShrink: 0
                        },
                        '& > svg path': {
                            fill: theme.palette.accentBlue.dark
                        },
                        '& a': {
                            color: 'accentBlue.dark'
                        }
                    }}
                >
                    <InfoIcon />
                    {infoText}
                </Typography>
            )}
        </Box>
    );
};

export default Input;
