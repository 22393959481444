import baseTheme from '../baseTheme';

const MuiRadio = {
    styleOverrides: {
        root: {
            width: '64px',
            height: '32px',
            padding: 0
        },
        switchBase: {
            width: '24px',
            height: '24px',
            padding: 0,
            margin: '4px',
            transition: baseTheme.transitions.create(['transform'], {
                duration: baseTheme.transitions.duration.standard,
                easing: baseTheme.transitions.easing.easeOutBack
            }),
            '&.Mui-checked': {
                transform: 'translateX(32px)',
                color: baseTheme.palette.common.white,
                '& + .MuiSwitch-track': {
                    backgroundColor: baseTheme.palette.accentBlue.dark,
                    opacity: 1,
                    border: 0
                }
            },
            '&.Mui-focusVisible + .MuiSwitch-track': {
                backgroundColor: baseTheme.palette.baseCloud.transparent20
            },
            '&.Mui-focusVisible.Mui-checked + .MuiSwitch-track': {
                backgroundColor: baseTheme.palette.accentBlue.light
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                opacity: 0.5
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                '&:hover': {
                    backgroundColor: baseTheme.palette.baseCloud.transparent10
                }
            },
            '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
                opacity: 0.5,
                '&:hover': {
                    backgroundColor: baseTheme.palette.accentBlue.dark
                }
            },
            '&:hover + .MuiSwitch-track': {
                backgroundColor: baseTheme.palette.baseCloud.transparent15
            },
            '&:hover.Mui-checked + .MuiSwitch-track': {
                backgroundColor: baseTheme.palette.accentBlue.main
            }
        },
        thumb: {
            boxSizing: 'border-box',
            width: '24px',
            height: '24px',
            boxShadow: 'none',
            flexShrink: 0
        },
        track: {
            borderRadius: '16px',
            backgroundColor: baseTheme.palette.baseCloud.transparent10,
            opacity: 1,
            transition: baseTheme.transitions.create(['background-color'], {
                duration: baseTheme.transitions.duration.standard,
                easing: baseTheme.transitions.easing.easeOutBack
            })
        },
        input: {
            width: '96px',
            height: '32px',
            top: '-4px',
            left: '-36px'
        }
    },
    variants: [
        {
            props: { size: 'small' },
            style: {
                width: '36px',
                height: '18px',
                '& .MuiSwitch-switchBase': {
                    width: '12px',
                    height: '12px',
                    margin: '3px',
                    '&.Mui-checked': {
                        transform: 'translateX(18px)'
                    }
                },
                '& .MuiSwitch-thumb': {
                    width: '12px',
                    height: '12px'
                },
                '& .MuiSwitch-switchBase .MuiSwitch-input': {
                    width: '54px',
                    height: '18px',
                    top: '-3px',
                    left: '-21px'
                }
            }
        }
    ]
};

export default MuiRadio;
