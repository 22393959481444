import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as StarOutlineIcon } from '../../assets/icons/starOutline.svg';
import isSlobs from '../../helpers/isSlobs';
import {
    storageSet,
    StorageType,
    tenantIdKey,
    tenantIdpKey
} from '../../helpers/storage';
import useAuth from '../../hooks/useAuth';
import usePreferencesMenuConfig from '../../hooks/usePreferencesMenuConfig';
import useTenants from '../../hooks/useTenants';
import { silentLogoutRoute } from '../../routes';
import ProfileItem from '../LeftMenu/ProfileItem';
import UserMenuItem from '../LeftMenu/UserMenuItem';

interface MobileMenuProfileItemProps {
    isOpen?: boolean;
    setIsOpen?: Dispatch<SetStateAction<string[]>>;
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
    isPremium?: boolean;
    isDeveloperView?: boolean;
}

const AnimatedBox = animated(Box);
const MobileMenuProfileItem = ({
    isOpen = false,
    setIsOpen,
    setMenuOpen,
    isPremium = false,
    isDeveloperView = false
}: MobileMenuProfileItemProps): JSX.Element => {
    const theme = useTheme();
    const auth = useAuth();
    const ref = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);
    const preferencesConfig = usePreferencesMenuConfig();
    const { userProfileData, currentTenant, allTenants } = useTenants();

    const [style, animate] = useSpring(
        () => ({
            height: '0px'
        }),
        []
    );

    useEffect(() => {
        if (ref.current) {
            animate({
                height: `${isOpen ? ref.current.offsetHeight : 0}px`
            });
        }
    }, [animate, ref, isOpen]);

    const handleSwitchTenant = async (id: string) => {
        const profile = auth?.profile;

        if (profile) {
            setMenuOpen(false);
            await storageSet<string>(tenantIdKey, id, StorageType.session);
            await storageSet<string>(
                tenantIdpKey,
                profile?.idp ?? '',
                StorageType.session
            );
        }
    };

    const hasTenantMenu =
        !isDeveloperView && allTenants && allTenants.length > 0 && !isSlobs();

    return (
        <Box
            ref={itemRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: isOpen
                    ? 'baseCloud.transparent20'
                    : 'transparent',
                transition: theme.transitions.create(['background-color'], {
                    duration: theme.transitions.duration.standard
                }),
                borderRadius: 2
            }}
        >
            <Button
                onClick={() => {
                    if (setIsOpen) {
                        setIsOpen((prevState) =>
                            isOpen
                                ? prevState.filter((item) => item !== 'profile')
                                : [...prevState, 'profile']
                        );
                    }

                    if (itemRef.current && !isOpen) {
                        itemRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    }
                }}
                disableRipple
                sx={{
                    fontSize: '1.6rem',
                    textTransform: 'none',
                    justifyContent: 'start',
                    color: isOpen ? 'common.white' : 'text.secondary',
                    overflow: 'hidden',
                    px: 4,
                    py: '14px',
                    borderRadius: 2,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        color: 'common.white',
                        backgroundColor: isOpen
                            ? 'transparent'
                            : 'baseCloud.transparent10'
                    }
                }}
            >
                <ProfileItem
                    width="auto"
                    avatarUrl={currentTenant?.profileImageUrl}
                    name={currentTenant?.name ?? ''}
                    isMe={currentTenant?.isMe}
                    isCurrentUserButton
                />

                {isPremium && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '24px',
                            height: '24px',
                            borderRadius: '100%',
                            backgroundColor: 'baseCloud.transparent10',
                            ml: '15px',
                            '& > svg ': {
                                width: '16px',
                                height: '16px',
                                '& path': {
                                    fill: theme.palette.accentYellow.dark
                                }
                            }
                        }}
                    >
                        <StarOutlineIcon />
                    </Box>
                )}

                <Box
                    component={ChevronDownIcon}
                    sx={{
                        width: '28px',
                        height: '28px',
                        ml: 'auto',
                        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: theme.transitions.create(['transform'], {
                            duration: theme.transitions.duration.standard
                        }),
                        '& > path': {
                            fill: isOpen
                                ? theme.palette.common.white
                                : theme.palette.text.secondary,
                            transition: theme.transitions.create(['fill'], {
                                duration: theme.transitions.duration.standard
                            })
                        }
                    }}
                />
            </Button>

            <AnimatedBox
                style={style}
                sx={{
                    overflow: 'hidden'
                }}
            >
                <Box
                    ref={ref}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 2,
                        py: 3,
                        border: '0 solid',
                        borderTopWidth: isOpen ? '1px' : 0,
                        borderColor: 'baseCloud.transparent10'
                    }}
                >
                    {hasTenantMenu && (
                        <>
                            <Typography
                                component="span"
                                sx={{
                                    color: 'text.secondary',
                                    pl: 2,
                                    mb: 2
                                }}
                            >
                                Your creators
                            </Typography>

                            {[...allTenants]
                                .sort((a, b) => Number(b.isMe) - Number(a.isMe))
                                .map((item, i) => (
                                    <Button
                                        key={`${item.name ?? ''}${i}`}
                                        component={NavLink}
                                        to={silentLogoutRoute()}
                                        onClick={async () => {
                                            if (
                                                item?.id !== currentTenant?.id
                                            ) {
                                                await handleSwitchTenant(
                                                    item.id
                                                );
                                            }
                                        }}
                                        variant="text"
                                        disableRipple
                                        disabled={
                                            item?.id === currentTenant?.id
                                        }
                                        sx={{
                                            justifyContent: 'start',
                                            textTransform: 'none',
                                            fontSize: '1.6rem',
                                            lineHeight: '2.4rem',
                                            py: 1,
                                            px: 2,
                                            mb: 1,
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                color: 'common.white'
                                            },
                                            '&.Mui-disabled': {
                                                opacity: 1,
                                                pointer: 'default',
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                    >
                                        <ProfileItem
                                            avatarUrl={item.profileImageUrl}
                                            name={item.name ?? ''}
                                            isMe={item.isMe}
                                            isCurrentUser={
                                                item?.id === currentTenant?.id
                                            }
                                        />
                                    </Button>
                                ))}
                        </>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: hasTenantMenu ? 3 : 0
                        }}
                    >
                        {!currentTenant?.isMe && (
                            <UserMenuItem
                                text="Back to my account"
                                to={silentLogoutRoute()}
                                onClick={async () => {
                                    if (userProfileData?.id) {
                                        await handleSwitchTenant(
                                            userProfileData.id
                                        );
                                    }
                                }}
                                isSwitchToManager
                            />
                        )}

                        {preferencesConfig.map((item) => (
                            <UserMenuItem
                                key={item.title}
                                text={item.title}
                                href={item.href}
                                to={item.route}
                                onClick={
                                    item.onClick ?? (() => setMenuOpen(false))
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </AnimatedBox>
        </Box>
    );
};

export default MobileMenuProfileItem;
