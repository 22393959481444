import baseTheme from '../baseTheme';

const MuiFormControlLabel = {
    styleOverrides: {
        root: {
            '&.Mui-disabled': {
                color: baseTheme.palette.text.secondary
            }
        },
        label: {
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            fontWeight: 500
        }
    }
};

export default MuiFormControlLabel;
