import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as StarOutlineIcon } from '../assets/icons/starOutline.svg';
import getA11yProps from '../helpers/getA11yProps';
import useFeatureFlags from '../hooks/useFeatureFlags';
import useUserSubscription from '../hooks/useUserSubscription';
import { TabNavConfig } from '../types';

interface TabNavProps {
    indicatorColor?: string;
    config: TabNavConfig[];
}

export const isTabActive = (slug: string, pathname: string) => {
    const splitPathname = pathname.split('/');

    return splitPathname[splitPathname.length - 1] === slug;
};

const TabNav = ({ indicatorColor, config }: TabNavProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { isEnabled, isLoaded } = useFeatureFlags();
    const { hasPremium } = useUserSubscription();

    const handleChange = (_: React.SyntheticEvent, value: string) => {
        navigate({
            pathname: value === config[0].value ? '' : value,
            search: location.search
        });
    };

    const activeTab = config.find((item) =>
        isTabActive(item.value, location.pathname)
    );

    return (
        <>
            {isLoaded && (
                <Tabs
                    value={activeTab ? activeTab.value : config[0].value}
                    onChange={handleChange}
                    aria-label="Tab navigation"
                    variant="scrollable"
                    scrollButtons={false}
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: indicatorColor
                        }
                    }}
                >
                    {config.map((item: TabNavConfig) => {
                        if (
                            (item.hasFeatureFlag &&
                                !isEnabled(
                                    `app.tabs.${item.value}`,
                                    item.defaultFlag
                                ) &&
                                !item.lockable) ||
                            item.isHidden
                        ) {
                            return null;
                        }

                        const isLocked =
                            item.hasFeatureFlag &&
                            item.lockable &&
                            !isEnabled(
                                `app.tabs.${item.value}`,
                                item.defaultFlag
                            );
                        let label: ReactNode = (
                            <span>
                                {isLocked ? '🔒' : ''} {item.label}
                                {isLocked ? ' - Coming soon' : ''}
                            </span>
                        );

                        if (item.isAlpha || item.isNew) {
                            label = (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {label}
                                    <Box
                                        component="span"
                                        sx={{
                                            width: '12px',
                                            height: '12px',
                                            borderRadius: '100%',
                                            backgroundColor: 'accentBlue.dark',
                                            ml: '10px'
                                        }}
                                    />
                                </Box>
                            );
                        }

                        if (item.isPremium && !hasPremium) {
                            label = (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {label}
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '100%',
                                            backgroundColor:
                                                'baseCloud.transparent10',
                                            ml: '10px',
                                            '& > svg ': {
                                                width: '16px',
                                                height: '16px',
                                                '& path': {
                                                    fill: theme.palette
                                                        .accentYellow.dark
                                                }
                                            }
                                        }}
                                    >
                                        <StarOutlineIcon />
                                    </Box>
                                </Box>
                            );
                        }

                        if (item.numberBadgeValue !== undefined) {
                            label = (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {label}
                                    <Box
                                        component="span"
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '100%',
                                            backgroundColor:
                                                'baseCloud.transparent15',
                                            ml: '10px',
                                            fontSize: '1.4rem',
                                            lineHeight: '2.4rem'
                                        }}
                                    >
                                        {item.numberBadgeValue ?? 0}
                                    </Box>
                                </Box>
                            );
                        }

                        return (
                            <Tab
                                key={item.value}
                                value={item.value}
                                disabled={isLocked}
                                label={label}
                                {...getA11yProps(item.value)}
                                sx={{
                                    '& > span': {
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%'
                                    }
                                }}
                            />
                        );
                    })}
                </Tabs>
            )}
        </>
    );
};

export default TabNav;
