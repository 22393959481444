import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useAnalytics } from './TrackingProvider';

interface SEOProps {
    title?: string;
    description?: string;
    image?: string;
}

const SEO = ({ title, description, image }: SEOProps): JSX.Element => {
    const analytics = useAnalytics();

    const siteName = 'Grow Your Stream with Actionable Analytics - StreamBee';
    const baseUrl = process.env.REACT_APP_BASE_URL as string;
    const url = window.location.href ?? baseUrl;
    const metaTitle = title
        ? `${title} | ${siteName}`
        : 'Grow Your Stream with Actionable Analytics - StreamBee';
    const metaDescription =
        description ??
        "StreamBee translates data from Twitch into actionable insights, helping you to make better streaming decisions. Sign up now - it's free forever!";
    const metaImage = image ?? `${baseUrl}/streambee-open-graph-cover.png`;

    useEffect(() => {
        if (title) {
            void analytics?.page(title, {
                title: metaTitle
            });
        }
    }, [analytics, title, metaTitle]);

    return (
        <Helmet
            title={metaTitle}
            meta={[
                {
                    name: `og:site_name`,
                    content: siteName
                },
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: metaTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:image`,
                    content: metaImage
                },
                {
                    property: `og:url`,
                    content: url
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    name: `twitter:title`,
                    content: metaTitle
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    name: `twitter:image`,
                    content: metaImage
                },
                {
                    name: `twitter:url`,
                    content: url
                }
            ]}
        />
    );
};

export default SEO;
