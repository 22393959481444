import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import isSlobs from '../helpers/isSlobs';

const appVersion = process.env.REACT_APP_VERSION as string;
const buildNumber = process.env.REACT_APP_BUILD_NUMBER as string;

interface AppVersionProps {
    isMobile?: boolean;
}

const AppVersion = ({ isMobile = false }: AppVersionProps): JSX.Element => (
    <Box
        sx={{
            mt: isMobile ? 6 : 0
        }}
    >
        {buildNumber && (
            <Typography
                className="animateOpacity"
                noWrap
                sx={{
                    width: '100%',
                    textAlign: isMobile ? 'left' : 'center',
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                    color: 'text.secondary'
                }}
            >
                Version Beta{' '}
                {`${
                    process.env.REACT_APP_ENV === 'development'
                        ? 'dev'
                        : appVersion
                }-${buildNumber}`}
            </Typography>
        )}
        {!buildNumber && isSlobs() && (
            <Typography
                className="animateOpacity"
                noWrap
                sx={{
                    width: '100%',
                    textAlign: isMobile ? 'left' : 'center',
                    fontSize: '1.2rem',
                    lineHeight: '1.8rem',
                    color: 'text.secondary'
                }}
            >
                Version Beta {process.env.REACT_APP_SLOBS_VERSION}-slobs
            </Typography>
        )}
    </Box>
);

export default AppVersion;
