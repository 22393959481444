import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { UserTenant } from '../graphql/generated/graphqlTypesAnalytics';
import userTenantsMock from '../graphql/mocks/userTenants';
import USER_TENANTS, { UserTenants } from '../graphql/queries/userTenants';
import useAuth from '../hooks/useAuth';

interface UseTenantsHook {
    userProfileData: UserTenant | null;
    currentTenant: UserTenant | null;
    allTenants: UserTenant[] | null;
    allTenantsWithManager: UserTenant[] | null;
    isManager: boolean;
    loading: boolean;
}

export default (useMockData = false): UseTenantsHook => {
    const auth = useAuth();
    const profile = auth?.profile;
    const { loading, data: userTenantsDataQuery } = useQuery<UserTenants>(
        USER_TENANTS,
        {}
    );

    const userTenantsData = useMockData
        ? userTenantsMock
        : userTenantsDataQuery;

    const userProfileData = useMemo(() => {
        if (
            userTenantsData?.userTenants?.tenants &&
            userTenantsData.userTenants.tenants.length
        ) {
            return userTenantsData.userTenants.tenants.filter(
                (item) => item?.isMe === true
            )[0];
        }

        return null;
    }, [userTenantsData]);

    const currentTenant = useMemo(() => {
        if (
            userTenantsData?.userTenants?.tenants &&
            userTenantsData.userTenants.tenants.length
        ) {
            const filteredTenants = userTenantsData.userTenants.tenants.filter(
                (item) => item?.id === profile?.tenant_id
            )[0];

            if (filteredTenants) {
                return {
                    ...filteredTenants,
                    name: profile?.tenant_name as string
                };
            }

            return userProfileData;
        }

        return null;
    }, [userTenantsData, profile, userProfileData]);

    const allTenants = useMemo(() => {
        if (
            userTenantsData?.userTenants?.tenants &&
            userTenantsData.userTenants.tenants.length
        ) {
            return userTenantsData.userTenants.tenants.filter(
                (item) => item?.id !== userProfileData?.id
            );
        }

        return null;
    }, [userTenantsData, userProfileData]);

    const allTenantsWithManager = useMemo(() => {
        if (
            userTenantsData?.userTenants?.tenants &&
            userTenantsData.userTenants.tenants.length
        ) {
            return userTenantsData.userTenants.tenants;
        }

        return null;
    }, [userTenantsData]);

    const isManager = useMemo(() => {
        if (!profile?.tenant_name && allTenants && allTenants?.length > 0) {
            return true;
        }

        return false;
    }, [profile, allTenants]);

    return {
        userProfileData,
        currentTenant,
        allTenants,
        allTenantsWithManager,
        isManager,
        loading
    };
};
