import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDown.svg';
import getTabSlug from '../../helpers/getTabSlug';
import isSlobs from '../../helpers/isSlobs';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import { TabNavConfig } from '../../types';
import { useAnalytics } from '../TrackingProvider';

interface MobileMenuItemProps {
    to?: string;
    href?: string;
    text: string;
    icon?: React.ReactNode;
    isSubMenuOpen?: boolean;
    isParentActive?: boolean;
    isLast?: boolean;
    isPremium?: boolean;
    setSubMenuOpen?: Dispatch<SetStateAction<string[]>>;
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
    items?: TabNavConfig[];
}

const AnimatedBox = animated(Box);
const MobileMenuItem = ({
    to,
    href,
    text,
    icon,
    isSubMenuOpen = false,
    isParentActive = false,
    setSubMenuOpen,
    setMenuOpen,
    isLast = false,
    isPremium = false,
    items
}: MobileMenuItemProps): JSX.Element => {
    const theme = useTheme();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const location = useLocation();
    const { isEnabled } = useFeatureFlags();
    const ref = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);

    const [style, animate] = useSpring(
        () => ({
            height: '0px'
        }),
        []
    );

    useEffect(() => {
        if (ref.current) {
            animate({
                height: `${isSubMenuOpen ? ref.current.offsetHeight : 0}px`
            });
        }
    }, [animate, ref, isSubMenuOpen]);

    const hasSubmenu = items && items.length > 0;
    let color = isSubMenuOpen ? 'common.white' : 'text.secondary';

    if (isPremium) {
        color = 'accentYellow.dark';
    }

    return (
        <Box
            ref={itemRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: isSubMenuOpen
                    ? 'baseCloud.transparent20'
                    : 'transparent',
                ...(isPremium && {
                    backgroundColor: 'baseCloud.transparent10',
                    backgroundImage: theme.gradients.product20Horizontal
                }),
                transition: theme.transitions.create(['background-color'], {
                    duration: theme.transitions.duration.standard
                }),
                borderRadius: 2,
                mb: isLast ? 0 : 2
            }}
        >
            <Button
                onClick={() => {
                    if (hasSubmenu) {
                        if (setSubMenuOpen) {
                            setSubMenuOpen((prevState) =>
                                isSubMenuOpen
                                    ? prevState.filter((item) => item !== text)
                                    : [...prevState, text]
                            );
                        }

                        if (itemRef.current && !isSubMenuOpen) {
                            itemRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }
                    } else {
                        if (href && isSlobs()) {
                            window.streamlabsOBS.v1.External.openExternalLink(
                                href
                            );
                        } else if (href) {
                            window.open(href, '_blank');
                        } else {
                            if (isPremium) {
                                void analytics?.track('Upgrade clicked');
                            }

                            setMenuOpen(false);
                            navigate(to ?? '/');
                        }
                    }
                }}
                disableRipple
                sx={{
                    height: 56,
                    fontSize: '1.6rem',
                    textTransform: 'none',
                    justifyContent: 'start',
                    color,
                    overflow: 'hidden',
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        color: isPremium ? 'accentYellow.dark' : 'common.white',
                        backgroundColor: isSubMenuOpen
                            ? 'transparent'
                            : 'baseCloud.transparent10'
                    }
                }}
            >
                {icon && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexShrink: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: '-2px',
                            mr: 3,
                            '& > svg': {
                                width: '32px',
                                height: '32px'
                            },
                            '& svg *': {
                                fill: isSubMenuOpen
                                    ? theme.palette.accentYellow.dark
                                    : theme.palette.text.secondary,
                                transition: theme.transitions.create(['fill'], {
                                    duration:
                                        theme.transitions.duration.standard
                                })
                            }
                        }}
                    >
                        {icon}
                    </Box>
                )}

                <Typography
                    component="span"
                    sx={{
                        ml: '-4px',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {text}
                </Typography>

                {hasSubmenu && (
                    <Box
                        component={ChevronDownIcon}
                        sx={{
                            width: '28px',
                            height: '28px',
                            ml: 'auto',
                            transform: isSubMenuOpen
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            transition: theme.transitions.create(
                                ['transform'],
                                {
                                    duration:
                                        theme.transitions.duration.standard
                                }
                            ),
                            '& path': {
                                fill: isSubMenuOpen
                                    ? theme.palette.common.white
                                    : theme.palette.text.secondary,
                                transition: theme.transitions.create(['fill'], {
                                    duration:
                                        theme.transitions.duration.standard
                                })
                            }
                        }}
                    />
                )}
            </Button>

            {hasSubmenu && (
                <AnimatedBox
                    style={style}
                    sx={{
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        ref={ref}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            px: 4,
                            py: 3,
                            border: '0 solid',
                            borderTopWidth: isSubMenuOpen ? '1px' : 0,
                            borderColor: 'baseCloud.transparent10'
                        }}
                    >
                        {items &&
                            items.map((item) => {
                                const isItemActive =
                                    getTabSlug(
                                        location.pathname,
                                        items[0].value
                                    ) === item.value && isParentActive;

                                if (
                                    item.hasFeatureFlag &&
                                    !isEnabled(
                                        `app.tabs.${item.value}`,
                                        item.defaultFlag
                                    )
                                ) {
                                    return null;
                                }

                                return (
                                    <Button
                                        key={item.label}
                                        onClick={() => {
                                            setMenuOpen(false);
                                            navigate({
                                                pathname: `${to ?? ''}${
                                                    item.value ===
                                                    items[0].value
                                                        ? ''
                                                        : `/${item.value}`
                                                }`,
                                                search: location.search
                                            });
                                        }}
                                        variant="text"
                                        disableRipple
                                        sx={{
                                            justifyContent: 'start',
                                            textTransform: 'none',
                                            fontSize: '1.6rem',
                                            lineHeight: '2.4rem',
                                            py: 2,
                                            px: 0,
                                            mb: 3,
                                            color: isItemActive
                                                ? 'common.white'
                                                : 'text.secondary',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                color: 'common.white'
                                            },
                                            '&:last-of-type': {
                                                mb: 0
                                            }
                                        }}
                                    >
                                        {isItemActive && (
                                            <Box
                                                sx={{
                                                    width: '2px',
                                                    height: '24px',
                                                    borderRadius: '1px',
                                                    backgroundColor:
                                                        'accentYellow.dark',
                                                    alignSelf: 'start',
                                                    mr: 3,
                                                    boxShadow: `0 0 4px ${alpha(
                                                        theme.palette
                                                            .accentYellow.main,
                                                        0.25
                                                    )}`
                                                }}
                                            />
                                        )}
                                        {item.label}
                                    </Button>
                                );
                            })}
                    </Box>
                </AnimatedBox>
            )}
        </Box>
    );
};

export default MobileMenuItem;
