import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import INVITE_MANAGER, {
    InviteTenantManagerWithEmail,
    InviteTenantManagerWithEmailVars
} from '../graphql/mutations/inviteTenantManagerWithEmail';
import parseGraphQLErrorMessage from '../helpers/parseGraphQLErrorMessage';
import showToast from '../helpers/showToast';
import validateEmail from '../helpers/validateEmail';
import ConnectModal from './ConnectModal';
import LoadingButton from './LoadingButton';
import { useAnalytics } from './TrackingProvider';

const AddManagerCard = (): JSX.Element => {
    const analytics = useAnalytics();
    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [inviteManager, { loading: inviteManagerLoading }] = useMutation<
        InviteTenantManagerWithEmail,
        InviteTenantManagerWithEmailVars
    >(INVITE_MANAGER, {
        update: (cache, mutationData) => {
            if (mutationData.data?.inviteTenantManagerWithEmail) {
                void analytics?.track('Manager invite success');

                showToast('Your invite has been sent successfully.');

                setModalOpen(false);
                setInviteEmail('');
            } else {
                void analytics?.track('Manager invite fail');

                showToast(
                    'Sending your invite has failed. Please try again later.'
                );
            }
        },
        onError: (err) => {
            if (err.graphQLErrors && err.graphQLErrors.length) {
                console.error(err.graphQLErrors); // eslint-disable-line no-console

                setError(parseGraphQLErrorMessage(err.graphQLErrors[0]));
            }
        }
    });

    return (
        <Box
            sx={{
                p: 4,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 1,
                backgroundColor: 'baseCloud.transparent5'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0
                }}
            >
                <Typography
                    sx={{
                        fontSize: '1.6rem',
                        lineHeight: '2.4rem',
                        fontWeight: 500
                    }}
                    noWrap
                >
                    Add a new manager
                </Typography>
                <Typography
                    sx={{
                        color: 'text.secondary'
                    }}
                    noWrap
                >
                    They will see your data
                </Typography>
            </Box>
            <LoadingButton
                variant="contained"
                color="subtle"
                size="medium"
                label="Add"
                onClick={() => setModalOpen(true)}
                sx={{
                    flexShrink: 0,
                    ml: 3
                }}
            />
            <ConnectModal
                title="Invite your manager"
                description="Please, fill in your managers email so we can send him an invite link to view your data."
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setError={setError}
                error={error}
                setValue={setInviteEmail}
                value={inviteEmail}
                defaultPlaceholder="manager@email.com"
                confirmButtonLoading={inviteManagerLoading}
                confirmButtonLabel="Invite"
                hasCloseButton={false}
                validator={(val) => {
                    if (val === '') {
                        return 'This field is required';
                    }

                    if (!validateEmail(val)) {
                        return 'Please use this format: manager@email.com';
                    }

                    return null;
                }}
                saveCallback={() => {
                    void analytics?.track('Add new manager button clicked');

                    if (inviteEmail && inviteEmail.length > 0) {
                        setError(undefined);
                        void inviteManager({
                            variables: {
                                email: inviteEmail
                            }
                        });
                    }
                }}
            />
        </Box>
    );
};

export default AddManagerCard;
