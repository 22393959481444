import { useLazyQuery } from '@apollo/client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { usermavenClient } from '@usermaven/sdk-js';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo
} from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import UAParser from 'ua-parser-js';

import GlobalStateContext from '../context';
import USER_BASIC_STREAM_INFO, {
    UserBasicStreamInfoData
} from '../graphql/queries/userBasicStreamInfo';
import USER_PROFILE, {
    UserProfileData,
    UserProfileVars
} from '../graphql/queries/userProfile';
import USER_SUBSCRIPTION, {
    UserSubscriptionData,
    UserSubscriptionVars
} from '../graphql/queries/userSubscription';
import isSlobs from '../helpers/isSlobs';
import useAuth from '../hooks/useAuth';
import { MAIN_API } from './GraphqlProvider';

interface TrackingProviderProps {
    segmentWriteKey: string;
    usermavenKey: string;
    children: ReactNode;
}

interface AnalyticsContextState {
    track: (eventName: string, options?: Record<string, unknown>) => void;
    page: (pageName: string, options?: Record<string, unknown>) => void;
}

const isPwa = () =>
    Boolean(
        (window.matchMedia &&
            window.matchMedia('(display-mode: standalone)').matches) ||
            'standalone' in window.navigator
    );

const isTwa = () =>
    isPwa() && document.referrer.includes('android-app://io.streambee.app.twa');

const isProduction = process.env.REACT_APP_ENV === 'production';
const AnalyticsContext = createContext<AnalyticsContextState | undefined>(
    undefined
);

export const useAnalytics = (): AnalyticsContextState | undefined =>
    useContext(AnalyticsContext);

const TrackingProvider = ({
    segmentWriteKey,
    usermavenKey,
    children
}: TrackingProviderProps): JSX.Element => {
    const auth = useAuth();
    const location = useLocation();
    const profile = auth?.profile;
    const isEnabled = isProduction; // parseInt(profile?.user_cookie_consent as string, 10) === 1
    const {
        state: { userCountry }
    } = useContext(GlobalStateContext);

    const [
        loadUserProfile,
        { data: userProfileData, called: userProfileCalled }
    ] = useLazyQuery<UserProfileData, UserProfileVars>(USER_PROFILE, {
        context: { clientName: MAIN_API }
    });

    const [
        loadUserSubscription,
        { data: userSubscription, called: userSubscriptionCalled }
    ] = useLazyQuery<UserSubscriptionData, UserSubscriptionVars>(
        USER_SUBSCRIPTION,
        {
            variables: {
                getTenantData: false
            }
        }
    );

    const [
        loadUserBasicStreamInfo,
        { data: userBasicStreamInfo, called: userBasicStreamInfoCalled }
    ] = useLazyQuery<UserBasicStreamInfoData>(USER_BASIC_STREAM_INFO);

    const usermaven = useMemo(() => {
        if (isEnabled) {
            return usermavenClient({
                key: usermavenKey,
                tracking_host: 'https://um.streambee.io'
            });
        }

        return undefined;
    }, [usermavenKey, isEnabled]);

    const segment = useMemo(() => {
        if (isEnabled) {
            return AnalyticsBrowser.load({ writeKey: segmentWriteKey });
        }

        return undefined;
    }, [segmentWriteKey, isEnabled]);

    useEffect(() => {
        if (profile) {
            if (!userProfileCalled) {
                void loadUserProfile({
                    variables: {
                        userId: profile?.sub
                    }
                });
            }

            if (!userSubscriptionCalled) {
                void loadUserSubscription();
            }

            if (!userBasicStreamInfoCalled) {
                void loadUserBasicStreamInfo();
            }
        }
    }, [
        profile,
        userProfileCalled,
        userSubscriptionCalled,
        userBasicStreamInfoCalled,
        loadUserProfile,
        loadUserSubscription,
        loadUserBasicStreamInfo,
        location
    ]);

    useEffect(() => {
        if (isEnabled && userProfileData) {
            if (segment) {
                void segment.identify(
                    userProfileData.userProfile.twitchUserId,
                    {
                        username: userProfileData.userProfile.displayName,
                        avatar: userProfileData.userProfile.profileImageUrl,
                        createdAt: userProfileData.userProfile.timeStamp,
                        type: userProfileData.userProfile.broadcasterType?.code
                    }
                );
            }

            if (usermaven && userSubscription && userBasicStreamInfo) {
                void usermaven.id({
                    id: userProfileData.userProfile.twitchUserId,
                    email: userProfileData.userProfile.email,
                    partner: userProfileData.userProfile.broadcasterType?.code,
                    created_at: userProfileData.userProfile?.timeStamp ?? '',
                    first_name: userProfileData.userProfile.displayName,
                    username: userProfileData.userProfile.displayName,
                    plan_name:
                        userSubscription?.userSubscription
                            ?.userSubscriptionPlan ?? 'Free',
                    first_stream:
                        userBasicStreamInfo?.userBasicStreamInfo
                            ?.firstStreamDate,
                    last_stream:
                        userBasicStreamInfo?.userBasicStreamInfo
                            ?.lastStreamDate,
                    stream_count:
                        userBasicStreamInfo?.userBasicStreamInfo?.streamCount,
                    last_login: new Date().toISOString(),
                    is_slobs: isSlobs()
                });
            }
        }
    }, [
        isEnabled,
        userProfileData,
        userSubscription,
        userBasicStreamInfo,
        segment,
        usermaven,
        userCountry
    ]);

    useEffect(() => {
        if (isEnabled && !isSlobs()) {
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GTM_ID as string
            };

            TagManager.initialize(tagManagerArgs);
        }
    }, [isEnabled]);

    return (
        <AnalyticsContext.Provider
            value={{
                track: (eventName, options) => {
                    const parser = new UAParser(window.navigator.userAgent);
                    const parserResults = parser.getResult();

                    void segment?.track(eventName, {
                        ...options,
                        device: parserResults.device.type ?? 'desktop',
                        pwa: isPwa(),
                        twa: isTwa(),
                        isSlobs: isSlobs(),
                        platform: parserResults.os.name
                    });
                },
                page: (pageName, options) => {
                    const parser = new UAParser(window.navigator.userAgent);
                    const parserResults = parser.getResult();

                    void segment?.page(pageName, {
                        ...options,
                        device: parserResults.device.type ?? 'desktop',
                        pwa: isPwa(),
                        twa: isTwa(),
                        isSlobs: isSlobs(),
                        platform: parserResults.os.name
                    });
                }
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

export default TrackingProvider;
