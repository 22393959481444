import moment from 'moment';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loader from './components/Loader';
import Page from './components/Page';
import TrackingProvider from './components/TrackingProvider';
import isSlobs from './helpers/isSlobs';
import showToast, { ToastVariant } from './helpers/showToast';
import {
    gameDeveloperLandingPageDataKey,
    storageGet,
    storageRemove,
    StorageType
} from './helpers/storage';
import useAuth from './hooks/useAuth';
import useFeatureFlags from './hooks/useFeatureFlags';
import useUserProfile from './hooks/useUserProfile';
import { accountManagementTabNavConfig } from './modules/AccountManagement';
import TeamTab from './modules/AccountManagement/TeamTab';
import PrivateRoutes from './modules/Auth/PrivateRoutes';
import SilentLogout from './modules/Auth/SilentLogout';
import { gameKeysTabNavConfig } from './modules/GameKeys';
import { growthTabNavConfig } from './modules/Growth';
import { monetizationInsightsTabNavConfig } from './modules/MonetizationInsights';
import { monetizationToolsTabNavConfig } from './modules/MonetizationTools';
import { portfolioManagementTabNavConfig } from './modules/PortfolioManagement';
import { streamTabNavConfig } from './modules/Stream';
import { whatToStreamTabNavConfig } from './modules/WhatToStream';
import { whenToStreamTabNavConfig } from './modules/WhenToStream';
import {
    accountManagement,
    addMoreKeys,
    authCallbackRoute,
    authRenewCallbackRoute,
    buyCredits,
    campaignAnalytics,
    campaignPublicLandingPage,
    campaigns,
    createCampaign,
    gameKeys,
    gameMarketing,
    growthRoute,
    homeRoute,
    logoutRoute,
    marketingServices,
    monetizationInsights,
    monetizationToolsRoute,
    onboarding,
    portfolioManagement,
    silentLogoutRoute,
    slobsLogin,
    streamRoute,
    streamsRoute,
    tenantInvite,
    whatToStream,
    whenToStream
} from './routes';
import { GameDeveloperLandingPageData } from './types';

const AccountTab = lazy(() => import('./modules/AccountManagement/AccountTab'));
const PreferencesTab = lazy(
    () => import('./modules/AccountManagement/PreferencesTab')
);
const AddMoreKeys = lazy(() => import('./modules/GameMarketing/AddMoreKeys'));
const MarketingServices = lazy(
    () => import('./modules/GameMarketing/MarketingServices')
);
const SubscriptionTab = lazy(
    () => import('./modules/AccountManagement/SubscriptionTab')
);
const AudienceTab = lazy(
    () => import('./modules/MonetizationInsights/AudienceTab')
);
const ChurnAnalyticsTab = lazy(
    () => import('./modules/MonetizationInsights/ChurnAnalyticsTab')
);
const ConversionRatesTab = lazy(
    () => import('./modules/MonetizationInsights/ConversionRatesTab')
);
const PatreonAnalyticsTab = lazy(
    () => import('./modules/MonetizationInsights/PatreonAnalyticsTab')
);
const WhaleAnalyticsTab = lazy(
    () => import('./modules/MonetizationInsights/WhaleAnalyticsTab')
);
const OnlyAdsTab = lazy(() => import('./modules/MonetizationTools/OnlyAdsTab'));
const CreatorToolsTab = lazy(
    () => import('./modules/MonetizationTools/CreatorToolsTab')
);
const StreamerEquipmentTab = lazy(
    () => import('./modules/MonetizationTools/StreamerEquipmentTab')
);
const MediaKitsTab = lazy(
    () => import('./modules/PortfolioManagement/MediaKitsTab')
);
const PortfolioInsightsTab = lazy(
    () => import('./modules/PortfolioManagement/PortfolioInsightsTab')
);
const StreamEngagementTab = lazy(
    () => import('./modules/Stream/StreamEngagementTab')
);
const StreamRaidsTab = lazy(() => import('./modules/Stream/StreamRaidsTab'));
const StreamMonetizationTab = lazy(
    () => import('./modules/Stream/StreamMonetizationTab')
);
const StreamOverviewTab = lazy(
    () => import('./modules/Stream/StreamOverviewTab')
);
const StreamViewersTab = lazy(
    () => import('./modules/Stream/StreamViewersTab')
);
const TopClipsReviewTab = lazy(
    () => import('./modules/Stream/TopClipsReviewTab')
);
const VodReviewTab = lazy(() => import('./modules/Stream/VodReviewTab'));
const HowLongToStreamTab = lazy(
    () => import('./modules/WhenToStream/HowLongToStreamTab')
);
const WhatDayToStreamTab = lazy(
    () => import('./modules/WhenToStream/WhatDayToStreamTab')
);
const WhatTimeToStreamTab = lazy(
    () => import('./modules/WhenToStream/WhatTimeToStreamTab')
);
const CreatorDashboardTab = lazy(
    () => import('./modules/Growth/CreatorDashboardTab')
);
const CampaignPublicLandingPage = lazy(
    () => import('./modules/GameMarketing/CampaignPublicLandingPage')
);
const TwitchTab = lazy(() => import('./modules/Growth/TwitchTab'));
const GameListTab = lazy(() => import('./modules/GameKeys/GameListTab'));
const MyGamesTab = lazy(() => import('./modules/GameKeys/MyGamesTab'));
const CampaignLandingPageTab = lazy(
    () => import('./modules/GameKeys/CampaignLandingPageTab')
);
const GameMarketing = lazy(() => import('./modules/GameMarketing'));
const CreateCampaign = lazy(
    () => import('./modules/GameMarketing/CreateCampaign')
);
const Campaigns = lazy(() => import('./modules/GameMarketing/Campaigns'));
const CampaignAnalytics = lazy(
    () => import('./modules/GameMarketing/CampaignAnalytics')
);
const BuyCredits = lazy(() => import('./modules/GameMarketing/BuyCredits'));
const Onboarding = lazy(() => import('./modules/Onboarding'));
const NotFound = lazy(() => import('./components/NotFound'));
const Stream = lazy(() => import('./modules/Stream'));
const Growth = lazy(() => import('./modules/Growth'));
const AccountManagement = lazy(() => import('./modules/AccountManagement'));
const Streams = lazy(() => import('./modules/Streams'));
const WhatToStream = lazy(() => import('./modules/WhatToStream'));
const GameAnalyticsTab = lazy(
    () => import('./modules/WhatToStream/GameAnalyticsTab')
);
const DiscoverNewGamesTab = lazy(
    () => import('./modules/WhatToStream/DiscoverNewGamesTab')
);
const WhenToStream = lazy(() => import('./modules/WhenToStream'));
const MonetizationTools = lazy(() => import('./modules/MonetizationTools'));
const MonetizationInsights = lazy(
    () => import('./modules/MonetizationInsights')
);
const PortfolioManagement = lazy(() => import('./modules/PortfolioManagement'));
const GameKeys = lazy(() => import('./modules/GameKeys'));
const Logout = lazy(() => import('./modules/Auth/Logout'));
const SlobsLogin = lazy(() => import('./modules/Auth/SlobsLogin'));
const Home = lazy(() => import('./modules/Auth/Home'));
const TenantInvite = lazy(() => import('./modules/Auth/TenantInvite'));
const AuthCallback = lazy(() => import('./modules/Auth/AuthCallback'));
const RenewCallback = lazy(() => import('./modules/Auth/RenewCallback'));

const App = (): JSX.Element => {
    const auth = useAuth();
    const profile = auth?.profile;
    const isTenant = !!profile?.tenant_name;
    const { isEnabled, isLoaded } = useFeatureFlags();
    const [showMaintenanceMode, setShowShowMaintenanceMode] =
        useState<boolean>(true);

    const { isBroadcasterView, loading } = useUserProfile();

    useEffect(() => {
        if (isEnabled('app.maintenanceMode') && showMaintenanceMode) {
            setShowShowMaintenanceMode(false);
            showToast(
                `We're currently performing server maintenance to enhance the stability of StreamBee. During this time, access to features and the accuracy of any data tracked may be impacted. We're working hard to restore full service as soon as possible. We apologize for any inconvenience.`,
                ToastVariant.warning,
                15000
            );
        }
    }, [isEnabled, showMaintenanceMode]);

    useEffect(() => {
        const handleStorage = async () => {
            const localStorageData =
                await storageGet<GameDeveloperLandingPageData>(
                    gameDeveloperLandingPageDataKey,
                    StorageType.local
                );

            if (localStorageData?.time) {
                const time = moment(localStorageData.time);

                if (moment().diff(time, 'days') > 7) {
                    await storageRemove(
                        gameDeveloperLandingPageDataKey,
                        StorageType.local
                    );
                }
            }
        };

        void handleStorage();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <TrackingProvider
            segmentWriteKey={process.env.REACT_APP_SEGMENT_WRITE_KEY as string}
            usermavenKey={process.env.REACT_APP_USERMAVEN_KEY as string}
        >
            <Page>
                <Suspense fallback={<Loader logoutMessageTimeoutSec={30} />}>
                    <Routes>
                        <Route element={<PrivateRoutes />}>
                            {isBroadcasterView && (
                                <>
                                    <Route
                                        path={homeRoute()}
                                        element={<Home />}
                                    />

                                    <Route
                                        path={streamsRoute()}
                                        element={<Streams />}
                                    />

                                    <Route
                                        path={whatToStream()}
                                        element={<WhatToStream />}
                                    >
                                        {isEnabled(
                                            'app.tabs.discover-new-games',
                                            true
                                        ) && (
                                            <Route
                                                index
                                                element={
                                                    <DiscoverNewGamesTab />
                                                }
                                            />
                                        )}

                                        <Route
                                            index={
                                                !isEnabled(
                                                    'app.tabs.discover-new-games',
                                                    true
                                                )
                                            }
                                            path={
                                                isEnabled(
                                                    'app.tabs.discover-new-games',
                                                    true
                                                )
                                                    ? whatToStreamTabNavConfig[1]
                                                          .value
                                                    : undefined
                                            }
                                            element={<GameAnalyticsTab />}
                                        />
                                    </Route>

                                    <Route
                                        path={whenToStream()}
                                        element={<WhenToStream />}
                                    >
                                        <Route
                                            index
                                            element={<WhatTimeToStreamTab />}
                                        />
                                        <Route
                                            path={
                                                whenToStreamTabNavConfig[1]
                                                    .value
                                            }
                                            element={<WhatDayToStreamTab />}
                                        />
                                        <Route
                                            path={
                                                whenToStreamTabNavConfig[2]
                                                    .value
                                            }
                                            element={<HowLongToStreamTab />}
                                        />
                                    </Route>

                                    {!isSlobs() && (
                                        <Route
                                            path={monetizationToolsRoute()}
                                            element={<MonetizationTools />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <StreamerEquipmentTab />
                                                }
                                            />

                                            <Route
                                                path={
                                                    monetizationToolsTabNavConfig[1]
                                                        .value
                                                }
                                                element={<CreatorToolsTab />}
                                            />

                                            <Route
                                                path={
                                                    monetizationToolsTabNavConfig[2]
                                                        .value
                                                }
                                                element={<OnlyAdsTab />}
                                            />
                                        </Route>
                                    )}

                                    <Route
                                        path={monetizationInsights()}
                                        element={<MonetizationInsights />}
                                    >
                                        <Route
                                            index
                                            element={<AudienceTab />}
                                        />

                                        <Route
                                            path={
                                                monetizationInsightsTabNavConfig[1]
                                                    .value
                                            }
                                            element={<ConversionRatesTab />}
                                        />

                                        <Route
                                            path={
                                                monetizationInsightsTabNavConfig[2]
                                                    .value
                                            }
                                            element={<WhaleAnalyticsTab />}
                                        />

                                        <Route
                                            path={
                                                monetizationInsightsTabNavConfig[3]
                                                    .value
                                            }
                                            element={<ChurnAnalyticsTab />}
                                        />

                                        {isEnabled(
                                            'app.tabs.patreon-analytics',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    monetizationInsightsTabNavConfig[4]
                                                        .value
                                                }
                                                element={
                                                    <PatreonAnalyticsTab />
                                                }
                                            />
                                        )}
                                    </Route>

                                    <Route
                                        path={portfolioManagement()}
                                        element={<PortfolioManagement />}
                                    >
                                        <Route
                                            index
                                            element={<MediaKitsTab />}
                                        />

                                        {isEnabled(
                                            'app.tabs.portfolio-insights',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    portfolioManagementTabNavConfig[1]
                                                        .value
                                                }
                                                element={
                                                    <PortfolioInsightsTab />
                                                }
                                            />
                                        )}
                                    </Route>

                                    {isEnabled('app.menu.gameKeys', true) &&
                                        !isTenant && (
                                            <Route
                                                path={gameKeys()}
                                                element={<GameKeys />}
                                            >
                                                <Route
                                                    index
                                                    element={<GameListTab />}
                                                />

                                                <Route
                                                    path={
                                                        gameKeysTabNavConfig[1]
                                                            .value
                                                    }
                                                    element={<MyGamesTab />}
                                                />

                                                <Route
                                                    path={
                                                        gameKeysTabNavConfig[2]
                                                            .value
                                                    }
                                                    element={
                                                        <CampaignLandingPageTab />
                                                    }
                                                />
                                            </Route>
                                        )}

                                    <Route
                                        path={onboarding()}
                                        element={<Onboarding />}
                                    />

                                    <Route
                                        path={streamRoute(':id')}
                                        element={<Stream />}
                                    >
                                        <Route
                                            index
                                            element={<StreamOverviewTab />}
                                        />

                                        {isEnabled(
                                            'app.tabs.engagement',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[1].value
                                                }
                                                element={
                                                    <StreamEngagementTab />
                                                }
                                            />
                                        )}

                                        {isEnabled(
                                            'app.tabs.viewers',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[2].value
                                                }
                                                element={<StreamViewersTab />}
                                            />
                                        )}

                                        {isEnabled('app.tabs.raids', true) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[3].value
                                                }
                                                element={<StreamRaidsTab />}
                                            />
                                        )}

                                        {isEnabled(
                                            'app.tabs.monetization',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[4].value
                                                }
                                                element={
                                                    <StreamMonetizationTab />
                                                }
                                            />
                                        )}

                                        {isEnabled(
                                            'app.tabs.vod-review',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[5].value
                                                }
                                                element={<VodReviewTab />}
                                            />
                                        )}

                                        {isEnabled(
                                            'app.tabs.top-clips',
                                            true
                                        ) && (
                                            <Route
                                                path={
                                                    streamTabNavConfig[6].value
                                                }
                                                element={<TopClipsReviewTab />}
                                            />
                                        )}
                                    </Route>

                                    <Route
                                        path={growthRoute()}
                                        element={<Growth />}
                                    >
                                        <Route
                                            index
                                            element={<CreatorDashboardTab />}
                                        />

                                        <Route
                                            path={growthTabNavConfig[1].value}
                                            element={<TwitchTab />}
                                        />
                                    </Route>

                                    <Route
                                        path={accountManagement()}
                                        element={<AccountManagement />}
                                    >
                                        <Route index element={<AccountTab />} />
                                        {!isSlobs() && (
                                            <Route
                                                path={
                                                    accountManagementTabNavConfig[1]
                                                        .value
                                                }
                                                element={<TeamTab />}
                                            />
                                        )}
                                        <Route
                                            path={
                                                accountManagementTabNavConfig[2]
                                                    .value
                                            }
                                            element={<PreferencesTab />}
                                        />

                                        <Route
                                            path={
                                                accountManagementTabNavConfig[3]
                                                    .value
                                            }
                                            element={<SubscriptionTab />}
                                        />
                                    </Route>
                                </>
                            )}

                            {!isBroadcasterView && (
                                <>
                                    <Route
                                        path={homeRoute()}
                                        element={<Navigate to={campaigns()} />}
                                    />

                                    <Route
                                        path={accountManagement()}
                                        element={<AccountManagement />}
                                    >
                                        <Route
                                            path={
                                                accountManagementTabNavConfig[2]
                                                    .value
                                            }
                                            element={<PreferencesTab />}
                                        />
                                    </Route>
                                </>
                            )}

                            <Route
                                path={gameMarketing()}
                                element={<GameMarketing />}
                            >
                                <Route
                                    path={campaigns()}
                                    element={<Campaigns />}
                                />

                                <Route
                                    path={campaignAnalytics(':id')}
                                    element={<CampaignAnalytics />}
                                />

                                <Route
                                    path={createCampaign(':id')}
                                    element={<CreateCampaign />}
                                />

                                <Route
                                    path={buyCredits()}
                                    element={<BuyCredits />}
                                />

                                <Route
                                    path={addMoreKeys(':id')}
                                    element={<AddMoreKeys />}
                                />

                                <Route
                                    path={marketingServices()}
                                    element={<MarketingServices />}
                                />

                                {!isBroadcasterView && (
                                    <Route
                                        index
                                        element={<Navigate to={campaigns()} />}
                                    />
                                )}
                            </Route>

                            <Route
                                path={silentLogoutRoute()}
                                element={<SilentLogout />}
                            />
                        </Route>

                        <Route
                            path={authCallbackRoute()}
                            element={<AuthCallback />}
                        />

                        <Route
                            path={tenantInvite()}
                            element={<TenantInvite />}
                        />

                        <Route
                            path={authRenewCallbackRoute()}
                            element={<RenewCallback />}
                        />

                        <Route path={logoutRoute()} element={<Logout />} />

                        <Route path={slobsLogin()} element={<SlobsLogin />} />

                        <Route
                            path={campaignPublicLandingPage(
                                ':gameName/:campaignId'
                            )}
                            element={<CampaignPublicLandingPage />}
                        />

                        {isLoaded && <Route path="*" element={<NotFound />} />}
                    </Routes>
                </Suspense>
            </Page>
        </TrackingProvider>
    );
};

export default App;
