import { gql } from '@apollo/client';

import USER_TENANT_FIELDS from '../fragments/userTenantFields';
import {
    MutationConfirmTenantInvitationTokenArgs,
    UserTenant
} from '../generated/graphqlTypesAnalytics';

export type ConfirmTenantInvitationToken = {
    confirmTenantInvitationToken: UserTenant;
};

export type ConfirmTenantInvitationTokenVars =
    MutationConfirmTenantInvitationTokenArgs;

export default gql`
    ${USER_TENANT_FIELDS}
    mutation ConfirmTenantInvitationToken($invitationTokenId: UUID!) {
        confirmTenantInvitationToken(invitationTokenId: $invitationTokenId) {
            ...UserTenantFields
        }
    }
`;
