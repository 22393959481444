import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useMemo
} from 'react';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../assets/icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from '../assets/icons/chevronRight.svg';
import { DEMO_ID } from '../configs';
import GlobalStateContext from '../context';
import { setStreamScrollPos } from '../context/actions';
import SEARCH_STREAM_DATA_IDS, {
    SearchStreamDataIds,
    SearchStreamDataIdsArgs
} from '../graphql/queries/searchStreamDataIds';
import useTenants from '../hooks/useTenants';
import { streamTabNavConfig } from '../modules/Stream';
import { streamRoute } from '../routes';

interface StreamNavigationMobileProps {
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const now = new Date();
const StreamNavigationMobile = ({
    setMenuOpen
}: StreamNavigationMobileProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { dispatch } = useContext(GlobalStateContext);
    const {
        isManager,
        allTenantsWithManager,
        loading: tenantsLoading
    } = useTenants();

    const splitPathName = location.pathname.split('/');
    const id = splitPathName[1] === 'stream' ? splitPathName[2] : undefined;
    const userProfileId = searchParams.get('userProfileId');

    const { loading, data } = useQuery<
        SearchStreamDataIds,
        SearchStreamDataIdsArgs
    >(SEARCH_STREAM_DATA_IDS, {
        variables: {
            dateTimeEnd: now,
            userProfileIds: isManager
                ? userProfileId
                    ? [userProfileId]
                    : []
                : []
        }
    });

    const handleNavigate = useCallback(
        (newId: string) => {
            const currentTabName = location.pathname.split('/')[3];
            const currentTabNavConfigItem = streamTabNavConfig.find(
                (tab) => tab.value === currentTabName
            );

            const scrollPos = window.scrollY;

            if (scrollPos > 0) {
                dispatch(setStreamScrollPos(scrollPos));
            }

            navigate({
                pathname: streamRoute(
                    `${newId}${
                        currentTabNavConfigItem
                            ? `/${currentTabNavConfigItem.value}`
                            : ''
                    }`
                ),
                search: location.search
            });
        },
        [dispatch, location.pathname, location.search, navigate]
    );

    const { prevId, nextId } = useMemo(() => {
        const index = data?.searchStreamData?.data?.findIndex(
            (stream) => stream.id === id
        );

        if (index !== undefined) {
            return {
                prevId: data?.searchStreamData?.data?.[index - 1]?.id,
                nextId: data?.searchStreamData?.data?.[index + 1]?.id
            };
        }

        return {
            prevId: undefined,
            nextId: undefined
        };
    }, [data, id]);

    const isDemo = id === DEMO_ID;

    return (
        <>
            {id && !isDemo && !loading && !tenantsLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        mb: 6
                    }}
                >
                    <Button
                        color="subtle"
                        size="medium"
                        disabled={!prevId}
                        startIcon={<ChevronLeftIcon />}
                        sx={{
                            flex: 1,
                            mr: 2,
                            px: 4,
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        onClick={() => {
                            if (prevId) {
                                setMenuOpen(false);
                                handleNavigate(prevId);
                            }
                        }}
                    >
                        Previous stream
                    </Button>
                    <Button
                        color="subtle"
                        size="medium"
                        disabled={!nextId}
                        endIcon={<ChevronRightIcon />}
                        sx={{
                            flex: 1,
                            px: 4,
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        onClick={() => {
                            if (nextId) {
                                setMenuOpen(false);
                                handleNavigate(nextId);
                            }
                        }}
                    >
                        Next stream
                    </Button>
                </Box>
            )}
        </>
    );
};

export default StreamNavigationMobile;
