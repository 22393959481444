import { useEffect, useState } from 'react';

import useAuth from '../hooks/useAuth';
import {
    FeatureFlagsContext,
    IFeatureFlagsContext
} from '../hooks/useFeatureFlags';

interface Props {
    children: React.ReactNode;
}

const FeatureFlagContextProvider = ({ children }: Props): JSX.Element => {
    const { profile, isAuthenticated } = useAuth();
    const [flagInfo, setFlagInfo] = useState<IFeatureFlagsContext>({
        isLoaded: false,
        flags: []
    });

    useEffect(() => {
        const token = profile?.token;

        const fetchFlags = () => {
            if (token) {
                fetch(
                    `${process.env.REACT_APP_API_URL as string}/Common/Flags`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                    .then((resp) => resp.json())
                    .then((result) => {
                        setFlagInfo({
                            isLoaded: true,
                            lastUpdate: new Date(),
                            flags: result as string[]
                        });
                    })
                    .catch((err) =>
                        // eslint-disable-next-line no-console
                        console.error("Couldn't fetch feature flags!")
                    );
            }
        };

        if (profile) {
            fetchFlags();
        }
    }, [profile, isAuthenticated]);

    return (
        <FeatureFlagsContext.Provider value={flagInfo}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export default FeatureFlagContextProvider;
