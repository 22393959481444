import { gql } from '@apollo/client';

import { TenantManager } from '../generated/graphqlTypesAnalytics';

export interface TenantManagers {
    tenantManagers: {
        managers: TenantManager[];
    };
}

export default gql`
    query TenantManagers {
        tenantManagers {
            managers {
                userId
                name
                profileImageUrl
            }
        }
    }
`;
