import { gql } from '@apollo/client';

import {
    SearchSettingsTimeRangeInput,
    WhenToStream
} from '../generated/graphqlTypesAnalytics';

export interface WhenToStreamData {
    whenToStream: WhenToStream;
}

export type WhenToStreamDataArgs = SearchSettingsTimeRangeInput;

export default gql`
    query WhenToStream($dateTimeFrom: DateTime, $dateTimeTo: DateTime) {
        whenToStream(
            searchSettings: {
                dateTimeFrom: $dateTimeFrom
                dateTimeTo: $dateTimeTo
            }
        ) {
            weekendAverageViewers {
                weekendEnum
                averageViewers {
                    averageViewers
                    numberOfStreams
                    timeStamp
                }
            }
            dayOfWeekResults {
                dayOfWeek
                averageFollowersGainedPerHour
                averageFollowersGained
                averageViewers
                averagePeakViewers
                averageWatchtime
                averageUniqueChatters
                averageUniqueActiveChatters
                averageChatMessagesPerHour
                numberOfStreams
                averageHoursWatched
                averageHoursStreamed
                periodicAverageViewers {
                    averageViewers
                    timeStamp
                    numberOfStreams
                }
            }
            joinedStreamPeriodicAverageFollowersGained {
                averageFollowersGained
                timeStamp
            }
            joinedStreamPeriodicAverageViewers {
                averageViewers
                timeStamp
            }
            joinedStreamPeriodicTotalFollowersGained {
                followersGained
                timeStamp
            }
            joinedStreamPeriodicCounts {
                count
                timeStamp
            }
            streamLengthGroups {
                hours
                averageViewers
                averageFollowersPerHour
                averageWatchtime
                streamsCount
            }
        }
    }
`;
