import baseTheme from '../baseTheme';

const MuiRadio = {
    defaultProps: {
        disableRipple: true
    },
    styleOverrides: {
        root: {
            width: '24px',
            height: '24px',
            padding: '2px',
            transition: baseTheme.transitions.create(['background-color'], {
                duration: baseTheme.transitions.duration.standard
            }),
            '& svg': {
                display: 'none'
            },
            '&:hover': {
                backgroundColor: baseTheme.palette.accentBlue.transparent15
            },
            '&.Mui-disabled': {
                opacity: 0.5
            },
            '& > span': {
                width: '20px',
                height: '20px',
                borderRadius: '10px',
                backgroundColor: baseTheme.palette.baseInk.light
            },
            '&.Mui-checked > span': {
                position: 'relative',
                width: '20px',
                height: '20px',
                borderRadius: '10px',
                border: '0px',
                backgroundColor: baseTheme.palette.accentBlue.dark,
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    display: 'block',
                    width: '8px',
                    height: '8px',
                    left: '6px',
                    top: '6px',
                    borderRadius: '4px',
                    backgroundColor: baseTheme.palette.common.white
                }
            }
        }
    }
};

export default MuiRadio;
