import { useQuery } from '@apollo/client';
import { alpha, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as AirtimeIcon } from '../assets/icons/airtime.svg';
import headerBg from '../assets/images/header.jpg';
import {
    StreamChannelDataResult,
    StreamData
} from '../graphql/generated/graphqlTypesAnalytics';
import streamOverviewDataMock from '../graphql/mocks/streamOverviewData';
import STREAM_OVERVIEW_DATA, {
    StreamOverviewData,
    StreamOverviewDataVars
} from '../graphql/queries/streamOverviewData';
import { formatDate } from '../helpers/formatDate';
import parseGameImage from '../helpers/parseGameImage';
import secondsToHms from '../helpers/secondsToHms';
import useTenants from '../hooks/useTenants';
import useUserSubscription from '../hooks/useUserSubscription';
import { streamTabNavConfig } from '../modules/Stream';
import Badge from './Badge';
import HorizontalScrollbar from './HorizontalScrollbar';
import Layout from './Layout';
import MobileTopMenu from './MobileTopMenu';
import SectionHeader from './SectionHeader';
import TabNav from './TabNav';
import Tag, { TagVariant } from './Tag';
import TopMenu from './TopMenu';

interface StreamHeaderProps {
    streamData?: StreamData;
    streamChannelData?: StreamChannelDataResult;
    isDemo?: boolean;
}

const StreamHeader = ({
    streamData,
    streamChannelData,
    isDemo = false
}: StreamHeaderProps): JSX.Element => {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { hasPremium } = useUserSubscription();
    const { allTenants, loading: tenantsLoading } = useTenants();

    const isMonetization = location.pathname.search('monetization') > -1;
    const demo = !hasPremium && isMonetization ? true : isDemo;

    const {
        loading: streamOverviewDataLoading,
        data: streamOverviewDataQuery
    } = useQuery<StreamOverviewData, StreamOverviewDataVars>(
        STREAM_OVERVIEW_DATA,
        {
            variables: {
                streamDataId: streamData?.id || ''
            },
            skip: streamData?.id === undefined || demo
        }
    );

    const streamTabNavConfigWithRaidsCounter = useMemo(
        () =>
            streamTabNavConfig.map((item) => {
                if (item.value === 'raids') {
                    return {
                        ...item,
                        numberBadgeValue: streamData?.raidsCount
                    };
                }

                return item;
            }),
        [streamData]
    );

    const getBottomGradient = (color: string) =>
        `linear-gradient(180deg, ${alpha(color, 0.1)} 0%, ${alpha(
            color,
            0.3
        )} 100%)`;

    const getCurrentTabColor = () => {
        const currentTabName = location.pathname.split('/')[3];
        const currentTabNavConfigItem = streamTabNavConfig.find(
            (tab) => tab.value === currentTabName
        );

        if (isMonetization && !hasPremium) {
            return theme.palette.accentYellow.dark;
        }

        if (currentTabNavConfigItem?.color) {
            return currentTabNavConfigItem.color;
        }

        return theme.palette.accentYellow.dark;
    };

    const isVodReview = location.pathname.search('vod-review') > -1;
    const isTopClips = location.pathname.search('top-clips') > -1;
    const bottomGradient = getBottomGradient(getCurrentTabColor());
    const titles = streamChannelData?.streamDataTwitchTitles;
    const games = streamChannelData?.streamDataTwitchGames;
    let lastTitle: string | undefined = 'no streams to show';
    let lastGameImageUrl;

    if (titles && titles.length > 0) {
        lastTitle = titles[titles.length - 1].title;
    }

    if (games && games.length > 0) {
        lastGameImageUrl = games[games.length - 1].imageUrl;
    }

    const streamOwner = allTenants?.find(
        (item) => item.userProfileId === streamData?.userProfileId
    );
    const streamOverviewData = demo
        ? streamOverviewDataMock
        : streamOverviewDataQuery;

    const streamOwnerBadge = (
        <>
            {streamOwner && (
                <Box
                    sx={{
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        mr: [0, undefined, 2]
                    }}
                >
                    <Tag
                        variant={TagVariant.avatar}
                        label={streamOwner.name ?? ''}
                        image={streamOwner.profileImageUrl}
                    />
                </Box>
            )}
        </>
    );

    const airTimeBadge = (
        <>
            {!!streamOverviewData?.streamOverviewData.airtimeSeconds && (
                <Box
                    sx={{
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        ml: [0, undefined, 2]
                    }}
                >
                    <Tag
                        variant={TagVariant.icon}
                        label={secondsToHms(
                            streamOverviewData?.streamOverviewData
                                .airtimeSeconds,
                            false
                        )}
                        icon={<AirtimeIcon />}
                    />
                </Box>
            )}
        </>
    );

    return (
        <>
            {!isMobile && (
                <TopMenu userProfileId={streamData?.userProfileId}>
                    <TabNav
                        indicatorColor={getCurrentTabColor()}
                        config={streamTabNavConfigWithRaidsCounter}
                    />
                </TopMenu>
            )}
            {isMobile && (
                <MobileTopMenu>
                    <TabNav
                        indicatorColor={getCurrentTabColor()}
                        config={streamTabNavConfigWithRaidsCounter}
                    />
                </MobileTopMenu>
            )}
            {isVodReview && (
                <SectionHeader
                    title="VOD Review"
                    description="Review the notable moments of your last stream"
                    isNew
                    hasTabNav
                    mobilePaddingTop={25}
                />
            )}
            {isTopClips && (
                <SectionHeader
                    title="Top clips"
                    description="The best clips &amp; clip-worthy moments of your broadcast"
                    hasTabNav
                    mobilePaddingTop={25}
                />
            )}
            {!streamOverviewDataLoading &&
                !tenantsLoading &&
                !isVodReview &&
                !isTopClips && (
                    <Box
                        sx={{
                            position: 'relative',
                            mt: [0, undefined, '-80px'],
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                background: `linear-gradient(180deg, ${alpha(
                                    theme.palette.common.white,
                                    0.02
                                )} 0%, transparent 100%)`
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                backgroundImage: `url(${
                                    lastGameImageUrl
                                        ? parseGameImage(lastGameImageUrl)
                                        : headerBg
                                })`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                opacity: 0.15
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                background: bottomGradient
                            }}
                        />

                        <Layout>
                            <Box
                                sx={{
                                    position: 'relative',
                                    zIndex: 0,
                                    pt: [32, undefined, 32],
                                    pb: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'end'
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            display: 'inline-block',
                                            verticalAlign: 'middle'
                                        }}
                                    >
                                        {lastTitle}
                                    </Typography>
                                    {!hasPremium && isMonetization && (
                                        <Badge
                                            isPremium
                                            fontSize="1.4rem"
                                            lineHeight="2rem"
                                            fontWeight={400}
                                            ml={2}
                                            inline
                                        >
                                            Premium
                                        </Badge>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        mt: 2
                                    }}
                                >
                                    {!isMobile && streamOwnerBadge}
                                    {streamOverviewData?.streamOverviewData
                                        .dateTimeStart &&
                                        streamOverviewData?.streamOverviewData
                                            .dateTimeEnd && (
                                            <Typography
                                                sx={{
                                                    color: 'text.secondary',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                {formatDate(
                                                    streamOverviewData
                                                        ?.streamOverviewData
                                                        .dateTimeStart
                                                )}{' '}
                                                -{' '}
                                                {formatDate(
                                                    streamOverviewData
                                                        ?.streamOverviewData
                                                        .dateTimeEnd
                                                )}
                                            </Typography>
                                        )}
                                    {!isMobile && airTimeBadge}
                                </Box>

                                <Box
                                    sx={{
                                        mt: 3
                                    }}
                                >
                                    <HorizontalScrollbar>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'nowrap',
                                                gap: 3
                                            }}
                                        >
                                            {isMobile && streamOwnerBadge}
                                            {isMobile && airTimeBadge}
                                            {streamChannelData?.streamDataTwitchTags?.map(
                                                (tag, i) => (
                                                    <Tag
                                                        key={`${
                                                            tag.twitchTag
                                                                ?.name || ''
                                                        }${i}`}
                                                        variant={
                                                            TagVariant.text
                                                        }
                                                        label={
                                                            tag.twitchTag
                                                                ?.name || ''
                                                        }
                                                    />
                                                )
                                            )}
                                        </Box>
                                    </HorizontalScrollbar>
                                </Box>
                            </Box>
                        </Layout>
                    </Box>
                )}
        </>
    );
};

export default memo(StreamHeader);
