import { TypographyVariantsOptions } from '@mui/material/styles';

import baseTheme from './baseTheme';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        bodySmall: React.CSSProperties;
        bodyLarge: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        bodySmall?: React.CSSProperties;
        bodyLarge?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        bodySmall: true;
        bodyLarge: true;
    }
}

const typography: TypographyVariantsOptions = {
    h1: {
        fontSize: '2.8rem',
        lineHeight: '3.2rem',
        fontWeight: 600,
        [baseTheme.breakpoints.up('md')]: {
            fontSize: '3.2rem',
            lineHeight: '4rem'
        }
    },
    h2: {
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
        fontWeight: 600,
        [baseTheme.breakpoints.up('md')]: {
            fontSize: '2.8rem',
            lineHeight: '3.2rem'
        }
    },
    h3: {
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
        fontWeight: 600,
        [baseTheme.breakpoints.up('md')]: {
            fontSize: '2.4rem',
            lineHeight: '3.2rem'
        }
    },
    h4: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: 600,
        [baseTheme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
            lineHeight: '2.8rem'
        }
    },
    h5: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: 600
    },
    body1: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: 400
    },
    bodySmall: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: 400
    },
    bodyLarge: {
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
        fontWeight: 400
    }
};

export default typography;
