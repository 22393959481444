export default (
    d: number,
    showSeconds = true,
    format: 'letters' | 'dots' | 'timeStamp' | 'words' = 'letters'
): string => {
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    let hDisplay = '';
    let mDisplay = '';
    let sDisplay = '';
    let output = '';

    if (format === 'words') {
        hDisplay = h > 0 ? `${h} ${h === 1 ? 'hour' : 'hours'} ` : '';
        mDisplay = m > 0 ? `${m} min ` : '';
        sDisplay = s > 0 ? `${s} sec ` : '';

        if (!showSeconds) {
            output = hDisplay + mDisplay;
        } else {
            output = hDisplay + mDisplay + sDisplay;
        }
    }

    if (format === 'letters') {
        hDisplay = h > 0 ? `${h}h ` : '';
        mDisplay = m > 0 ? `${m}m ` : '';
        sDisplay = s > 0 ? `${s}s ` : '';

        if (!showSeconds) {
            output = hDisplay + mDisplay;
        } else {
            output = hDisplay + mDisplay + sDisplay;
        }
    }

    if (format === 'dots') {
        hDisplay = h < 10 ? `0${h}` : `${h}`;
        mDisplay = m < 10 ? `0${m}` : `${m}`;
        sDisplay = s < 10 ? `0${s}` : `${s}`;

        if (!showSeconds) {
            output = `${hDisplay}:${mDisplay}`;
        } else {
            output = `${hDisplay}:${mDisplay}:${sDisplay}`;
        }
    }

    if (format === 'timeStamp') {
        hDisplay = h < 10 ? `0${h}` : `${h}`;
        mDisplay = m < 10 ? `0${m}` : `${m}`;
        sDisplay = s < 10 ? `0${s}` : `${s}`;

        output = `${hDisplay}h${mDisplay}m${sDisplay}s`;
    }

    if (d === 0 && format === 'letters') {
        output = '0s';
    }

    return output;
};
