import { alpha, PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        accentYellow: Palette['primary'];
        accentOrange: Palette['primary'];
        accentRed: Palette['primary'];
        accentPink: Palette['primary'];
        accentPurple: Palette['primary'];
        accentBlue: Palette['primary'];
        accentCyan: Palette['primary'];
        accentTurquoise: Palette['primary'];
        accentGreen: Palette['primary'];
        accentLavender: Palette['primary'];
        baseCloud: Palette['primary'];
        baseInk: Palette['primary'];
        subtle: Palette['primary'];
        errorSubtle: Palette['primary'];
        discord: Palette['primary'];
        youTube: Palette['primary'];
        twitch: Palette['primary'];
        twitter: Palette['primary'];
        patreon: Palette['primary'];
        instagram: Palette['primary'];
        tikTok: Palette['primary'];
        streamlabs: Palette['primary'];
        chartBackground: Palette['primary'];
    }

    interface PaletteOptions {
        accentYellow?: PaletteOptions['primary'];
        accentOrange?: PaletteOptions['primary'];
        accentRed?: PaletteOptions['primary'];
        accentPink?: PaletteOptions['primary'];
        accentPurple?: PaletteOptions['primary'];
        accentBlue?: PaletteOptions['primary'];
        accentCyan?: PaletteOptions['primary'];
        accentTurquoise?: PaletteOptions['primary'];
        accentGreen?: PaletteOptions['primary'];
        accentLavender?: PaletteOptions['primary'];
        baseCloud?: PaletteOptions['primary'];
        baseInk?: PaletteOptions['primary'];
        subtle?: PaletteOptions['primary'];
        errorSubtle?: PaletteOptions['primary'];
        discord?: PaletteOptions['primary'];
        youTube?: PaletteOptions['primary'];
        twitch?: PaletteOptions['primary'];
        twitter?: PaletteOptions['primary'];
        patreon?: PaletteOptions['primary'];
        instagram?: PaletteOptions['primary'];
        tikTok?: PaletteOptions['primary'];
        streamlabs?: PaletteOptions['primary'];
        chartBackground?: PaletteOptions['primary'];
    }

    interface PaletteColor {
        transparent5?: string;
        transparent10?: string;
        transparent15?: string;
        transparent20?: string;
        transparent25?: string;
        transparent30?: string;
        transparent35?: string;
        transparent40?: string;
        transparent45?: string;
        transparent50?: string;
        blurFallback?: string;
    }
    interface SimplePaletteColorOptions {
        transparent5?: string;
        transparent10?: string;
        transparent15?: string;
        transparent20?: string;
        transparent25?: string;
        transparent30?: string;
        transparent35?: string;
        transparent40?: string;
        transparent45?: string;
        transparent50?: string;
        blurFallback?: string;
    }
}

export const chartLineStroke = '#3f495c';

export const white = '#fff';
const black = '#000';

const typo100 = white;
const typo90 = '#afbbcf';

const baseInk100 = '#000814';
const baseInk90 = '#0e1420';
const baseInk80 = '#141b29';

const baseCloudBlurFallback = '#1F2837';
const baseCloud90 = '#ccdfff';

const accentYellow100 = '#fece2f';
const accentYellow90 = '#fed344';
const accentYellow80 = '#fed859';

const accentOrangeBlurFallback = '#241e18';
const accentOrange100 = '#ed982f';
const accentOrange90 = '#eda144';
const accentOrange80 = '#efab58';

const accentRedBlurFallback = '#26131d';
const accentRed100 = '#ff5050';
const accentRed90 = '#ff6262';
const accentRed80 = '#ff7373';

const accentPink100 = '#fb5c8d';
const accentPink90 = '#fb6c98';
const accentPink80 = '#fc7da4';

const accentPurple100 = '#7a4af0';
const accentPurple90 = '#875cf2';
const accentPurple80 = '#956ef3';

const accentBlueBlurFallback = '#071537';
const accentBlue100 = '#2f5ffe';
const accentBlue90 = '#446ffe';
const accentBlue80 = '#597ffe';

const accentCyan100 = '#2ab7fe';
const accentCyan90 = '#3fbffe';
const accentCyan80 = '#55c6fe';

const accentTurquoise100 = '#00cbae';
const accentTurquoise90 = '#1ad0b6';
const accentTurquoise80 = '#33d5be';

const accentGreen100 = '#35b846';
const accentGreen90 = '#49bf58';
const accentGreen80 = '#5dc66b';

const accentLavender100 = '#a172f7';
const accentLavender90 = '#af89f5';
const accentLavender80 = '#bc9cf7';

const discord = '#5865F2';
const youTube = '#FF0000';
const twitch = '#9146FF';
const twitter = '#1D9BF0';
const patreon = '#ff424d';
const instagram = '#C13584';
const tikTok = '#FE2C55';
const streamlabs = '#80f5d2';

const chartModalBackground = '#272F3F';
const chartBackground = '#151E2C';

export const gameColors = {
    green: accentGreen100,
    orange: accentOrange100,
    red: accentRed100,
    purple: accentPurple100,
    pink: accentPink100,
    blue: accentBlue100,
    cyan: accentCyan100,
    yellow: accentYellow100
};

// Theme colors
let palette: PaletteOptions = {
    accentYellow: {
        dark: accentYellow100,
        main: accentYellow90,
        light: accentYellow80,
        transparent5: alpha(accentYellow100, 0.05),
        transparent10: alpha(accentYellow100, 0.1),
        transparent15: alpha(accentYellow100, 0.15),
        transparent20: alpha(accentYellow100, 0.2),
        transparent25: alpha(accentYellow100, 0.25),
        transparent30: alpha(accentYellow100, 0.3),
        transparent35: alpha(accentYellow100, 0.35),
        transparent40: alpha(accentYellow100, 0.4),
        transparent45: alpha(accentYellow100, 0.45),
        transparent50: alpha(accentYellow100, 0.5)
    },
    accentOrange: {
        dark: accentOrange100,
        main: accentOrange90,
        light: accentOrange80,
        transparent5: alpha(accentOrange100, 0.05),
        transparent10: alpha(accentOrange100, 0.1),
        transparent15: alpha(accentOrange100, 0.15),
        transparent20: alpha(accentOrange100, 0.2),
        transparent25: alpha(accentOrange100, 0.25),
        transparent30: alpha(accentOrange100, 0.3),
        transparent35: alpha(accentOrange100, 0.35),
        transparent40: alpha(accentOrange100, 0.4),
        transparent45: alpha(accentOrange100, 0.45),
        transparent50: alpha(accentOrange100, 0.5),
        blurFallback: accentOrangeBlurFallback
    },
    accentRed: {
        dark: accentRed100,
        main: accentRed90,
        light: accentRed80,
        transparent5: alpha(accentRed100, 0.05),
        transparent10: alpha(accentRed100, 0.1),
        transparent15: alpha(accentRed100, 0.15),
        transparent20: alpha(accentRed100, 0.2),
        transparent25: alpha(accentRed100, 0.25),
        transparent30: alpha(accentRed100, 0.3),
        transparent35: alpha(accentRed100, 0.35),
        transparent40: alpha(accentRed100, 0.4),
        transparent45: alpha(accentRed100, 0.45),
        transparent50: alpha(accentRed100, 0.5),
        blurFallback: accentRedBlurFallback
    },
    accentPink: {
        dark: accentPink100,
        main: accentPink90,
        light: accentPink80,
        transparent5: alpha(accentPink100, 0.05),
        transparent10: alpha(accentPink100, 0.1),
        transparent15: alpha(accentPink100, 0.15),
        transparent20: alpha(accentPink100, 0.2),
        transparent25: alpha(accentPink100, 0.25),
        transparent30: alpha(accentPink100, 0.3),
        transparent35: alpha(accentPink100, 0.35),
        transparent40: alpha(accentPink100, 0.4),
        transparent45: alpha(accentPink100, 0.45),
        transparent50: alpha(accentPink100, 0.5)
    },
    accentPurple: {
        dark: accentPurple100,
        main: accentPurple90,
        light: accentPurple80,
        transparent5: alpha(accentPurple100, 0.05),
        transparent10: alpha(accentPurple100, 0.1),
        transparent15: alpha(accentPurple100, 0.15),
        transparent20: alpha(accentPurple100, 0.2),
        transparent25: alpha(accentPurple100, 0.25),
        transparent30: alpha(accentPurple100, 0.3),
        transparent35: alpha(accentPurple100, 0.35),
        transparent40: alpha(accentPurple100, 0.4),
        transparent45: alpha(accentPurple100, 0.45),
        transparent50: alpha(accentPurple100, 0.5)
    },
    accentBlue: {
        dark: accentBlue100,
        main: accentBlue90,
        light: accentBlue80,
        transparent5: alpha(accentBlue100, 0.05),
        transparent10: alpha(accentBlue100, 0.1),
        transparent15: alpha(accentBlue100, 0.15),
        transparent20: alpha(accentBlue100, 0.2),
        transparent25: alpha(accentBlue100, 0.25),
        transparent30: alpha(accentBlue100, 0.3),
        transparent35: alpha(accentBlue100, 0.35),
        transparent40: alpha(accentBlue100, 0.4),
        transparent45: alpha(accentBlue100, 0.45),
        transparent50: alpha(accentBlue100, 0.5),
        blurFallback: accentBlueBlurFallback
    },
    accentCyan: {
        dark: accentCyan100,
        main: accentCyan90,
        light: accentCyan80,
        transparent5: alpha(accentCyan100, 0.05),
        transparent10: alpha(accentCyan100, 0.1),
        transparent15: alpha(accentCyan100, 0.15),
        transparent20: alpha(accentCyan100, 0.2),
        transparent25: alpha(accentCyan100, 0.25),
        transparent30: alpha(accentCyan100, 0.3),
        transparent35: alpha(accentCyan100, 0.35),
        transparent40: alpha(accentCyan100, 0.4),
        transparent45: alpha(accentCyan100, 0.45),
        transparent50: alpha(accentCyan100, 0.5)
    },
    accentTurquoise: {
        dark: accentTurquoise100,
        main: accentTurquoise90,
        light: accentTurquoise80,
        transparent5: alpha(accentTurquoise100, 0.05),
        transparent10: alpha(accentTurquoise100, 0.1),
        transparent15: alpha(accentTurquoise100, 0.15),
        transparent20: alpha(accentTurquoise100, 0.2),
        transparent25: alpha(accentTurquoise100, 0.25),
        transparent30: alpha(accentTurquoise100, 0.3),
        transparent35: alpha(accentTurquoise100, 0.35),
        transparent40: alpha(accentTurquoise100, 0.4),
        transparent45: alpha(accentTurquoise100, 0.45),
        transparent50: alpha(accentTurquoise100, 0.5)
    },
    accentGreen: {
        dark: accentGreen100,
        main: accentGreen90,
        light: accentGreen80,
        transparent5: alpha(accentGreen100, 0.05),
        transparent10: alpha(accentGreen100, 0.1),
        transparent15: alpha(accentGreen100, 0.15),
        transparent20: alpha(accentGreen100, 0.2),
        transparent25: alpha(accentGreen100, 0.25),
        transparent30: alpha(accentGreen100, 0.3),
        transparent35: alpha(accentGreen100, 0.35),
        transparent40: alpha(accentGreen100, 0.4),
        transparent45: alpha(accentGreen100, 0.45),
        transparent50: alpha(accentGreen100, 0.5)
    },
    accentLavender: {
        dark: accentLavender100,
        main: accentLavender90,
        light: accentLavender80,
        transparent5: alpha(accentLavender100, 0.05),
        transparent10: alpha(accentLavender100, 0.1),
        transparent15: alpha(accentLavender100, 0.15),
        transparent20: alpha(accentLavender100, 0.2),
        transparent25: alpha(accentLavender100, 0.25),
        transparent30: alpha(accentLavender100, 0.3),
        transparent35: alpha(accentLavender100, 0.35),
        transparent40: alpha(accentLavender100, 0.4),
        transparent45: alpha(accentLavender100, 0.45),
        transparent50: alpha(accentLavender100, 0.5)
    },
    baseCloud: {
        main: baseCloud90,
        transparent5: alpha(baseCloud90, 0.05),
        transparent10: alpha(baseCloud90, 0.1),
        transparent15: alpha(baseCloud90, 0.15),
        transparent20: alpha(baseCloud90, 0.2),
        transparent25: alpha(baseCloud90, 0.25),
        transparent30: alpha(baseCloud90, 0.3),
        transparent35: alpha(baseCloud90, 0.35),
        transparent40: alpha(baseCloud90, 0.4),
        transparent45: alpha(baseCloud90, 0.45),
        transparent50: alpha(baseCloud90, 0.5),
        blurFallback: baseCloudBlurFallback
    },
    baseInk: {
        dark: baseInk100,
        main: baseInk90,
        light: baseInk80
    },
    chartBackground: {
        main: chartBackground,
        light: chartModalBackground
    }
};

// Social platforms colors
palette = {
    ...palette,
    discord: {
        main: discord
    },
    youTube: {
        main: youTube
    },
    twitch: {
        main: twitch
    },
    twitter: {
        main: twitter
    },
    patreon: {
        main: patreon
    },
    instagram: {
        main: instagram
    },
    tikTok: {
        main: tikTok
    },
    streamlabs: {
        main: streamlabs
    }
};

// MUI default colors
palette = {
    ...palette,
    background: {
        default: baseInk100
    },
    common: {
        white,
        black
    },
    text: {
        primary: typo100,
        secondary: typo90,
        disabled: typo90
    },

    // buttons
    primary: {
        main: accentYellow90
    },
    secondary: {
        main: accentBlue90
    },
    error: {
        main: accentRed90
    },
    success: {
        main: accentGreen90
    },
    subtle: {
        main: baseInk90
    },
    errorSubtle: {
        main: alpha(accentRed100, 0.15)
    }
};

export default palette;
