import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';
import { ReactComponent as MenuCloseIcon } from '../../assets/icons/menuClose.svg';
import { ReactComponent as StreamBeeLogo } from '../../assets/images/logo.svg';
import GlobalStateContext from '../../context';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import useMenuConfig, { MenuItem } from '../../hooks/useMenuConfig';
import useUserSubscription from '../../hooks/useUserSubscription';
import { onboarding } from '../../routes';
import AppVersion from '../AppVersion';
import { onPaths } from '../LeftMenu/MenuItem';
import StreamNavigationMobile from '../StreamNavigationMobile';
import MobileMenuItem from './MobileMenuItem';
import MobileMenuProfileItem from './MobileMenuProfileItem';

const AnimatedBox = animated(Box);
const MobileMenu = (): JSX.Element => {
    const theme = useTheme();
    const location = useLocation();
    const { isEnabled } = useFeatureFlags();
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
    const menuRef = useRef<HTMLDivElement>(null);
    const {
        state: { onboardingStep, isDeveloperView }
    } = useContext(GlobalStateContext);
    const onboardingPathMatch = useMatch(onboarding());

    const { hasPremium } = useUserSubscription(false);

    const menuConfig = useMenuConfig(hasPremium);

    const matchRoutes = (item: MenuItem): boolean => {
        let matched = onPaths(
            item.activePaths ?? [],
            item.omittedPaths ?? [],
            location
        );

        if (item?.activePaths && item.activePaths[0] === '/') {
            matched = location.pathname === '/';
        }

        return matched;
    };

    const menuAnimation = useSpring({
        transform: isMenuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
        onStart: () => {
            if (isMenuOpen && menuRef.current) {
                disableBodyScroll(menuRef.current);
            }
        },
        onRest: () => {
            if (!isMenuOpen && menuRef.current && menuConfig) {
                const newOpenSubMenus: string[] = [];

                menuConfig.forEach((item) => {
                    const matched = matchRoutes(item);

                    if (matched) {
                        newOpenSubMenus.push(item.title);
                    }
                });

                setOpenSubMenus(newOpenSubMenus);
                enableBodyScroll(menuRef.current);
            }
        }
    });

    useEffect(() => {
        if (menuConfig) {
            const newOpenSubMenus: string[] = [];

            menuConfig.forEach((item) => {
                const matched = matchRoutes(item);

                if (matched) {
                    newOpenSubMenus.push(item.title);
                }
            });

            setOpenSubMenus(newOpenSubMenus);
        }
    }, [menuConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    display: 'flex',
                    px: 4,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: theme.mobileMenuHeight,
                    zIndex: 'mobileHeader',
                    backgroundColor: 'baseCloud.blurFallback',
                    transform: `translateY(${
                        onboardingStep < 3 && !!onboardingPathMatch
                            ? `-${theme.mobileMenuHeight}`
                            : 0
                    })`,
                    transition: theme.transitions.create(['transform'], {
                        duration: theme.transitions.duration.standard
                    })
                }}
            >
                <StreamBeeLogo />

                <IconButton
                    sx={{
                        position: 'relative',
                        right: 0,
                        top: 0,
                        p: 0,
                        ml: 'auto'
                    }}
                    onClick={() => setMenuOpen(true)}
                >
                    <HamburgerIcon />
                </IconButton>
            </Box>

            <AnimatedBox
                ref={menuRef}
                style={menuAnimation}
                sx={{
                    position: 'fixed',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 'mobileMenu',
                    backgroundColor: 'baseInk.main',
                    px: 4,
                    pt: 3,
                    pb: 8
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        mb: 6
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'relative',
                            right: 0,
                            top: 0,
                            p: 0,
                            ml: 'auto'
                        }}
                        onClick={() => setMenuOpen(false)}
                    >
                        <MenuCloseIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <StreamNavigationMobile setMenuOpen={setMenuOpen} />

                    <MobileMenuProfileItem
                        isOpen={
                            openSubMenus.findIndex(
                                (name) => name === 'profile'
                            ) > -1
                        }
                        setIsOpen={setOpenSubMenus}
                        setMenuOpen={setMenuOpen}
                        isPremium={hasPremium}
                        isDeveloperView={isDeveloperView}
                    />

                    <Box
                        sx={{
                            borderTop: '1px solid',
                            borderColor: 'baseCloud.transparent20',
                            mt: 8,
                            mb: 8
                        }}
                    />

                    {menuConfig &&
                        menuConfig.map((item, i) => {
                            if (item.loading) {
                                return null;
                            }

                            if (
                                item.featureFlag &&
                                !isEnabled(item.featureFlag ?? '')
                            ) {
                                return null;
                            }

                            return (
                                <Fragment key={item.title}>
                                    <MobileMenuItem
                                        to={item.route}
                                        href={item.href}
                                        text={item.title}
                                        icon={item.icon}
                                        isLast={i === menuConfig.length - 1}
                                        isPremium={item.isPremium}
                                        isSubMenuOpen={
                                            openSubMenus.findIndex(
                                                (name) => name === item.title
                                            ) > -1
                                        }
                                        isParentActive={onPaths(
                                            item.activePaths ?? [],
                                            item.omittedPaths ?? [],
                                            location
                                        )}
                                        setSubMenuOpen={setOpenSubMenus}
                                        setMenuOpen={setMenuOpen}
                                        items={item.items}
                                    />
                                </Fragment>
                            );
                        })}
                </Box>

                <AppVersion isMobile />
            </AnimatedBox>
        </>
    );
};

export default MobileMenu;
