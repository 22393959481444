import { StreamChannelDataResult } from '../generated/graphqlTypesAnalytics';
import playedGames from './playedGames';

export const streamTitles = [
    {
        title: 'TOURNEY PRACTICE >:] | !tourney',
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        length: 4463
    },
    {
        title: 'i need to finish breaking down my bed frame | !tourney',
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        length: 2583
    },
    {
        title: 'Demo stream',
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        length: 9780
    }
];

export default {
    streamChannelData: {
        streamDataTwitchGames: playedGames,
        streamDataTwitchTitles: streamTitles,
        streamDataTwitchTags: [
            {
                twitchTag: {
                    id: 'tagId',
                    isAuto: true,
                    name: 'Your'
                },
                dateTimeStart: new Date(),
                dateTimeEnd: new Date(),
                length: 1
            },
            {
                twitchTag: {
                    id: 'tagId2',
                    isAuto: true,
                    name: 'Data'
                },
                dateTimeStart: new Date(),
                dateTimeEnd: new Date(),
                length: 1
            },
            {
                twitchTag: {
                    id: 'tagId3',
                    isAuto: true,
                    name: 'After'
                },
                dateTimeStart: new Date(),
                dateTimeEnd: new Date(),
                length: 1
            },
            {
                twitchTag: {
                    id: 'tagId3',
                    isAuto: true,
                    name: 'Next'
                },
                dateTimeStart: new Date(),
                dateTimeEnd: new Date(),
                length: 1
            },
            {
                twitchTag: {
                    id: 'tagId4',
                    isAuto: true,
                    name: 'Stream'
                },
                dateTimeStart: new Date(),
                dateTimeEnd: new Date(),
                length: 1
            }
        ]
    } as StreamChannelDataResult
};
