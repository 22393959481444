import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import USER_SUBSCRIPTION, {
    UserSubscriptionData,
    UserSubscriptionVars
} from '../graphql/queries/userSubscription';

interface UseUserSubscriptionHook {
    hasPremium: boolean;
    hasProPlan: boolean;
    isLoading: boolean;
    isLoaded: boolean;
}

export default (getTenantData = true): UseUserSubscriptionHook => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const { data, loading } = useQuery<
        UserSubscriptionData,
        UserSubscriptionVars
    >(USER_SUBSCRIPTION, {
        variables: {
            getTenantData
        }
    });

    useEffect(() => {
        if (data) {
            setIsLoaded(true);
        }
    }, [data]);

    return {
        hasPremium: data?.userSubscription?.isActive ?? false,
        hasProPlan:
            data?.userSubscription?.userSubscriptionPlan === 'Pro Plan' ??
            false,
        isLoading: loading,
        isLoaded
    };
};
