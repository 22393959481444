import { gql } from '@apollo/client';

import STREAM_DATA_RETENTION_FIELDS from '../fragments/streamDataRetentionFields';

export default gql`
    ${STREAM_DATA_RETENTION_FIELDS}
    fragment StreamDataFields on StreamData {
        id
        userProfileId
        streamDataTwitchTitles {
            dateTimeStart
            dateTimeEnd
            title
            length
        }
        streamDataTwitchGames {
            twitchGameId
            name
            imageUrl
            dateTimeStart
        }
        activeChatters
        streamChatProcessingErrorMessages
        streamChatProcessingStatus
        streamDataProcessingErrorMessages
        streamDataProcessingStatus
        twitchVideoId
        dateTimeStart
        dateTimeEnd
        length
        raidsCount
        version
        tier1SubscribersGained
        tier2SubscribersGained
        tier3SubscribersGained
        tier1GiftedSubscribersGained
        tier1GiftedSubscribersLost
        tier2GiftedSubscribersGained
        tier2GiftedSubscribersLost
        tier3GiftedSubscribersGained
        tier3GiftedSubscribersLost
        giftedSubscribersGained
        primeSubscribersGained
        subscribersGained
        performanceIndicator
        performanceIndicatorV2
        performanceIndicatorV2Aggregated
        dropRate
        interactions
        channelPointsRedeemed
        predictionsInteractions
        pollsInteractions
        pollCount
        newFollowers
        followersGained
        clipsMade
        averagePositionInDirectory
        averagePositionInDirectoryByLanguage
        totalWordCount
        averageTimeToFollow
        emotesUsed
        subscriptionPointsGained
        bitsUsed
        newSubscriptions
        renewedSubscriptions
        subscribersLost
        chatRank
        chattersRank
        followersRank
        viewersRank
        giftedSubscribersLost
        primeSubscribersLost
        subscriptionPointsLost
        streamChattersEngagements {
            streamMetricsType
            superChatters
            engagedChatters
            casualChatters
            singleMessageChatters
            lurkers
            chatMessages
            multiMessageChatters
        }
        streamViewersMetrics {
            streamMetricsType
            regularViewers
            casualViewers
            oneStreamViewers
            zeroStreamViewers
            newViewers
            estimatedUniqueViewers
            activeViewersRatio
            views
            returningViewers
            averageWatchtime
            hoursWatched
            averageViewers
            peakViewers
        }
        streamDataRetentions {
            ...StreamDataRetentionFields
        }
    }
`;
