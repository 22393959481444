import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDown.svg';
import isSlobs from '../../helpers/isSlobs';
import {
    storageSet,
    StorageType,
    tenantIdKey,
    tenantIdpKey
} from '../../helpers/storage';
import useAuth from '../../hooks/useAuth';
import usePreferencesMenuConfig from '../../hooks/usePreferencesMenuConfig';
import useTenants from '../../hooks/useTenants';
import { silentLogoutRoute } from '../../routes';
import AppVersion from '../AppVersion';
import ConditionalWrapper from '../ConditionalWrapper';
import ScrollbarSmall from '../ScrollbarSmall';
import { useAnalytics } from '../TrackingProvider';
import ProfileItem from './ProfileItem';
import UserMenuItem from './UserMenuItem';

interface ProfileMenuProps {
    isOpen: boolean;
    isHover: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    handleClickAway: () => void;
    isDeveloperView?: boolean;
}

const AnimatedBox = animated(Box);

const ProfileMenu = ({
    isOpen,
    isHover,
    setIsOpen,
    handleClickAway,
    isDeveloperView = false
}: ProfileMenuProps): JSX.Element => {
    const analytics = useAnalytics();
    const theme = useTheme();
    const auth = useAuth();
    const isHoverRef = React.useRef(isHover);
    const preferencesConfig = usePreferencesMenuConfig();
    const { userProfileData, currentTenant, allTenants, loading } =
        useTenants();

    const menuAnimation = useSpring({
        transform: isOpen ? 'translate3D(0, 0px, 0)' : 'translate3D(0, 8px, 0)',
        opacity: isOpen ? 1 : 0
    });

    useEffect(() => {
        isHoverRef.current = isHover;
    }, [isHover]);

    const handleSwitchTenant = async (id: string) => {
        const profile = auth?.profile;

        if (profile) {
            setIsOpen(false);
            await storageSet<string>(tenantIdKey, id, StorageType.session);
            await storageSet<string>(
                tenantIdpKey,
                profile?.idp ?? '',
                StorageType.session
            );
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                <AnimatedBox
                    style={menuAnimation}
                    sx={{
                        pointerEvents: isOpen ? 'all' : 'none',
                        position: 'absolute',
                        bottom: '60px',
                        left: 0,
                        right: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 2,
                        p: 2,
                        zIndex: 'select',
                        transition: theme.transitions.create(
                            ['background-color'],
                            {
                                duration: theme.transitions.duration.standard
                            }
                        ),
                        backdropFilter: [
                            'none',
                            undefined,
                            theme.blurs.elevation1
                        ],
                        backgroundColor: [
                            'baseCloud.blurFallback',
                            undefined,
                            'baseCloud.transparent10'
                        ]
                    }}
                >
                    {!isDeveloperView &&
                        allTenants &&
                        allTenants.length > 0 &&
                        !isSlobs() && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mb: 4
                                }}
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        color: 'text.secondary',
                                        pl: 2,
                                        mb: 2
                                    }}
                                >
                                    Your creators
                                </Typography>

                                <ConditionalWrapper
                                    condition={allTenants.length > 5}
                                    wrapper={(
                                        conditionalChildren: JSX.Element
                                    ) => (
                                        <ScrollbarSmall
                                            maxHeight={280}
                                            hasTopGradient={true}
                                        >
                                            {conditionalChildren}
                                        </ScrollbarSmall>
                                    )}
                                >
                                    <>
                                        {[...allTenants]
                                            .sort(
                                                (a, b) =>
                                                    Number(b.isMe) -
                                                    Number(a.isMe)
                                            )
                                            .map((item, i) => (
                                                <Button
                                                    key={`${
                                                        item.name ?? ''
                                                    }${i}`}
                                                    component={NavLink}
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        fontSize: '1.6rem',
                                                        textTransform: 'none',
                                                        justifyContent: 'start',
                                                        color: 'text.secondary',
                                                        px: 2,
                                                        py: 1,
                                                        mb: 1,
                                                        borderRadius: 1,
                                                        '&:last-of-type': {
                                                            mb: 0
                                                        },
                                                        backgroundColor:
                                                            'transparent',
                                                        '&:hover': {
                                                            color: 'common.white',
                                                            backgroundColor:
                                                                'baseCloud.transparent15'
                                                        },
                                                        '&.active': {
                                                            color: 'common.white',
                                                            backgroundColor:
                                                                'baseCloud.transparent20'
                                                        },
                                                        '&.Mui-disabled': {
                                                            opacity: 1,
                                                            pointer: 'default',
                                                            backgroundColor:
                                                                'transparent'
                                                        }
                                                    }}
                                                    to={silentLogoutRoute()}
                                                    disabled={
                                                        item?.id ===
                                                        currentTenant?.id
                                                    }
                                                    onClick={async () => {
                                                        if (
                                                            item?.id !==
                                                            currentTenant?.id
                                                        ) {
                                                            await handleSwitchTenant(
                                                                item.id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <ProfileItem
                                                        avatarUrl={
                                                            item.profileImageUrl
                                                        }
                                                        name={item.name ?? ''}
                                                        isMe={item.isMe}
                                                        isCurrentUser={
                                                            item?.id ===
                                                            currentTenant?.id
                                                        }
                                                    />
                                                </Button>
                                            ))}
                                    </>
                                </ConditionalWrapper>
                            </Box>
                        )}

                    {!currentTenant?.isMe && (
                        <UserMenuItem
                            text="Back to my account"
                            to={silentLogoutRoute()}
                            onClick={async () => {
                                if (userProfileData?.id) {
                                    await handleSwitchTenant(
                                        userProfileData.id
                                    );
                                }
                            }}
                            isSwitchToManager
                        />
                    )}

                    {preferencesConfig.map((item) => (
                        <UserMenuItem
                            key={item.title}
                            text={item.title}
                            href={item.href}
                            to={item.route}
                            onClick={() => {
                                if (item.onClick) {
                                    item.onClick();
                                }

                                setIsOpen(false);
                            }}
                            isChangelog={item.isChangelog}
                        />
                    ))}

                    <Box
                        sx={{
                            mt: 2
                        }}
                    >
                        <AppVersion />
                    </Box>
                </AnimatedBox>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                >
                    {!loading && (
                        <Button
                            onClick={() => {
                                if (isHoverRef.current) {
                                    setIsOpen(!isOpen);
                                    void analytics?.track('User menu open');
                                }
                            }}
                            className={classNames({
                                active: isOpen
                            })}
                            sx={{
                                display: 'flex',
                                textTransform: 'none',
                                justifyContent: 'start',
                                overflow: 'hidden',
                                px: 2,
                                py: '10px',
                                borderRadius: 2,
                                backgroundColor: 'transparent',
                                minWidth: '44px',
                                '&:hover': {
                                    color: 'common.white',
                                    backgroundColor: 'baseCloud.transparent10'
                                },
                                '&.active': {
                                    color: 'common.white',
                                    backgroundColor: 'baseCloud.transparent20'
                                },
                                '& svg': {
                                    width: '24px',
                                    height: '24px',
                                    ml: 'auto',
                                    flexShrink: 0,
                                    transform: isOpen
                                        ? 'rotate(180deg)'
                                        : 'none',
                                    transition: theme.transitions.create(
                                        ['transform'],
                                        {
                                            duration:
                                                theme.transitions.duration
                                                    .standard
                                        }
                                    ),
                                    '& path': {
                                        fill: theme.palette.common.white
                                    }
                                }
                            }}
                        >
                            <ProfileItem
                                avatarUrl={currentTenant?.profileImageUrl}
                                name={currentTenant?.name ?? ''}
                                isMe={currentTenant?.isMe}
                                isCurrentUserButton
                            />

                            <Box
                                className="animateOpacity"
                                component={ChevronDownIcon}
                            />
                        </Button>
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

export default ProfileMenu;
