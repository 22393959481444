const MuiCheckbox = {
    defaultProps: {
        disableRipple: true
    },
    styleOverrides: {
        root: {
            padding: 0,
            width: '24px',
            height: '24px'
        }
    }
};

export default MuiCheckbox;
