import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { MouseEventHandler, ReactNode } from 'react';

interface LoadingButtonProps {
    isLoading?: boolean;
    disabled?: boolean;
    label?: string;
    size?: 'small' | 'medium' | 'large' | 'compact' | undefined;
    variant?: 'text' | 'contained' | 'outlined' | 'icon' | undefined;
    color?:
        | 'subtle'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'errorSubtle'
        | undefined;
    disableRipple?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    sx?: Record<string, unknown>;
}

const LoadingButton = ({
    isLoading = false,
    disabled = false,
    label,
    disableRipple = false,
    type,
    startIcon,
    endIcon,
    size = 'small',
    variant = 'contained',
    color = 'primary',
    onClick,
    sx
}: LoadingButtonProps): JSX.Element => (
    <Button
        variant={variant}
        size={size}
        type={type}
        color={color}
        disabled={isLoading || disabled}
        disableRipple={disableRipple}
        startIcon={isLoading ? undefined : startIcon}
        endIcon={isLoading ? undefined : endIcon}
        sx={{
            ...sx,
            '& > span.MuiCircularProgress-root > svg > *': {
                fill: 'transparent'
            }
        }}
        onClick={onClick && onClick}
    >
        {isLoading ? (
            <CircularProgress
                size={size === 'large' ? 24 : 20}
                color={color === 'primary' ? 'secondary' : 'primary'}
            />
        ) : (
            label
        )}
    </Button>
);

export default LoadingButton;
