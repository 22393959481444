import { createContext, useCallback, useContext } from 'react';

export interface IFeatureFlagsContext {
    isLoaded: boolean;
    lastUpdate?: Date;
    flags: string[];
}

export const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
    isLoaded: false,
    flags: []
});

type IsEnabledType = (flagName: string, defaultValue?: boolean) => boolean;

interface IFeatureFlagsHook {
    /**
     * Boolean indicating if the feature flags list has been loaded from
     * the API.
     */
    isLoaded: boolean;
    /**
     * Date of the last feature flags list update.
     */
    lastUpdate?: Date;
    /**
     * List of enabled feature flags.
     */
    flags: string[];
    /**
     * Function to check if a flag has been enabled or not.
     * @param flagName: Name of the flag to check.
     * @param defaultValue: Default flag value if the flags haven't been
     *                      loaded yet. This parameter is optional.
     * @returns Boolean indicating if the flag is enabled or not.
     */
    isEnabled: IsEnabledType;
}

// useFeatureFlags
export default (): IFeatureFlagsHook => {
    const { isLoaded, lastUpdate, flags } = useContext(FeatureFlagsContext);

    const isEnabled = useCallback<IsEnabledType>(
        (flagName: string, defaultValue = false): boolean =>
            isLoaded ? flags.indexOf(flagName) > -1 : defaultValue,
        [isLoaded, flags]
    );

    return {
        isLoaded,
        lastUpdate,
        flags,
        isEnabled
    };
};
