import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import { ReactComponent as StarOutlineIcon } from '../assets/icons/starOutline.svg';
import { ResponsiveValue } from '../types';

interface BadgeProps {
    icon?: ReactNode;
    color?: string;
    textColor?: string;
    fontSize?: string;
    fontWeight?: number;
    lineHeight?: string;
    isPremium?: boolean;
    inline?: boolean;
    mt?: ResponsiveValue;
    ml?: ResponsiveValue;
    px?: ResponsiveValue;
    children: ReactNode;
}

const Badge = ({
    icon,
    color = 'accentBlue.dark',
    textColor,
    fontSize = '1.6rem',
    lineHeight = '2.4rem',
    fontWeight = 500,
    isPremium = false,
    inline = false,
    mt = 0,
    ml = 0,
    px = 3,
    children
}: BadgeProps): JSX.Element => {
    const theme = useTheme();

    let contentColor = textColor ?? theme.palette.common.white;
    if (isPremium) {
        contentColor = theme.palette.accentYellow.dark;
    }

    return (
        <Box
            component={inline ? 'span' : 'div'}
            sx={{
                display: inline ? 'inline-flex' : 'flex',
                alignItems: 'center',
                verticalAlign: inline ? 'middle' : 'unset',
                fontWeight,
                borderRadius: '14px',
                color: contentColor,
                fontSize,
                lineHeight,
                py: 1,
                px,
                mt,
                ml,
                whiteSpace: 'nowrap',
                '& svg': {
                    width: '20px',
                    height: '20px',
                    mr: 2,
                    '& > *': {
                        fill: contentColor
                    }
                },
                backgroundImage: isPremium
                    ? theme.gradients.product20Horizontal
                    : 'none',
                backgroundColor: isPremium
                    ? [
                          'baseCloud.blurFallback',
                          undefined,
                          'baseCloud.transparent10'
                      ]
                    : color,
                backdropFilter: isPremium
                    ? ['none', undefined, theme.blurs.elevation2]
                    : 'none'
            }}
        >
            {icon && !isPremium && icon}
            {isPremium && <StarOutlineIcon />}
            {children}
        </Box>
    );
};

export default Badge;
