import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import {
    authCallbackRoute,
    authRenewCallbackRoute,
    campaignPublicLandingPage,
    logoutRoute,
    silentLogoutRoute,
    slobsLogin
} from '../routes';
import LeftMenu from './LeftMenu';
import MobileMenu from './MobileMenu';

interface PageProps {
    children?: ReactNode;
}

const Page = ({ children }: PageProps): JSX.Element => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const auth = useAuth();

    return (
        <Box
            sx={{
                display: ['block', undefined, 'flex']
            }}
        >
            {location.pathname !== logoutRoute() &&
                location.pathname !== authCallbackRoute() &&
                location.pathname !== authRenewCallbackRoute() &&
                location.pathname !== silentLogoutRoute() &&
                location.pathname !== slobsLogin() &&
                !location.pathname.includes(
                    `${campaignPublicLandingPage()}/`
                ) &&
                auth.isAuthenticated && (
                    <>
                        {isMobile && <MobileMenu />} {!isMobile && <LeftMenu />}
                    </>
                )}
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexShrink: 0,
                    width: [
                        '100%',
                        undefined,
                        `calc(100% - ${theme.menuWidth})`
                    ]
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Page;
