import { gql } from '@apollo/client';

import { UserBasicStreamInfo } from '../generated/graphqlTypesAnalytics';

export interface UserBasicStreamInfoData {
    userBasicStreamInfo: UserBasicStreamInfo;
}

export default gql`
    query UserBasicStreamInfo {
        userBasicStreamInfo {
            firstStreamDate
            lastStreamDate
            streamCount
        }
    }
`;
