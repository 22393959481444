export const DEMO_ID = 'demo'; // eslint-disable-line

export const allGames = 'all';
export const otherGames = 'others';

export const discordUrl = 'https://discord.gg/3bF7UQNgDC';

export const tikTokReconnectDate = '2023-08-28T15:00:00';

export const userProfileTypeBroadcaster = 'Broadcaster';
export const userProfileTypeGameDeveloper = 'GameDeveloper';

export const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 80,
    elementCount: 100,
    dragFriction: 0.25,
    duration: 2000,
    stagger: 2,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};
