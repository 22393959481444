import type { ReactNode } from 'react';
import React from 'react';

import { globalReducer, INITIAL_STATE } from '../context';
import GlobalStateContext from '../context';

interface GlobalStateProviderProps {
    children?: ReactNode;
}

const GlobalStateProvider = ({
    children
}: GlobalStateProviderProps): JSX.Element => {
    const [globalState, dispatchToGlobal] = React.useReducer(
        globalReducer,
        INITIAL_STATE
    );

    return (
        <GlobalStateContext.Provider
            value={{ state: globalState, dispatch: dispatchToGlobal }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
