import jwt_decode from 'jwt-decode';
import { useContext, useMemo } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';

import GlobalStateContext from '../context';
import isSlobs from '../helpers/isSlobs';

interface UserProfile {
    token?: string;
    sub?: string;
    idp?: string;
    tenant_id?: string;
    tenant_name?: string;
}

interface UseAuthHook {
    profile: UserProfile | null;
    isAuthenticated: boolean;
    authContext: AuthContextProps;
}

export default (): UseAuthHook => {
    const auth = useAuth();
    const {
        state: { slobsToken }
    } = useContext(GlobalStateContext);

    const isAuthenticated = isSlobs() ? !!slobsToken : auth.isAuthenticated;
    const profile = useMemo(() => {
        if (!isAuthenticated) {
            return null;
        }

        if (isSlobs() && slobsToken) {
            const decodedToken = jwt_decode<UserProfile>(slobsToken);

            return {
                token: slobsToken,
                sub: decodedToken.sub
            };
        }

        return {
            token: auth?.user?.access_token,
            sub: auth?.user?.profile.sub,
            idp: auth?.user?.profile.idp as string,
            tenant_id: auth?.user?.profile?.tenant_id as string,
            tenant_name: auth?.user?.profile?.tenant_name as string
        };
    }, [slobsToken, auth, isAuthenticated]);

    return {
        profile,
        isAuthenticated,
        authContext: auth
    };
};
