import moment from 'moment';

export const startDate = ['2021-01-01T00:00:00.000Z'];

export const formatDate = (
    date: string | Date,
    hourFormat: '2-digit' | 'numeric' | 'none' = 'numeric',
    minuteFormat: '2-digit' | 'numeric' | 'none' = 'numeric',
    yearFormat: '2-digit' | 'numeric' | 'none' = 'numeric',
    monthFormat: 'numeric' | 'short' | 'long' | 'none' = 'numeric',
    dayFormat: 'numeric' | '2-digit' | 'none' = 'numeric',
    weekdayFormat: 'short' | 'long' | 'none' = 'none'
): string => {
    if (!date) {
        return '';
    }

    let newDate = date;
    if (!(date instanceof Date)) {
        newDate = new Date(date);
    }

    const options: Intl.DateTimeFormatOptions = {
        day: dayFormat === 'none' ? undefined : dayFormat,
        weekday: weekdayFormat === 'none' ? undefined : weekdayFormat,
        month: monthFormat === 'none' ? undefined : monthFormat,
        year: yearFormat === 'none' ? undefined : yearFormat,
        hour: hourFormat === 'none' ? undefined : hourFormat,
        minute: minuteFormat === 'none' ? undefined : minuteFormat
    };

    return (newDate as Date).toLocaleDateString([], options);
};

export const formatTime = (date: string | Date): string => {
    if (!date) {
        return '';
    }

    let newDate = date;
    if (!(date instanceof Date)) {
        newDate = new Date(date);
    }

    return (newDate as Date).toLocaleTimeString([], {
        timeStyle: 'short'
    });
};

export const formatMonth = (
    date: string | Date,
    shortFormat = false,
    withDay = false
): string => {
    const monthFormat = shortFormat ? 'MMM' : 'MMMM';

    return moment(date).format(withDay ? `${monthFormat} D` : monthFormat);
};
