import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { logoutRoute } from '../routes';

interface LoaderProps {
    height?: string;
    logoutMessageTimeoutSec?: number;
}

const Loader = ({
    height = '300px',
    logoutMessageTimeoutSec
}: LoaderProps): JSX.Element => {
    const [showLogoutMessage, setShowLogoutMessage] = useState<boolean>(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (logoutMessageTimeoutSec) {
            timer = setTimeout(
                () => setShowLogoutMessage(true),
                logoutMessageTimeoutSec * 1000
            );
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [logoutMessageTimeoutSec]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: showLogoutMessage ? 'auto' : height,
                    py: showLogoutMessage ? 6 : 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress />
                {showLogoutMessage && (
                    <>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                mt: 10,
                                mb: 4
                            }}
                        >
                            Stuck on the loading screen? <br />
                            Try logging out and clearing your browser cache.
                        </Typography>
                        <Button
                            component={NavLink}
                            to={logoutRoute()}
                            color="subtle"
                            sx={{
                                mb: 4
                            }}
                        >
                            Logout
                        </Button>
                    </>
                )}
            </Box>
        </>
    );
};

export default Loader;
