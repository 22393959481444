import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

import Layout from './Layout';
import StreamNavigation from './StreamNavigation';

interface TopMenuProps {
    userProfileId?: string;
    children?: ReactNode;
}

const TopMenu = ({ userProfileId, children }: TopMenuProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                width: '100%',
                pt: 8,
                zIndex: 'tabNavModalOpen',
                '&:before': {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backdropFilter: theme.blurs.elevation1,
                    backgroundColor: 'transparent',
                    background: `linear-gradient(180deg, ${theme.palette.baseInk.main} 0%, transparent 100%)`
                }
            }}
        >
            <Layout>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {children}

                    {userProfileId && (
                        <StreamNavigation userProfileId={userProfileId} />
                    )}
                </Box>
            </Layout>
        </Box>
    );
};

export default TopMenu;
