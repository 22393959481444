import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { Dispatch, FormEvent, ReactNode, SetStateAction } from 'react';

import Input from './Form/Input';
import LoadingButton from './LoadingButton';
import Modal from './Modal';

interface ConnectModalProps {
    title: string;
    description: string | ReactNode;
    placeholder?: string;
    defaultPlaceholder?: string;
    setError: Dispatch<SetStateAction<string | undefined>>;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    saveCallback: () => void;
    error?: string;
    validator?: (val: string) => string | null;
    hasCloseButton?: boolean;
    confirmButtonLabel?: string;
    confirmButtonColor?: 'primary' | 'error';
    confirmButtonLoading?: boolean;
}

const ConnectModal = ({
    title,
    description,
    placeholder,
    defaultPlaceholder = 'Enter your server invite',
    setError,
    value,
    setValue,
    modalOpen,
    setModalOpen,
    saveCallback,
    error,
    validator,
    hasCloseButton = true,
    confirmButtonLabel = 'Save',
    confirmButtonColor = 'primary',
    confirmButtonLoading = false
}: ConnectModalProps): JSX.Element => {
    const defaultValidator = (val: string): string | null => {
        if (val === '') {
            return 'This field is required';
        }

        return null;
    };

    return (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            hasCloseButton={hasCloseButton}
            width="540px"
            height="auto"
        >
            <Box
                sx={{
                    pr: 8
                }}
            >
                {title && (
                    <Typography
                        sx={{
                            fontSize: '2.4rem',
                            lineHeight: '3.2rem',
                            fontWeight: 600
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {description && (
                    <Typography
                        sx={{
                            fontSize: '1.6rem',
                            lineHeight: '2.4rem',
                            mt: 1,
                            color: 'text.secondary'
                        }}
                    >
                        {description}
                    </Typography>
                )}
            </Box>

            <Box
                component="form"
                sx={{
                    mt: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();

                    const err = validator
                        ? validator(value)
                        : defaultValidator(value);

                    if (err) {
                        setError(err);
                    } else {
                        saveCallback();
                    }
                }}
            >
                <Input
                    error={error}
                    setError={setError}
                    setValue={setValue}
                    placeholder={placeholder ?? defaultPlaceholder}
                    autoFocus
                />
                <Box
                    sx={{
                        mt: 6,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                        gap: 4
                    }}
                >
                    <Button
                        variant="contained"
                        color="subtle"
                        size="large"
                        sx={{
                            flexShrink: 0
                        }}
                        onClick={() => setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        size="large"
                        color={confirmButtonColor}
                        isLoading={confirmButtonLoading}
                        label={confirmButtonLabel}
                        sx={{
                            flexShrink: 0
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default ConnectModal;
