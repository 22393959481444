import { gql } from '@apollo/client';

import {
    QueryUserProfileArgs,
    UserProfile
} from '../generated/graphqlTypesApi';

export interface UserProfileData {
    userProfile: UserProfile;
}

export type UserProfileVars = QueryUserProfileArgs;

export default gql`
    query UserProfile($userId: String!) {
        userProfile(userId: $userId) {
            twitchUserId
            displayName
            profileImageUrl
            email
            timeStamp
            broadcasterType {
                code
            }
            userProfileType {
                code
            }
        }
    }
`;
