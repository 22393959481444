import { useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import MobileTopMenu from '../../components/MobileTopMenu';
import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import isSlobs from '../../helpers/isSlobs';
import useUserProfile from '../../hooks/useUserProfile';

export const accountManagementTabNavConfig = [
    {
        value: 'account',
        label: 'Account'
    },
    {
        value: 'team',
        label: 'Team',
        isHidden: false,
        isPremium: true
    },
    {
        value: 'preferences',
        label: 'Preferences'
    },
    {
        value: 'subscription',
        label: 'Subscription',
        hasFeatureFlag: true
    }
];

function AccountManagement(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { isBroadcasterView } = useUserProfile();
    accountManagementTabNavConfig[1].isHidden = isSlobs();

    return (
        <>
            {!isMobile && isBroadcasterView && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={accountManagementTabNavConfig}
                    />
                </TopMenu>
            )}

            {isMobile && (
                <MobileTopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={accountManagementTabNavConfig}
                    />
                </MobileTopMenu>
            )}

            <Outlet />
        </>
    );
}

export default AccountManagement;
