export function homeRoute(): string {
    return `/`;
}

export function monetizationToolsRoute(): string {
    return `/streamer-toolkit`;
}

export function monetizationInsights(): string {
    return `/monetization-insights`;
}

export function portfolioManagement(): string {
    return `/portfolio-management`;
}

export function gameKeys(): string {
    return `/game-keys`;
}

export function streamsRoute(): string {
    return `/streams`;
}

export function growthRoute(): string {
    return `/creator-dashboard`;
}

export function whatToStream(): string {
    return `/what-to-stream`;
}

export function whenToStream(): string {
    return `/when-to-stream`;
}

export function accountManagement(): string {
    return `/account-management`;
}

export function onboarding(): string {
    return `/onboarding`;
}

export function tenantInvite(): string {
    return `/tenant-invite`;
}

export function streamRoute(id?: string): string {
    return `/stream${id ? `/${id}` : ''}`;
}

export function authCallbackRoute(): string {
    return `/auth/callback`;
}

export function authRenewCallbackRoute(): string {
    return `/auth/renew`;
}

export function logoutRoute(): string {
    return `/auth/logout`;
}

export function slobsLogin(): string {
    return `/auth/slobs-login`;
}

export function silentLogoutRoute(): string {
    return `/auth/silent-logout`;
}

export function gameMarketing(): string {
    return `/game-marketing`;
}

export function campaigns(): string {
    return `${gameMarketing()}/campaigns`;
}

export function campaignAnalytics(id?: string): string {
    return `${gameMarketing()}/analytics${id ? `/${id}` : ''}`;
}

export function buyCredits(): string {
    return `${gameMarketing()}/buy-credits`;
}

export function marketingServices(): string {
    return `${gameMarketing()}/marketing-services`;
}

export function createCampaign(id?: string): string {
    return `${gameMarketing()}/create-campaign${id ? `/${id}` : ''}`;
}

export function addMoreKeys(id?: string): string {
    return `${gameMarketing()}/add-more-keys${id ? `/${id}` : ''}`;
}

export function campaignPublicLandingPage(params?: string): string {
    return `/campaign${params ? `/${params}` : ''}`;
}
