import baseTheme from '../baseTheme';

const MuiTab = {
    styleOverrides: {
        root: {
            alignItems: 'flex-start',
            textTransform: 'none',
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            minHeight: 'auto',
            padding: '12px',
            fontWeight: '400',
            color: baseTheme.palette.text.secondary,
            transition: baseTheme.transitions.create(['color'], {
                duration: baseTheme.transitions.duration.standard
            }),
            '&.Mui-selected': {
                color: baseTheme.palette.common.white
            },
            '&:hover': {
                color: baseTheme.palette.common.white
            }
        }
    }
};

export default MuiTab;
