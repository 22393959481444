import { BreakpointsOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xxl: true;
    }
}

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
        xxl: 1600
    }
};

export default breakpoints;
