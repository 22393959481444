import { StreamStatus } from '../graphql/generated/graphqlTypesAnalytics';

export enum OverallStreamStatus {
    normal,
    beingProcessed,
    partialData,
    corruptedData,
    historicalData
}

export default (
    streamStatus?: StreamStatus,
    chatStatus?: StreamStatus
): OverallStreamStatus | undefined => {
    if (
        streamStatus === StreamStatus.Error &&
        chatStatus === StreamStatus.Error
    ) {
        return OverallStreamStatus.corruptedData;
    } else if (
        streamStatus === StreamStatus.Error ||
        chatStatus === StreamStatus.Error
    ) {
        return OverallStreamStatus.partialData;
    } else if (
        streamStatus === StreamStatus.CalculatedFromTracker &&
        chatStatus === StreamStatus.CalculatedFromTracker
    ) {
        return OverallStreamStatus.historicalData;
    } else if (
        streamStatus &&
        chatStatus &&
        streamStatus === StreamStatus.Finished &&
        chatStatus === StreamStatus.Finished
    ) {
        return OverallStreamStatus.normal;
    }

    return OverallStreamStatus.beingProcessed;
};
