import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
    GameDeveloperCampaignStatus,
    UserAvailableGameDeveloperCampaign
} from '../graphql/generated/graphqlTypesAnalytics';
import AVAILABLE_GAME_KEYS_FOR_USER from '../graphql/queries/availableGameKeysForUser';
import {
    AvailableGameKeysForUserData,
    AvailableGameKeysForUserVars
} from '../graphql/queries/availableGameKeysForUser';

interface UseGameKeysHook {
    gameKeys?: AvailableGameKeysForUserData;
    availableGameKeys?: UserAvailableGameDeveloperCampaign[];
    myGameKeys?: UserAvailableGameDeveloperCampaign[];
    loading: boolean;
}

export default (
    variables: AvailableGameKeysForUserVars,
    skip = false
): UseGameKeysHook => {
    const {
        loading: availableGameKeysForUserLoading,
        data: availableGameKeysForUserData
    } = useQuery<AvailableGameKeysForUserData, AvailableGameKeysForUserVars>(
        AVAILABLE_GAME_KEYS_FOR_USER,
        {
            variables,
            skip
        }
    );

    const availableGameKeys = useMemo(
        () =>
            availableGameKeysForUserData?.availableGameKeysForUser.filter(
                (key) => {
                    return (
                        !key.claimedAt &&
                        !key.isInWishList &&
                        key.status !== GameDeveloperCampaignStatus.Closed
                    );
                }
            ),
        [availableGameKeysForUserData]
    );

    const myGameKeys = useMemo(
        () =>
            availableGameKeysForUserData?.availableGameKeysForUser.filter(
                (key) => {
                    return key.claimedAt || key.isInWishList;
                }
            ),
        [availableGameKeysForUserData]
    );

    return {
        gameKeys: availableGameKeysForUserData,
        availableGameKeys,
        myGameKeys,
        loading: availableGameKeysForUserLoading
    };
};
