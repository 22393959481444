import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import Loader from '../../components/Loader';
import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import useUserSubscription from '../../hooks/useUserSubscription';

export const monetizationInsightsTabNavConfig = [
    {
        value: 'audience',
        label: 'Audience',
        isPremium: true
    },
    {
        value: 'conversion-rates',
        label: 'Conversion rates',
        isPremium: true
    },
    {
        value: 'top-supporters',
        label: 'Top Supporters',
        isPremium: true
    },
    {
        value: 'churn-analytics',
        label: 'Churn Analytics',
        isPremium: true
    },
    {
        value: 'patreon-analytics',
        label: 'Patreon Analytics',
        hasFeatureFlag: true,
        isPremium: true
    }
];

interface OutletContext {
    hasPremium?: boolean;
}

export const useMonetizationInsightsOutletContext = () => {
    return useOutletContext<OutletContext>();
};

function MonetizationInsights(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        hasPremium,
        isLoading: isPremiumLoading,
        isLoaded: isPremiumLoaded
    } = useUserSubscription();

    if (isPremiumLoading || !isPremiumLoaded) {
        return <Loader />;
    }

    return (
        <>
            {!isMobile && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={monetizationInsightsTabNavConfig}
                    />
                </TopMenu>
            )}

            <Outlet
                context={{
                    hasPremium
                }}
            />
        </>
    );
}

export default MonetizationInsights;
