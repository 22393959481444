import { gql } from '@apollo/client';

import { DataPageOfStreamData } from '../generated/graphqlTypesAnalytics';

export interface SearchStreamDataIds {
    searchStreamData: DataPageOfStreamData;
}

export type SearchStreamDataIdsArgs = {
    dateTimeEnd: Date;
    userProfileIds: string[];
};

export default gql`
    query SearchStreamDataIds(
        $dateTimeEnd: DateTime
        $userProfileIds: [UUID!]
    ) {
        searchStreamData(
            streamSearchSettings: {
                pageSize: 999
                sortOrder: ASCENDING
                sortMember: DATE_TIME_START
                dateTimeEnd: $dateTimeEnd
                includeRetentions: false
                userProfileIds: $userProfileIds
            }
        ) {
            pageNumber
            totalPages
            data {
                id
            }
        }
    }
`;
