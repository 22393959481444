import baseTheme from '../baseTheme';

const MuiCssBaseline = {
    styleOverrides: {
        html: {
            fontSize: '62.5%'
        },
        body: {
            right: 0,
            overflowX: 'hidden'
        },
        '.visx-tooltip': {
            zIndex: 1400
        },
        '.Toastify__toast-container': {
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        '.Toastify__toast': {
            minHeight: '48px',
            padding: '12px 12px 12px 10px',
            margin: '0 10px 10px 10px',
            boxShadow: 'none',
            borderRadius: '6px',
            textShadow: 'none',
            '&.Toastify__toast--success': {
                backdropFilter: ['none', undefined, baseTheme.blurs.elevation3],
                backgroundColor: [
                    baseTheme.palette.baseCloud.blurFallback,
                    undefined,
                    baseTheme.palette.baseCloud.transparent10
                ]
            },
            '&.Toastify__toast--error': {
                backdropFilter: ['none', undefined, baseTheme.blurs.elevation3],
                backgroundColor: [
                    baseTheme.palette.accentRed.blurFallback,
                    undefined,
                    baseTheme.palette.accentRed.transparent15
                ],
                '.Toastify__toast-icon': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: baseTheme.palette.accentRed.dark
                }
            },
            '&.Toastify__toast--warning': {
                backdropFilter: ['none', undefined, baseTheme.blurs.elevation3],
                backgroundColor: [
                    baseTheme.palette.accentOrange.blurFallback,
                    undefined,
                    baseTheme.palette.accentOrange.transparent15
                ],
                '.Toastify__toast-icon': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: baseTheme.palette.accentOrange.dark
                }
            },
            '&.Toastify__toast--info': {
                backdropFilter: ['none', undefined, baseTheme.blurs.elevation3],
                backgroundColor: [
                    baseTheme.palette.accentBlue.blurFallback,
                    undefined,
                    baseTheme.palette.accentBlue.transparent15
                ],
                '.Toastify__toast-icon': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: baseTheme.palette.accentBlue.dark
                }
            }
        },
        '.Toastify__progress-bar': {
            height: 'auto',
            top: 0,
            zIndex: -1,
            opacity: 1,
            backgroundColor: baseTheme.palette.baseCloud.transparent10,
            '&.Toastify__progress-bar--error': {
                backgroundColor: baseTheme.palette.accentRed.transparent10
            },
            '&.Toastify__progress-bar--warning': {
                backgroundColor: baseTheme.palette.accentOrange.transparent10
            },
            '&.Toastify__progress-bar--info': {
                backgroundColor: baseTheme.palette.accentBlue.transparent10
            }
        },
        '.Toastify__toast-icon': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            height: '20px',
            marginRight: baseTheme.spacing(2),
            borderRadius: '6px',
            backgroundColor: 'transparent',
            '& > svg': {
                width: '20px',
                height: '20px'
            }
        },
        '.Toastify__toast-body': {
            display: 'flex',
            alignItems: 'center',
            color: baseTheme.palette.common.white,
            fontFamily: baseTheme.typography.fontFamily,
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            fontWeight: 400,
            padding: 0,
            margin: 0
        }
    }
};

export default MuiCssBaseline;
