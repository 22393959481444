import baseTheme from '../baseTheme';

const MuiTab = {
    styleOverrides: {
        root: {
            minHeight: '44px',
            [baseTheme.breakpoints.up('md')]: {
                minHeight: '48px'
            }
        },
        indicator: {
            borderRadius: '2px'
        },
        flexContainer: {
            gap: baseTheme.spacing(4)
        }
    }
};

export default MuiTab;
