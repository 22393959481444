import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import {
    Dispatch,
    memo,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDown.svg';
import getTabSlug from '../../helpers/getTabSlug';
import isSlobs from '../../helpers/isSlobs';
import nameToId from '../../helpers/nameToId';
import { storageGet, StorageType } from '../../helpers/storage';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import useTenants from '../../hooks/useTenants';
import { TabNavConfig } from '../../types';
import { breatheAnimation } from '../Chart/TopMoments';
import MenuBadge from '../MenuBadge';
import { useAnalytics } from '../TrackingProvider';

const AnimatedBox = animated(Box);
export const onPaths = (
    activePaths: string[],
    omittedPaths: (string | undefined)[],
    location: Location
) => {
    let matched = false;
    activePaths.forEach((path) => {
        if (location.pathname.search(path) > -1) {
            matched = true;
        }
    });

    omittedPaths.forEach((path) => {
        if (path && location.pathname.search(path) > -1) {
            matched = false;
        }
    });

    return matched;
};

interface MenuItemProps {
    to?: string;
    href?: string;
    onClick?: () => void;
    text: string;
    icon?: React.ReactNode;
    activePaths?: string[];
    omittedPaths?: (string | undefined)[];
    isPremium?: boolean;
    isDemo?: boolean;
    isAlpha?: boolean;
    isNew?: boolean;
    numberBadgeValue?: number;
    isSubMenuOpen?: boolean;
    setOpenSubMenu?: Dispatch<SetStateAction<string | undefined>>;
    items?: TabNavConfig[];
    notificationDotColor?: string;
}

const MenuItem = ({
    to,
    href,
    onClick,
    text,
    icon,
    activePaths = [],
    omittedPaths = [],
    isPremium = false,
    isDemo = false,
    isAlpha = false,
    isNew = false,
    numberBadgeValue,
    isSubMenuOpen = false,
    setOpenSubMenu,
    items,
    notificationDotColor
}: MenuItemProps): JSX.Element => {
    const theme = useTheme();
    const { isEnabled } = useFeatureFlags();
    const analytics = useAnalytics();
    const location = useLocation();
    const navigate = useNavigate();
    const { isManager } = useTenants();
    const ref = useRef<HTMLDivElement>(null);
    const [sectionVisited, setSectionVisited] = useState<boolean>(true);
    const id = `section-visited-${nameToId(text)}`;
    const isSubMenuOpenAndExists = isSubMenuOpen && items && items.length > 0;

    const [style, api] = useSpring(
        () => ({
            height: '0px',
            opacity: 0
        }),
        []
    );

    const isActive = useMemo(() => {
        let matched = onPaths(activePaths, omittedPaths, location);

        if (activePaths[0] === '/') {
            matched = location.pathname === '/';
        }

        return matched;
    }, [activePaths, omittedPaths, location]);

    useEffect(() => {
        if (ref.current && items && items.length > 0) {
            api.start({
                height: `${isSubMenuOpen ? ref.current.offsetHeight : 0}px`,
                opacity: isSubMenuOpen ? 1 : 0
            });
        }
    }, [api, ref, isSubMenuOpen, items]);

    useEffect(() => {
        const handleStorageSet = (e: CustomEvent<{ key: string }>) => {
            if (e.detail.key === id) {
                setSectionVisited(true);
            }
        };

        const handleInitValue = async () => {
            if (notificationDotColor) {
                const initValue = await storageGet<boolean>(
                    id,
                    StorageType.local
                );

                setSectionVisited(initValue ?? false);

                document.addEventListener(
                    'storageSet',
                    handleStorageSet as EventListener
                );
            }
        };

        void handleInitValue();

        return () => {
            if (notificationDotColor) {
                document.removeEventListener(
                    'storageSet',
                    handleStorageSet as EventListener
                );
            }
        };
    }, [notificationDotColor, id]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                ml: isPremium ? 0 : '2px',
                mr: isPremium ? 0 : '2px',
                '&:before': {
                    content: '""',
                    opacity: isSubMenuOpenAndExists ? 1 : 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    background: `linear-gradient(180deg, ${
                        theme.palette.baseCloud.transparent5 ?? ''
                    } 0%, ${theme.palette.baseCloud.transparent10 ?? ''} 100%)`,
                    borderRadius: '8px',
                    transition: theme.transitions.create(['opacity'], {
                        duration: theme.transitions.duration.standard
                    })
                }
            }}
        >
            {!isManager && notificationDotColor && !sectionVisited && (
                <>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '30px',
                            top: '5px',
                            width: '12px',
                            height: '12px',
                            borderRadius: '6px',
                            backgroundColor: notificationDotColor
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '26px',
                            top: '1px',
                            width: '20px',
                            height: '20px',
                            borderRadius: '10px',
                            backgroundColor: alpha(notificationDotColor, 0.25),
                            animation: `${breatheAnimation} 2.5s ease-out infinite normal`
                        }}
                    />
                </>
            )}

            <Button
                onClick={() => {
                    if (setOpenSubMenu && items && items.length > 0) {
                        setOpenSubMenu(text);
                    }

                    if (to && !isSubMenuOpen) {
                        navigate(to);
                    }

                    if (href && isSlobs()) {
                        window.streamlabsOBS.v1.External.openExternalLink(href);
                    } else if (href) {
                        window.open(href, '_blank');
                    }

                    if (isPremium) {
                        void analytics?.track('Upgrade clicked');
                    }

                    if (onClick) {
                        onClick();
                    }
                }}
                className={isActive ? 'active' : ''}
                disableRipple
                sx={{
                    height: '44px',
                    justifyContent: 'start',
                    overflow: 'hidden',
                    p: isPremium ? 3 : '10px',
                    borderRadius: '8px',
                    minWidth: '44px',
                    backgroundColor: isSubMenuOpenAndExists
                        ? theme.palette.baseCloud.transparent20
                        : 'transparent',
                    boxShadow: 'none',
                    '&:before': {
                        content: '""',
                        opacity: 0,
                        transition: theme.transitions.create(['opacity'], {
                            duration: theme.transitions.duration.standard
                        }),
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        background: `linear-gradient(180deg, ${
                            theme.palette.baseCloud.transparent5 ?? ''
                        } 0%, ${
                            theme.palette.baseCloud.transparent10 ?? ''
                        } 100%)`
                    },
                    ...(isPremium && {
                        '&:after': {
                            content: '""',
                            width: isSubMenuOpen ? '100%' : '300px',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                            backgroundColor: 'baseCloud.transparent10',
                            backgroundImage: theme.gradients.product20Horizontal
                        }
                    }),
                    '&:hover': {
                        backgroundColor: isSubMenuOpenAndExists
                            ? theme.palette.baseCloud.transparent20
                            : 'transparent',
                        color: isPremium ? 'accentYellow.dark' : 'common.white',
                        '&:before': {
                            opacity: isSubMenuOpenAndExists ? 0 : 1
                        }
                    },
                    '&.active': {
                        '& > span': {
                            color: isPremium
                                ? 'accentYellow.dark'
                                : 'common.white'
                        },
                        '&:before': {
                            opacity: isSubMenuOpenAndExists ? 0 : 1
                        }
                    },
                    '& .icon svg *': {
                        fill: isPremium
                            ? theme.palette.accentYellow.dark
                            : theme.palette.text.secondary,
                        transition: theme.transitions.create(['fill'], {
                            duration: theme.transitions.duration.standard
                        })
                    },
                    '&:hover  .icon svg *': {
                        fill: isPremium
                            ? theme.palette.accentYellow.dark
                            : theme.palette.common.white
                    },
                    '&.active .icon svg *': {
                        fill: theme.palette.accentYellow.dark
                    }
                }}
            >
                {icon && (
                    <Box
                        className="icon"
                        sx={{
                            display: 'flex',
                            flexShrink: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 3,
                            '& > svg': {
                                width: '24px',
                                height: '24px'
                            }
                        }}
                    >
                        {icon}
                    </Box>
                )}

                <Typography
                    component="span"
                    className="animateOpacity"
                    sx={{
                        display: 'flex',
                        whiteSpace: 'nowrap',
                        fontSize: '1.6rem',
                        lineHeight: '2.4rem',
                        textTransform: 'none',
                        color: isPremium
                            ? 'accentYellow.dark'
                            : 'text.secondary',
                        fontWeight: 500
                    }}
                >
                    {text}

                    {isPremium && isEnabled('app.menu.upgradeBadge') && (
                        <MenuBadge ml={2} isPremium>
                            -30%
                        </MenuBadge>
                    )}

                    {numberBadgeValue !== undefined && (
                        <Box
                            component="span"
                            sx={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '100%',
                                backgroundColor: 'baseCloud.transparent15',
                                ml: 2,
                                fontSize: '1.4rem',
                                lineHeight: '2.4rem',
                                color: 'common.white'
                            }}
                        >
                            {numberBadgeValue}
                        </Box>
                    )}

                    {isDemo && (
                        <MenuBadge ml={2} color={theme.palette.accentBlue.main}>
                            Demo
                        </MenuBadge>
                    )}

                    {isAlpha && (
                        <MenuBadge ml={2} color={theme.palette.accentBlue.main}>
                            Alpha
                        </MenuBadge>
                    )}

                    {isNew && (
                        <MenuBadge ml={2} color={theme.palette.accentBlue.main}>
                            New
                        </MenuBadge>
                    )}
                </Typography>

                {items && items.length > 0 && (
                    <Box
                        className="animateOpacity"
                        component={ChevronDownIcon}
                        sx={{
                            width: '24px',
                            height: '24px',
                            ml: 'auto',
                            transform: isSubMenuOpen
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            '& path': {
                                fill: isSubMenuOpen
                                    ? theme.palette.common.white
                                    : theme.palette.text.secondary,
                                transition: theme.transitions.create(['fill'], {
                                    duration:
                                        theme.transitions.duration.standard
                                })
                            }
                        }}
                    />
                )}
            </Button>

            {items && items.length > 0 && (
                <AnimatedBox
                    style={style}
                    sx={{
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        ref={ref}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            pt: 2,
                            pb: 3
                        }}
                    >
                        {items.map((item) => {
                            const isItemActive =
                                getTabSlug(
                                    location.pathname,
                                    items[0].value
                                ) === item.value && isActive;

                            if (
                                item.hasFeatureFlag &&
                                !isEnabled(
                                    `app.tabs.${item.value}`,
                                    item.defaultFlag
                                )
                            ) {
                                return null;
                            }

                            return (
                                <Button
                                    key={item.label}
                                    onClick={() => {
                                        navigate(
                                            `${to ?? ''}${
                                                item.value === items[0].value
                                                    ? ''
                                                    : `/${item.value}`
                                            }`
                                        );
                                    }}
                                    variant="text"
                                    disableRipple
                                    sx={{
                                        justifyContent: 'start',
                                        py: 1,
                                        px: 3,
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            '& > .label': {
                                                color: 'common.white'
                                            },
                                            '& > .indicator': {
                                                width: '16px',
                                                backgroundColor: 'common.white'
                                            }
                                        }
                                    }}
                                >
                                    <Box
                                        className="indicator"
                                        component="span"
                                        sx={{
                                            display: 'block',
                                            mx: 3,
                                            width: isItemActive
                                                ? '16px'
                                                : '2px',
                                            height: '2px',
                                            flexShrink: 0,
                                            borderRadius: '1px',
                                            backgroundColor: isItemActive
                                                ? 'common.white'
                                                : 'text.secondary',
                                            transition:
                                                theme.transitions.create(
                                                    [
                                                        'width',
                                                        'background-color'
                                                    ],
                                                    {
                                                        duration:
                                                            theme.transitions
                                                                .duration
                                                                .standard
                                                    }
                                                )
                                        }}
                                    />

                                    <Typography
                                        className="label"
                                        component="span"
                                        sx={{
                                            ml: 2,
                                            fontSize: '1.6rem',
                                            lineHeight: '2.4rem',
                                            color: isItemActive
                                                ? 'common.white'
                                                : 'text.secondary',
                                            fontWeight: isItemActive
                                                ? 600
                                                : 500,
                                            transition:
                                                theme.transitions.create(
                                                    ['color'],
                                                    {
                                                        duration:
                                                            theme.transitions
                                                                .duration
                                                                .standard
                                                    }
                                                )
                                        }}
                                        noWrap
                                    >
                                        {item.label}
                                    </Typography>

                                    {item.isAlpha && (
                                        <MenuBadge
                                            ml={2}
                                            color={
                                                theme.palette.accentBlue.main
                                            }
                                        >
                                            Alpha
                                        </MenuBadge>
                                    )}

                                    {item.isPremium && (
                                        <MenuBadge ml={2} isPremium>
                                            Premium
                                        </MenuBadge>
                                    )}

                                    {item.isNew && (
                                        <MenuBadge
                                            ml={2}
                                            color={
                                                theme.palette.accentBlue.main
                                            }
                                        >
                                            New
                                        </MenuBadge>
                                    )}

                                    {item.numberBadgeValue !== undefined && (
                                        <Box
                                            component="span"
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                borderRadius: '100%',
                                                backgroundColor:
                                                    'baseCloud.transparent15',
                                                ml: 2,
                                                fontSize: '1.4rem',
                                                lineHeight: '2.4rem',
                                                color: 'common.white'
                                            }}
                                        >
                                            {item.numberBadgeValue}
                                        </Box>
                                    )}
                                </Button>
                            );
                        })}
                    </Box>
                </AnimatedBox>
            )}
        </Box>
    );
};

const propsEqualityCheck = (
    prevProps: MenuItemProps,
    nextProps: MenuItemProps
) =>
    isEqual(prevProps.activePaths, nextProps.activePaths) &&
    isEqual(prevProps.omittedPaths, nextProps.omittedPaths) &&
    prevProps.isSubMenuOpen === nextProps.isSubMenuOpen;

export default memo(MenuItem, propsEqualityCheck);
