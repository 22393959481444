import { gql } from '@apollo/client';

import STREAM_DATA_FIELDS from '../fragments/streamData';
import {
    DataPageOfStreamData,
    StreamDataSearchSettingsInput
} from '../generated/graphqlTypesAnalytics';

export interface SearchStreamDataById {
    searchStreamData: DataPageOfStreamData;
}

export type SearchStreamDataByIdArgs = StreamDataSearchSettingsInput;

export default gql`
    ${STREAM_DATA_FIELDS}
    query SearchStreamDataById(
        $identifiers: [UUID!]
        $includeRetentions: Boolean!
        $userProfileIds: [UUID!]
    ) {
        searchStreamData(
            streamSearchSettings: {
                identifiers: $identifiers
                includeRetentions: $includeRetentions
                userProfileIds: $userProfileIds
            }
        ) {
            data {
                ...StreamDataFields
            }
        }
    }
`;
