import { Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';

interface ButtonWithSwitchProps {
    value: boolean;
    size?: 'small' | 'medium' | 'large' | 'compact' | undefined;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onChange: () => void;
    label?: string;
}

const ButtonWithSwitch = ({
    value,
    size,
    startIcon,
    endIcon,
    onChange,
    label
}: ButtonWithSwitchProps): JSX.Element => (
    <Button
        color="subtle"
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        sx={{
            flexShrink: 0,
            justifyContent: 'start'
        }}
        onClick={() => onChange()}
    >
        <Typography
            component="span"
            className="animateOpacity"
            sx={{
                pr: 3,
                mr: 'auto',
                fontSize: '1.4rem',
                lineHeight: '2rem',
                flexShrink: 0
            }}
            noWrap
        >
            {label}
        </Typography>

        <Box
            className="animateOpacity"
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Switch checked={value} value={value} size="small" />
        </Box>
    </Button>
);

export default ButtonWithSwitch;
