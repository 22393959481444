import { gql } from '@apollo/client';

import {
    BooleanResult,
    MutationRemoveTenantArgs
} from '../generated/graphqlTypesAnalytics';

export interface RemoveTenant {
    removeTenant: BooleanResult;
}

export type RemoveTenantVars = MutationRemoveTenantArgs;

export default gql`
    mutation RemoveTenant($tenantId: UUID!) {
        removeTenant(tenantId: $tenantId) {
            value
        }
    }
`;
