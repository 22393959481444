import { useMutation, useQuery } from '@apollo/client';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { BooleanResult } from '../graphql/generated/graphqlTypesAnalytics';
import CONFIRM_TENANT_INVITATION_TOKEN, {
    ConfirmTenantInvitationToken,
    ConfirmTenantInvitationTokenVars
} from '../graphql/mutations/confirmTenantInvitationToken';
import DELETE_TENANT_INVITATION_TOKEN, {
    DeleteTenantInvitationTokenVars
} from '../graphql/mutations/deleteTenantInvitationToken';
import TENANT_INVITATIONS, {
    TenantInvitationsData
} from '../graphql/queries/tenantInvitations';
import USER_TENANTS, { UserTenants } from '../graphql/queries/userTenants';
import showToast, { ToastVariant } from '../helpers/showToast';
import ConfirmationModal from './ConfirmationModal';
import { useAnalytics } from './TrackingProvider';

interface TenantInvitationsProviderProps {
    children: ReactNode;
}

const TenantInvitationsProvider = ({
    children
}: TenantInvitationsProviderProps): JSX.Element => {
    const analytics = useAnalytics();
    const [managerInviteModalOpen, setManagerInviteModalOpen] =
        useState<boolean>(false);
    const {
        loading: tenantInvitationsDataLoading,
        data: tenantInvitationsData
    } = useQuery<TenantInvitationsData>(TENANT_INVITATIONS, {});

    const [confirmTenantInvitationToken] = useMutation<
        ConfirmTenantInvitationToken,
        ConfirmTenantInvitationTokenVars
    >(CONFIRM_TENANT_INVITATION_TOKEN, {
        errorPolicy: 'ignore',
        update: (cache, mutationData, options) => {
            if (mutationData.data) {
                const userTenantsData = cache.readQuery<UserTenants>({
                    query: USER_TENANTS
                });

                cache.writeQuery({
                    query: USER_TENANTS,
                    data: {
                        userTenants: {
                            tenants: [
                                ...(userTenantsData?.userTenants.tenants ?? []),
                                mutationData.data.confirmTenantInvitationToken
                            ]
                        }
                    }
                });

                cache.writeQuery({
                    query: TENANT_INVITATIONS,
                    data: {
                        tenantInvitations: {
                            fromUser:
                                tenantInvitationsData?.tenantInvitations
                                    ?.fromUser,
                            toUser: tenantInvitationsData?.tenantInvitations?.toUser?.filter(
                                (item) =>
                                    item.invitationTokenId !==
                                    options.variables?.invitationTokenId
                            )
                        }
                    }
                });

                void analytics?.track(
                    'Tenant invitation token confirm success'
                );
                showToast(
                    `You successfully became ${
                        mutationData.data.confirmTenantInvitationToken.name ??
                        ''
                    }'s manager.`
                );
            } else {
                void analytics?.track(
                    'Something went wrong. Please try again later.'
                );
                showToast('hello from the other side', ToastVariant.error);
            }

            setManagerInviteModalOpen(false);
        }
    });

    const [deleteTenantInvitationToken] = useMutation<
        BooleanResult,
        DeleteTenantInvitationTokenVars
    >(DELETE_TENANT_INVITATION_TOKEN, {
        errorPolicy: 'ignore',
        update: (cache, mutationData, options) => {
            if (mutationData.data) {
                const tenantInvitations =
                    cache.readQuery<TenantInvitationsData>({
                        query: TENANT_INVITATIONS
                    });

                cache.writeQuery({
                    query: TENANT_INVITATIONS,
                    data: {
                        tenantInvitations: {
                            fromUser:
                                tenantInvitations?.tenantInvitations?.fromUser,
                            toUser: tenantInvitations?.tenantInvitations?.toUser?.filter(
                                (item) =>
                                    item.invitationTokenId !==
                                    options.variables?.invitationTokenId
                            )
                        }
                    }
                });

                void analytics?.track(
                    'Tenant invitation token decline success'
                );

                showToast('Manager invitation declined.');
            } else {
                void analytics?.track('Tenant invitation token decline fail');

                showToast(
                    'Something went wrong. Please try again later.',
                    ToastVariant.error
                );
            }

            setManagerInviteModalOpen(false);
        }
    });

    useEffect(() => {
        if (
            !tenantInvitationsDataLoading &&
            tenantInvitationsData?.tenantInvitations?.toUser?.length
        ) {
            setManagerInviteModalOpen(true);
        }
    }, [tenantInvitationsDataLoading, tenantInvitationsData]);

    const firstInvite = useMemo(
        () =>
            tenantInvitationsData?.tenantInvitations?.toUser &&
            tenantInvitationsData?.tenantInvitations?.toUser[0],
        [tenantInvitationsData]
    );

    return (
        <>
            <ConfirmationModal
                isOpen={managerInviteModalOpen}
                setOpen={setManagerInviteModalOpen}
                title={`${
                    firstInvite?.userName ?? ''
                } is inviting you to become their manager.`}
                description="Accept this invite to see their data, metrics and overall performance."
                confirmButtonLabel="Accept"
                confirmButtonColor="primary"
                rejectButtonColor="errorSubtle"
                rejectButtonLabel="Decline"
                rejectCallback={() => {
                    void deleteTenantInvitationToken({
                        variables: {
                            invitationTokenId:
                                firstInvite?.invitationTokenId ?? ''
                        }
                    });
                }}
                confirmCallback={() => {
                    void confirmTenantInvitationToken({
                        variables: {
                            invitationTokenId:
                                firstInvite?.invitationTokenId ?? ''
                        }
                    });
                }}
            />
            {children}
        </>
    );
};

export default TenantInvitationsProvider;
