import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, {
    ReactNode,
    useCallback,
    useLayoutEffect,
    useRef,
    useState
} from 'react';

interface MobileTopMenuProps {
    children?: ReactNode;
}

const MobileTopMenu = ({ children }: MobileTopMenuProps): JSX.Element => {
    const theme = useTheme();
    const scrollPos = useRef<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const handleScroll = useCallback(() => {
        const newScrollPos = window.scrollY;

        if (newScrollPos === 0 || newScrollPos < scrollPos.current) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }

        scrollPos.current = newScrollPos;
    }, [setIsVisible]);

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: theme.mobileMenuHeight,
                transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
                transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.standard
                }),
                left: 0,
                width: '100%',
                zIndex: 'mobileTopMenu',
                height: '44px',
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
                overflow: 'hidden',
                backdropFilter: ['none', undefined, theme.blurs.elevation2],
                backgroundColor: [
                    alpha(theme.palette.baseCloud.blurFallback ?? '', 0.8),
                    undefined,
                    'baseCloud.transparent10'
                ]
            }}
        >
            {children}
        </Box>
    );
};

export default MobileTopMenu;
