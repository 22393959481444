import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
    Navigate,
    Outlet,
    useOutletContext,
    useParams
} from 'react-router-dom';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import StreamHeader from '../../components/StreamHeader';
import { DEMO_ID } from '../../configs';
import {
    StreamData,
    StreamDataTwitchTitle
} from '../../graphql/generated/graphqlTypesAnalytics';
import searchStreamDataByIdMock from '../../graphql/mocks/searchStreamDataById';
import streamChannelDataMock from '../../graphql/mocks/streamChannelData';
import SEARCH_STREAM_DATA_BY_ID, {
    SearchStreamDataById,
    SearchStreamDataByIdArgs
} from '../../graphql/queries/searchStreamDataById';
import STREAM_CHANNEL_DATA, {
    StreamChannelData,
    StreamChannelDataVars
} from '../../graphql/queries/streamChannelData';
import getColor from '../../helpers/getColor';
import getOverallStreamStatus, {
    OverallStreamStatus
} from '../../helpers/getOverallStreamStatus';
import useTenants from '../../hooks/useTenants';
import { streamsRoute } from '../../routes';
import baseTheme from '../../theme/baseTheme';
import { StreamDataTwitchGameWithColor, StreamParams } from '../../types';
import { TabNavConfig } from '../../types';

export const streamTabNavConfig: TabNavConfig[] = [
    {
        value: 'overview',
        label: 'Overview',
        hasFeatureFlag: true,
        defaultFlag: true,
        lockable: false,
        color: baseTheme.palette.accentYellow.dark
    },
    {
        value: 'engagement',
        label: 'Engagement',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: false,
        color: baseTheme.palette.accentPink.dark
    },
    {
        value: 'viewers',
        label: 'Viewers',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: true,
        color: baseTheme.palette.accentCyan.dark
    },
    {
        value: 'raids',
        label: 'Raids',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: false,
        color: baseTheme.palette.accentLavender.dark
    },
    {
        value: 'monetization',
        label: 'Monetization',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: false,
        isPremium: true,
        color: baseTheme.palette.accentTurquoise.dark
    },
    {
        value: 'vod-review',
        label: 'VOD Review',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: false,
        isNew: true,
        color: baseTheme.palette.accentYellow.dark
    },
    {
        value: 'top-clips',
        label: 'Top clips',
        hasFeatureFlag: true,
        defaultFlag: false,
        lockable: false,
        color: baseTheme.palette.accentYellow.dark
    }
];

interface OutletContext {
    streamData?: StreamData;
    playedGames: StreamDataTwitchGameWithColor[];
    playedGamesDemo: StreamDataTwitchGameWithColor[];
    titles?: StreamDataTwitchTitle[];
    titlesDemo?: StreamDataTwitchTitle[];
    uniquePlayedGames: StreamDataTwitchGameWithColor[];
    uniquePlayedGamesDemo: StreamDataTwitchGameWithColor[];
    overallStreamStatus?: OverallStreamStatus;
    isDemo?: boolean;
}

export const useStreamOutletContext = () => {
    return useOutletContext<OutletContext>();
};

function Stream(): JSX.Element {
    const { id } = useParams<StreamParams>();
    const {
        isManager,
        allTenantsWithManager,
        loading: tenantsLoading
    } = useTenants();

    const { loading: streamDataLoading, data: dataQuery } = useQuery<
        SearchStreamDataById,
        SearchStreamDataByIdArgs
    >(SEARCH_STREAM_DATA_BY_ID, {
        variables: {
            identifiers: [id ?? ''],
            includeRetentions: false,
            userProfileIds: isManager
                ? allTenantsWithManager?.map((item) => item.userProfileId) ?? []
                : []
        },
        skip: id === 'undefined' || !id || id === DEMO_ID
    });

    let streamData =
        dataQuery?.searchStreamData?.data &&
        dataQuery?.searchStreamData?.data[0];
    const isDemo =
        id === DEMO_ID ||
        getOverallStreamStatus(
            streamData?.streamDataProcessingStatus,
            streamData?.streamChatProcessingStatus
        ) === OverallStreamStatus.historicalData;

    if (isDemo) {
        [streamData] = searchStreamDataByIdMock.searchStreamData.data;
    }

    const { loading: streamChannelDataLoading, data: streamChannelDataQuery } =
        useQuery<StreamChannelData, StreamChannelDataVars>(
            STREAM_CHANNEL_DATA,
            {
                variables: {
                    streamDataId: streamData?.id || ''
                },
                skip: streamData?.id === undefined || isDemo
            }
        );

    const streamChannelData = isDemo
        ? streamChannelDataMock
        : streamChannelDataQuery;
    const games = streamChannelData?.streamChannelData.streamDataTwitchGames;
    const gamesDemo =
        streamChannelDataMock?.streamChannelData.streamDataTwitchGames;
    const titles = streamChannelData?.streamChannelData.streamDataTwitchTitles;
    const titlesDemo =
        streamChannelDataMock?.streamChannelData.streamDataTwitchTitles;

    const [
        playedGames,
        playedGamesDemo,
        uniquePlayedGames,
        uniquePlayedGamesDemo
    ] = useMemo(() => {
        let gamesWithColor: StreamDataTwitchGameWithColor[] = [];
        let gamesWithColorDemo: StreamDataTwitchGameWithColor[] = [];
        let uniqueGamesWithColor: StreamDataTwitchGameWithColor[] = [];
        let uniqueGamesWithColorDemo: StreamDataTwitchGameWithColor[] = [];

        if (games) {
            gamesWithColor = games.map((item, index) => ({
                ...item,
                color: getColor(index)
            }));

            gamesWithColor = gamesWithColor.map((item, index) => {
                const duplicateGames = gamesWithColor.filter(
                    (g, i) => item.twitchGameId === g.twitchGameId && index > i
                );

                if (duplicateGames.length) {
                    return {
                        ...item,
                        color: duplicateGames[0].color
                    };
                }

                return item;
            });

            uniqueGamesWithColor = gamesWithColor.filter(
                (item, pos) =>
                    gamesWithColor.findIndex(
                        (g) => item.twitchGameId === g.twitchGameId
                    ) === pos
            );
        }

        if (gamesDemo) {
            gamesWithColorDemo = gamesDemo.map((item, index) => ({
                ...item,
                color: getColor(index)
            }));

            gamesWithColorDemo = gamesWithColorDemo.map((item, index) => {
                const duplicateGames = gamesWithColorDemo.filter(
                    (g, i) => item.twitchGameId === g.twitchGameId && index > i
                );

                if (duplicateGames.length) {
                    return {
                        ...item,
                        color: duplicateGames[0].color
                    };
                }

                return item;
            });

            uniqueGamesWithColorDemo = gamesWithColorDemo.filter(
                (item, pos) =>
                    gamesWithColorDemo.findIndex(
                        (g) => item.twitchGameId === g.twitchGameId
                    ) === pos
            );
        }

        return [
            gamesWithColor,
            gamesWithColorDemo,
            uniqueGamesWithColor,
            uniqueGamesWithColorDemo
        ];
    }, [games, gamesDemo]);

    if (streamDataLoading || streamChannelDataLoading || tenantsLoading) {
        return <Loader />;
    }

    if (!streamData && !isDemo) return <Navigate to={streamsRoute()} />;

    return (
        <>
            <StreamHeader
                streamData={streamData}
                streamChannelData={streamChannelData?.streamChannelData}
                isDemo={isDemo}
            />
            <Layout>
                <Outlet
                    context={{
                        streamData,
                        playedGames,
                        playedGamesDemo,
                        uniquePlayedGames,
                        uniquePlayedGamesDemo,
                        titles,
                        titlesDemo,
                        overallStreamStatus: getOverallStreamStatus(
                            streamData?.streamDataProcessingStatus,
                            streamData?.streamChatProcessingStatus
                        ),
                        isDemo
                    }}
                />
            </Layout>
        </>
    );
}

export default Stream;
