import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import {
    sectionVisitedGrowthKey,
    storageSet,
    StorageType
} from '../../helpers/storage';

export const growthTabNavConfig = [
    {
        value: 'creator-dashboard',
        label: 'Creator Dashboard'
    },
    {
        value: 'twitch',
        label: 'Twitch',
        isNew: true
    }
];

function Growth(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const handleSectionVisited = async () => {
            await storageSet<boolean>(
                sectionVisitedGrowthKey,
                true,
                StorageType.local
            );
        };

        void handleSectionVisited();
    }, []);

    return (
        <>
            {!isMobile && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={growthTabNavConfig}
                    />
                </TopMenu>
            )}
            <Outlet />
        </>
    );
}

export default Growth;
