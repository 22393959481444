import createTheme from '@mui/material/styles/createTheme';

import breakpoints from './breakpoints';
import palette from './palette';

const baseTheme = createTheme({
    spacing: 4,
    palette,
    breakpoints,
    typography: {
        htmlFontSize: 10,
        fontFamily: [
            'Silka',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    shape: {
        borderRadius: 3
    },
    blurs: {
        none: 'blur(0px)',
        lightest: 'blur(2px)',
        elevation0: 'blur(24px)',
        elevation1: 'blur(48px)',
        elevation2: 'blur(64px)',
        elevation3: 'blur(96px)'
    },
    menuWidth: '80px',
    mobileMenuHeight: '56px'
});

export default baseTheme;
