import 'simplebar/dist/simplebar.min.css';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';

import { ResponsiveValue } from '../types';

interface ScrollbarSmallProps {
    height?: ResponsiveValue;
    maxHeight?: ResponsiveValue;
    hasBottomGradient?: boolean;
    hasTopGradient?: boolean;
    scrollbarType?: 'normal' | 'thick';
    children?: ReactNode;
}

const ScrollbarSmall = ({
    height,
    maxHeight = 256,
    hasBottomGradient = true,
    hasTopGradient = false,
    scrollbarType = 'normal',
    children
}: ScrollbarSmallProps): JSX.Element => {
    const theme = useTheme();
    const ref = useRef<SimpleBar>(null);
    const [isScrolledToStart, setIsScrolledToStart] = useState<boolean>(true);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState<boolean>(false);

    const recalculateScrollPosition = useCallback(() => {
        const scrollContainer = ref.current?.getScrollElement();

        if (scrollContainer) {
            const start = scrollContainer.scrollTop === 0;
            const end =
                Math.round(
                    scrollContainer.scrollTop + scrollContainer.clientHeight
                ) === scrollContainer.scrollHeight;

            if (start && !isScrolledToStart) {
                setIsScrolledToStart(true);
            } else if (!start && isScrolledToStart) {
                setIsScrolledToStart(false);
            }

            if (end && !isScrolledToEnd) {
                setIsScrolledToEnd(true);
            } else if (!end && isScrolledToEnd) {
                setIsScrolledToEnd(false);
            }
        }
    }, [isScrolledToStart, isScrolledToEnd]);

    useEffect(() => {
        const scrollbarInstance = ref.current;

        scrollbarInstance
            ?.getScrollElement()
            .addEventListener('scroll', recalculateScrollPosition);

        return () => {
            scrollbarInstance
                ?.getScrollElement()
                .removeEventListener('scroll', recalculateScrollPosition);
        };
    }, [recalculateScrollPosition]);

    return (
        <Box
            sx={{
                '& .simplebar-track.simplebar-vertical': {
                    width: scrollbarType === 'normal' ? '4px' : '6px'
                },
                '& .simplebar-track.simplebar-vertical:before': {
                    content: "''",
                    position: 'absolute',
                    width: scrollbarType === 'normal' ? '4px' : '6px',
                    height: '100%',
                    borderRadius: scrollbarType === 'normal' ? '2px' : '3px',
                    backgroundColor: 'baseCloud.transparent20'
                },
                '& .simplebar-scrollbar.simplebar-visible:before': {
                    backgroundColor: 'baseCloud.main',
                    borderRadius: scrollbarType === 'normal' ? '2px' : '3px',
                    width: scrollbarType === 'normal' ? '4px' : '6px',
                    opacity: 1,
                    left: 0,
                    top: '0px !important',
                    bottom: '0px !important'
                },
                '& .simplebar-wrapper': {
                    maskImage:
                        hasBottomGradient && !isScrolledToEnd
                            ? `linear-gradient(180deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 24px), transparent 100%)`
                            : 'none'
                },
                '& .simplebar-content-wrapper': {
                    pr: 3,
                    maskImage:
                        hasTopGradient && !isScrolledToStart
                            ? `linear-gradient(0deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} calc(100% - 24px), transparent 100%)`
                            : 'none'
                }
            }}
        >
            <Box
                ref={ref}
                component={SimpleBar}
                sx={{
                    height,
                    maxHeight,
                    overflowX: 'hidden'
                }}
                autoHide={false}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ScrollbarSmall;
