import { Action, ActionTypes, ModalState } from './index';

export const setLeftMenuOpen = (isOpen: boolean): Action => ({
    type: ActionTypes.SET_LEFT_MENU_OPEN,
    payload: isOpen
});

export const setModalState = (state: ModalState): Action => ({
    type: ActionTypes.SET_MODAL_STATE,
    payload: state
});

export const setOnboardingStep = (step: number): Action => ({
    type: ActionTypes.SET_ONBOARDING_STEP,
    payload: step
});

export const setSlobsToken = (token: string | undefined): Action => ({
    type: ActionTypes.SET_SLOBS_TOKEN,
    payload: token
});

export const setStreamScrollPos = (pos: number): Action => ({
    type: ActionTypes.SET_STREAM_SCROLL_POS,
    payload: pos
});

export const setUserCountry = (country: string): Action => ({
    type: ActionTypes.SET_USER_COUNTRY,
    payload: country
});

export const setIsDeveloperView = (isDeveloperView: boolean): Action => ({
    type: ActionTypes.SET_IS_DEVELOPER_VIEW,
    payload: isDeveloperView
});
