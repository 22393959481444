import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import TabNav from '../../components/TabNav';
import TopMenu from '../../components/TopMenu';
import {
    sectionVisitedWhatToStreamKey,
    storageSet,
    StorageType
} from '../../helpers/storage';
import useFeatureFlags from '../../hooks/useFeatureFlags';

export const whatToStreamTabNavConfig = [
    {
        value: 'discover-new-games',
        label: 'Discover new games',
        hasFeatureFlag: true
    },
    {
        value: 'game-analytics',
        label: 'Game analytics'
    }
];

function WhatToStream(): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { isEnabled } = useFeatureFlags();

    useEffect(() => {
        const handleSectionVisited = async () => {
            await storageSet<boolean>(
                sectionVisitedWhatToStreamKey,
                true,
                StorageType.local
            );
        };

        void handleSectionVisited();
    }, []);

    return (
        <>
            {!isMobile && isEnabled('app.tabs.discover-new-games', true) && (
                <TopMenu>
                    <TabNav
                        indicatorColor={theme.palette.accentYellow.dark}
                        config={whatToStreamTabNavConfig}
                    />
                </TopMenu>
            )}

            <Outlet />
        </>
    );
}

export default WhatToStream;
