import baseTheme from '../baseTheme';

const MuiTooltip = {
    defaultProps: {
        enterTouchDelay: 50,
        leaveTouchDelay: 6000
    },
    styleOverrides: {
        tooltip: {
            boxShadow: 'none',
            borderRadius: baseTheme.spacing(2),
            padding: baseTheme.spacing(3),
            fontSize: '1.4rem',
            lineHeight: '2rem',
            paddingLeft: baseTheme.spacing(3),
            paddingRight: baseTheme.spacing(3),
            paddingTop: baseTheme.spacing(2),
            paddingBottom: baseTheme.spacing(2),
            background: `linear-gradient(${
                baseTheme.palette.baseCloud.transparent20 ?? ''
            }, ${
                baseTheme.palette.baseCloud.transparent20 ?? ''
            }), linear-gradient(${baseTheme.palette.baseInk.main}, ${
                baseTheme.palette.baseInk.main
            })`
        },
        arrow: {
            '&:before': {
                background: `linear-gradient(${
                    baseTheme.palette.baseCloud.transparent20 ?? ''
                }, ${
                    baseTheme.palette.baseCloud.transparent20 ?? ''
                }), linear-gradient(${baseTheme.palette.baseInk.main}, ${
                    baseTheme.palette.baseInk.main
                })`
            }
        }
    }
};

export default MuiTooltip;
